import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Group91 from "../../assets/icons/Group91.png";
import editclientlogo from "../../assets/icons/editclientlogo.svg";
import "../../styles/local/addclient.css";
import Group62 from "../../assets/icons/Group62.svg";
import addclient from "../../assets/icons/addclient.svg";
import { addClient } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import DiscardAlert from "../../components/dialogs/Discard";

let c = 0;

const AddClient = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ show, setShow ] = useState([ { contact: "", id: 1 } ]);
  const [ display, setDisplay ] = useState([ { contact: "", id: 1 } ]);
  const UserId = window.sessionStorage.getItem("userid");
  const companyId = window.sessionStorage.getItem("compId");
  const [ Name, setName ] = useState("");
  const [ contactCount, setContactCount ] = useState(1);
  const [ address1, setAddress1 ] = useState("");
  const [ address2, setAddress2 ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const [ notes, setNotes ] = useState("");

  const [ contactNumber, setContactNumber ] = useState("");
  const [ contactName, setContactName ] = useState("");
  const [ contactPhoneNumber, setContactPhoneNumber ] = useState("");
  const [ contactEmail, setContactEmail ] = useState("");
  const [ emailInvalid, setEmailInvalid ] = useState(false);
  const [ emailInvalid2, setEmailInvalid2 ] = useState(false);
  const fetchclient = useSelector((state) => state.user.addClient);



  const [ contacts, setContacts ] = useState([
    {
      name: "",
      phonenumber: "",
      email: "",
      companyid: companyId,
    },
  ]);
  const handleChange = (id, field, val) => {
    const modifiedContacts = contacts;
    modifiedContacts[ id ][ field ] = val;

    setContacts(modifiedContacts);
  };

  const handleClick = () => {
    setShow([ ...show, { contact: "", id: contactCount + 1 } ]);
    setContactCount(contactCount + 1);
    setContactNumber(contactNumber + 1);

    const modifiedContacts = contacts;

    modifiedContacts.push({
      name: "",
      phonenumber: "",
      email: "",
      companyid: companyId,
    });

    setContacts(modifiedContacts);
  };

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function hasNonBlankValidEmail(contacts) {
    for (const contact of contacts) {
      if (!contact.email || !isValidEmail(contact.email)) {
        return false; // Return false if the email is blank or invalid
      }
    }
    return true; // Return true if all emails are non-blank and valid
  }

  const handleAdd = (e) => {

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isAllContactValidate = hasNonBlankValidEmail(contacts);

    if (isAllContactValidate) {

      if (!re.test(email)) {
        setEmailInvalid(true);
      }

      // else if (isAllContactValidate) {
      //   setEmailInvalid2(true);
      // }

      else {

        dispatch(
          addClient(
            UserId,
            Name,
            address1,
            address2,
            email,
            phoneNumber,
            notes,
            contacts.filter(({ name, phonenumber, email }) => !(name === "" && phonenumber === "" && email === ""))
          )
        );
        setTimeout(() => {
          history.push("/client");
          window.location.reload();
        }, 1000);

        e.preventDefault();
      }
    }
  };
  useEffect(() => {
    setContacts(contacts);
  }, [ contacts ]);

  useEffect(() => {
    setDisplay(show);
  }, [ show ]);

  const [ cancel, setCance ] = useState(false);
  const [ msg, setMsg ] = useState("");
  const handleCancelBtn = () => {
    setCance(true);
    setMsg("The entered data will not be saved. Do you want to cancel adding new client?");
  };

  return (
    <>
      <Header />

      {cancel && (
        <DiscardAlert
          // msg={msg}
          msg=<p style={{ paddingTop: "33px" }}>{msg}</p>
          handleCancel={() => {
            setCance(false);
          }}
          handleOk={() => {
            history.push("/client");
          }}
        />
      )}

      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-4">
            <h3 className="py-3 ps-2 themecolor header-title-sk">Clients</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-11 custCardCount">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5 className="my-4  text-sm-start text-center">
                    {/* comment this class above line <h5> tag - "fntSupp2" */}
                    <span className="me-4">
                      <img src={editclientlogo} className="addclienicon" />
                    </span>
                    <span className="client">Clients</span>
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <div className="row ms-sm-4 me-sm-2 px-5">
                      <h3 className="mt-5 ps-4 fntSupp1">Client Name</h3>
                      <input
                        className=" form-control suppInput mt-2 ms-3 fntSupp4"
                        onChange={(e, value) => setName(e.target.value)}
                        type="text"
                      />

                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName neww asc mt-2  mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">
                              Street, House number
                            </span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => setAddress1(e.target.value)}
                          />
                        </div>
                        <div className=" box-contactName neww mt-2 mt-2 mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">
                              Postal ZIP Code, Place
                            </span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => setAddress2(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName new11 asc1 mt-2 mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">E-mail</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => {
                              setEmail(e.target.value)
                              setEmailInvalid(false);
                            }}
                          />
                        </div>

                        <div className="mt-2 ps-3 mt-xl-1">
                          {emailInvalid == true && (
                            <div className="ps-4" style={{ color: "red", fontSize: "14px" }}>
                              Email is invalid
                            </div>
                          )}
                        </div>

                        <div className=" box-contactName new11 mt-2 mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">Telephone</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {contacts.map((item, index) => {

                      const validEmail = new RegExp(
                        "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
                      )

                      return (
                        <div
                          key={index}
                          // key={item.phonenumber}
                          className="row ms-sm-4 me-sm-2 px-5"
                        >
                          <div className="col-12 col-sm-9">
                            <h3 className="mt-4 pt-4 ps-2 fntSupp1">
                              Contact Name
                            </h3>
                          </div>

                          <div className="col-12 col-sm-3 text-sm-end mt-sm-5 pt-sm-1 ">
                            {contacts.length - 1 === index ? (
                              <span
                                className="fntSupp1"
                                onClick={handleClick}
                                style={{ cursor: "pointer", fontSize: "14px" }}
                              >
                                Add...
                              </span>
                            ) : (
                              <img
                                alt=""
                                src={Group62}
                                onClick={() => {
                                  let data = [ ...contacts ];
                                  data.splice(index, 1);
                                  setContacts(data);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </div>

                          <input
                            className=" form-control suppInput mt-2 ms-3 fntSupp4"
                            type="text"
                            onChange={(e) => {
                              handleChange(index, "name", e.target.value);
                              setContactName(e.target.value);
                            }}
                            value={item.name || ""}
                          // defaultValue={item.name}
                          />

                          <div className="col-12 col-lg-6">
                            <div className=" box-contactName addclient mt-2 mt-xl-1">
                              <div className="d-flex align-items-start">
                                <span className="fntSuppclients">Telephone</span>
                              </div>

                              <input
                                type="text"
                                className="fntSupp4"
                                onChange={(e) => {
                                  handleChange(
                                    index,
                                    "phonenumber",
                                    e.target.value
                                  );
                                  setContactPhoneNumber(e.target.value);
                                }}
                                value={item.phonenumber || ""}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className=" box-contactName new33 mt-2 mt-xl-1">
                              <div className="d-flex align-items-start">
                                <span className="fntSuppclient">E-mail</span>
                              </div>

                              <input
                                type="text"
                                className="fntSupp4"
                                onChange={(e) => {
                                  handleChange(index, "email", e.target.value);
                                  setContactEmail(e.target.value);
                                  setEmailInvalid2(false);
                                }}
                                value={item.email || ""}
                              />
                            </div>

                            <div className="mt-2 ps-3 mt-xl-1">
                              {/* {emailInvalid2 == true && (
                                <div className="ps-" style={{ color: "red", fontSize: "14px" }}>
                                  Email is invalid
                                </div>
                              )} */}

                              {!validEmail?.test(item.email) && item.email ?
                                <div className="ps-" style={{ color: "red", fontSize: "14px" }}>
                                  Email is invalid
                                </div> : ""}

                            </div>

                          </div>
                        </div>
                      )

                    })}
                  </div>

                  <div className="col-12 col-lg-5">
                    <div className="row ps-4 ps-lg-0">
                      <h3 className="mt-5 fntSupp3">Notes</h3>
                      <div className="pe-5">
                        <textarea
                          // style={{ border: "1px solid #ced4da" }}
                          className="form-control notesForm2 mt-1 fntSupp4"
                          onChange={(e) => setNotes(e.target.value)}
                          rows="14"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4 ">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 mt-5 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-4 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc cancel2 w-100 h-100 p-2"
                          // onClick={() => history.push("/client")}
                          onClick={handleCancelBtn}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            Name == "" ||
                              address1 == "" ||
                              address2 == "" ||
                              email == "" ||
                              phoneNumber == "" ||
                              contacts[ 0 ].name == "" ||
                              contacts[ 0 ].phonenumber == "" ||
                              contacts[ 0 ].email == "" ||
                              contacts[ 0 ].companyid == ""
                              ? // notes == ""
                              "countBtnchange1 w-100 p-2"
                              : "countBtnchange w-100 p-2"
                          }
                          onClick={(e) => {
                            handleAdd(e);
                          }}
                          disabled={
                            Name == "" ||
                            address1 == "" ||
                            address2 == "" ||
                            email == "" ||
                            phoneNumber == "" ||
                            contacts[ 0 ].name == "" ||
                            contacts[ 0 ].phonenumber == "" ||
                            contacts[ 0 ].email == "" ||
                            contacts[ 0 ].companyid == ""
                          }
                        >
                          ADD
                        </button>
                      </div>
                      <div className="row me-5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClient;
