import Header from "../../components/Header";
import "../../styles/local/addProducts.css";
import producticon from "../../assets/icons/producticon.svg";
import Group62 from "../../assets/icons/Group62.svg";
import { useEffect, useState, useRef } from "react";
import {
  fetchProductStock,
  fetchPartSuggest,
  CreatePart,
  fetchSuppliers,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";


import React from "react";
import { Link, useHistory } from "react-router-dom";
import Cancel from "../../components/dialogs/Cancel";
import Select from "react-select";
import { useQuery } from "../../utils/appHooks";
import { Button } from "react-bootstrap";
import EuropeanInput from "../../utils/EuropeanInput";

const AddProducts = () => {
  const refNewStandardWageInput = useRef(null);
  const selectRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const UserId = window.sessionStorage.getItem("userid");
  const [pagenumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(false);
  const fetchproductstock = useSelector(
    (state) => state.user.fetchProductStock
  );
  const createpart = useSelector((state) => state.user.CreatePart);
  const suggestPart = useSelector((state) => state.user.suggestPart);
  const initialRender = React.useRef(true);
  // const [productId, setProductId] = useState("");
  // const [contact, setContact] = useState([]);
  const [partName, setPartName] = useState("");
  const [partname, setPartname] = useState("");
  const [partid, setPartid] = useState("");

  // const [productdescription, setProductdescription] = useState("zero");
  const [internalid, setInternalid] = useState("");
  // const [totalcurrentquantity, setTotalcurrentquantity] = useState(0);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [supplierid, setSupplierid] = useState("");
  const [suppliercode, setSuppliercode] = useState("");
  const [unitprice, setUnitprice] = useState("");
  const [leadtime, setLeadtime] = useState("");
  const [instock, setInstock] = useState("");
  const [partList, setPartList] = useState([]);
  const [suppliername, setSuppliername] = useState("");
  const fetchsuppliers = useSelector((state) => state.user.fetchSuppliers);
  const [customDrop, setCustomDrop] = useState(false);
  const [showError, setShowError] = useState(false);
  const [partarrow, setPartarrow] = useState("");
  const [partarrow2, setPartarrow2] = useState("");
  const queryParams = useQuery();
  //  const productparts = [supplierid, suppliercode, unitprice, leadtime];
  // contact.forEach((item) => productparts.push({ supplierid: item }));


  useEffect(() => {
    dispatch(fetchSuppliers());
    // setIsCalled(false);
    // setIsCalled1(false);
  }, []);


  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchsuppliers != null) {
        if (fetchsuppliers.status == true) {
          setData(fetchsuppliers.data);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchsuppliers]);

  /////// Response from product.js screen for internal id , start /////////

  const [data2, setData2] = useState([]);

  React.useEffect(() => {
    if (fetchproductstock != null) {
      if (fetchproductstock.status == true) {
        setData2(fetchproductstock.data);
      }
    }
    else {
      initialRender.current = false;
    }
  }, [fetchproductstock]);

  useEffect(() => {
    // dispatch(fetchProductStock(pagenumber));
    // BELOW FOR GETING ALL INTERNAL ID not only according to the pagenumber
    dispatch(fetchProductStock());
  }, []);

  console.log("data2", data2)

  ///////// Response from product.js screen internal id , end /////////

  ///////// for suggest part start ////////

  const initialRender2 = React.useRef(true);
  const [suggestPartData, setSuggestPartData] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    dispatch(fetchPartSuggest(partname));
  }, [partname]);

  useEffect(() => {
    if (!initialRender2.current) {
      if (suggestPart != null) {
        if (suggestPart.status == true) {
          setSuggestPartData(suggestPart.data);
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [suggestPart]);

  // console.log("SuggestedData", suggestPartData)

  const arrowupDownPart2 = () => {
    setPartarrow2((prev) => !prev);
  };

  useEffect(() => {
    const isCon = queryParams.get("ap")
    if (isCon === "ap-form") {
      const obj = window.sessionStorage.getItem("ap-form");
      const jsnObj = JSON.parse(obj);
      setPartid(jsnObj.partid);
      setPartname(jsnObj.partname);
      setInternalid(jsnObj.internalid);
      setInstock(jsnObj.instock);
      setMin(jsnObj.min);
      setMax(jsnObj.max);
      setPartList(jsnObj.partList ?? []);
      setShow(false);
    }
  }, [])

  const handleCreatePartClick = () => {
    const currentObj = {
      partid,
      partname,
      internalid,
      instock,
      min,
      max,
      partList
    }
    window.sessionStorage.setItem("ap-form", JSON.stringify(currentObj));
    history.replace({ pathname: history.location.pathname, search: "?ap=ap-form" })
    history.push({ pathname: "/add-supplier" })
  }

  const onChangeHandler2 = (e) => {
    if (e != null) {
      setPartid(e.partid);
      setPartname(e.partname);
      setShow(false);
      setCustomDrop(false)
    }
  };

  ////////// for suggest part end /////////

  ////////// for internalid validation start //////

  const onChangeInternalid = (e) => {
    // if (e != "") {
    //   let isExited = data2.findIndex((item) => item.internalid == e)
    //   if (isExited !== -1) {
    //     setShowError(true)
    //   } else {
    //     setShowError(false)
    //   }
    // } else {
    //   setShowError(false)
    // }


    // let isValid = data2.findIndex(item => item.internalid === e)
    ///// OR WE CAN DO THIS ALSO LIKE ABOVE /////
    let isValid = data2.findIndex((item) => item.internalid === e)

    if (isValid !== -1) {
      setShowError(true)
    } else {
      setShowError(false)
    }

  };

  //////// for internalid validation end /////////

  const handleAdd = (e) => {
    dispatch(
      CreatePart(UserId, partname, internalid, instock, max, min, partList)
    );
    setTimeout(() => {
      history.goBack();
      // window.location.reload();
    }, 1000);


    e.preventDefault();
  };




  // const partsuppliers = [
  //   {
  //     supplierid: supplierid,
  //     suppliercode: suppliercode,
  //     unitprice: unitprice,
  //     leadtime: leadtime,
  //   },
  // ];
  // contact.forEach((item) =>
  //   partsuppliers.push({
  //     supplierid: item.supplierid,
  //     suppliercode: item.suppliercode,
  //     unitprice: item.unitprice,
  //     leadtime: item.leadtime,
  //   })
  // );


  const handelonclick = (supplierid) => {
    // setSupplierid(supplierid);
  };

  const toggleSelect = (i) => {
    setCustomDrop(true)
  };

  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };

  const onChangeHandler = (e) => {
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[ index ];
    // const option = el.getAttribute("id");
    // setSupplierid(option);
    // setSuppliername(el.innerText);
    if (e != null) {
      setSupplierid(e.supplierid);
      setSuppliername(e.name);
      setCustomDrop(false)
    }
  };


  const handleAddPart = () => {

    setPartList([
      ...partList,
      { suppliername, suppliercode, unitprice, leadtime, supplierid },
    ]);
    setSupplierid("");
    setSuppliername("");
    setSuppliercode("");
    setUnitprice("");
    setLeadtime("");
    selectRef.current.clearValue()
  };


  // const handleClick = (index) => {
  //   const optionsCopy = [...data];
  //   optionsCopy[index].disabled = true;
  //   setData(optionsCopy);
  // };


  // const handleClick = (index) => {
  //   const optionsCopy = [...data];
  //   if (optionsCopy[index] !== optionsCopy[index]) {
  //     optionsCopy[index].disabled = true;
  //   } else {
  //     optionsCopy[index].disabled = false;
  //   }


  //   setData(optionsCopy);
  // };


  return (
    <>
      {cancel && (
        <Cancel
          handleCancel={() => {
            setCancel(false);
          }}
          handleOk={() => {
            // history.push("/products");
            history.goBack();
          }}
        />
      )}
      <Header />
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-4 pb-5">
            <h3 className="py-3 ps-2 themecolor header-title-sk">  Products & Parts</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-12 custCardCount p-0 shadow">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5
                    className="py-4 cntStockfont"
                    style={{ fontSize: "22px" }}
                  >
                    <span className="me-4">
                      <img className="countProicon" src={producticon} />
                    </span>
                    Product/Part
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row ps-5 ">
                  <div className="col-11">
                    <div className="row">
                      <div className="col-xl-7 col-12 pt-5">
                        <div className="row">
                          <h5 className=" cntStockfont">
                            <span className="me-4 ">
                              <img className="countProicon" src={producticon} />
                            </span>
                            Part Name
                          </h5>
                        </div>
                        <div className="row ps-xl-5 pe-3 pe-xl-0 ms-1 ">
                          {/* <input
                            className=" form-control suppInput fntSupp4"
                            style={{ height: "51px", background: "#fafafa" }}
                            onChange={(e) => {
                              setShow(true);
                              setPartname(e.target.value);
                            }}
                            type="text"
                            value={partname}
                           />
                           {suggestPartData != null && show && suggestPartData.map((item) => {
                            return (
                              <>
                                <ul className="list-group">
                                  <li className="list-group ps-2"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className=""
                                      onClick={() => {
                                        setPartname(item.partname)
                                        setShow(false)
                                      }}
                                    >
                                      {item.partname}
                                    </div>
                                    <div className="custom-select-selected" > {item.partid}</div>
                                    <div className="custom-select-selected" > {item.internalid}</div>

                                  </li>
                                </ul>
                              </>
                            );
                          })} */}

                          {/* New Select start */}

                          <div className="custom-select-box custom-with-input"
                            style={{ position: "absolute", width: "47.4%" }}
                          >
                            {/* {!customDrop ? <div className="custom-select-selected" onClick={() => toggleSelect(null)} > {partname ? partname : "Select..."}</div> : ""} */}

                            <input
                              className=" form-control suppInput fntSupp4"
                              style={{ height: "48px" }}
                              placeholder="Type a Part name"
                              onChange={(e) => {
                                setShow(true);
                                setPartname(e.target.value);
                              }}
                              type="text"
                              value={partname}
                            />

                            {show ?
                              <div className={`custom-select-outer`}>
                                {suggestPartData != null && suggestPartData.map((item, index) => {
                                  return (
                                    <>
                                      <div className={`list-select-box ${item.partid === partid ? 'active' : ""}`} key={index} id={item.partid} onClick={() => onChangeHandler2(item)}>
                                        {item.partname}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                              : ""}
                          </div>

                          {/* New Select end */}

                        </div>

                      </div>

                      {/* {suggestPartData != null && suggestPartData.map((item) => {
                        return (
                          <>
                            <div className="row ps-xl-5 pe-5 pe-xl-0 ms-1 mb-2 mt-xl-0">
                              <div className="custom-select-box" >
                                <div className="custom-select-selected" > {item.partid}</div>
                                <div className="custom-select-selected" > {item.partname}</div>
                                <div className="custom-select-selected" > {item.internalid}</div>
                              </div>
                            </div>
                          </>
                        );
                      })} */}

                      <div className="col-xl-2 col-md-6  text-center">
                        <div className="row text-center">
                          <div className="col-md-12 pt-xl-5 mt-xl-2 mt-4 ps-md-4 pe-4 pe-xl-2">
                            <div className="addProductFnt mt-xl-2 pt-xl-1 mb-1">
                              Internal ID
                            </div>
                            <input
                              className=" form-control suppInput1 fntSupp4 mt-xl-2 centretext"
                              style={{ background: "#fafafa" }}
                              value={internalid}
                              onChange={(e) => {
                                setInternalid(e.target.value);
                                onChangeInternalid(e.target.value);
                              }}
                              type="text"
                            />

                            <p className="text-danger">
                              {showError ? "This ID already exists" : ""}
                            </p>

                          </div>
                        </div>
                      </div>
                      <div className="col-3 col-xl-3 col-md-6 col-12">
                        <div className="row  text-center">
                          <div className="col-xl-4 col-md-4 col-sm-4 col-6 pt-xl-5 mt-xl-1 mt-4  text-center">
                            <div className="addProductFnt mt-xl-2 pt-xl-2 mb-1">
                              In Stock
                            </div>
                            <EuropeanInput
                              hidePreview={true}
                              value={instock}
                              onChange={(value) => setInstock(value)}
                              withoutSymbol={true}
                              className="form-control suppInput1 fntSupp4 mt-xl-2 centretext"
                              style={{ background: "#fafafa" }}
                            />
                          </div>
                          <div className="col-xl-4 col-md-4 col-sm-4 col-6 pt-xl-5 mt-xl-1 mt-4 pe-4 pe-md-2 text-center">
                            <div className="addProductFnt mt-xl-2 pt-xl-2 mb-1">
                              MIN
                            </div>

                            <input
                              className=" form-control suppInput1 fntSupp4 mt-xl-2 centretext"
                              style={{ background: "#fafafa" }}
                              onChange={(e) => setMin(e.target.value)}
                              type="number"
                              value={min}
                              min={0}
                              onkeypress="return event.charCode >= 48"
                            />
                          </div>
                          <div className="col-xl-4 col-md-4 col-sm-4 col-12 pt-xl-5 mt-xl-1 mt-4 pe-4 pe-md-2 text-center">
                            <div className="addProductFnt mt-xl-2 pt-xl-2 mb-1">
                              MAX
                            </div>
                            <input
                              className=" form-control suppInput1 fntSupp4 mt-xl-2 centretext"
                              style={{ background: "#fafafa" }}
                              onChange={(e) => setMax(e.target.value)}
                              type="number"
                              value={max}
                              min={0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>


                <div className="row ps-5 ">
                  <div className="col-xl-11">
                    <div className="row">
                      <div className="col-xl-7 pt-5">
                        <div className="row">
                          <div className="col-md-8 col-sm-7 col-12">
                            <h5 className=" cntStockfont">
                              <span className="me-4 ">
                                <img
                                  className="countProicon"
                                  src={producticon}
                                />
                              </span>
                              Supplier
                            </h5>
                          </div>
                          <div className="col-md-4 col-sm-5 col-12 text-sm-end addProductFnt2 pe-5 pe-xl-0 pt-1
                          ">
                            <Button
                              variant="ghost"
                              onClick={handleCreatePartClick}
                              style={{
                                color: "#80cfe6",
                              }}
                            >
                              {" "}
                              (+) Create Supplier
                            </Button>
                          </div>
                        </div>


                        {partList.map((item) => {
                          return (
                            <>
                              <div className="row ps-xl-5 pe-5 pe-xl-0 ms-1 mb-2 mt-xl-0 slt-target">

                                <div className="custom-select-box" >
                                  <div className="custom-select-selected" > {item.suppliername}</div>
                                </div>
                              </div>
                            </>
                          );
                        })}

                        <div className="row ps-xl-5 pe-5 pe-xl-0 ms-1 mt-2 mt-xl-0 sup12"
                          style={{ position: "relative" }}
                          onClick={arrowupDownPart}
                        >


                          <Select

                            ref={selectRef}
                            classNamePrefix="sk-custom-select"
                            className={
                              partarrow == "" ? "sk-custom-container-prod  padding-height" : "ArrowUpDownpur sk-custom-container-prod  padding-height"
                            }
                            placeholder={"Type a Supplier name"}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={data}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.supplierid}
                            onChange={(value) => onChangeHandler(value)}

                          />
                        </div>
                      </div>
                      <div className="col-xl-2 col-md-5 col-12 pt-xl-5 mt-xl-1  mt-xxl-0 mt-4 ps-lg-4 pe-5 pe-md-2 text-center">
                        <div className="addProductFnt1 mb-3 pt-2 pb-xl-2 pb-1 pb-xxl-1">
                          Supplier Code
                        </div>
                        <div className="mt-2">
                          {partList.map((item) => {
                            return (
                              <>
                                <input
                                  className=" form-control suppInput1 fntSupp4 mt-2"
                                  style={{
                                    textAlign: "center",
                                    background: "#fafafa",
                                  }}
                                  value={item.suppliercode}
                                  type="text"
                                />
                              </>
                            );
                          })}
                        </div>
                        <div className="mt-2">
                          <input
                            className=" form-control suppInput1 fntSupp4 mt-2"
                            onChange={(e) => setSuppliercode(e.target.value)}
                            style={{ textAlign: "center", background: "#fafafa" }}
                            type="text"
                            value={suppliercode}
                          />
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-7 col-12 pe-5 pe-xl-0">
                        <div className="row">
                          <div className="col-sm-5 col-6 pt-xl-5 mt-xl-1 mt-xxl-0 mt-4  text-center">
                            <div className="addProductFnt1 mb-3 pt-2 pb-1 pb-xl-2  pb-xxl-1">
                              Unit Price
                            </div>
                            {partList.map((item) => {
                              return (
                                <>
                                  <EuropeanInput
                                    key={'add-part-1'}
                                    className=" form-control suppInput1 fntSupp4 mb-2"
                                    style={{
                                      textAlign: "center",
                                      background: "#fafafa",
                                    }}
                                    value={item.unitprice}
                                    min={0}
                                  />

                                </>
                              );
                            })}
                            <EuropeanInput
                              key={'add-part-2'}
                              className=" form-control suppInput1 fntSupp4 mb-2"
                              style={{
                                textAlign: "center",
                                background: "#fafafa",
                              }}
                              onChange={(newValue) => setUnitprice(newValue)}
                              value={unitprice}
                              min={0}
                            />

                          </div >
                          <div className="col-sm-7 col-6 pt-xl-5 mt-xl-1 mt-4 mt-xxl-0 text-center">
                            <div className="addProductFnt1 mb-3 pt-2 pb-xl-2 pb-1 pb-xxl-1">
                              Lead time
                            </div>
                            {partList.map((item) => {
                              return (
                                <>
                                  <input
                                    className=" form-control suppInput1 fntSupp4 mt-2  "
                                    style={{
                                      textAlign: "center",
                                      background: "#fafafa",
                                    }}
                                    value={item.leadtime}
                                    type="number"
                                    min={0}
                                  />
                                </>
                              );
                            })}
                            <input
                              className=" form-control suppInput1 fntSupp4 mt-2"
                              style={{
                                textAlign: "center",
                                background: "#fafafa",
                              }}
                              onChange={(e) => setLeadtime(e.target.value)}
                              value={leadtime}
                              type="number"
                              min={0}
                            />
                          </div>
                        </div >
                      </div >
                    </div >
                  </div >
                  <div className="col-xl-1 text-start pt-xl-5 mt-xl-3 ps-4 ps-xl-3">
                    <div className="row">
                      {partList.map((item, index) => {
                        return (
                          <>
                            <div
                              className="mt-2 addProductFnt3 addProductFnt4New"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={Group62}
                                className="icons pt-1"
                                alt="dlt"
                                onClick={() => {
                                  let data = [...partList];
                                  data.splice(index, 1);
                                  setPartList(data);
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                      <div
                        className="mt-2 addProductFnt3 addProductFnt4New"
                        onClick={handleAddPart}
                        style={{ cursor: "pointer" }}
                      >
                        Add...
                      </div>
                    </div>
                  </div>
                </div >


                <div className="row mt-5 pt-5 ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={() => {
                            setCancel(true);
                          }}
                          className="countBtncanc21 h-100 w-100 p-2"
                        >
                          CANCEL
                        </button>
                      </div>


                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            partname == "" ||
                              internalid == "" ||
                              instock == "" ||
                              min == "" ||
                              max == "" ||
                              suppliercode == "" ||
                              showError
                              // suppliername == ""
                              // partList == ""

                              ? "countBtnchange1 w-100 h-100 p-2"
                              : "countBtnchange  w-100  h-100 p-2"
                          }
                          onClick={(e) => {
                            handleAdd(e);
                          }}
                          disabled={partname == "" || internalid == "" || instock == "" || min == "" || max == "" || partList.length == 0 || showError ? true : false
                          }

                        // disabled={
                        //   partName !== "" ||
                        //   internalid !== "" ||
                        //   instock !== "" ||
                        //   min !== "" ||
                        //   max !== "" ||
                        //   suppliercode !== "" ||  partList.length > 0 ? false : true



                        //   // partList == ""

                        // }
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            </div >
          </div >
        </div >
      </div >
    </>
  );
};
export default AddProducts;