import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Holder from '../../assets/icons/pole.svg';
import '../../styles/local/order.css';
import warehouseIcon from '../../assets/icons/warehouse.svg';
import calender from '../../assets/icons/calender.svg';
import stockIcon from '../../assets/icons/Stock.svg';
import productsIcon from '../../assets/icons/Products.svg';
import teamIcon from '../../assets/icons/team.svg';
import { useDispatch, useSelector } from 'react-redux';
import { CreateOrder, fetchAwaitingOrder, fetchUserDetails, fetchwarehouse, nextOrder, stockAvailable } from '../../redux/actions/action';
import MoveCard from '../../components/dialogs/MoveCard';
import Loader from '../../components/Loader'
import Header from '../../components/Header';
import ProductDnd from "../../components/ProductDnd";
import TeamUserDnd from "../../components/TeamUserDnd";
import WarehouseDnd from "../../components/WarehouseDnd";
import DatePicker from "react-datepicker";
import OrderCreated from '../../components/dialogs/OrderCreated';
import { useHistory } from "react-router-dom";
import moment from "moment";
const itemsFromBackend = [
  { id: "1", content: "First task" },

];
const clientname = window.sessionStorage.getItem("clientname");





function OrderCopy() {
  const history = useHistory();
  const clientname = window.sessionStorage.getItem("clientname");
  const finalClientname = clientname.split(" ");
  const firstName = finalClientname[0].toString();
  const lastName = finalClientname[1].toString();
  const [columns, setColumns] = useState([]);
  const [columns1, setColumns1] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const stockData = useSelector(state => state.user.stockAvailable);
  const createOrder = useSelector(state => state.user.createOrder);
  const dispatch = useDispatch();
  const userId = window.sessionStorage.getItem("userid")
  const [awaitData, setAwaitData] = useState([]);
  const [waredata, setWaredata] = useState([]);
  const initialRender = React.useRef(true);
  const [productdetails, setProductdetails] = useState();
  const teamid = window.sessionStorage.getItem("teamId");
  const [userdata, setuserdata] = useState();
  const userDatas = useSelector((state) => state.user.fetchUserDetails);
  const warehouseDetails = useSelector((state) => state.user.fetchWarehouse);
  const [finaluserdata, setuserData] = useState([]);
  const [wareArray, setWareArray] = useState([]);
  const [iteratorVal, setIteratorVal] = useState(0);
  const [StatusPopup, setStatusPopup] = useState(false);
  const [productAdded, setProductAdded] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [dueDate, setDueDate] = useState(moment().hours(9).minutes(0).seconds(0).toDate());
  const [deliveryDate, setDeliveryDate] = useState('');
  const [loading, setLoading] = useState(true)
  const [loading1, setLoading1] = useState(false)
  const [statusId, setStatusId] = useState('');
  const [orderCreated, setOrderCreated] = useState(false);
  const [createdOrderId, setCreatedOrderId] = useState('');
  const [createdStatusId, setCreatedStatusId] = useState('');
  var today = new Date();
  var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const finDate = new Date(date);
  var date = finDate.toString().split(' ')
  const onDragEnd = (result, columns, setColumns) => {

    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];

      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const addedArr = [...productAdded]
      var count = 0;
      for (let i = 0; i < productAdded.length; i++) {
        if (sourceItems[source.index].productid == productAdded[i].productid) {
          count = count + 1;
        }
      }
      if (count == 0) {
        addedArr.push(sourceItems[source.index])
        addedArr[addedArr.length - 1].quantity = 0;

        setProductAdded(addedArr);
      }

      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

    } else {
      const column = columns[source.droppableId];

      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };


  const onDragEnd1 = (result, columns, setColumns) => {

    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];

      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const addedArr = []
      addedArr.push(sourceItems[source.index])
      setWareArray(addedArr)



      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

    } else {
      const column = columns[source.droppableId];

      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  const onDragEnd2 = (result, columns, setColumns) => {

    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];

      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const addedArr = [...userArray]
      var count = 0;
      for (let i = 0; i < userArray.length; i++) {
        if (sourceItems[source.index].userid == userArray[i].userid) {
          count = count + 1;
        }
      }
      if (count == 0) {
        addedArr.push(sourceItems[source.index])
        // addedArr[addedArr.length - 1].quantity = 0;
        // addedArr.quantity.push(0)
        setUserArray(addedArr);

      }

      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      //   setColumns({
      //     ...columns,
      //     [source.droppableId]: {
      //       ...sourceColumn,
      //       items: sourceItems
      //     },
      //     [destination.droppableId]: {
      //       ...destColumn,
      //       items: destItems
      //     }
      //   });
    } else {
      const column = columns[source.droppableId];

      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };
  useEffect(() => {
    dispatch(fetchwarehouse());
    dispatch(fetchUserDetails(1, teamid));
    dispatch(stockAvailable("08da2f31-46b4-4ce8-80b3-fdffac7a3080"));


  }, [stockAvailable, fetchwarehouse]);

  React.useEffect(() => {

    if (!initialRender.current) {
      if (createOrder != null) {
        if (createOrder.status == true) {


          setLoading1(false)
          setOrderCreated(true)
          setCreatedOrderId(createOrder.data.orderid.split("-")[4])
          setCreatedStatusId(createOrder.data.statusid)

        }

        else {
          setLoading1(false)

        }
      }
    } else {
      initialRender.current = false;
    }

  }, [createOrder]);

  React.useEffect(() => {

    if (!initialRender.current) {
      if (userDatas != null) {
        if (userDatas.status == true) {


          setuserData(userDatas.data.filter((item, userid) => (item.userdetails.length > 0)))
          const columnsFromBackend = {
            ["1"]: {
              name: "Requested",
              items: []

            },
            ["2"]: {
              name: "To do",

              items: userDatas.data.filter((item, userid) => (item.userdetails.length > 0))
            },

          };
          setColumns2(columnsFromBackend)


        }

        else {

        }
      }
    } else {
      initialRender.current = false;
    }

  }, [userDatas]);

  React.useEffect(() => {

    if (!initialRender.current) {
      if (warehouseDetails != null) {
        if (warehouseDetails.status == true) {


          const columnsFromBackend = {
            ["1"]: {
              name: "Requested",
              items: []

            },
            ["2"]: {
              name: "To do",

              items: warehouseDetails.data
            },

          };
          setColumns1(columnsFromBackend)
        }

        else {

        }
      }
    } else {
      initialRender.current = false;
    }

  }, [warehouseDetails]);
  useEffect(() => {
    if (!initialRender.current) {
      if (stockData != null) {
        if (stockData.status == true) {

          setProductdetails(stockData.data);

          const columnsFromBackend = {
            ["1"]: {
              name: "Requested",
              items: []

            },
            ["2"]: {
              name: "To do",

              items: stockData.data
            },

          };
          setColumns(columnsFromBackend)


        }
        setLoading(false)


      }

      else {



      }

    } else {
      initialRender.current = false;
    }

  }, [stockData]);

  const handleClose = (statusId, destination) => {
    setStatusPopup(false)
    setStatusId(statusId)

  }

  const handleQuantity = (e, index) => {
    const list = [...productAdded]
    list[index].quantity = e.target.value;
    setProductAdded(list)


  }
  const handleUpdateQuantity = (e, type, index) => {
    if (type == "add") {
      const list = [...productAdded]
      list[index].quantity = parseInt(list[index].quantity) + 1;
      setProductAdded(list)
    }
    else {
      const list = [...productAdded]
      if (list[index].quantity == 0) {
      } else {
        list[index].quantity = parseInt(list[index].quantity) - 1;
        setProductAdded(list)
      }
    }
  }
  const handleRemove = (index) => {
    const list = [...productAdded]
    list.splice(index, 1)
    setProductAdded(list)

  }

  const handleRemove1 = (index) => {
    const list = [...wareArray]
    list.splice(index, 1)
    setWareArray(list)

  }

  const handleCreateOrder = () => {

    // const productarr=[...productAdded]
    // for (let i = 0; i < productarr.length; i++) {
    //   delete productarr[i].productdetails;
    // }

    const teamarr = [{ assigneeuserid: "" }]
    for (let i = 0; i < userArray.length; i++) {
      teamarr.push({ assigneeuserid: userArray[i].userid })
    }

    var deliveryDate1 = moment(dueDate).utc().toDate();
    setLoading1(true)

    dispatch(CreateOrder(userId, wareArray[0].warehouseid, statusId == '' ? "08da2f31-46b4-4ce8-80b3-fdffac7a3058" : statusId, deliveryDate1, productAdded, teamarr, "client", 0))
  }
  const CustomInput = React.forwardRef((props, ref) => {

    return (

      <p className='fw-bold  mb-0'><span onClick={props.onClick} ref={ref}><img src={calender} className="calenOrder" alt="" /></span>due date</p >

    )

  })

  const handleChangeDate = (e) => {
    var dt = new Date(e);
    setDeliveryDate(dt)
    setDueDate(dt)
  }

  const handleClose1 = () => {

    setOrderCreated(false)
    history.push("/home")

  }
  const handleAddOrder = () => {
    setOrderCreated(false)
    setProductAdded([])
    setUserArray([])
    setWareArray([])
    setStatusId('')
    setDeliveryDate('')

  }


  return (<div className="container-fluid" >
    <Header />
    {StatusPopup && <MoveCard handleClose={handleClose} position={true} statusId="" />}
    {orderCreated && <OrderCreated handleCancel={handleClose1} handleAdd={handleAddOrder} orderId={createdOrderId} statusId={createdStatusId} />}
    <h3 className='py-3 ps-2 themecolor'>Order</h3>
    {loading == true ? <Loader />
      :
      <div style={productAdded.length < 3 ? { display: "flex", justifyContent: "center", maxHeight: "239px" } : { display: "flex", justifyContent: "center", maxHeight: (productAdded.length * 25) + 239 }}>
        <DragDropContext
          onDragEnd={result => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                key={columnId}
              >
                {/* <h2>{column.name}</h2> */}
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              //   ? "lightblue"
                              //   : "lightgrey",
                              ? "transparent"
                              : "transparent",
                            padding: 4,
                            width: 515,
                            minHeight: 200
                          }}
                        >{index == 0 &&
                          <div className='row pe-0 pe-xl-5 pe-lg-5 pe-md-5 pe-sm-0 '>
                            <div className='backOrder p-0 position-relative py-1  pe-4 text-end '>
                              <button type='button' className={wareArray.length == 0 || userArray.length == 0 || productAdded.length == 0 ? 'rounded-circle addBtnOrder' : 'rounded-circle addBtnOrderActive'} disabled={wareArray.length == 0 || loading1 == true || userArray.length == 0 || productAdded.length == 0 ? true : false} onClick={(e) => { handleCreateOrder(e) }}>
                                Add {loading1 == true && <div className="spinner-border spinner-border-sm" role="status">
                                  <span className="sr-only">Add</span>
                                </div>}</button>
                              {/* <p className='fw-bold  mb-0'><span><img src={calender} className="calenOrder" alt="" /></span>due date</p > */}
                              <DatePicker
                                selected={dueDate}
                                onChange={handleChangeDate}
                                customInput={<CustomInput />}
                                placeholderText="due date"
                                popperPlacement="bottom-end"
                                minDate={new Date()}
                              />
                              <h5 className="py-0 my-0">

                                {moment(dueDate).format("DD.MMM.YYYY")}
                              </h5>



                            </div>
                            <div className='card pb-3  px-4 cardBorder1'>
                              <div className='col-12 align-self-center' >
                                <div className='row pt-2 pb-3'>
                                  <div className='col  ps-0 align-self-center'>

                                  </div>
                                  <div className='col text-end'>

                                  </div>
                                </div>
                                <div className='row  ' style={{ color: "#60AAC6" }}>

                                  <div className='col-6 ps-4'>
                                    <h5>Product</h5>
                                  </div>
                                  <div className='col-4 text-center ps-4'>
                                    <h5>Qty</h5>
                                  </div>
                                  {/* <div className='col-3 text-end pe-0 '>
                                  <h5>Remove</h5>
                                </div> */}
                                  <div className='col-2 text-end pe-0 '>
                                    <h5>Chk</h5>
                                  </div>
                                </div>
                                {productAdded.length == 0 ? <div>
                                  <div className="conatiner  mt-2 text-center" style={{ border: "6px dotted #DAF4FA", cursor: "pointer" }}>
                                    <p className=" fs-4  pt-3 " style={{ color: "#003766" }}>+ Drag Product</p>
                                  </div>

                                </div> :
                                  <div style={{ marginTop: "10px", marginBottom: "34px" }}>

                                    {productAdded.map((item, index) => {

                                      return (
                                        <div className='row pt-2 '>
                                          <div className='col-7 ps-4 align-self-center'>
                                            {/* <h5>Concrete Block (2x3m)</h5> */}
                                            {item.productdetails[0].productname}
                                          </div>
                                          <div className='col-3 text-start ps-0'>
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className=" minpluBtn fw-bold fs-4 me-2" disabled={item.quantity == 0 ? true : false} onClick={(e) => { handleUpdateQuantity(e, "sub", index) }}>-</span>
                                              </div>
                                              <input type="text" value={item.quantity == 0 ? 1 : item.quantity} onChange={(e) => {


                                                // setIteratorVal(parseInt(e.target.value));
                                                handleQuantity(e, index)


                                              }} className="form-control  text-center " style={{ height: "35px", width: "20px" }} />
                                              <div className="input-group-prepend">
                                                <span className=" fs-4 fw-bold minpluBtn ms-2" onClick={(e) => { handleUpdateQuantity(e, "add", index) }}>+</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='col-1 text-start fw-bold text-center  align-self-center'>
                                            <span onClick={() => handleRemove(index)} className="productremove" >X</span>
                                          </div>
                                          <div className='col-1 text-end fw-bold fs-5 text-center  align-self-center'>
                                            <input type='radio' />
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                }
                              </div>






                            </div>
                          </div>
                          }


                          {index == 1 &&
                            <div className="row" style={{ maxHeight: "230px", overflowY: "scroll" }}>
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.productid}
                                    draggableId={item.productid}
                                    index={index}


                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div className="col-2 px-1 pb-2"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}

                                        >

                                          <div className={item.productdetails[0]?.isConcreteBlock == 0 ? 'card  px-0 text-center w-100 h-100 hold1 me-2' : 'card  px-0 text-center w-100 h-100 hold me-2'} style={{ cursor: "move" }}>
                                            <img src={Holder} className="imgOrder pt-2" alt="" />

                                            <p className='py-0 my-0'>{(item.productdetails[0]?.productname.split(" "))[0]}</p>

                                            <p className='pb-2 my-0'>{item.productdetails[0]?.productname.split(" ").pop()}</p>
                                          </div>
                                        </div>


                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                            </div>
                          }

                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    }
    {loading == true ? ''
      :
      <div style={{ display: "flex", justifyContent: "center", maxHeight: "168px" }}>

        <DragDropContext
          onDragEnd={result => onDragEnd1(result, columns1, setColumns1)}
        >
          {Object.entries(columns1).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                key={columnId}
              >
                {/* <h2>{column.name}</h2> */}
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              //   ? "lightblue"
                              //   : "lightgrey",
                              ? "transparent"
                              : "transparent",
                            padding: 4,
                            width: 515,
                            minHeight: 200
                          }}
                        >{index == 0 &&
                          <div className='row pe-0 pe-xl-5 pe-lg-5 pe-md-5 pe-sm-0 '>

                            <div className='card pb-3  px-4 cardBorder2'>
                              <div className='col-12 align-self-center' >



                                <div className='row pt-2 justify-content-center'>
                                  <div className='row px-2 mt-5'>
                                    <div className='col-2 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start'>
                                      <img src={stockIcon} className="orderIconPop mt-2" alt="" />
                                    </div>
                                    <div className='col-10 align-self-center' >
                                      <div className='row pt-2 '>
                                        <div className='col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center'>
                                          <h4 className='orderPopupHeadText'>Stock Status</h4>
                                        </div>
                                        <div className='col col justify-content-end d-flex text-end'>
                                          <div className="form-check form-switch">
                                            <input className="form-check-input ordcheck" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-2 align-self-center' >
                                    </div>
                                  </div>
                                  {wareArray.length == 0 ? <div className='row px-2 mt-2'>
                                    <div className='col-2 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0 '>
                                      <img src={warehouseIcon} className="orderIconPop mt-2" alt="" />

                                    </div>
                                    <div className='col-10 align-self-center' >
                                      <div className='row pt-2 '>
                                        <div className='col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center'>
                                          <h4 className='orderPopupHeadText '>Warehouse</h4>
                                        </div>
                                        <div className='col text-center align-self-center' style={{ border: "6px dotted #DAF4FA", height: "40px", cursor: "pointer" }}>


                                          <p className="  " style={{ color: "#003766" }}>+ Drag Warehouse</p>

                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-2 align-self-center' >

                                    </div>
                                  </div> : wareArray.map((item) => <div>
                                    <div className='row px-2 mt-3'>
                                      <div className='col-2 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0 '>
                                        <img src={warehouseIcon} className="orderIconPop " alt="" />

                                      </div>
                                      <div className='col-10 align-self-center' >
                                        <div className='row pt-2 '>
                                          <div className='col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 '>
                                            <h4 className='orderPopupHeadText '>Warehouse</h4>
                                          </div>
                                          <div className='col text-end '>
                                            <p className="">
                                              {item.warehousename}
                                              <span onClick={() => { handleRemove1(index) }} className='my-0 ps-5 productremove' >X</span>
                                            </p>

                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-2 align-self-center' >

                                      </div>
                                    </div>
                                  </div>
                                  )}
                                </div>

                              </div>





                            </div>
                          </div>
                          }

                          {index == 1 &&
                            <div className="row" style={{ maxHeight: "347px" }}>
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.warehouseid}
                                    draggableId={item.warehouseid}
                                    index={index}


                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div className="col-4 px-1 pb-2"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}

                                        >


                                          <div className='col px-0 pe-2' >
                                            <div className='card flex-row py-3 px-0 ' style={{ background: "#FAFAFA" }}>
                                              <div className='col-4 text-center'> <img src={warehouseIcon} className="orderIconPop" /></div>
                                              <div className='col-8 text-center'>
                                                <h6 className='my-0'>Warehouse</h6>
                                                <h6 className='my-0'> {item.warehousename}</h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>


                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                            </div>
                          }

                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    }

    {loading == true ? ''
      :
      <div className="mb-5" style={{ display: "flex", justifyContent: "center" }}>

        <DragDropContext
          onDragEnd={result => onDragEnd2(result, columns2, setColumns2)}
        >
          {Object.entries(columns2).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                key={columnId}
              >
                {/* <h2>{column.name}</h2> */}
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              //   ? "lightblue"
                              //   : "lightgrey",
                              ? "transparent"
                              : "transparent",
                            padding: 4,
                            width: 515,
                            minHeight: 200
                          }}
                        >{index == 0 &&
                          <div className='row pe-0 pe-xl-5 pe-lg-5 pe-md-5 pe-sm-0 '>

                            <div className='card pb-3  px-4 cardBorder3'>


                              <div className='row px-2'>
                                <div className='col-2 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0'>
                                  <img src={teamIcon} className="orderIconPop mt-3" alt="" />
                                </div>
                                <div className='col-10 align-self-center' >
                                  <div className='row pt-2 '>
                                    <div className='col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center'>
                                      <h4 className='orderPopupHeadText'>Team</h4>
                                    </div>
                                    {userArray.length == 0 ?
                                      <div className='col text-center align-self-center' style={{ border: "6px dotted #DAF4FA", height: "40px", cursor: "pointer" }}>


                                        <p className="  " style={{ color: "#003766" }}>+ Drag Team</p>

                                      </div>
                                      : ''}
                                  </div>
                                </div>

                                <div className='col-2 align-self-center' >

                                </div>

                                <div className='col-10 align-self-center' >
                                  <div className='row pt-2 '>
                                    {userArray.map((item, index) =>

                                      <div className='col-2 d-flex ps-0 ps-xl-0 ps-lg-0 ps-sm-3 '>
                                        <div className='col-6 text-center px-0'><button type="button" className="teambtnOrder2 mt-3 fw-bold">
                                          {((item.userdetails[0].firstname[0]) + (item.userdetails[0].lastname[0])).toUpperCase()}
                                        </button>


                                        </div>
                                        <div className="col-6 text-start">
                                          <span className='btn  fw-bold text-danger pb-5 ms-1' onClick={() => {
                                            const list = [...userArray]
                                            list.splice(index, 1)
                                            setUserArray(list)
                                          }} >X</span>

                                        </div>


                                      </div>

                                    )}
                                  </div>

                                </div>
                              </div>
                              <div className='row px-2'>
                                <div className='col-2 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0'>
                                  <img src={productsIcon} className="orderIconPop mt-2" alt="" />
                                </div>
                                <div className='col-10 align-self-center' >
                                  <div className='row pt-2 '>
                                    <div className='col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center'>
                                      <h4 className='orderPopupHeadText'>Production</h4>
                                    </div>
                                    <div className='col text-end'>
                                      <button type='button' className='btn btn-primary px-4 moveBtn' disabled={wareArray.length == 0 || userArray.length == 0 || productAdded.length == 0 ? true : false} onClick={() => setStatusPopup(true)} ><spna></spna>Move<span><i className="bi bi-arrow-right ps-3" style={{ marginTop: "20px" }}></i></span></button>

                                    </div>
                                  </div>
                                </div>
                                <div className='col-2 align-self-center' >

                                </div>
                              </div>
                              <div className='row pt-4'>
                                <div className='col-2 px-0 text-end justify-content-end d-flex align-self-end'>
                                  <button type="button" className="teambtn fw-bold">

                                    {clientname == undefined || clientname == null ? 'SD' : firstName[0].toUpperCase() + '' + lastName[0].toUpperCase()}
                                  </button>
                                </div>
                                <div className='col-10 pe-3'>
                                  <div className='row pb-2'>
                                    <div className='col-6 ps-4'>Activity</div>
                                    <div className='col-6 text-end pe-4'>Show details…</div>
                                  </div>
                                  <input type="text" className="inputOrdercmnt" placeholder='  write a comment…' />
                                </div>
                              </div>

                            </div>
                          </div>
                          }


                          {index == 1 &&
                            <div className="row" style={{ maxHeight: "347px", overflowY: "scroll" }}>
                              {column.items.map((item, index) => {

                                return (
                                  <Draggable
                                    key={item.userid}
                                    draggableId={item.userid}
                                    index={index}


                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div className="col-2 px-1 pb-2"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}

                                        >

                                          <div className='col-12 pt-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-3 col-xl-12 ps-3 col-lg-5  col-md-5' >
                                            <div className='row mt-3'>

                                              <div className='col-5 teambtnOrder text-center px-0'>
                                                {((item.userdetails[0].firstname[0]) + (item.userdetails[0].lastname[0])).toUpperCase()}
                                              </div>

                                            </div>


                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                            </div>
                          }

                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    }


  </div>);
}

export default OrderCopy;
