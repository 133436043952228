import React, { useEffect, useState } from 'react';
import SignUp from "../../../components/dialogs/SignUp";
import LogIn from "../../../components/dialogs/LogIn";
import ForgotPassword from "../../../components/dialogs/ForgotPassword";
import Header from '../../../components/Header';
import { useHistory } from 'react-router-dom';

const Landingpage = () => {
    
    const history=useHistory();
    const [OpenSignup, setOpenSignup] = useState(false);
    const [OpenForgetPassword, setOpenForgetPassword] = useState(false);
    const [OpenLogin, setOpenLogin] = useState(true);
    
    const openLogin = () => {
        setOpenSignup(!OpenSignup);
        setOpenLogin(!OpenLogin);
    }

    const openSignup = () => { 
        setOpenLogin(!OpenLogin);
        setOpenSignup(!OpenSignup);

    }

    const openForgetPassword = () => {
        setOpenLogin(!OpenLogin);
        setOpenForgetPassword(!OpenForgetPassword);
    }

    const signupPopup = () => {
        setOpenSignup(!OpenSignup);
    }

    const loginPopup = () => {
        setOpenLogin(!OpenLogin);
    }


    const forgetPasswordPopup = () => {
        setOpenForgetPassword(!OpenForgetPassword);
        setOpenLogin(!OpenLogin);
    }
   
    return (<div>
        {OpenSignup && <SignUp
            handleClose={signupPopup}
            clickLogin={openLogin}
        />}

        {OpenLogin && <LogIn
            handleClose={loginPopup}
            clickSignup={openSignup}
            clickForgetPassword={openForgetPassword}
        />}

        {OpenForgetPassword && <ForgotPassword

            handleClose={forgetPasswordPopup}
        />}
        <div>
            {/* <h1>landing page</h1> */}
        </div>

    </div>)
}

export default Landingpage