import React, { useMemo, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Loader from "../../components/Loader";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Header";
import { fetchOrders, fetchErrorCode, CalendarDataUpdate } from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/local/timelineview.css";
import Orderpopup from "../../components/dialogs/Orderpopup";
import calender from "../../assets/icons/calender.svg";
import moment from "moment";
import { findDOMNode } from 'react-dom';
import { Col, Overlay, Row, Tooltip } from "react-bootstrap";
import { ReactComponent as SpecialPartIcon } from "../../assets/icons/Path554.svg"

const DnDCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment)

function TimelineView() {
  const [allEvents, setAllEvents] = useState([]);
  const dispatch = useDispatch();
  const UserID = window.sessionStorage.getItem("userid");
  const fetchOrder = useSelector((state) => state.user.fetchOrder);
  const timeline_props = useSelector((state) => state.user.timeline_props);
  console.log("prop", timeline_props);
  const fetchErrorCodeResponse = useSelector(
    (state) => state.user.fetchErrorCodeResponse
  );
  const initialRender = React.useRef(true);
  const [cardPopup, setCardPopup] = React.useState(false);
  const [view, setView] = useState("Day");
  const [destination, setDestination] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  const [showError, setShowError] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [hoverErrorList, setHoverErrorList] = useState([]);

  // const formats = {
  //   weekdayFormat: (date, culture, localizer) => localizer.format(date, 'd mmmm yyyy', culture)
  // }

  const { formats } = useMemo(
    () => ({
      formats: {
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
          return localizer.format(start, 'MMM dd', culture) + ' - ' + localizer.format(end, 'MMM dd', culture)
        }
      },
    }),
    []
  )

  const handleOnChange = (event) => {
    setDestination(event.target.value);
    dispatch(
      fetchOrders(UserID, event.target.value)
    );
  };

  React.useEffect(() => {
    dispatch(fetchErrorCode());
    dispatch(fetchOrders(UserID, destination));
  }, []);

  React.useEffect(() => {
    if (timeline_props) {
      dispatch(fetchErrorCode());
      dispatch(fetchOrders(UserID, destination));
    }
  }, []);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchOrder != null) {
        if (fetchOrder.status == true) {
          let data1 = fetchOrder.data.map((val) => {
            return {
              ordercode: val.ordercode,
              clientname: val.clientname,
              statusid: val.statusid,
              orderid1: val.orderid.split("-")[4],
              orderid: val.orderid,
              start: moment.utc(val.deliverydate).local().toDate(),
              end: moment.utc(val.deliverydate).local().add(30, 'minutes').toDate(),
              isconcreteblock: val.isConcreteBlock,
              isspecial: val.isSpecialPart,
              productname: val.productsdetails[0]?.name,
            };
          });
          setAllEvents(data1);
          setLoadershow(false)
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchOrder]);

  React.useEffect(() => {
    if (fetchErrorCodeResponse.status === true) {
      const progress = fetchErrorCodeResponse?.data[0]?.progressErrorIds.split(",");
      const queueErrorIds = fetchErrorCodeResponse?.data[0]?.queueErrorIds.split(",");
      if (progress) {
        setErrorList([...progress, ...queueErrorIds]);
      }
    }
  }, [fetchErrorCodeResponse]);


  const hoverChange = (id, value) => {
    setHoverErrorList(id);
    setShowError(value)
  };


  function CustomToolbar(props) {
    const [view1, setView1] = useState("All status");

    const handleView = (val) => {
      setView(val.charAt(0).toUpperCase() + val.slice(1));
      props.onView(val);
    };
    const handleView1 = (val) => {
      setView1(val);
      props.onView(val);
      var statusId = "";
      if (val === "Pipeline") {
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3058";
      } else if (val === "Qeue") {
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3059";
      } else if (val === "Complete") {
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3062";
      } else if (val === "In Progress") {
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3060";
      } else {
        statusId = "00000000-0000-0000-0000-000000000000";
      }
      dispatch(fetchOrders(UserID, statusId));
    };

    return (
      <div className="rbcheader rbc-toolbar d-flex justify-content-between">
        <span className="rbc-btn-group ">
          <button
            type="button"
            className="calender-headerbtn2"
            onClick={() => props.onNavigate("TODAY")}
          >
            Today
          </button>
          <button
            type="button"
            className="calender-headerbtn"
            onClick={() => props.onNavigate("PREV")}
          >
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>

          <button
            type="button"
            className="calender-headerbtn"
            onClick={() => props.onNavigate("NEXT")}
          >
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
          <span className="rbc-toolbar-label calender-headerbtn">
            {props.label}
          </span>
          <span className="rbc-btn-group ms-3">
            <div className="dropdown">
              <button
                className="btn  calender-headerbtn3"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {props.view.charAt(0).toUpperCase() + props.view.slice(1)}
                <i className="bi bi-chevron-down dropdown-arrow ms-3 px-1 pt-1"></i>
              </button>
              <div
                className="dropdown-menu view-btn"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={`${view === "Day" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView("day")}
                >
                  Day
                </a>
                <a
                  className={`${view === "Week" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView("week")}
                >
                  Week
                </a>
                <a
                  className={`${view === "Month" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView("month")}
                >
                  Month
                </a>
              </div>
            </div>

            {/* <button type="button" className={props.view =="month" ? "selected-headerbtn1" : "calender-headerbtn1"} onClick={() => props.onView('month')}>Month</button>
           <button type="button" className={props.view =="week" ? "selected-headerbtn1" : "calender-headerbtn1"} onClick={() => props.onView('week')} >Week</button>
         
           <button type="button" className={props.view =="day" ? "selected-headerbtn1" : "calender-headerbtn1"} onClick={() => props.onView('day')}>Day</button> */}
          </span>
          <span className="rbc-btn-group ms-5">
            <div className="dropdown">
              <button
                className="btn  calender-headerbtn3"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onChange={(e) => handleOnChange(e)}
              >
                {view1}
                <i className="bi bi-chevron-down dropdown-arrow ms-3 px-1 pt-1 "></i>
              </button>
              <div
                className="dropdown-menu view-btn"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className={`${view1 === "All status" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView1("All status")}
                >
                  All status
                </a>
                <a
                  className={`${view1 === "Complete" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView1("Complete")}
                >
                  Complete
                </a>
                <a
                  className={`${view1 === "In Progress" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView1("In Progress")}
                >
                  In Progress
                </a>
                <a
                  className={`${view1 === "Qeue" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView1("Qeue")}
                >
                  Queue
                </a>
                <a
                  className={`${view1 === "Pipeline" && "active-menu"} dropdown-item calender-headerbtn3`}
                  onClick={(e) => handleView1("Pipeline")}
                >
                  Pipeline
                </a>
              </div>
            </div>
          </span>

          {/* <span className="rbc-btn-group ms-3">
          <select className="form-select" aria-label="Default select example" onChange={(e) => handleOnChange(e) } value={destination} >
            <option selected disabled>Status</option>
            <option value="00000000-0000-0000-0000-000000000000">All</option>
            <option value="08da2f31-46b4-4ce8-80b3-fdffac7a3062">Complete</option>
            <option value="08da2f31-46b4-4ce8-80b3-fdffac7a3060">In Progress</option>
            <option value="08da2f31-46b4-4ce8-80b3-fdffac7a3059">Qeue</option>
            <option value="08da2f31-46b4-4ce8-80b3-fdffac7a3058">Pipeline</option>
          </select>
          </span> */}
        </span>
      </div>
    );
  }

  function Event({ event }) {

    const [showtooltip, setshowtooltip] = useState(false);

    const closetooltip = () => {
      setshowtooltip(false);
    };

    const opentooltip = () => {
      setshowtooltip(true);
    };
    const ref = useRef(null);

    const gettarget = () => {
      return findDOMNode(ref.current);
    };

    return (
      <>
        {errorList.includes(event.orderid) && <OrderErrorIndicator key={event.orderid} />}
        <div
          ref={ref}
          className={`${event.isconcreteblock == 0 ? "concret-block-card" : "normal-card"} ${event.isspecial == 0 ? "normal-padding" : "special-part-padding"}`}
        >
          <Overlay
            rootclose
            target={gettarget}
            show={showtooltip}
            placement="top"
            onhide={closetooltip}
          >
            <Tooltip className="p-0">
              <div className="d-flex flex-column" style={{ width: 185 }}>
                <div className="d-flex justify-content-between">
                  <b>Product:</b>
                  <span className="text-end">{event.productname}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <b>Quote:</b>
                  <span className="text-end">{event.ordercode}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <b>Client:</b>
                  <span className="text-end">{event.clientname}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <b>Date:</b>
                  <span className="text-end">{moment(event.end).format("DD.MM.YYYY")}</span>
                </div>
              </div>
            </Tooltip>
          </Overlay>
          {event.isspecial == 1 &&
            <SpecialPartIcon className={"special-part-icon"} onMouseOver={opentooltip} onMouseOut={closetooltip} />
          }
          <div className="d-flex flex-column justify-content-around h-100" onMouseOver={opentooltip} onMouseOut={closetooltip}>
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="text-order-product-name">
                {event.productname}
              </div>
              <div className="text-order-quote">
                <span>Quote</span>&nbsp;{event.ordercode}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="text-order-client-name">
                {event.clientname}
              </div>
              <div className="text-order-due-date">
                <img alt="" src={calender}></img>&nbsp;{moment(event.end).format("DD.MM.YYYY")}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const handleEvent = (event) => {
    window.sessionStorage.setItem("orderId", event.orderid);
    const dateFormat = new Date(event.end);
    const finalDate =
      dateFormat.toString().slice(8, 10) +
      "." +
      dateFormat.toString().slice(4, 7) +
      "." +
      dateFormat.toString().slice(11, 15);
    window.sessionStorage.setItem("dueDate", finalDate);
    setCardPopup(true);
  };

  const handleClose = () => {
    setCardPopup(false);
  };

  const [loadershow, setLoadershow] = useState(true)

  const onEventDrop = ({ event, start, end }) => {
    const newDate = moment(start).toDate()
    dispatch(CalendarDataUpdate(event.orderid, "Date", event.statusid, moment(newDate).toDate()));
    // dispatch(fetchOrders(UserID, destination));
    // console.log({ event, start, end })
    const idx = allEvents.indexOf(event);
    const updatedEvent = {
      ...event,
      start: moment(start).toDate(),
      end: moment(start).add(30, 'minutes').toDate()
    };
    const nextEvents = [...allEvents];
    nextEvents.splice(idx, 1, updatedEvent);
    setAllEvents(nextEvents);
  }

  return (
    <div className="rbc">
      <Header />
      {cardPopup && <Orderpopup handleClose={handleClose} />}
      {/* <h3 className='py-3 ps-2 themecolor'>Timeline View</h3> */}
      {loadershow === true ? (
        <Loader />
      ) : (
        <DnDCalendar
          onEventDrop={onEventDrop}
          dayLayoutAlgorithm={"no-overlap"}
          localizer={localizer}
          events={allEvents}
          showAllEvents={true}
          defaultView="day"
          views={["month", "week", "day"]}
          style={{
            height: "800px",
            marginRight: "50px",
            marginLeft: "50px",
            marginBottom: "100px",
            marginTop: "10px",
            overflowX: "scroll"
          }}
          onSelectEvent={(event) => handleEvent(event)}
          formats={formats}
          components={{
            event: Event,
            toolbar: CustomToolbar,
          }}
        />
      )}
    </div>
  );
}

const OrderErrorIndicator = () => {
  const [showtooltip, setshowtooltip] = useState(false);
  const ref = useRef(null);

  const closetooltip = () => {
    setshowtooltip(false);
  };

  const opentooltip = () => {
    setshowtooltip(true);
  };

  const gettarget = () => {
    return findDOMNode(ref.current);
  };
  return (
    <div>
      <Overlay
        rootclose
        target={gettarget}
        show={showtooltip}
        placement="top"
        onhide={closetooltip}
      >
        <Tooltip className="p-0 stock-assignee-missing-tooltip">
          Stock or Assignees <br /> need your attention!
        </Tooltip>
      </Overlay>
      <div ref={ref} className="order-error-icon" onMouseEnter={opentooltip} onMouseLeave={closetooltip}>!</div>
    </div>
  )
}

export default TimelineView;
