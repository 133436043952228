import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import Group191 from "../../assets/icons/addusericon.svg";
import "../../styles/local/teamadd.css";
import teamIcon from "../../assets/icons/teamIcon.svg";
import DiscardAlert from "../../components/dialogs/Discard";
import Group62 from "../../assets/icons/Group62.svg";
import { userSignup } from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import calender from "../../assets/icons/calendar3.svg";
import eyeicon from "../../assets/icons/eye.svg";
import Image from "react-bootstrap/Image";
import Add1 from "../../assets/icons/Add1.png";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import axios from "axios";
import { API_URL } from "..//..//redux/constants/type";
import EuropeanInput from "../../utils/EuropeanInput";
let c = 0;

const TeamAdd = () => {
  const refNewStandardWageInput = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [imgUrl, setImgurl] = useState("");
  const [isActive, setIsActive] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const initialRender = React.useRef(true);
  const [show, setShow] = useState([{ contact: "", id: 1 }]);

  const UserId = window.sessionStorage.getItem("userid");
  const companyId = window.sessionStorage.getItem("compId");
  // const roleId = window.sessionStorage.getItem("roleid");
  const teamid = window.sessionStorage.getItem("teamId");
  const roleName = window.sessionStorage.getItem("rolename");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [contactCount, setContactCount] = useState(1);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [costHour, setCostHour] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactEndingdate, setContactEndingdate] = useState("");
  const [contactStartingdate, setContactStartingdate] = useState("");
  const [contactDescription, setContactDescription] = useState("");
  const [availability, setAvailability] = useState(false);
  const [userstate, setUserstate] = useState("1");
  const [display, setDisplay] = useState([{ contact: "", id: 1 }]);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const inputFile = React.useRef(null);
  // const [imgUrl, setImgurl] = useState("");
  const fetchUsersdata = useSelector((state) => state.user.signupData);
  const [singupRes, setSingupRes] = useState();
  const radios = [
    { name: "Owner", value: "1" },
    { name: "Manager", value: "2" },
    { name: "User", value: "3" },
  ];

  // const [checked, setChecked] = useState(false);
  // const [radioValue, setRadioValue] = useState('1');

  const [contacts, setContacts] = useState([
    {
      description: "",
      startingdate: "",
      endingdate: "",
    },
  ]);
  const [contactsnull, setContactsnull] = useState([]);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  // const Imagedlt = () => {
  //   setImgurl("");
  // };

  const [language, setLanguae] = useState([]);
  React.useEffect(() => {
    // setLanguae(lang.english[0].signup)
  }, []);
  const [role, setRole] = useState("08da2f30-beca-4c07-8e42-1311289b9e4b");
  const [isDisabled, setDisabled] = useState(false);
  const ownref = useRef(null);

  const [avilindexerror, setAvilindexerror] = useState("");
  const handleChange = (id, field, val) => {
    const modifiedContacts = contacts;
    modifiedContacts[id][field] = val;

    setContacts(modifiedContacts);
  };

  const Imagedlt = () => {
    setImgurl("");
  };

  const handleClick = () => {
    setShow([...show, { contact: "", id: contactCount + 1 }]);
    setContactCount(contactCount + 1);
    setContactNumber(contactNumber + 1);
    const modifiedContacts = contacts;

    modifiedContacts.push({
      description: "",
      startingdate: "",
      endingdate: "",
    });

    setContacts(modifiedContacts);
  };

  const handleFileChange = (e) => {
    const target = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target);
    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setImgurl(response.data[0]);
        } else {
        }
      })
      .catch(function (error) { });
  };

  const handleAdd = (e) => {

    const now = new Date().getTime();
    console.log("today date", now);
    const isValid = contacts.filter(
      p => new Date(p.startingdate) <= now && new Date(p.endingdate) >= now
    );

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(email)) {
      setEmailInvalid(true);
    } else {
      if (availability === true) {
        dispatch(
          userSignup(
            firstname,
            lastname,
            email,
            address1,
            password,
            phoneNumber,
            costHour,
            imgUrl,
            availability === true ? 1 : 0,
            userstate == availability == true && isValid.length > 0 ? 0 : 1,
            jobtitle,
            notes,
            teamid,
            role,
            companyId,
            contacts
          )
        );
      } else {
        dispatch(
          userSignup(
            firstname,
            lastname,
            email,
            address1,
            password,
            phoneNumber,
            costHour,
            imgUrl,
            availability == false ? 0 : 1,
            userstate == availability == false ? 1 : 0,
            jobtitle,
            notes,
            teamid,
            role,
            companyId,
            // contactsnull
            // contacts
          )
        );
      }
      e.preventDefault();
    }
  };
  console.log("userstate22", userstate)
  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchUsersdata != null) {
        if (fetchUsersdata.status == true) {
          history.push("/team");
        } else {
          setSingupRes(fetchUsersdata.message);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchUsersdata]);
  useEffect(() => {
    setContacts(contacts);
  }, [contacts]);

  useEffect(() => {
    if (emailInvalid == true) {
      setSingupRes(false);
    } else if (singupRes == true) {
      setEmailInvalid(false);
    }
  });

  useEffect(() => {
    setDisplay(show);
  }, [show]);
  const [jobtitle, setJobtitle] = useState("");
  const setGender = (event) => {
    setJobtitle(event.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  useEffect(() => {
    setDisplay(show);
  }, [show]);

  const [cancel, setCance] = useState(false);
  const [msg, setMsg] = useState("");
  const handleCancelBtn = () => {
    setCance(true);
    setMsg(
      "The entered data will not be saved. Do you want to cancel adding new member? "
    );
  };

  // ASC JOB ROLE BORDER start {jobtitle === "Mechanic"}

  useEffect(() => {
    if (jobtitle === "Mechanic") {
      setIsActive("one");
    } else if (jobtitle === "Mechanic on site") {
      setIsActive("two");
    } else if (jobtitle === "Driver") {
      setIsActive("three");
    } else {
      setIsActive("four");
    }
  });

  // ASC JOB ROLE BORDER END

  const handleChange2 = (event) => {
    setJobtitle(event.target.value);
  };

  return (
    <>
      <Header />

      {cancel && (
        <DiscardAlert
          // msg={msg}
          msg=<p style={{ paddingTop: "33px" }}>{msg}</p>
          handleCancel={() => {
            setCance(false);
          }}
          handleOk={() => {
            history.push("/team");
          }}
        />
      )}
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-5">
            <h3 className="countFontclr stckFont">Team Member</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="custCardCount">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5 className="py-4 fntSupp28 text-sm-start text-center">
                    <span className="me-4">
                      <img src={teamIcon} className="" width={40} />
                    </span>
                    <span>Team Member</span>
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row ms-4 ">
                  <div className="col-12 col-lg-7">
                    <div className="row mx-2">
                      <div className="col-12 col-lg-6">
                        <div>
                          <h3 className="mt-5 fntSupp1 ms-2">First Name</h3>
                          <input
                            className=" form-control suppInput mt-2 fntSupp4 ms-1"
                            onChange={(e, value) =>
                              setFirstname(e.target.value)
                            }
                            type="text"
                            value={firstname}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 pe-0">
                        <div>
                          <h3 className="mt-5 fntSupp1">Last Name</h3>
                          <input
                            className=" form-control suppInput mt-2 fntSupp4"
                            onChange={(e, value) => setLastname(e.target.value)}
                            type="text"
                            value={lastname}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row ms-3 me-2 mt-1">
                      <div className="box-contactName mt-2 mt-xl-1 mx-2">
                        <div className="">
                          <span className="fntSupp12">Address</span>
                        </div>

                        <input
                          type="text"
                          className="fntSupp4"
                          onChange={(e) => setAddress1(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row ms-3 mt-1">
                      {/* <div className="col-12 col-lg-4 ms-sm-4 me-sm-2 "> */}

                      <div className="col-12 col-lg-5">
                        <div className=" box-contactName mt-2 mt-xl-1 me-sm-3 me-xl-0">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp12">E-mail</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            // onChange={(e) => setEmail(e.target.value)}

                            onChange={(e) => {
                              setEmail(e.target.value)
                              setEmailInvalid(false)
                            }}
                            value={email}
                          />
                        </div>

                        <div className="  mt-2 mt-xl-1">
                          {emailInvalid == true && (
                            <div className="ps-3" style={{ color: "red", fontSize: "14px" }}>
                              Email is invalid
                            </div>
                          )}
                          {
                            <div className="ps-3" style={{ color: "red", fontSize: "14px" }}>
                              {singupRes}
                            </div>
                          }
                        </div>
                      </div>

                      <div className="  col-12 col-lg-4  mt-2 mt-xl-1">
                        <div className="box-contactName me-sm-3">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp12">Telephone</span>
                          </div>

                          <input
                            type="number"
                            className="fntSupp4"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div
                        className="col-12  col-lg-3  mt-2 mt-xl-1"
                      // style={{ width: "156px" }}
                      >
                        <div className="box-contactName">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp12">Cost Hour</span>
                          </div>

                          <EuropeanInput
                            min={0}
                            previewAlignment="start"
                            className="fntSupp4"
                            onChange={(newValue) => setCostHour(newValue)}
                            value={costHour}
                          />

                        </div>
                      </div>

                      <div className="rowm mt-3" style={{ width: "676px" }}>
                        <div className="box-contactName mt-2 mt-xl-5 mx-0 ml-0">
                          <div className="">
                            <span className="fntSupp12">Password</span>
                          </div>

                          <input
                            type={passwordType}
                            className="fntSupp4"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </div>
                      </div>
                      <div>
                        {passwordType === "password" ? (
                          <i
                            className="bi bi-eye-slash eyeicon"
                            onClick={togglePassword}
                          ></i>
                        ) : (
                          <img
                            src={eyeicon}
                            alt="eye"
                            className="eyeicon"
                            onClick={togglePassword}
                          />
                        )}
                      </div>
                    </div>
                    <div className=" mt-sm-5 pt-sm-0">
                      {/* <div children="" className="Availabilitybox mx-3">
                        {" "}
                        <h5 className="addperiod">Availability</h5>
                        {contacts.length < contacts.length + 1 ? (
                          // <h6
                          //   className="addperiod"
                          //   onClick={handleClick}
                          //   style={{ cursor: "pointer" }}
                          //   disabled={availability1 == true}
 
                          // >
                          //   Add Period...
                          // </h6>
 
                          <button
                            // type="button"
                            className={
                              availability == ""
                                // ? "countBtnchange1 p-2"
                                // : "countBtnchange p-2"
 
                                // for ADD period
                                ? "addperiod1 p-1"
                                : "addperiod2 p-1"
                            }
                            onClick={handleClick}
                            disabled={
                              availability == ""
                            }
                          >
                            Add Period...
                          </button>
 
                        ) : (
                          " "
                        )}
                      </div> */}
                      <div
                        children=""
                        className="Availabilitybox mx-3 ms-4"
                        style={{ paddingLeft: "10px" }}
                      >
                        {" "}
                        <h5 className="addperiod">Availability</h5>
                        {contacts.length < contacts.length + 1 ? (
                          // <h6
                          //   className="addperiod"
                          //   onClick={handleClick}
                          //   style={{ cursor: "pointer" }}
                          //   disabled={availability1 == true}

                          // >
                          //   Add Period...
                          // </h6>

                          <button
                            // type="button"
                            className={
                              availability == ""
                                ? // ? "countBtnchange1 p-2"
                                // : "countBtnchange p-2"

                                // for ADD period
                                "addperiod1 pb-1"
                                : "addperiod2 pb-1"
                            }
                            onClick={handleClick}
                            disabled={availability == ""}
                          >
                            Add Period...
                          </button>
                        ) : (
                          " "
                        )}
                      </div>
                      <div
                        // key={item.phonenumber}
                        className="row ms-sm-4 me-sm-2 availabilitybox"
                      >
                        <div className="col-4 ">
                          <input
                            type="checkbox"
                            id="switch1"
                            className="checkbox"
                            checked={availability}
                            onChange={() => {
                              setAvailability(!availability);
                            }}
                          />
                          <label for="switch1" className="toggle">
                            {/* <p className='text-center mt-2 textClrSign'></p> */}
                          </label>
                        </div>

                        <div className=" col-4 py-1 text-center">
                          <p className="text-muted">
                            <span>
                              <img
                                src={calender}
                                className="calenOrder"
                                alt=""
                              />
                            </span>
                            Starting Date
                          </p>
                        </div>
                        <div className=" col-4 py-1 text-start">
                          <p className="text-muted">
                            <span>
                              <img
                                src={calender}
                                className="calenOrder"
                                alt=""
                              />
                            </span>
                            Ending Date
                          </p>
                        </div>

                        {contacts
                          .sort(
                            (a, b) => {
                              return (
                                new Date(a.startingdate) - new Date(b.startingdate)
                              )
                            }
                            // item.startingdate &&
                            // item.endingdate
                          )
                          .map((item, index) => (
                            <div key={index} className=" row mb-1 pe-0">
                              <div className=" col-5 ">
                                <div className=" box-contactName me-1">
                                  <input
                                    type="text"
                                    className=" fntSupp4 "
                                    onChange={(e) => {
                                      handleChange(
                                        index,
                                        "description",
                                        e.target.value
                                      );
                                      setContactDescription(e.target.value);
                                    }}
                                    value={item.description || ""}
                                    disabled={availability == false}
                                  />
                                  {/* <h4 className="vacations box-contactName py-1">Vacations </h4> */}
                                </div>
                              </div>
                              <div className=" col-3 numcolor">
                                <div className=" box-contactName me-1">
                                  <input
                                    type="date"
                                    className="fntSupp"
                                    onChange={(e) => {
                                      handleChange(
                                        index,
                                        "startingdate",
                                        e.target.value
                                      );
                                      setContactStartingdate(e.target.value);
                                    }}
                                    value={item.startingdate || ""}
                                    disabled={availability == false}
                                  />
                                </div>
                                {/* <p className=" box-contactName vacations py-1">01.08.2022</p> */}
                              </div>
                              <div className="numcolor col-3 ">
                                {/* <p className=" box-contactName py-1">01.08.2022</p> */}
                                <div className=" box-contactName me-1">
                                  <input
                                    type="date"
                                    className="fntSupp"
                                    onChange={(e) => {
                                      handleChange(
                                        index,
                                        "endingdate",
                                        e.target.value
                                      );
                                      setContactEndingdate(e.target.value);
                                    }}
                                    value={item.endingdate || ""}
                                    disabled={availability == false}
                                  />
                                </div>
                              </div>
                              <div className="col-1  pt-1">
                                <button
                                  onClick={() => {
                                    let data = [...contacts];
                                    data.splice(index, 1);
                                    setContacts(data);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  width={15}
                                  disabled={availability == false}
                                  className="deletebtn"
                                />
                              </div>
                              {availability == true &&
                                item.endingdate == "" &&
                                item.startingdate == "" &&
                                item.description == "" && (
                                  <div className="errormsg1">
                                    Please Fill This Availability Block
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* <div className="">
                      <div className="ms-4 ps-4 ps-lg-0">
                        <h3 className="mt-4 fntSupp1">Notes</h3>
                        <div className="pe-5">
                          <textarea
                            className="form-control notesForm1 mt-2 fntSupp45"
                            onChange={(e) => setNotes(e.target.value)}
                            rows="14"
                          ></textarea>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-12 col-lg-5">
                    {/* <div className="row">
                                        </div> */}

                    <div className="row ps-4 ps-lg-0 mt-3 teamdaddres">
                      {/* <h3 className="mt-5 fntSupp3">Notes</h3> */}
                      {/* <Image roundedCircle src={Add1}></Image> */}
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="col-12 text-xl-center text-lg-center text-md-center col-xl-12 col-md-12 col-lg-12 pb-4 ps-xxl-3 ps-xl-5 ps-md-3 ps-sm-3">
                          {imgUrl == "" ? (
                            <div>
                              <button
                                className="signUpldbtn text-center mt-4 hide fw-bold ms-xxl-4"
                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-2 rounded-circle"
                              // checked={jobtitle === "Driver"}
                              className={
                                isActive == "four"
                                  ? " ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5  rounded-circle default-img-border mt-4 "
                                  : " ms-xxl-4 ms-xl-0 rounded-circle hide"
                              }
                              alt=""
                            />
                          )}

                          {imgUrl == "" ? (
                            <div>
                              <button
                                className="signUpldbtn text-center  mt-4 fw-bold ms-xxl-4"
                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-2 rounded-circle"
                              // checked={jobtitle === "Driver"}
                              className={
                                isActive == "one"
                                  ? "  ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5 rounded-circle Mechanicimgborder mt-4"
                                  : " ms-xxl-4 ms-xl-0 one rounded-circle hide"
                              }
                              alt=""
                            />
                          )}

                          {imgUrl == "" ? (
                            <div>
                              <button
                                // className="signUpldbtn text-center mt-4 fw-bold ms-4"
                                className={
                                  isActive == "two"
                                    ? "hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                    : "two hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                }
                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-2 rounded-circle"
                              // checked={jobtitle === "Driver"}
                              className={
                                isActive == "two"
                                  ? " ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5 rounded-circle Mechaniconsiteimgborder mt-4 "
                                  : " ms-xxl-4 ms-xl-0 two rounded-circle hide"
                              }
                              alt=""
                            />
                          )}

                          {imgUrl == "" ? (
                            <div>
                              <button
                                // className="signUpldbtn text-center mt-4 fw-bold ms-4"
                                className={
                                  isActive == "three"
                                    ? "hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                    : "three hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                }
                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-2 rounded-circle"
                              // checked={jobtitle === "Driver"}
                              className={
                                isActive == "three"
                                  ? " ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5 rounded-circle driverimgborder mt-4 "
                                  : " ms-xxl-4 ms-xl-0 three rounded-circle hide"
                              }
                              alt=""
                            />
                          )}

                          <input
                            type="file"
                            id="file"
                            onChange={(e) => {
                              handleFileChange(e);
                            }}
                            ref={inputFile}
                            placeholder=""
                            className="custom-file-input  ms-3"
                          />

                          <div className="d-flex ms-xxl-3 ms-xl-3 ms-lg-2 ms-sm-5 ps-xl-0 ps-lg-0 ps-md-3 ps-sm-0">
                            <p className="text-muted ms-xxl-5 ms-xl-0 ms-lg-0 ms-sm-0 ps-xl-0 ps-lg-0 ps-md-0 ps-sm-0 replacepic picres">
                              Replace Picture
                            </p>
                            {/* <img
                              alt="Delete"
                              src={Group62}
                              height="25px"
                              width="25px"
                              // onClick={onButtonClick}
                              className="ms-2 imgdelete"
                            /> */}

                            {/* <input type='file' id='file' ref={inputFile} onChange={(e) => { handleFileChange(e); }} style={{ display: 'none' }} /> */}

                            <img
                              type="button"
                              className="ps-2 dlticon"
                              alt="User"
                              src={Group62}
                              onClick={Imagedlt}
                              style={{
                                cursor: "pointer",
                                marginBottom: "15px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-xl-6 col-sm-6 mt-5 jobtitle">
                        <p className="text-muted">Select Job Title</p>
                        <div
                          onChange={(e) => {
                            setGender(e);
                          }}
                        >
                          {/* <input type="radio" value="MALE" name="gender"   /> Male
                                                    <input type="radio" value="FEMALE" name="gender"  /> Female */}

                          <div className="form-check">
                            <input
                              style={{ cursor: "pointer" }}
                              className="form-check-input red-color"
                              type="radio"
                              value="Mechanic"
                              name="Mechanic"
                              id="switch2"
                              checked={jobtitle === "Mechanic"}
                              onClick={() => {
                                setIsActive("one");
                                setJobtitle("");
                              }}
                              onChange={handleChange2}
                            />
                            <label className="form-check-label" for="switch2">
                              Mechanic
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              style={{ cursor: "pointer" }}
                              className="form-check-input blue-color"
                              type="radio"
                              value="Mechanic on site"
                              name="Mechaniconsite"
                              id="switch3"
                              checked={jobtitle === "Mechanic on site"}
                              onClick={() => {
                                setIsActive("two");
                                setJobtitle("");
                              }}
                              onChange={handleChange2}
                            />
                            <label className="form-check-label" for="switch3">
                              Mechanic on site
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              style={{ cursor: "pointer" }}
                              className="form-check-input orange-color"
                              type="radio"
                              value="Driver"
                              name="Driver"
                              id="switch4"
                              checked={jobtitle === "Driver"}
                              onClick={() => {
                                setIsActive("three");
                                setJobtitle("");
                              }}
                              onChange={handleChange2}
                            />
                            <label className="form-check-label" for="switch4">
                              Driver
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5"></div>
                    <div className="row mt-1"></div>

                    <div className="ms-xl-4 ms-lg-4 ms-md-4 ms-sm-0 px-xl-2 px-lg-2 px-md-2 px-sm-0">
                      <div className="row text-center signtogbt menuControl ms-xl-4 ms-lg-4 ms-md-4 ms-sm-0 mb-0">
                        <div className="col-4 p-0">
                          {roleName === "Manager" ? (
                            <button
                              type="button"
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4d"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Owner
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setRole("08da2f30-beca-4c07-8e42-1311289b9e4d");
                              }}
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4d"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Owner
                            </button>
                          )}
                          {/* <button
                            type="button"
                            onClick={() => {
                              setRole("08da2f30-beca-4c07-8e42-1311289b9e4d");
                            }}
                            className={
                              role === "08da2f30-beca-4c07-8e42-1311289b9e4d"
                                ? " py-2 px-0  signtogbtn1"
                                : " py-2 px-0  signtogbtn"
                            }
                          >
                            Owner
                          </button> */}
                        </div>
                        <div className="col-4 p-0">
                          {roleName === "Manager" ? (
                            <button
                              type="button"
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4c"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Manager
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setRole("08da2f30-beca-4c07-8e42-1311289b9e4c");
                              }}
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4c"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Manager
                            </button>
                          )}
                          {/* <button
                            type="button"
                            // onClick={() => {
                            //   setRole("08da2f30-beca-4c07-8e42-1311289b9e4c");
                            // }}
                            className={
                              role === "08da2f30-beca-4c07-8e42-1311289b9e4c"
                                ? " py-2 px-0  signtogbtn1"
                                : " py-2 px-0  signtogbtn"
                            }
                          >
                            Manager
                          </button> */}
                        </div>
                        <div className="col-4 p-0">
                          <button
                            type="button"
                            onClick={() => {
                              setRole("08da2f30-beca-4c07-8e42-1311289b9e4b");
                            }}
                            className={
                              role === "08da2f30-beca-4c07-8e42-1311289b9e4b"
                                ? " py-2  px-1 signtogbtn1"
                                : " py-2  px-0 signtogbtn"
                            }
                          >
                            User
                          </button>
                        </div>
                      </div>

                      <div className="ms-2 ps-5 ps-lg-3  me-5 mt-5">
                        <h3 className="pt-4 fntSupp1 ">Notes</h3>
                        <div className="pe-2">
                          <textarea
                            className="form-control notesForm1 mt-2 fntSupp45 notes"
                            onChange={(e) => setNotes(e.target.value)}
                            rows="14"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}

                  {/*  */}
                </div>
                <div className="row ps-4 ms-1 pe-4 pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4 ">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 mt-5 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc w-100 h-100 p-2"
                          // onClick={() => history.push("/team")}
                          onClick={handleCancelBtn}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            jobtitle == "" ||
                              firstname == "" || lastname == "" || password == ""
                              ? // ||
                              // availability == false
                              // address1 == "" ||
                              // password == "" ||
                              // email == "" ||
                              // phoneNumber == "" ||
                              // availability == "" ||
                              // contacts[0].description == "" ||
                              // contacts[0].startingdate == "" ||
                              // contacts[0].endingdate == "" ||
                              // for second Field
                              // costHour == "" ||
                              // jobtitle == ""
                              "countBtnchange1 w-100 p-2"
                              : "countBtnchange w-100 h-100 p-2"
                          }
                          onClick={(e) => {
                            handleAdd(e);
                          }}
                          disabled={
                            firstname == "" || lastname == "" || password == ""
                            // ||
                            // availability == false
                            // address1 == "" ||
                            // password == "" ||
                            // email == "" ||
                            // phoneNumber == "" ||
                            // availability == "" ||
                            // contacts[0].name == "" ||
                            // contacts[0].phonenumber == "" ||
                            // contacts[0].email == "" ||
                            // costHour == "" ||
                            // jobtitle == ""
                            // notes == ""
                          }
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamAdd;
