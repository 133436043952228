import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../styles/local/client.css";
import clientADD from "../../assets/icons/clientADD.svg";
import pencil_icon from "../../assets/icons/pencil_icon.svg";
import Group62 from "../../assets/icons/Group62.svg";
import duplicate from "../../assets/icons/duplicate.svg";
import {
  fetchClient,
  deleteClients,
  fetchClientsById,
  addClient,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteCard from "../../components/dialogs/DeleteCard";
import DeleteClient from "../../components/dialogs/DeleteClient";
import arrowup from "../../assets/icons/arrowup.svg";
import Headerclient from "../../components/Headerclient";
import Pagination from "../../components/Pagination";
import { ADD_CLI } from "../../redux/constants/type";

const Client = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const UserId = window.sessionStorage.getItem("userid");
  const fetchclient = useSelector((state) => state.user.fetchClient);
  const fetchclientsbyid = useSelector((state) => state.user.fetchClientsById);
  const newCLientAdded = useSelector((state) => state.user.addClient);
  const [ data, setData ] = useState([]);
  const [ clientsearch, setClientsearch ] = useState("");
  const [ sortdata, setSortData ] = useState(true);
  const [ sortarrow, setSortarrow ] = useState("");
  const [ pagenumber, setPageNumber ] = useState(1);
  const [ searchclient, setSearchclient ] = useState("");
  // const [ pagenumber, setPageNumber ] = useState(0);
  const [ client, setClient ] = useState([]);
  const initialRender = React.useRef(true);
  const initialRender1 = React.useRef(true);
  const [ contacts, setContacts ] = useState([]);
  const [ StatusPopup, setStatusPopup ] = useState(false);
  const [ clientId, setClientId ] = useState(null);
  const [ isCalled, setIsCalled ] = useState(false);
  const [ isCalled1, setIsCalled1 ] = useState(false);
  const [ totalCount, setTotalCount ] = useState(0);
  const [ highlightClient, setHighlightClient ] = useState("");
  // const sorting = () => {
  //   const data1 = [...data].sort((a, b) => (a.name < b.name ? -1 : 1));
  //   setData(data1)
  // };

  // const unsorting = () => {
  //   dispatch(fetchClient(pagenumber));
  // };

  useEffect(() => {
    if (newCLientAdded && newCLientAdded.status) {
      dispatch(fetchClient(pagenumber, searchclient));
      setHighlightClient(newCLientAdded.data.clientid);
      setTimeout(() => {
        dispatch({ type: ADD_CLI, data: null });
      }, 3000);
    } else {
      if (highlightClient) {
        setHighlightClient("");
      }
    }
  }, [ dispatch, newCLientAdded ]);

  const sorting = () => {
    setSortData((prev) => !prev);
    setSortarrow((prev) => !prev);
    // setSortarrow("one"); for if we want to arrow up and down only onetime click 
    handlesortName();
  };

  const handlesortName = () => {
    if (sortdata) {
      const data1 = [ ...data ].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
      setData(data1);
      // return 0;
    } else {
      dispatch(fetchClient(pagenumber, searchclient));
      // const data1 = [...data].sort((a, b) => (a.name > b.name ? -1 : 1));
      // setData(data1);
      // return 0;
    }
    // setData(data1);
  };

  useEffect(() => {
    if (searchclient >= 0) {
      dispatch(fetchClient(pagenumber, searchclient));
    } else {
      dispatch(fetchClient(0, searchclient));
    }
    // setIsCalled(false);
    // setIsCalled1(false);
  }, [ searchclient ]);

  const handleClose = () => {
    setStatusPopup(false);
  };

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchclient != null) {
        if (fetchclient.status == true) {
          setData(fetchclient.data);
          setTotalCount(fetchclient.totalCounts);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ fetchclient ]);

  React.useEffect(() => {
    if (!initialRender1.current) {
      if (fetchclientsbyid != null) {
        if (fetchclientsbyid.status == true) {
          setClient(fetchclientsbyid.data);
        }
      }
    } else {
      initialRender1.current = false;
    }
  }, [ fetchclientsbyid ]);
  const [ activeIndex, setindex ] = useState([]);
  const handleShow = (index) => {
    if (activeIndex.includes(index)) {
      let fil = [ ...activeIndex ].filter((x) => x !== index);
      setindex(fil);
    } else {
      setindex((prev) => [ ...prev, index ]);
    }
  };

  const pageNumbers = [ ...Array(pagenumber + 2).keys() ].slice(1);

  // NEW FILTER START

  // const [ members, setMembers ] = useState([]);

  // let mainMembers;

  // const itemsPerPage = 10;
  // const numberOfPages = itemsPerPage;
  // const newMembers = Array.from({ length: numberOfPages }, (_, index) => {
  //   const start = index * itemsPerPage;
  //   return data.slice(start, start + itemsPerPage);
  // });
  // mainMembers = data;

  // useEffect(() => {
  //   if (data) {
  //     if (clientsearch) {
  //       let tempMembers = [ ...data ];
  //       tempMembers = tempMembers.filter((item) => {
  //         // const fullName = `${item.contactdetails[ 0 ].firstname} ${item.contactdetails[ 0 ].lastname}`;
  //         // {item.clientcontact[ 0 ]?.contactdetails[ 0 ]?.name}
  //         const fullName = `${item.clientcontact[ 0 ]?.contactdetails[ 0 ]?.name}`;
  //         const contactPhone = `${item.clientcontact[ 0 ]?.contactdetails[ 0 ]?.phonenumber}`;
  //         // const fullName = `${item.name}`;
  //         return (
  //           // item.firstname.toLowerCase().startsWith(clientsearch) ||
  //           // item.lastname.toLowerCase().startsWith(clientsearch) ||

  //           item.name.toLowerCase().startsWith(clientsearch) ||
  //           fullName.toLowerCase().startsWith(clientsearch) ||
  //           item.email.toLowerCase().startsWith(clientsearch) ||
  //           contactPhone.toLowerCase().startsWith(clientsearch) ||

  //           item.phonenumber
  //             .toLowerCase()
  //             .startsWith(clientsearch)
  //         );
  //       });

  //       const itemsPerPage = 80;
  //       const numberOfPages = Math.ceil(tempMembers?.length / itemsPerPage);
  //       const newMembers = Array.from({ length: numberOfPages }, (_, index) => {
  //         const start = index * itemsPerPage;
  //         return tempMembers.slice(start, start + itemsPerPage);
  //       });
  //       setMembers(newMembers);
  //     }
  //   }
  // }, [ clientsearch ]);

  // NEW FILTER END

  return (
    <>
      {StatusPopup && (
        <DeleteClient
          handleClose={handleClose}
          clientId={clientId}
          pagenumber={pagenumber}
          setIsCalled1={setIsCalled1}
        />
      )}
      <Headerclient
        setClientsearch={setClientsearch}
        searchclient={searchclient}
        setSearchclient={setSearchclient}
      />
      <div className="parent">
        <div className="outer mb-3">
          <div className="add-supplier-row">
            <div className="">
              <img
                alt=""
                src={clientADD}
                className="Add1"
                onClick={() => history.push("/add-client")}
              />
            </div>
            <div className="image-heading countFontclr">
              <h2>Clients</h2>
            </div>
          </div>
          <div className="tableWrapper">
            <table className="table table-hover-client">
              <thead>
                <tr style={{ borderBottom: "1px solid #8080807a" }}>
                  <th className="fnt2">
                    <img
                      src={arrowup}
                      alt=""
                      className={sortarrow == "" ? "" : "ArrowUpDown"}
                      onClick={sorting}
                      style={{ cursor: "pointer" }}
                    ></img>
                    {/* <img src={arrowup} className="px-2 " onClick={unsorting} style={{ cursor: "pointer" }}></img> */}
                    &nbsp; Company Name
                  </th>
                  <th className="fnt1">Telephone</th>
                  <th className="fnt1">E-mail</th>
                  <th className="fnt1">Contact Name</th>
                  <th className="fnt1" style={{ visibility: "hidden" }}>
                    b
                  </th>
                  <th className="fnt1">Contact Telephone</th>
                  <th className="fnt1"></th>
                </tr>
              </thead>

              {data
                .filter(
                  (item) =>
                    item.name.toLowerCase().includes(clientsearch) ||
                    item.clientcontact[ 0 ]?.contactdetails[ 0 ]?.name
                      .toLowerCase()
                      .includes(clientsearch) ||
                    item.clientcontact[ 0 ]?.contactdetails[ 0 ]?.phonenumber
                      .toLowerCase()
                      .includes(clientsearch) ||
                    item.email.toLowerCase().includes(clientsearch)
                )
                .map((item, index) => (
                  <tbody className="tabLine">
                    <tr key={index} className={highlightClient === item.clientid ? "new-row" : ""}>
                      <td className="fnttable ps-3" style={{ fontWeight: "bolder" }}>
                        {item.name}
                      </td>
                      <td className="fnttable">{item.phonenumber}</td>
                      <td className="fnttable">{item.email}</td>
                      <td className="fnttable">
                        {item.clientcontact[ 0 ]?.contactdetails[ 0 ]?.name}
                      </td>

                      <td>
                        {item.clientcontact.length > 1 ? (
                          <button
                            className="bubbless"
                          // onClick={() => handleShow(index)}
                          >
                            +{item.clientcontact.length - 1}
                          </button>
                        ) : (
                          " "
                        )}
                      </td>

                      {/* <td className="fnttable">
                    <div className="row">
                      <div className="col">
                        {item.clientcontact[0]?.contactdetails[0]?.name}
                      </div>
                      <div className=" col">
                        {item.clientcontact.length > 1 ? (
                          <button
                            className="bubble"
                            // onClick={() => handleShow(index)}
                          >
                            +{item.clientcontact.length - 1}
                          </button>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </td> */}

                      {/* <td className="fnttable">
                    {item.clientcontact[0]?.contactdetails[0]?.name}
                  </td> */}
                      <td className="fnttable">
                        {item.clientcontact[ 0 ]?.contactdetails[ 0 ]?.phonenumber}
                      </td>

                      <td>
                        <img
                          alt=""
                          src={pencil_icon}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.sessionStorage.setItem(
                              "clientid",
                              item.clientid
                            );
                            history.push("/update-client");
                          }}
                        />
                        <img
                          alt=""
                          src={duplicate}
                          className="px-3"
                          onClick={() => {
                            {
                              item.clientcontact.map((val) =>
                                contacts.push({
                                  name: val.contactdetails[ 0 ].name,
                                  companyid:
                                    "3ba6542d-de49-4ffd-bdc5-e5d53313b309",
                                  phonenumber:
                                    val.contactdetails[ 0 ].phonenumber,
                                  email: val.contactdetails[ 0 ].email,
                                })
                              );
                            }
                            dispatch(
                              addClient(
                                UserId,
                                item.name + " Copy",
                                item.address1,
                                item.address2,
                                item.email,
                                item.phonenumber,
                                item.notes,
                                contacts
                              )
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          alt=""
                          src={Group62}
                          onClick={() => {
                            setStatusPopup(true);
                            setClientId(item.clientid);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}
              {data.length === 0 && <span>No data to display!</span>}
            </table>
          </div>
          <Pagination
            currentPage={pagenumber}
            totalCount={totalCount}
            onPageChange={(page) => {
              dispatch(fetchClient(page, searchclient))
              setPageNumber(page)
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Client;
