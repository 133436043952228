import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../../redux/actions/action";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import {
  fetchSuppliers,
  deleteProduct,
  fetchProductStock,
  deletePart,
} from "../../redux/actions/action";
import "../../styles/local/deletecard.css";

const DeleteProduct = (props) => {
  const dispatch = useDispatch();
  const [destination, setDestination] = React.useState("");
  const changeStatusData = useSelector((state) => state.user.changeStatus);
  const initialRender = React.useRef(true);
  const orderId = window.sessionStorage.getItem("orderId");
  const inputRef = useRef(null);
  const history = useHistory();

  const handleDelete = () => {
    if (props.type === "Part") {
      dispatch(deletePart(props.productId));
      dispatch(fetchProductStock(props.pagenumber));
      props.setIsCalled1(true);
      props.handleClose();
      setTimeout(() => {
        window.location.reload(); 
      }, 1000);
    } else {
      dispatch(deleteProduct(props.productId));
      dispatch(fetchProductStock(props.pagenumber));
      props.setIsCalled1(true);
      props.handleClose();
      setTimeout(() => {
        window.location.reload(); 
      }, 1000);
    }
  };

  return (
    <div className="bg-container">
      <Modal
        animation={false}
        show={true}
        className="popovermodal11"
        style={{
          width: "20em",
        }}
        bottom
      >
        <div className="container popheaderbg1 pt-3 pb-2 ps-3">
          <div className="row text-center my-3 mx-2">
            You will delete this information and all the information related
            with it.
          </div>
          <div className="row justify-content-center my-2 mb-4">
            Do you want to proceed?
          </div>
        </div>
        <div className="">
          <div className="row justify-content-center ">
            <div className="col-6 pe-0 text-center">
              <button
                className=" popcancelbtn"
                type="button"
                onClick={props.handleClose}
              >
                Cancel
              </button>
            </div>
            <div className="col-6 ps-0 text-center">
              <button
                // onClick={handleUpdate}
                className="popaddbtn "
                type="button"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default DeleteProduct;
