import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PageNotFound from "../pages/static/en/PageNotFound";
import Homepage from "../pages/dynamic/Homepage";
import Profile from "../pages/dynamic/Profile";
import Landingpage from "../pages/static/en/LandinPage";
import ChangePassword from "../pages/static/en/ChangePassword";
import { useHistory } from "react-router-dom";
import Sidebar from "../pages/static/en/Sidebar";
import TeamUser from "../pages/dynamic/TeamUser";
import TimelineView from "../pages/dynamic/TimelineView";
import Inventory from "../pages/dynamic/Inventory";
import Order from "../pages/dynamic/Order";
import OrderCopy from "../pages/dynamic/OrderCopy";
import CountStock from "../pages/dynamic/CountStock";
import Client from "../pages/dynamic/Client";
import AddClient from "../pages/dynamic/AddClient";
import UpdateClient from "../pages/dynamic/UpdateClient";
import Warehouse from "../pages/dynamic/Warehouse";
import supplier from "../pages/dynamic/Supplier";
import AddSupplier from "../pages/dynamic/AddSupplier";
import UpdateSupplier from "../pages/dynamic/UpdateSupplier";
import Products from "../pages/dynamic/Products";
import AddProducts from "../pages/dynamic/AddProducts";
import UpdateProducts from "../pages/dynamic/UpdateProducts";
import UserVerification from "../pages/static/en/UserVerification";
import ComposeProduct from "../pages/dynamic/ComposeProduct";
// import TeamAdd from "../pages/dynamic/TeamAdd";
import Composeadd from "../pages/dynamic/Composeadd";
import AddPuchasingOrder from "../pages/dynamic/AddPuchasingOrder";
import TeamAdd from "../pages/dynamic/TeamAdd";
import UpdateTeamUser from "../pages/dynamic/UpdateTeamUser";
// import NewPolicy from "../pages/static/en/NewPolicy";

const Router = (props) => {
  const history = useHistory();
  const userId = window.sessionStorage.getItem("userid");

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          userId != null ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  };

  return (
    <>
      <Switch>
        <Route exact path="/" component={Landingpage} />
        <PrivateRoute exact path="/home" component={Homepage} />
        {/* <Route exact path="/sbar" component={Sidebar} /> */}
        <Route
          exact
          path="/changepassword/:userid"
          component={ChangePassword}
        />
        <Route
          exact
          path="/user-verification/:userid"
          component={UserVerification}
        />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/count-stock" component={CountStock} />
        <PrivateRoute exact path="/team" component={TeamUser} />
        <PrivateRoute exact path="/timelineview" component={TimelineView} />
        <PrivateRoute exact path="/inventory" component={Inventory} />
        <PrivateRoute exact path="/order" component={Order} />
        <PrivateRoute exact path="/ordercopy" component={OrderCopy} />
        <PrivateRoute exact path="/warehouse" component={Warehouse} />
        <PrivateRoute exact path="/client" component={Client} />
        <PrivateRoute exact path="/add-client" component={AddClient} />
        <PrivateRoute exact path="/update-client" component={UpdateClient} />
        {/* <PrivateRoute
                    exact
                    path="/count-stock"
                    component={CountStock}
                /> */}
        <PrivateRoute exact path="/supplier" component={supplier} />
        <PrivateRoute exact path="/add-supplier" component={AddSupplier} />
        <PrivateRoute exact path="/add-team-member" component={TeamAdd} />
        <PrivateRoute
          exact
          path="/update-team-member"
          component={UpdateTeamUser}
        />
        <PrivateRoute exact path="/products" component={Products} />
        <PrivateRoute exact path="/add-parts" component={AddProducts} />
        <PrivateRoute exact path="/compose-add" component={Composeadd} />
        <PrivateRoute
          exact
          path="/add-puchasing-order"
          component={AddPuchasingOrder}
        />
        <PrivateRoute
          exact
          path="/add-puchasing-order/:id"
          component={AddPuchasingOrder}
        />
        <PrivateRoute
          exact
          path="/compose-product"
          component={ComposeProduct}
        />
        <PrivateRoute
          exact
          path="/update-products"
          component={UpdateProducts}
        />
        <PrivateRoute
          exact
          path="/update-supplier"
          component={UpdateSupplier}
        />
           {/* <PrivateRoute exact path="/new-policy" component={NewPolicy} /> */}

        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default React.memo(Router) ;
