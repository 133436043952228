import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import "../../styles/local/cancel.css";
const Cancel = (props) => {
  return (
    <div>
      <Modal
        className="createordermodal"
        animation={false}
        show={true}
        centered
      >
        <ModalBody className="px-3 pb-0 modalbody">
          <div className="container px-5">
            <div className="row text-center">
              <p style={{ paddingTop: "30px" }}>
                The changes made will not be saved in the system. Do you want to
                cancel the entered data.
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="modalfooter">
          <div className="row justify-content-center">
            <div className="col-6 pe-0 text-center">
              <button
                className="  nobtn"
                type="button"
                onClick={props.handleCancel}
              // style={{fontWeight: "700"}}
              >
                No
              </button>
            </div>
            <div className="col-6 ps-0 text-center">
              <button
                className=" addbtn "
                type="button"
                onClick={(e) => {
                  props.handleOk();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default Cancel;
