import React, { useEffect, useRef, useState, useContext } from "react";
import "../../styles/local/landingpage.css";
import Header from "../../components/Header";
import DraggableCard from "../../components/DraggableCard";
import {
  stockAvailable,
  shipmentStatus,
  nextOrder,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import cart from "../../assets/icons/cart.svg";
import Loader from "../../components/Loader";
import { cardShow } from "../static/en/Sidebar";
import { stockQuntityFormat } from "../../utils/NumberFormatter";

const Homepage = () => {
  const dispatch = useDispatch();
  const expandMenu = useContext(cardShow);
  const UserID = window.sessionStorage.getItem("userid");
  const stockData = useSelector((state) => state.user.stockAvailable);
  const nextOrderData = useSelector((state) => state.user.nextOrder);
  const shipmentData = useSelector((state) => state.user.shipmentStatus);
  const fetchOrderStatusData = useSelector(
    (state) => state.user.fetchOrderStatus
  );
  const [loading, setLoading] = useState(false);
  const [loadertwo, setLoadertwo] = useState(false);

  useEffect(() => {
    dispatch(shipmentStatus("08da2f31-46b4-4ce8-80b3-fdffac7a3061"));
    dispatch(nextOrder(UserID));
    dispatch(stockAvailable("08da2f31-46b4-4ce8-80b3-fdffac7a3080"));
  }, []);



  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }, []);

  // React.useEffect(() => {
  //   setLoading(true);
  //   window.location.reload();
  // }, []);

  React.useEffect(() => {
    if (shipmentData != null) {
      if (shipmentData.status == true) {
        setLoading(false);
      }
    }
  }, [shipmentData]);

  const handleShipment = () => {
    dispatch(shipmentStatus("08da2f31-46b4-4ce8-80b3-fdffac7a3061"));
  };

  return (
    <>
      <Header />
      <div>
        <div>
          <div className="container-fluid  homeback home_scroll">
            {/* <h3 className="py-3 ps-2 themecolor">Dashboard</h3> */}
            {/* <div className="row pb-5">
              <div
                className={
                  loading == true
                    ? "col-xl-10 col-lg-9 col-md-12 col-sm-12 col-12 "
                    : "col-xl-10 col-lg-9 col-md-12 col-sm-12 col-12  scrollsection"
                }
              >
                <DraggableCard handleShipment={handleShipment} />
              </div> */}
            {loading == true ? (
              ""
            ) : (
              <div className="row pb-5">
                {loadertwo == true && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border loader" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                <div
                  className={
                    loading == true || expandMenu === true
                      ? "col-xl-10 col-lg-9 col-md-12 col-sm-12 col-12 "
                      : "col-xl-10 col-lg-9 col-md-12 col-sm-12 col-12 scrollsection"
                  }
                >
                  <DraggableCard handleShipment={handleShipment} />
                </div>
                <div className={expandMenu === false ? "col-xl-2 col-lg-3 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0 mt-md-4 mt-sm-4 mt-lg-0 px-xl-0 px-lg-0 px-md-5 px-sm-5 px-2" : "d-none"}>
                  <div className="card pt-3 mb-3 pb-1  homeCardLastcol">
                    <div className="card-body text-center">
                      <div className="row text-center">
                        <div className="col-4 pe-0">
                          {/* <i className="bi bi-box homeBox"></i> */}
                          <img src={cart} alt="" />
                        </div>
                        <div className="col pe-0 text-start">
                          <h5
                            className="card-title"
                            style={{ fontWeight: "700", color: "#003766" }}
                          >
                            Total Stock Available
                          </h5>
                        </div>
                      </div>
                      <div className="row pt-4">
                        {/* <div className="row">
                          <h6 className="arrivingtext1 ps-4 col-7 col-lg-8 col-md-5 col-sm-7">
                            Arriving
                          </h6>
                        </div> */}
                        {stockData != null &&
                          stockData.data.map((val, index) =>
                            index < 5 ? (
                              <div className="d-flex pe-0 ">
                                <div className="homeCardDigit col-3 mx-2">
                                  {stockQuntityFormat(val?.instock)}
                                </div>
                                {/* <div className="plussSign">+</div>
                                <div className="arrivingtext2 col-2">
                                  {val.arriving}
                                </div> */}
                                <div
                                  className="homeCardText col-7 mx-1"
                                  style={{
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  {val?.partname}
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          )}
                        {/* <div className="col-4 pe-0">
                      <p className="homeCardDigit">807</p>
                    </div>
                    <div className="col-8 text-start homeCardText">Post</div>
                    <div className="col-4 pe-0">
                      <p className="homeCardDigit">320</p>
                    </div>
                    <div className="col-8 text-start homeCardText">
                      Glass Shield
                    </div>
                    <div className="col-4 pe-0">
                      <p className="homeCardDigit">720</p>
                    </div>
                    <div className="col-8 text-start homeCardText">Screw</div>
                    <div className="col-4 pe-0">
                      <p className="homeCardDigit">478</p>
                    </div>
                    <div className="col-8 text-start homeCardText">Holder</div> */}
                      </div>
                    </div>
                  </div>
                  {/* ready for shipment comment if u require uncomment */}
                  {/* <div className="card pt-3 pb-1  homeCardLastcol">
                    <div className="card-body text-center">
                      <div className="col px-4">
                        <h5 className="card-title">Ready For Shipment</h5>
                        <h1 className="homeCardDigit">
                          {shipmentData != null && shipmentData.data}
                        </h1>
                        <p className="homeCardText ">Outdoor Shelter</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="card pt-3 mt-3 pb-1 homeCardLastcol">
                    <div className="card-body text-center">
                      <div className="col px-4">
                        <h5 className="card-title"
                          style={{ fontWeight: "700", color: "#003766" }}>Orders for next 30 days</h5>
                        <h1 className="homeCardDigit">
                          {nextOrderData != null && nextOrderData.data}
                        </h1>
                        <p className="homeCardText ">Outdoor Shelter</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
