import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Group91 from "../../assets/icons/Group91.png";
import "../../styles/local/addSupplier.css";
import Group62 from "../../assets/icons/Group62.svg";
import { addSupplier, updateSupplier } from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchSuppliersById } from "../../redux/actions/action";

const Contact = (props) => {
  const defaultContact = props.contact;
  const [ name, setName ] = useState(defaultContact.name);
  const [ phone, setPhone ] = useState(defaultContact.phonenumber);
  const [ email, setEmail ] = useState(defaultContact.email);
  const roleName = window.sessionStorage.getItem("rolename");
  const { index, handleChange, contacts, setContacts, handleClick } = props;

  useEffect(() => {
    setName(defaultContact.name);
    setPhone(defaultContact.phonenumber);
    setEmail(defaultContact.email);

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(email)) {
      props.setEmailInvalid2(true);
    } else {
      props.setEmailInvalid2(false);

    }

  }, [ contacts, name, phone, email, props.contact ]);

  return (
    <React.Fragment>
      <div className="row ms-sm-4 me-sm-2 px-5">
        <div className="col-12 col-sm-9 mt-sm-4 pt-sm-3">
          <h3 className=" pt-3 fntSupp1">Contact Name</h3>
        </div>

        <div className="col-12 col-sm-3 text-sm-end mt-sm-5 pt-sm-1 ">
          {contacts.length - 1 === index ? (
            <span
              type="button"
              className={roleName !== "User" ? " fntSupp1" : "d-none  fntSupp1"}
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            >
              Add..
            </span>
          ) : (
            <img
              className={roleName !== "User" ? " " : "d-none"}
              src={Group62}
              className={roleName !== "User" ? "nullCss" : "d-none  nullCss"}
              onClick={() => {
                let data = [ ...contacts ];
                data.splice(index, 1);
                setContacts(data);
              }}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>

        <input
          className={roleName !== "User" ? " form-control suppInput mt-2 fntSupp4" : "field-disable  form-control suppInput mt-2 fntSupp4"}
          type="text"
          onChange={(e) => {
            handleChange(index, "name", e.target.value);
            setName(e.target.value);
          }}
          value={name}
        />

        <div className="col-12 col-lg-6">
          <div className=" box-contactName mt-2 mt-xl-1 str14">
            <div className="d-flex align-items-start">
              <span className="lev">Telephone</span>
            </div>
            <input
              type="text"
              className=
              {roleName !== "User" ? "fntSupp4" : "field-disable  fntSupp4"}
              onChange={(e) => {
                handleChange(index, "phonenumber", e.target.value);
                setPhone(e.target.value);
              }}
              value={phone}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className=" box-contactName mt-2 mt-xl-1 str13">
            <div className="d-flex align-items-start">
              <span className="lev">E-mail</span>
            </div>

            <input
              type="text"
              className=
              {roleName !== "User" ? "fntSupp4" : "field-disable  fntSupp4"}
              onChange={(e) => {
                handleChange(index, "email", e.target.value);
                setEmail(e.target.value);
              }}
              value={email}
            />
          </div>

          <div className="mt-2 ps-2 mt-xl-1">
            {!props.validEmail?.test(email) && email ?
              <div className="ms-1 ps-4" style={{ color: "red", fontSize: "14px" }}>
                Email is invalid
              </div> : ""}
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

const UpdateSupplier = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ show, setShow ] = useState([ { contact: "", id: 1 } ]);
  const [ display, setDisplay ] = useState([ { contact: "", id: 1 } ]);
  const UserId = window.sessionStorage.getItem("userid");
  const fetchsuppliersbyid = useSelector(
    (state) => state.user.fetchSuppliersById
  );
  const companyId = window.sessionStorage.getItem("compId");
  const [ Name, setName ] = useState("");
  const [ contactCount, setContactCount ] = useState(1);
  const [ address1, setAddress1 ] = useState("");
  const [ address2, setAddress2 ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const [ notes, setNotes ] = useState("");
  const [ contname, setContactName ] = useState("");
  const [ contactNumber, setContactNumber ] = useState("");
  const roleName = window.sessionStorage.getItem("rolename");
  const [ supplier, setSupplier ] = useState([]);
  const [ partList, setPartList ] = useState([]);
  const initialRender = React.useRef(true);
  const [ detailscontactname, setDetailscontactname ] = useState("");
  const [ contactPhoneNumber, setContactPhoneNumber ] = useState("");
  const [ contactEmail, setContactEmail ] = useState("");
  const [ emailInvalid, setEmailInvalid ] = useState(false);
  const [ emailInvalid2, setEmailInvalid2 ] = useState(false);
  const [ errorInvalid, setErrorInvalid ] = useState(false);
  const [ supplierId, setSupplierId ] = useState("");
  const [ detailssupplierid, setDetailssupplierid ] = useState("");
  const [ detailsname, setDetailsname ] = useState("");
  const [ detailsphonenumber, setDetailsphonenumber ] = useState("");
  const [ detialsemail, setDetailsemail ] = useState("");
  const [ contact, setcontact ] = useState("");
  const [ contacts, setContacts ] = useState([
    {
      name: "",
      phonenumber: "",
      email: "",
    },
  ]);

  const handleChange = (id, field, val) => {
    const modifiedContacts = contacts;
    modifiedContacts[ id ][ field ] = val;
    setContacts(modifiedContacts);
  };

  const handleClick = () => {
    setShow([ ...show, { contact: "", id: contactCount + 1 } ]);
    setContactCount(contactCount + 1);
    setContactNumber(contactNumber + 1);
    const modifiedContacts = contacts;
    modifiedContacts.push({
      name: "",
      phonenumber: "",
      email: ""

    });

    setContacts(modifiedContacts);
  };

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function hasNonBlankValidEmail(contacts) {
    for (const contact of contacts) {
      if (!contact.email || !isValidEmail(contact.email)) {
        return false; // Return false if the email is blank or invalid
      }
    }
    return true; // Return true if all emails are non-blank and valid
  }

  const handleAdd = (e) => {
    let listContatcts = contacts;
    listContatcts = listContatcts.filter(x => x.name !== "" && x.email !== "" && x.phonenumber !== "");
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isAllContactValidate = hasNonBlankValidEmail(contacts);

    if (isAllContactValidate) {

      if (!re.test(email)) {
        setEmailInvalid(true);
      } else {
        dispatch(
          updateSupplier(
            supplierId,
            Name,
            address1,
            address2,
            email,
            phoneNumber,
            notes,
            listContatcts
          )
        );
        setTimeout(() => {
          history.goBack()
        }, 1000);

        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    dispatch(fetchSuppliersById(window.sessionStorage.getItem("supplierid")));
  }, []);
  let helper = [];

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchsuppliersbyid != null) {
        if (fetchsuppliersbyid.status == true) {
          helper = fetchsuppliersbyid.data[ 0 ]?.contactdetails;
          setSupplier(fetchsuppliersbyid.data);
          setSupplierId(fetchsuppliersbyid.data[ 0 ]?.supplierid);
          setName(fetchsuppliersbyid.data[ 0 ]?.name);
          setAddress1(fetchsuppliersbyid.data[ 0 ]?.address1);
          setAddress2(fetchsuppliersbyid.data[ 0 ]?.address2);
          setEmail(fetchsuppliersbyid.data[ 0 ]?.email);
          setPhoneNumber(fetchsuppliersbyid.data[ 0 ]?.phonenumber);
          setNotes(fetchsuppliersbyid.data[ 0 ]?.notes);

          let formatContacts = fetchsuppliersbyid.data[ 0 ]?.contactdetails.map(
            (contact) => {
              return {
                contactid: contact?.contacts[ 0 ].contactid,
                name: contact?.contacts[ 0 ].name,
                phonenumber: contact?.contacts[ 0 ].phonenumber,
                email: contact?.contacts[ 0 ].email,
                subject: "",
                notes: "",
                isdeleted: 0,
              };
            }
          );
          setContacts(formatContacts);

          {
            fetchsuppliersbyid[ 0 ]?.contactdetails.map(
              (item) => (
                setShow([ ...show, { contact: "", id: contactCount + 1 } ]),
                setContactCount(contactCount + 1)
              )
            );
          }
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ fetchsuppliersbyid ]);

  return (
    <>
      <Header />
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-5">
            <h3 className="py-3 ps-2 themecolor header-title-sk">Suppliers</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-11 custCardCount">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5 className="py-4 fntSupp2 text-sm-start text-center">
                    <span className="me-4">
                      <img src={Group91} className="countProicon" />
                    </span>
                    <span style={{ fontSize: "22px" }}>Suppliers </span>
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <div className="row ms-sm-4 me-sm-2 px-5">
                      <h3 className="mt-5 fntSupp1">Name</h3>
                      <input

                        className={roleName !== "User" ? "form-control suppInput mt-2 fntSupp4" : "field-disable form-control suppInput mt-2 fntSupp4"}

                        onChange={(e, value) => setName(e.target.value)}
                        value={Name}
                        type="text"
                      />

                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName mt-2 mt-xl-1 str12">
                          <div className="d-flex align-items-start">
                            <span className="lev">
                              Street, House number
                            </span>
                          </div>

                          <input
                            type="text"
                            className={roleName !== "User" ? "fntSupp4" : "field-disable  fntSupp4"}
                            onChange={(e) => setAddress1(e.target.value)}
                            value={address1}
                          />
                        </div>
                        <div className=" box-contactName mt-2 mt-xl-1 str12">
                          <div className="d-flex align-items-start">
                            <span className="lev">
                              Postal ZIP Code, Place
                            </span>
                          </div>

                          <input
                            type="text"
                            className={roleName !== "User" ? "fntSupp4" : "field-disable  fntSupp4"}
                            onChange={(e) => setAddress2(e.target.value)}
                            value={address2}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName mt-2 mt-xl-1 str13">
                          <div className="d-flex align-items-start">
                            <span className="lev">E-mail</span>
                          </div>

                          <input
                            type="text"
                            className={roleName !== "User" ? "fntSupp4" : "field-disable  fntSupp4"}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                        <div className="ps-4 mt-2 mt-xl-1">
                          {emailInvalid == true && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              Email is invalid
                            </div>
                          )}
                        </div>
                        <div className=" box-contactName mt-2 mt-xl-1 str13">
                          <div className="d-flex align-items-start">
                            <span className="lev">Telephone</span>
                          </div>

                          <input
                            type="text"
                            className={roleName !== "User" ? "fntSupp4" : "field-disable  fntSupp4"}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                          />
                        </div>
                      </div>
                    </div>

                    {contacts.map((item, index) => {

                      const validEmail = new RegExp(
                        "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
                      )
                      return (
                        < Contact
                          index={index}
                          contact={item}
                          contacts={contacts}
                          setContacts={setContacts}
                          handleChange={handleChange}
                          handleClick={handleClick}
                          emailInvalid2={emailInvalid2}
                          setEmailInvalid2={setEmailInvalid2}
                          validEmail={validEmail}
                        />
                      )
                    })}
                  </div>

                  <div className="col-12 col-lg-5">
                    <div className="row ps-4 ps-lg-0">
                      <h3 className="mt-5 fntSupp3">Notes</h3>
                      <div className="pe-5">
                        <textarea
                          className={roleName !== "User" ? "form-control notesForm mt-2 fntSupp4" : "field-disable  form-control notesForm mt-2 fntSupp4"}
                          defaultValue={supplier[ 0 ]?.notes}
                          onChange={(e) => setNotes(e.target.value)}
                          rows="14"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4 ">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 mt-5 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className={roleName !== "User" ? "row justify-content-end" : "d-none"}>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc w-100 h-100 p-2"
                          style={{ fontSize: "15px" }}
                          onClick={() => history.push("/supplier")}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            Name == "" ||
                              address1 == "" ||
                              address2 == "" ||
                              email == "" ||
                              phoneNumber == ""
                              ?
                              "countBtnchange1 w-100 p-2"
                              : "countBtnchange w-100 p-2"
                          }
                          style={{ height: "45px" }}
                          onClick={(e) => {
                            handleAdd(e);
                          }}
                          disabled={
                            errorInvalid ||
                            emailInvalid2 ||
                            Name == "" ||
                            address1 == "" ||
                            address2 == "" ||
                            email == "" ||
                            phoneNumber == ""

                          }
                        >
                          CHANGE
                        </button>
                      </div>
                    </div>

                    <div className={roleName === "User" ? "row justify-content-end" : "d-none"}>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={() => history.push("/supplier")}
                          className="countBtnchange w-100 p-2"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateSupplier;
