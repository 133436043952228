import Header from "../../components/Header";
import "../../styles/local/countstock.css";
import producticon from "../../assets/icons/producticon.svg";
import lang from "../../lang/countStock.json";
import { useEffect, useState } from "react";
import {
  addComments,
  deleteComment,
  fetchComments,
  fetchProductStock,
  updateProductStock,
  UpdatePartStock
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import EuropeanInput from "../../utils/EuropeanInput";
import DltIcon from "../../assets/icons/Group62.svg";
import DeleteCommentPopup from "../../components/dialogs/DeleteCommentPopup";


const CountStock = () => {
  const clientname = window.sessionStorage.getItem("clientname");
  const userId = window.sessionStorage.getItem("userid");
  const finalClientname = clientname.split(" ");
  const firstName = finalClientname[ 0 ].toString();
  const lastName = finalClientname[ 1 ].toString();
  const [ language, setLanguage ] = useState([]);
  const fetchproductStock = useSelector(
    (state) => state.user.fetchProductStock
  );
  const updateStockData = useSelector((state) => state.user.updateStockData);
  const fetchCommentData = useSelector((state) => state.user.fetchComment);
  const updateCommentData = useSelector((state) => state.user.addComment);
  const [ stockData, setStockCountData ] = useState([]);
  const [ singleData, setSingleStock ] = useState([]);
  const [ productArray, setProductArray ] = useState([]);
  const [ minAmount, setMinAmount ] = useState("");
  const [ maxAmount, setMaxAmount ] = useState("");
  const [ stock, setStock ] = useState("");
  const [ newstock, setNewstock ] = useState("");
  const [ comments, setComments ] = useState([]);
  const [ minComments, setMinComments ] = useState([]);
  const [ maxComments, setMaxComments ] = useState([]);
  const [ fetchUniqueId, setFetchUniqueId ] = useState("");
  const [ firstNameComm, setFirstNameComm ] = useState("");
  const [ lastNameComm, setLastNameComm ] = useState("");
  const [ commentid, setCommentid ] = useState("");
  const [ commentDltPopup, setCommentDltPopup ] = useState(false);
  const [ commentType, setCommentType ] = useState("");
  const [ currentDate, setcurrentDate ] = useState("");
  const initialRender2 = React.useRef(true);
  const [ count, setCount ] = useState(1);
  const [ countProductID, setCountProductID ] = useState(0);
  const [ disableBtn, setDisablebtn ] = useState(true);
  const [ productId, setProductId ] = useState("");
  const [ enablecomm, setEnableComm ] = useState(false);
  const [ instockWarn, setInstockWarn ] = useState(false);
  const [ instockWarnMin, setInstockWarnMin ] = useState(false);
  const [ showcmnt, setShowcmnt ] = useState(true);
  const [ firstNameCom, setFirstNameCom ] = useState("");
  const [ lastNameCom, setLastNameCom ] = useState("");
  const [ customDrop, setCustomDrop ] = useState(false);
  const [ stackId, setStackId ] = useState("");
  const [ customStackName, setCustomStackName ] = useState("");
  const [ partarrow, setPartarrow ] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setLanguage(lang.data[ 0 ]);
  }, []);

  useEffect(() => {
    dispatch(fetchProductStock(0, "", null));
  }, [ updateCommentData ]);
  const handleInc = () => {
    setStock(stock + 1);
    if (maxAmount < stock) {
      setInstockWarn(true);
      setInstockWarnMin(false);
    } else if (minAmount > stock + 1) {
      setInstockWarn(false);
      setInstockWarnMin(true);
    } else {
      setInstockWarn(false);
      setInstockWarnMin(false);
    }
  };
  const handleDec = () => {
    setStock(stock - 1);
    if (maxAmount < stock - 1) {
      setInstockWarn(true);
      setInstockWarnMin(false);
    } else if (minAmount > stock - 1) {
      setInstockWarn(false);
      setInstockWarnMin(true);
    } else {
      setInstockWarn(false);
      setInstockWarnMin(false);
    }
  };

  const handleInstock = (e) => {
    if (Boolean(e.target.value)) {
      if (maxAmount < e.target.value) {
        setStock(e.target.value);
        setInstockWarn(true);
        setInstockWarnMin(false);
      } else if (minAmount > e.target.value) {
        setStock(e.target.value);
        setInstockWarnMin(true);
        setInstockWarn(false);
      } else {
        setStock(e.target.value);
        setInstockWarn(false);
        setInstockWarnMin(false);
      }
    } else {
      setStock(0);
    }
  };
  useEffect(() => {
    setProductId(singleData[ 0 ]?.id);
    setStock(singleData[ 0 ]?.instock);
    setMinAmount(singleData[ 0 ]?.mincount);
    setMaxAmount(singleData[ 0 ]?.maxcount);

  }, [ singleData ]);

  const [ relatedtoid, setRelatedtoid ] = useState("08da2f31-46b4-4ce8-80b3-fdffac7a3076");

  const handleSend = (e) => {
    if (commentType) {
      dispatch(addComments(userId, productId, commentType, "part",));
    }
  };

  React.useEffect(() => {
    if (!initialRender2.current) {
      if (updateCommentData != null) {
        if (updateCommentData.status == true) {
          setCommentType([]);
        } else {
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [ updateCommentData ]);

  React.useEffect(() => {
    if (!initialRender2.current) {
      if (fetchCommentData != null) {
        if (fetchCommentData.status == true) {
          setMinComments([]);
          var date = new Date(fetchCommentData.data[ 0 ]?.timestamp)
            .toString()
            .split(" ");
          setcurrentDate(date);
          setComments(fetchCommentData.data);
          setFirstNameComm(fetchCommentData.data[ 0 ]?.firstname);
          setLastNameComm(fetchCommentData.data[ 0 ]?.lastname);
          for (var i = 0; i < 3; i++) {
            minComments.push(fetchCommentData.data[ i ]);
          }
          setMaxComments(minComments.filter((item) => item != undefined));
        } else {
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [ fetchCommentData, fetchUniqueId, updateCommentData ]);

  React.useEffect(async () => {
    if (!initialRender2.current) {
      if (fetchproductStock != null) {
        if (fetchproductStock.status == true) {
          let filterParts = fetchproductStock.data.filter((item) => item.type == "Part")
          setStockCountData(filterParts);
          setFetchUniqueId(
            fetchUniqueId == ""
              ?
              fetchproductStock.data.id
              : fetchUniqueId
          );
          setSingleStock(
            stockData.filter((item) => item.id == fetchUniqueId)
          );

          setProductId(singleData[ 0 ]?.id);
          setStock(singleData[ 0 ]?.totalcurrentquantity);
          setMinAmount(singleData[ 0 ]?.mincount);
          setMaxAmount(singleData[ 0 ]?.maxcount);

          if (singleData[ 0 ]?.totalcurrentquantity > singleData[ 0 ]?.maxcount) {
            setStock(singleData[ 0 ]?.maxcount);
            setInstockWarn(true);
          } else if (
            singleData[ 0 ]?.totalcurrentquantity == singleData[ 0 ]?.maxcount
          ) {
            setStock(singleData[ 0 ]?.maxcount);

            setInstockWarn(false);
          } else if (
            singleData[ 0 ]?.totalcurrentquantity == singleData[ 0 ]?.mincount
          ) {
            setStock(singleData[ 0 ]?.mincount);

            setInstockWarnMin(false);
          } else if (
            singleData[ 0 ]?.totalcurrentquantity < singleData[ 0 ]?.mincount
          ) {
            setStock(singleData[ 0 ]?.mincount);
            setInstockWarnMin(true);
          } else {
            setStock(singleData[ 0 ]?.totalcurrentquantity);
            setInstockWarn(false);
          }
        } else {
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [ fetchproductStock, fetchUniqueId, updateCommentData ]);

  const handleSelect = (value = null) => {
    if (value) {
      setFetchUniqueId(value.id);
    }
  };

  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };

  const handleNext = async (value = null) => {
    const params = [ {
      partid: productId,
      instock: stock,
      mincount: parseInt(minAmount),
      maxcount: parseInt(maxAmount),
    } ];
    if (value) {
      const currentIndex = stockData.findIndex(x => x.id == value);
      setDisablebtn(currentIndex === stockData.length - 1);
      dispatch(UpdatePartStock(params));
      setFetchUniqueId(value);
    } else {
      const currentIndex = stockData.findIndex(x => x.id == fetchUniqueId);
      if (stockData.length > 0) {
        if (currentIndex === -1) {
          setFetchUniqueId(stockData[ 0 ].id);
          setStackId(stockData[ 0 ].id)
          setCustomStackName(stockData[ 0 ].name)
        } else {
          const newIndex = currentIndex;
          if (newIndex === stockData.length - 1) {
            dispatch(UpdatePartStock(params));
            setFetchUniqueId(stockData[ 0 ].id);
            setStackId(stockData[ 0 ].id)
            setCustomStackName(stockData[ 0 ].name)
          } else {
            if (disableBtn) {
              setDisablebtn(false);
            }
            dispatch(UpdatePartStock(params));

            setFetchUniqueId(stockData[ currentIndex + 1 ].id);
            setStackId(stockData[ currentIndex + 1 ].id)
            setCustomStackName(stockData[ currentIndex + 1 ].name)
            await dispatch(fetchComments(stockData[ currentIndex + 1 ].id, "part"));
          }
        }
      }
    }

    if (commentType.length > 0) {
      let trimText = commentType.trim();
      if (trimText !== "") {
        dispatch(addComments(userId, productId, trimText, "part",));
      }
    }
    setCommentType("");
    await dispatch(fetchProductStock(0, "", null));
  };
  const handleCancel = () => {
    history.push("/inventory");
  };

  const handleChange = (e) => {
    const params = [ {
      partid: productId,
      instock: stock,
      mincount: parseInt(minAmount),
      maxcount: parseInt(maxAmount),
    } ];

    dispatch(UpdatePartStock(params));

    if (singleData) {
      if (commentType.length > 0) {
        let trimText = commentType.trim();
        if (trimText !== "") {
          dispatch(addComments(userId, productId, trimText, "part",));
        }
      }
    }
    setTimeout(() => {
      history.push("/Inventory");
    }, 1000);
  };
  const handleComment = (e) => {
    setCommentType(e.target.value);
  };


  const toggleSelect = (i) => {
    setCustomDrop(true)
  };

  const [ fetchCommId, setFetchCommId ] = useState("");

  const handleStockChange = (val) => {
    console.log("ggg", val)
    if (val) {
      setFetchUniqueId(val.id);
      dispatch(fetchComments(val.id, "part"));
    }
    setStackId(val.id)
    setCustomStackName(val.name)
    setCustomDrop(false)
    setDisablebtn(false)
  };

  const handleClose = () => setCommentDltPopup(false);

  const handleCommentDelete = (val) => {
    setCommentDltPopup(false);
    dispatch(deleteComment(commentid));
    setTimeout(() => {
      dispatch(fetchComments(fetchCommId, "part"));
    }, 1000);

    // history.goBack();
  };

  return (
    <>
      <Header />
      {commentDltPopup && (
        <DeleteCommentPopup
          show={commentDltPopup}
          // onHide={() => setModalShow(false)}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          // handleCancel={handlePopupCancel}
          handleDelete={handleCommentDelete}
        />
      )}
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-4">
            <h3 className="py-3 ps-2 themecolor header-title-sk">{language.inventory}</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-11 custCardCount">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5 className="py-4 cntStockfont">
                    <span className="me-4">
                      <img className="countProicon" src={producticon} />
                    </span>
                    {language.countStock}
                  </h5>
                </div>
              </div>
              <div className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row px-xl-5 px-lg-5 px-md-5 px-sm-4 px-3">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    <h5 className="pt-4 cntStockfont">
                      <span className="me-4 ">
                        <img className="countProicon" src={producticon} />
                      </span>
                      {language.product}
                    </h5>
                    <div className=" ms-xl-5 ms-lg-5 ms-md-5 ms-sm-0 ps-xl-3 ps-lg-3 ps-md-3 ps-sm-0 stock" onClick={arrowupDownPart} style={{ zIndex: "1", position: "relative" }}>
                      <Select
                        classNamePrefix="sk-custom-select"
                        className={
                          partarrow == "" ? "sk2-custom-container" : "ArrowUpDownpur sk2-custom-container"
                        }
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        options={stockData}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={(value) => {
                          handleStockChange(value);
                          setFetchCommId(value.id)
                        }}
                        value={stockData.find(function (option) {
                          return option.id === fetchUniqueId;
                        })}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <h6 className="d-flex justify-content-center mt-5 mt-sm-4 mt-md-5 mt-lg-5 mt-xl-5 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0">Internal ID</h6>
                    <div className="ps-xl-0 ps-lg-0 ps-md-0 ps-sm-0 ms-sm-0 ms-md-0 ms-lg-0 ms-xl-0">
                      <input
                        readOnly
                        value={
                          singleData.length > 0
                            ? singleData[ 0 ].internalid
                            : ""
                        }
                        className="text input w-100 form-control text-center  countInput countinputtext"
                        type="text"
                        style={{ height: "42px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ps-3 pe-5 ps-xl-5 ps-lg-5 ps-md-5 pe-xl-5 pe-lg-5 pe-md-5 pt-4 py-5">

                  <div className=" col-xl-1 col-lg-1 col-md-1"></div>

                  <div className=" col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
                    <div className="row" style={{ color: "#80CFE6" }}>
                      <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 ps-0">
                        <h6 className="text-center">{language.inStock}</h6>
                        <div className="row">
                          <div className="col align-self-center text-center">
                            <button
                              type="button"
                              style={{ background: "none", border: "0px" }}
                              disabled={stock < 1}
                              role="button"
                              onClick={handleDec}
                              className="my-0 fs-4 py-0 fw-bold"
                            >
                              <span className="symbolicon">  - </span>
                            </button>
                          </div>
                          <div className="col-6  px-0">
                            <EuropeanInput
                              hidePreview={true}
                              value={stock}
                              onChange={(value) => handleInstock({ target: { value: value } })}
                              withoutSymbol={true}
                              className="text input w-100 form-control text-center countInput countinputtext"
                            />
                          </div>

                          <div className="col align-self-center text-center">
                            <button
                              type="button"
                              style={{ background: "none", border: "0px" }}
                              disabled={stock > maxAmount - 1}
                              role="button"
                              onClick={handleInc}
                              className="my-0 fs-4 py-0 fw-bold"
                            >
                              <span className="symbolicon">  + </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-xl-3  col-lg-3 col-md-2 col-sm-3 "></div>
                  <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ps-3">
                    <div className="row  pt-lg-0 pt-md-0 pt-sm-2 pt-xl-0">
                      <div className="col-12 pt-3 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col">
                            <h6 className="text-center">{language.min}</h6>
                            <input
                              onChange={(e) => setMinAmount(e.target.value)}
                              value={minAmount}
                              className="text input w-100 form-control text-center countInput countinputtext"
                              type="text"
                            />
                          </div>
                          <div className="col">
                            <h6 className="text-center">{language.max}</h6>
                            <input
                              onChange={(e) => setMaxAmount(e.target.value)}
                              value={maxAmount}
                              className="text input w-100 form-control text-center countInput countinputtext"
                              type="text"
                            />
                          </div>
                        </div>
                        <h6></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ps-4 ms-1 pe-5  pb-4 ">
                  <div className="row text-end px-0">
                    <div className="col-12 col-xl-10 col-lg-9"></div>
                    <div className="col-12 col-xl-2 col-lg-3">
                      <p
                        role="button"
                        className="px-1 my-0 py-2"
                        onClick={() => {
                          setEnableComm(!enablecomm);
                          setShowcmnt(!showcmnt);
                        }}
                      >
                        {!enablecomm ? "Show more…" : "Hide details…"}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-xl-1 col-lg-1 col-md-12 col-sm-12  pb-3 pb-xl-0  align-self-center px-0 text-end justify-content-center d-flex align-self-center">
                    <button type="button" className="teambtn fw-bold">
                      {clientname == undefined || clientname == null
                        ? "SD"
                        : firstName[ 0 ].toUpperCase() +
                        "" +
                        lastName[ 0 ].toUpperCase()}
                    </button>
                  </div>
                  <div className="col-12 col-xl-11 col-lg-11 col-md-12 col-sm-12 align-self-center position-relative ps-4 ps-xl-0 d-inline pe-3">
                    <input
                      type="text"
                      rows="3"
                      value={commentType}
                      onChange={(e) => {
                        handleComment(e);
                      }}
                      className="ps-2 pe-5 form-control w-100 text_Border"
                      placeholder="write a comment…"
                    />
                  </div>
                </div>

                <div
                  style={{
                    maxHeight: "160px",
                    marginBottom: "20px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {fetchUniqueId !== undefined ? showcmnt
                    ? maxComments.map((item) => (
                      <>
                        <div className="row ps-4 mt-2 ms-1 pe-5  pb-4 ">
                          <div className="col-12 col-xl-1 col-lg-1 col-md-12 col-sm-12  pb-3 pb-xl-0  align-self-center px-0 text-end justify-content-center d-flex align-self-center">
                            <button
                              type="button"
                              className="teambtn ms-4 ms-xl-0 ms-lg-0 fw-bold"
                            >

                              {item.firstname.toUpperCase()[ 0 ] +
                                item.lastname.toUpperCase()[ 0 ]}
                            </button>
                          </div>

                          {item.message ?
                            <>
                              <div className="col-11 d-flex justify-content-between comment_Deleted">
                                <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12 align-self-center ps-4 ps-xl-0 position-relative d-inline pe-3">
                                  <p className="mb-0">{item.message}</p>
                                </div>
                                <div className="col-12 col-xl-2 col-lg-2 col-md-12 col-sm-12 align-self-center ps-4 ps-xl-0 position-relative d-inline pe-3 d-flex">

                                  {new Date(item.timestamp)
                                    .toString()
                                    .split(" ")[ 2 ] +
                                    "." +
                                    new Date(item.timestamp)
                                      .toString()
                                      .split(" ")[ 1 ] +
                                    "." +
                                    new Date(item.timestamp)
                                      .toString()
                                      .split(" ")[ 3 ]}

                                  <div className="ps-3">
                                    {userId === item.userid && (
                                      <img
                                        src={DltIcon}
                                        className="count_Dlt"
                                        alt="pensil"
                                        onClick={() => {
                                          // dispatch(deleteComment(commentid))
                                          console.log("tes2", item.commentid)
                                          setCommentid(item.commentid);
                                          setCommentDltPopup(true);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <div className="col-12 col-xl-11 col-lg-11 col-md-12 col-sm-12 pe-3  align-self-center position-relative comment_Deleted"
                            >
                              <p className="mb-0 ps-0 p-2">This message was deleted</p>
                            </div>
                          }
                        </div>

                      </>
                    ))
                    : "" : ""}


                  {fetchUniqueId !== undefined ?
                    comments && enablecomm ? comments.map((item) => (
                      <>
                        <div className="row ps-4 mt-2 ms-1 pe-5 pb-4">
                          <div className="col-12 col-xl-1 col-lg-1 col-md-12 col-sm-12 pb-3 pb-xl-0  align-self-center px-0 text-end justify-content-center d-flex align-self-center">
                            <button
                              type="button"
                              className="teambtn ms-4 ms-xl-0 ms-lg-0 fw-bold"
                            >
                              {/* {firstNameComm[ 0 ].toUpperCase() +
                                "" +
                                lastNameComm[ 0 ].toUpperCase()} */}

                              {item.firstname[ 0 ].toUpperCase() +
                                "" +
                                item.lastname[ 0 ].toUpperCase()}
                            </button>
                          </div>

                          {item.message ?
                            <>
                              <div className="col-11 d-flex justify-content-between comment_Deleted">
                                <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12 align-self-center ps-4 ps-xl-0 position-relative d-inline pe-3">
                                  <p className="mb-0">{item.message}</p>
                                </div>
                                <div className="col-12 col-xl-2 col-lg-2 col-md-12 col-sm-12 align-self-center ps-4 ps-xl-0 position-relative d-inline pe-3 d-flex">

                                  {new Date(item.timestamp)
                                    .toString()
                                    .split(" ")[ 2 ] +
                                    "." +
                                    new Date(item.timestamp)
                                      .toString()
                                      .split(" ")[ 1 ] +
                                    "." +
                                    new Date(item.timestamp)
                                      .toString()
                                      .split(" ")[ 3 ]}

                                  <div className="ps-3">
                                    {userId === item.userid && (
                                      <img
                                        src={DltIcon}
                                        className="count_Dlt"
                                        alt="pensil"
                                        onClick={() => {
                                          // dispatch(deleteComment(commentid))
                                          console.log("tes2", item.commentid)
                                          setCommentid(item.commentid);
                                          setCommentDltPopup(true);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <div className="col-12 col-xl-11 col-lg-11 col-md-12 col-sm-12 pe-3  align-self-center position-relative comment_Deleted"
                            >
                              <p className="mb-0 ps-0 p-2">This message was deleted</p>
                            </div>
                          }
                        </div>
                      </>
                    ))
                      : "" : " "}
                </div>

                <div className="row ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={handleCancel}
                          className="countBtncanc w-100 h-100 p-2"
                        >
                          {language.cancel}
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3  my-1 col-xl-3">
                        <button
                          type="button"
                          disabled={disableBtn}
                          onClick={() => handleNext()}
                          className={
                            disableBtn
                              ? "countBtnDis w-100 h-100 p-2"
                              : "countBtn w-100 h-100 p-2"
                          }
                        >
                          {language.next}
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          disabled={disableBtn}
                          onClick={handleChange}
                          className="countBtnchange w-100 h-100 p-2"
                        >
                          {language.change}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CountStock;
