import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../styles/local/products.css";
import Add1 from "../../assets/icons/Add1.png";
import pencil_icon from "../../assets/icons/pencil_icon.svg";
import Group62 from "../../assets/icons/Group62.svg";
import arrowsort from "../../assets/icons/arrowsort.png";
import duplicate from "../../assets/icons/duplicate.svg";
import Alert from "react-bootstrap/Alert";
import {
  fetchSuppliers,
  fetchProductStock,
  fetchProductById,
  ComposeProducts,
  CreatePart,
  deleteSuppliers,
  fetchSuppliersById,
  addSupplier,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteCard from "../../components/dialogs/DeleteCard";
import AddProducts from "./AddProducts";
import DeleteProduct from "../../components/dialogs/DeleteProduct";
import Composeadd from "./Composeadd";
import Cancel from "../../components/dialogs/Cancel";
import Headerproductpart from "../../components/Headerproductpart";
import Pagination from "../../components/Pagination";
import decimalSeparator from "../../utils/decimalSeparator";
import { europeanFormat } from "../../utils/NumberFormatter";
import EuropeanInput from "../../utils/EuropeanInput";
const Products = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const UserId = window.sessionStorage.getItem("userid");
  const roleName = window.sessionStorage.getItem("rolename");
  // const fetchsuppliers = useSelector((state) => state.user.fetchSuppliers);
  const fetchproductstock = useSelector(
    (state) => state.user.fetchProductStock
  );

  const fetchpartsbyid = useSelector((state) => state.user.fetchPartsById);
  const fetchproductbyid = useSelector((state) => state.user.fetchProductById);
  // const fetchsuppliersbyid = useSelector(
  //   (state) => state.user.fetchSuppliersById
  // );
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [fetchproduct, setFetchproduct] = useState([]);
  const [compClientID, setcompClientID] = useState("");
  const [pagenumber, setPageNumber] = useState(1);
  const [searchproducts, setSearchproducts] = useState("");
  // const [ pagenumber, setPageNumber ] = useState(0);
  const [supplier, setSupplier] = useState([]);
  const initialRender = React.useRef(true);
  const initialRender1 = React.useRef(true);
  const [contacts, setContacts] = useState([]);
  const [productparts, setProductparts] = useState([]);
  const [StatusPopup, setStatusPopup] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [partId, setPartId] = useState(null);
  const [type, setType] = useState(null);
  const [productid, setProductid] = useState(null);
  const [isCalled, setIsCalled] = useState(false);
  const [isCalled1, setIsCalled1] = useState(false);
  const [activeIndex, setindex] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [sortDate, setSortDate] = useState(true);
  const [sortDate2, setSortDate2] = useState(true);
  const [sortDate3, setSortDate3] = useState(true);
  const [sortarrow, setSortarrow] = useState("");
  const [sortarrow2, setSortarrow2] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    // if (searchproducts == 0) {
    //   dispatch(fetchProductStock(pagenumber));
    // } else {
    //   dispatch(fetchProductStock(pagenumber, searchproducts));
    // }
    // setIsCalled(false);
    // setIsCalled1(false);
    dispatch(fetchProductStock(pagenumber, searchproducts, type));
    sessionStorage.removeItem('conditionType');
  }, [searchproducts]);

  const handleClose = () => {
    setStatusPopup(false);
  };

  // useEffect(() => {
  //   dispatch(fetchProductById(productid));
  // }, []);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchproductstock != null) {
        if (fetchproductstock.status == true) {
          setData(fetchproductstock.data);
          setTotalCount(fetchproductstock.totalCounts);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchproductstock]);

  // React.useEffect(() => {
  //   if (!initialRender.current) {
  //     if (fetchproductbyid != null) {
  //       if (fetchproductbyid.status == true) {
  //         setFetchproduct(fetchproductbyid.data);
  //       }
  //     }
  //   } else {
  //     initialRender.current = false;
  //   }
  // }, [fetchproductbyid]);

  React.useEffect(() => {
    if (!initialRender1.current) {
      if (fetchpartsbyid != null) {
        if (fetchpartsbyid.status == true) {
          setSupplier(fetchpartsbyid.data);
        }
      }
    } else {
      initialRender1.current = false;
    }
  }, [fetchpartsbyid]);

  // React.useEffect(() => {
  //   if (!initialRender1.current) {
  //     if (fetchsuppliersbyid != null) {
  //       if (fetchsuppliersbyid.status == true) {
  //         setSupplier(fetchsuppliersbyid.data);
  //       }
  //     }
  //   } else {
  //     initialRender1.current = false;
  //   }
  // }, [fetchsuppliersbyid]);

  const handleEdit = (type, productId, partId) => {
    if (type === "Part") {
      // window.sessionStorage.setItem("partID", partId);
      window.sessionStorage.setItem("partID", productId);
      history.push("update-products");
    } else {
      window.sessionStorage.setItem("productID", productId);
      history.push("compose-product");
    }
  };

  const handleShow = (index) => {
    setIsActive((current) => !current);
    if (activeIndex.includes(index)) {
      let fil = [...activeIndex].filter((x) => x !== index);
      setindex(fil);
    } else {
      setindex((prev) => [...prev, index]);
    }
  };
  // const handleEdit = (partID) => {
  // window.sessionStorage.setItem("partID", partID);
  // history.push("compose-product");
  // };

  // const handleDelete = () => {
  //   dispatch(deleteContact(compClientID));
  // };

  //ASC Name sorting start

  const handlerSort = () => {
    setSortDate((prev) => !prev);
    setSortarrow((prev) => !prev);
    handlerSortName();
  };

  // const handlerSortName = () => {
  //   if (sortDate) {
  //     const sortedData = [ ...data ].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,)

  // const sortedData = [ ...data ].sort((a, b) => {
  //   if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  //   if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  //   return 0;
  // });
  //   setData(sortedData);
  // } else {
  //   const sortedData = [ ...data ].reverse((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,)




  //     // or we can do it for desending order with another way
  //     // const sortedData = [ ...data ].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,)

  //     // const sortedData = [ ...data ].reverse((a, b) => {
  //     //   if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  //     //   if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  //     //   return 0;
  //     // });
  //     setData(sortedData);
  //     // dispatch(fetchProductStock(pagenumber));

  //     // const sortedData = [...data].sort((a, b) => {
  //     //   if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
  //     //   if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
  //     //   return 0;
  //     // });
  //     // setData(sortedData);
  //   }
  // };

  //ASC Name sorting end

  const conditionType = window.sessionStorage.getItem("conditionType");

  const [isActive2, setIsActive2] = useState("");

  //ASC internal id sorting start

  const handlerSort2 = () => {
    setSortDate2((prev) => !prev);
    setSortarrow2((prev) => !prev);
    handlerSortName2();
  };

  // const handlerSortName2 = () => {
  //   if (sortDate2) {
  //     // const sortedData2 = [ ...data ].sort((a, b) => a.internalid > b.internalid ? 1 : -1,)
  //     const sortedData2 = [ ...data ].sort((a, b) => a.internalid - b.internalid);

  //     setData(sortedData2);
  //   } else {
  //     // dispatch(fetchProductStock(pagenumber));

  //     // const sortedData2 = [ ...data ].reverse((a, b) => a.internalid - b.internalid)
  //     const sortedData2 = [ ...data ].sort((a, b) => b.internalid - a.internalid);
  //     // or we can do it for desending order with another way
  //     // const sortedData2 = [ ...data ].sort((a, b) => b.internalid - a.internalid)

  //     setData(sortedData2);
  //   }
  // };

  // ASC internal id sorting end

  ////// Sorting Apply From Backend  Internal ID Start////////

  const handlerSortName2 = () => {
    if (sortDate2) {
      dispatch(fetchProductStock(pagenumber, "", "downwordinternalid"));
      window.sessionStorage.setItem("conditionType", "downwordinternalid");
    } else {
      dispatch(fetchProductStock(pagenumber, "", "upwordinternalid"));
      window.sessionStorage.setItem("conditionType", "upwordinternalid");
    }
  };

  ////// Sorting Apply From Backend  Internal ID End////////

  ////// Sorting Apply From Backend  Product Part Start ////

  const handlerSortName = () => {
    if (sortDate) {
      dispatch(fetchProductStock(pagenumber, "", "productpartnameZtoA"));
      window.sessionStorage.setItem("conditionType", "productpartnameZtoA");
    } else {
      dispatch(fetchProductStock(pagenumber, "", "productpartname"));
      window.sessionStorage.setItem("conditionType", "productpartname");
    }
  };

  ////// Sorting Apply From Backend  Product Part End //// 

  // ASC type sorting start

  const handlerSort3 = () => {
    setSortDate3((prev) => !prev);
    // setSortarrow((prev) => !prev);
    handlerSortName3();
  };

  // const handlerSortName3 = () => {
  //   if (sortDate3) {
  //     const sortedData3 = [ ...data ].sort((a, b) => a.type < b.type ? 1 : -1,)

  //     setData(sortedData3);
  //   } else {
  //     dispatch(fetchProductStock(pagenumber));

  //     // const sortedData3 = [ ...data ].reverse((a, b) => a.type - b.type)
  //     // or we can do it for desending order with another way
  //     // const sortedData2 = [ ...data ].sort((a, b) => b.type - a.type)

  //     // setData(sortedData3);
  //   }
  // };

  // ASC type sorting end

  //////// Sorting By Type ///////
  const handlerSortName3 = () => {
    if (sortDate3) {
      dispatch(fetchProductStock(pagenumber, "", "product"));
      window.sessionStorage.setItem("conditionType", "product");
    } else {
      dispatch(fetchProductStock(pagenumber, "", ""));
      window.sessionStorage.setItem("conditionType", "part");
    }

  }


  const pageNumbers = [...Array(pagenumber + 2).keys()].slice(1);


  const excludeColumns = ["id", "color"];

  // const handleChange = (value) => {
  //   setQuery(value);
  //   filterData(value);

  // };

  // const filterData = (value) => {
  //   const lowercasedValue = value.toLowerCase().trim();
  //   if (lowercasedValue === "") setData(data);
  //   else {
  //     const filteredData = data.filter((item) => {
  //       return Object.keys(item).some((key) =>
  //         excludeColumns.includes(key)
  //           ? false
  //           : item[key].toString().toLowerCase().includes(lowercasedValue)
  //       );
  //     });
  //     setData(filteredData);
  //   }
  // };
  const convertINtoEUR2 = (number) => {
    let text1 = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
    return (
      text1
    )

  }
  console.log("text", convertINtoEUR2(48.999))


  return (
    <>
      {StatusPopup && (
        <DeleteProduct
          handleClose={handleClose}
          productId={supplierId}
          // partId={partId}
          type={type}
          pagenumber={pagenumber}
          // iscalled={isCalled}
          setIsCalled1={setIsCalled1}
        />
      )}
      <Headerproductpart
        setQuery={setQuery}
        searchproducts={searchproducts}
        setSearchproducts={setSearchproducts}
      // query={query}
      // handleChange={handleChange}
      />
      <div className="parent">
        <div className="outer mb-3">
          <div className=" pb-5 pt-2 productHeading">
            <h3 className="py-3 ps-2 themecolor header-title-sk">  Products & Parts</h3>
            {/* <input
              type="text"
              placeholder="search..."
              onChange={(e) => setQuery(e.target.value)}
            /> */}
          </div>
          <div className="tableWrapper">
            <table className="table table-hover">
              <thead>
                <tr className="tabel-heading">
                  <th
                  // className="fnt12"
                  // className={sortDate2 ? "fnt12" : "fnt2 fw-bold toggelsort"
                  // }
                  >
                    <img
                      className={sortarrow2 == "" ? "" : "ArrowRotate"}
                      src={arrowsort}
                      alt="arrowicon"
                      height="14px"
                      width="4px"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handlerSort2();
                        setIsActive2("one");
                      }}
                    />
                    &nbsp; <span
                      // className={sortDate2 ? "fnt12 " : "fnt2 fw-bold toggelsort"}
                      className={isActive2 === "one" || "" ? "fw-bold toggelsort " : "fnt2 fnt12"}
                      onClick={() => {
                        dispatch(fetchProductStock(pagenumber, "", ""));
                        sessionStorage.removeItem('conditionType');
                        setIsActive2("");
                      }}
                    >
                      Internal ID
                    </span>
                  </th>

                  <th className={sortDate3 ? "fnt12" : "fnt2 fw-bold toggelsort"}
                    onClick={() => {
                      handlerSort3();
                      // setIsActive2("");
                    }}
                  // className="fnt1"
                  >
                    Type</th>
                  <th
                  // className={`${sortDate ? "fnt2 sortclr" : "fnt2 fw-bold toggelsort"
                  //   }`}
                  >
                    <img
                      className={sortarrow == "" ? "" : "ArrowRotate"}
                      src={arrowsort}
                      alt="arrowicon"
                      height="14px"
                      width="4px"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handlerSort();
                        setIsActive2("three");
                      }}
                    // onClick={() => handlerSort()}
                    />
                    &nbsp; <span
                      // className={sortDate2 ? "fnt12 " : "fnt2 fw-bold toggelsort"}
                      className={isActive2 === "three" || "" ? "fw-bold toggelsort " : "fnt2 fnt12"}
                      onClick={() => {
                        dispatch(fetchProductStock(pagenumber, "", ""));
                        sessionStorage.removeItem('conditionType');
                        setIsActive2("");
                      }}
                    >
                      Product/Part
                    </span>
                    {/* &nbsp; Product/Part */}
                  </th>
                  <th className="fnt1">Unit Price</th>
                  <th className="fnt1">Supplier Name</th>
                  <th className="fnt1 hidden">bubble</th>
                  <th className="fnt1 w-25">Lead Time</th>
                  <th className="fnt1 text-center">Stock</th>
                  <th></th>
                  {/* <th></th> */}
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (item) =>
                      item.name.toLowerCase().includes(query) ||
                      item.type.toLowerCase().includes(query)
                    // item.internalid.toLowerCase().includes()
                    // item.partsuppliers[ 0 ].suppliername.toLowerCase().includes(query)
                  )

                  .map((item, index) => {
                    const { unitprice, suppliername, leadtime, id } =
                      (item.partsuppliers || [])[0] || {};
                    return (
                      <tr
                        key={index}
                        style={{
                          color: "#003766",
                          fontWeight: "500",
                        }}
                      >
                        <td>{item.internalid}</td>
                        <td>{item.type}</td>
                        <td className={isActive2 === "three" || "" ? "fw-bold" : ""}>
                          {item.name}
                          {/* {(item.partsuppliers||[])[0]?.} */}
                        </td>
                        <td>

                          {item.type == "Product" ? <>
                            EUR&nbsp;
                            {europeanFormat(item.unitprice)}

                          </> : <>

                            {(item.partsuppliers || [])?.map((item, i) => {
                              if (i === 0) {
                                return (
                                  <>
                                    EUR&nbsp;{europeanFormat(item.unitprice)}
                                  </>
                                );
                              }
                            })}
                          </>}
                          {activeIndex.includes(index) && <>
                            {(item.partsuppliers || []).length - 1 ? (
                              <div style={{
                                // color: "#0f5132",
                                padding: "10px 0px"
                              }}>
                                {(item.partsuppliers || [])?.map((item, i) => {
                                  if (i === 0) return "";
                                  return (
                                    <div key={i}>
                                      EUR&nbsp;{europeanFormat(item.unitprice)}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              " "
                            )}
                          </>}
                        </td>
                        <td>
                          {/* {suppliername} */}

                          {(item.partsuppliers || [])?.map((item, i) => {
                            if (i === 0) {
                              return (
                                <>
                                  {item.suppliername}
                                </>
                              );
                            }
                          })}

                          {activeIndex.includes(index) && <>
                            {(item.partsuppliers || []).length - 1 ? (
                              <div style={{
                                // color: "#0f5132",
                                padding: "10px 0px"
                              }}>
                                {(item.partsuppliers || [])?.map((item, i) => {
                                  if (i === 0) return "";
                                  return (
                                    <div key={i}>
                                      {item.suppliername.length > 12 ? item.suppliername.slice(0, 12) + "..." : item.suppliername}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              " "
                            )} </>}
                        </td>
                        <td>
                          {(item.partsuppliers || [])?.length > 1 ? (
                            <button
                              className="bubble"
                              style={{
                                backgroundColor: activeIndex.includes(index) ? "#80cfe6" : "",
                                color: activeIndex.includes(index) ? "white" : "",
                              }}
                              onClick={() => handleShow(index)}
                            >
                              +{(item.partsuppliers || [])?.length - 1}
                            </button>
                          ) : (
                            " "
                          )}
                        </td>
                        <td>
                          {/* {leadtime} */}
                          {(item.partsuppliers || [])?.map((item, i) => {
                            if (i === 0) {
                              return (
                                <>
                                  {item.leadtime + " Days"}
                                </>
                              );
                            }
                          })}

                          {/* {leadtime > 0 ? <span>&nbsp;Days</span> : null} */}

                          {activeIndex.includes(index) && <>

                            {(item.partsuppliers || []).length - 1 ? (
                              <div style={{
                                color: "#0f5132",
                                padding: "10px 0px"
                              }}>
                                {(item.partsuppliers || [])?.map((item, i) => {
                                  if (i === 0) return "";
                                  return (
                                    <div key={i}>
                                      {item.leadtime + " Days"}
                                    </div>
                                  );
                                })}

                              </div>
                            ) : (
                              " "
                            )}

                          </>}

                        </td>
                        <td>
                          <EuropeanInput
                            hidePreview={true}
                            withoutSymbol={true}
                            value={item.instock > 0 ? item.instock : null}
                          />
                        </td>
                        <td>
                          <img
                            src={pencil_icon}
                            className={roleName !== "user" ? "icons" : "d-none"}
                            alt="pensil"
                            onClick={() => {
                              handleEdit(item.type, item.id, id);
                            }}
                          />
                          <img
                            src={duplicate}
                            className={roleName !== "User" ? "icons1" : "d-none"}
                            alt="pensil"
                            onClick={() => {
                              if (item.type === "Part") {
                                const partsuppliers = [];
                                item.partsuppliers.forEach((val) =>
                                  partsuppliers.push({
                                    supplierid: val.id,
                                    suppliercode: val.suppliercode,
                                    unitprice: val.unitprice,
                                    leadtime: val.leadtime,
                                  })
                                );
                                dispatch(
                                  CreatePart(
                                    UserId,
                                    item.name + " - Copy",
                                    "C- " + item.internalid,
                                    item.instock,
                                    item.maxcount,
                                    item.mincount,
                                    partsuppliers
                                  )
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              } else {
                                const productparts = [];
                                item.productparts.forEach((val) =>
                                  productparts.push({
                                    partid: val.id,
                                    allotedquantity: val.allotedquantity,
                                  })
                                );
                                dispatch(
                                  ComposeProducts(
                                    UserId,
                                    item.name + " -Copy",
                                    "string",
                                    "C- " + item.internalid,
                                    item.unitprice,
                                    item.totalcurrentquantity,
                                    item.mincount,
                                    item.maxcount,
                                    item.imageurl,
                                    productparts
                                  )
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              }
                            }}
                          />
                          <img
                            src={Group62}
                            className={roleName !== "User" ? "icons2" : "d-none"}
                            alt="pensil"
                            onClick={() => {
                              setStatusPopup(true);
                              setSupplierId(item.id);
                              setType(item.type);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {data.length === 0 && <span>No records found to display!</span>}
          </div>
          <Pagination
            currentPage={pagenumber}
            totalCount={totalCount}
            onPageChange={(page) => {
              dispatch(fetchProductStock(page, "", conditionType))
              setPageNumber(page)
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Products;
