import React from 'react'
import { NumericFormat } from 'react-number-format';
import { europeanFormat, europeanFormatWithSymbol, stockQuntityFormat } from './NumberFormatter';

const EuropeanInput = ({ value, onChange, previewAlignment, hidePreview, withoutSymbol, ...props }) => {
    const hasPreview = !Boolean(hidePreview);
    return (
        <div className='position-relative'>
            <div className={`${props.className} position-absolute h-100 w-100 d-flex align-items-center justify-content-${previewAlignment || 'center'} z-1`}>
                {hasPreview ? (withoutSymbol ? europeanFormat(value) : europeanFormatWithSymbol(value)) : stockQuntityFormat(value)}
            </div>
            <NumericFormat
                {...props}
                className={`${props.className} erp-input position-relative z-2`}
                value={Number(value)}
                onValueChange={(values) => {
                    onChange && onChange(values.floatValue)
                }}
                decimalSeparator=','
                disabled={!Boolean(onChange)}
                // allowLeadingZeros={false}
            />
        </div>
    )
}

export default React.forwardRef(EuropeanInput)