import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";

// import styles from "../../styles/style.css";
import { updateUserVerifySignup } from "../../../redux/actions/action"

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";


const UserVerification = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { userid } = useParams();
  // const userId = userid.userid;



  // const userId = window.sessionStorage.getItem("userid");



  const [ isverified, setIsverified ] = useState("");

  const updateUserVerifySignupData = useSelector((state) => state.user.updateUserVarify);


  const initialRender = React.useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      if (updateUserVerifySignupData != null) {
        if (updateUserVerifySignupData.status == true) {

          setIsverified(updateUserVerifySignupData.data[ 0 ].isverified)

          setTimeout(() => {
            // setSucess(true);
          }, 1000);
        } else {
          // setSucess(false);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ updateUserVerifySignupData ]);

  const onclickconfirm = () => {
    dispatch(updateUserVerifySignup(userid, isverified));
    history.push("/")
  }

  return (
    <div className="container">
      <Modal
        className="changedialog border-0"
        animation={false}
        show={true}
        centered
      >
        <ModalBody className="changedialog border-0">
          <div className="container ">
            <form>
              <div className=" centeralign">
                <h1 className="heading">
                  Get in-demant resources
                  <br /> to <b>innovate your world</b>
                </h1>
                <h1 className="subheading">
                  You`re on your way to connecting with great talent!
                </h1>
              </div>
              <div className="container mt-sm-5 ">
                <div className="row btncontainer mt-sm-4">
                  <div>
                    <button
                      className="btn createbutton"
                      // onClick={() => {
                      //   dispatch(updateUserVerifySignup(userid, isverified));
                      //   // history.push("/home")
                      // }}
                      onClick={onclickconfirm}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody >
      </Modal >
    </div >
  );
};

export default UserVerification;
