import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import moment from "moment";
import { API_URL } from "..//..//redux/constants/type";
import axios from "axios";
import Header from "../../components/Header";
import "..//..//styles/local/addPuchasingOrder.css";
import Group62 from "../../assets/icons/Group62.svg";
import Stock from "../../assets/icons/Stock.svg";
import inventoryIcon from "../../assets/icons/Group 1102.svg";
import lastsupIcon from "../../assets/icons/Group 1087.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSuppliers,
  LastPurchase,
  FetchPurchasingOrdersById,
  FetchParts,
  AdvisedPartPurchasing,
  CreatePurchasingOrders,
  FetchPartsBySupplier,
  addComments,
  fetchComments,
  UpdatePurchasingOrders,
} from "../../redux/actions/action";
import { Link, useHistory, useParams } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import decimalSeparator from "../../utils/decimalSeparator";
import { useQuery } from "../../utils/appHooks";
import { europeanFormat, stockQuntityFormat } from "../../utils/NumberFormatter";
import EuropeanInput from "../../utils/EuropeanInput";
const colourStyles = {
  control: (styles) => ({
    ...styles,
    padding: "5px 3px",
    border: "0.5px solid #707070",
    borderRadius: "10px",
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
};

const formatDate = (value) => {
  let date = new Date(value);
  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "numeric" });
  const year = date.toLocaleString("default", { year: "numeric" });
  return day + "." + month + "." + year;
};

export default function AddPuchasingOrder(props) {
  const queryParams = useQuery();
  const [show, setShow] = useState(true);
  const [orderid2, setOrderid2] = useState("");
  const [supplierarrow, setSupplierarrow] = useState("");
  const [partarrow, setPartarrow] = useState("");
  const [isActive, setIsActive] = useState("");
  const [expandMenu, setExpandMenu] = React.useState(false);
  const [supplier, setSupplier] = useState("");
  const [lastPurchaselist, setLastPurchaselist] = useState("");
  const [supplierDetails, setSupplierDetails] = useState([]);
  const [partArraypdf, setPartArraypdf] = useState([]);
  const [partlist, setPartlist] = useState([]);
  const dispatch = useDispatch();
  const userID = window.sessionStorage.getItem("userid");
  const roleName = window.sessionStorage.getItem("rolename");
  const fetchsuppliers = useSelector((state) => state.user.fetchSuppliers);
  const lastPurchase = useSelector((state) => state.user.lastPurchase);
  const lastPartsPurchase = useSelector((state) => state.user.lastPartsPurchase);
  const FetchPartsdata = useSelector((state) => state.user.FetchParts);
  const fetchCommentData = useSelector((state) => state.user.fetchComment);
  const PurchasingOrderResponse = useSelector((state) => state.user.CreatePurchasingOrder);
  const UpdatePurchasingResponse = useSelector((state) => state.user.updatePurchasing);
  const [customDrop, setCustomDrop] = useState(false);
  const [CustomSupplierName, setCustomSupplierName] = useState("");
  const [comments, setComments] = useState([]);
  const [firstNameComm, setFirstNameComm] = useState("");
  const [lastNameComm, setLastNameComm] = useState("");
  const [currentDate, setcurrentDate] = useState("");
  const [editWareHouse, setEditWareHouse] = useState(false);
  const [IsLastOrders, setIsLastOrders] = useState(false);
  const [createdOrderId, setCreatedOrderId] = useState();
  const fetchPartBySupplier = useSelector(
    (state) => state.user.fetchPartBySupplier
  );

  const AdvisedPartPurchasingdata = useSelector(
    (state) => state.user.AdvisedPartPurchasing
  );
  const CreatePurchasingdata = useSelector(
    (state) => state.user.CreatePurchasingOrder
  );
  const pagenumber = "0";
  const ordercodedata = { orderCode: lastPurchase.data?.ordercode };

  const [modalShow, setModalShow] = useState(false);
  const [orderparts, setOrderparts] = useState([
    {
      partName: "",
      OrderQty: "",
      internalId: "",
      SupplierCode: "",
      inStock: "",
    },
  ]);

  const [customDropId, setCustomDropId] = useState("");
  const [partid, setPartid] = useState("");
  const [supplierid, setSupplierid] = useState("");
  const [partSelectName, setPartSelectName] = useState("");
  const [isShowLastPurchase, setIsShowLastPurchase] = useState(false);

  const history = useHistory();
  const params = useParams();
  useEffect(() => {
    dispatch(fetchSuppliers());
    dispatch(FetchParts());
    dispatch(AdvisedPartPurchasing());
  }, [fetchSuppliers, FetchParts, AdvisedPartPurchasing]);

  const [supplierInfo, setSupplierInfo] = useState(null);
  const [lastPartsPurchaseList, setLastPartsPurchaseList] = useState([]);
  useEffect(() => {
    if (!initialRender.current) {
      if (lastPartsPurchase?.status == true) {
        let NewSupplierData = [];
        lastPartsPurchase?.data[0]?.partdetails.forEach((element) => {
          NewSupplierData.push({
            ...element,
            value: element.partid,
            label: element.partname,
            totalPrice: element.unitprice * element.orderquantity,
          });
        });
        setLastPartsPurchaseList(NewSupplierData);
      } else {
      }
    } else {
      initialRender.current = false;
    }
  }, [lastPartsPurchase]);


  const [supplierDataList, setSupplierDataList] = useState([]);

  useEffect(() => {
    if (!initialRender.current) {
      if (fetchsuppliers.status == true) {
        let NewSupplierData = [];
        fetchsuppliers?.data?.forEach((element) => {
          NewSupplierData.push({
            ...element,
            value: element.supplierid,
            label: element.name,
          });
        });
        setSupplierDataList(NewSupplierData);
      } else {
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchsuppliers]);



  const [isUpdatedSupplier, setIsUpdatedSupplier] = useState(false);

  const fetchPurchasingOrderDetailsByOrderId = (purchaseorderid) => {
    axios.post(`${API_URL}/api/Stock/FetchPurchasingOrdersById`, { purchaseorderid }).then((res) => {
      if (res.status && res.data.status) {
        let orderData = res.data.data ? res.data.data[0] : null;
        if (orderData) {
          orderData.orderid = purchaseorderid;
          let orderParts = [];
          if (orderData.partdetails) {
            orderData.partdetails.forEach((item) => {
              orderParts.push({
                partName: item.partname,
                OrderQty: item.orderquantity,
                internalId: item.internalid,
                SupplierCode: item.suppliercode,
                inStock: item.instock,
                totalPrice: item.unitprice * item.orderquantity,
                allData: orderData,
                partid: item.partid,
                leadtime: parseInt(orderData.leadtime),
                isOld: true
              });
            });
          }
          setSupplierInfo(orderData);
          handleOnSelect(orderData);
          setOrderparts(orderParts);
          setLastPurchaselist(orderData.supplierid)
          dispatch(FetchPartsBySupplier(orderData.supplierid));
          setIsUpdatedSupplier(true);
          setIsShowLastPurchase(true);
        }
      }
    })
  }


  const [partBySupplierData, setPartBySupplierData] = useState([]);

  useEffect(() => {
    if (!initialRender.current) {
      if (fetchPartBySupplier.status == true) {
        let NewSupplierData = [];
        fetchPartBySupplier?.data?.forEach((element) => {
          NewSupplierData.push({
            ...element,
            value: element.partid,
            label: element.parts[0]?.partname,
          });
        });
        setPartBySupplierData(NewSupplierData);
      } else {
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchPartBySupplier]);

  useEffect(() => {
    if (supplierDetails.length === 0) {
      setPartBySupplierData([]);
      setTotalCount(0);
    }
  }, [supplierDetails]);

  useEffect(() => {
    const isCon = queryParams.get("apo")
    if (isCon === "apo-form") {
      const obj = window.sessionStorage.getItem("apo-form");
      const jsnObj = JSON.parse(obj);
      handleOnSelect(jsnObj.supplierDetails)
      setComment(jsnObj.comment);
      setSupplierInfo(jsnObj.supplierDetails);
      setPartlist(jsnObj.partList ?? []);
      setOrderparts(jsnObj.orderparts ?? []);
      setIsLastOrders(jsnObj.IsLastOrders);
      setIsUpdatedSupplier(jsnObj.isUpdatedSupplier);
      setIsShowLastPurchase(jsnObj.isShowLastPurchase);
      setLastPartsPurchaseList(jsnObj.lastPartsPurchaseList ?? [])
      setShow(false);
    }
    else if (params.id) {
      fetchPurchasingOrderDetailsByOrderId(params.id);
    }
  }, [params])

  const handleRedirectionWithStateSave = (redirectPath) => {

    const currentObj = {
      supplierDetails: {
        ...supplierDetails,
        orderid: params.id
      },
      comment,
      partlist,
      orderparts,
      IsLastOrders,
      lastPartsPurchaseList,
      isUpdatedSupplier,
      isShowLastPurchase
    }
    window.sessionStorage.setItem("apo-form", JSON.stringify(currentObj));
    history.replace({ pathname: history.location.pathname, search: "?apo=apo-form" })
    history.push({ pathname: redirectPath })
  }

  useEffect(() => {
    if (!initialRender.current) {
      if (PurchasingOrderResponse.status == true) {
        setRelatedtoid(PurchasingOrderResponse.data.orderid)
        setCreatedOrderId(PurchasingOrderResponse.data.ordercode)
      }

    } else {
      initialRender.current = false;
    }

  }, [PurchasingOrderResponse]);


  const handleServiceChange = (e, index) => {
    const { name, value, key } = e.target;
    const list = [...orderparts];
    list[index][name] = value;

    setOrderparts((prevState) => {
      const NewState = [...prevState];
      const selectIndex = NewState[index];
      NewState[index] = {
        ...selectIndex,
        totalPrice: NewState[index]?.allData?.unitprice * value,
      };
      return NewState;
    });
  };

  const handleServiceRemove = (index) => {
    const list = [...orderparts];
    list.splice(index, 1);
    setOrderparts(list);
  };

  const handleServiceRemove2 = (index) => {
    const list = [...lastPartsPurchaseList];
    list.splice(index, 1);
    setLastPartsPurchaseList(list);
  };

  const handleServiceAdd = () => {
    setOrderparts([
      ...orderparts,
      {
        partName: "",
        OrderQty: "",
        internalId: "",
        SupplierCode: "",
        inStock: "",
      },
    ]);
  };

  const initialRender = React.useRef(true);
  React.useEffect(() => {
    if (!initialRender.current) {
      if (FetchPartsdata?.status == true) {
        setPartlist(FetchPartsdata.data);
      } else {
      }
    } else {
      initialRender.current = false;
    }
  }, [FetchPartsdata]);

  const [lastPurchaseData, setLastPurchaseData] = useState([]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (lastPurchase?.status == true) {
        setLastPurchaseData(lastPurchase.data);
        if (props?.match?.params?.id || editWareHouse) {
          setIsShowLastPurchase(true)
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [lastPurchase]);


  const getLastPart = () => {
    dispatch(FetchPurchasingOrdersById(lastPurchaseData.orderid));
    setIsLastOrders(true)
  };

  const handleOnSelect = (item) => {
    setSupplierDetails(item);
    dispatch(LastPurchase(item.supplierid));
    dispatch(FetchPartsBySupplier(item.supplierid));
    setCustomSupplierName(item.name)
    setSupplierid(item.supplierid)
    setCustomDrop(false)
    setIsLastOrders(false)
    setEditWareHouse(true)
    if (!props?.match?.params?.id) {
      setOrderparts([
        {
          partName: "",
          OrderQty: "",
          internalId: "",
          SupplierCode: "",
          inStock: "",
        },
      ]);
    }
  };

  const arrowupDown = () => {
    setSupplierarrow((prev) => !prev);
    // for if we want to arrow up and down every time click

    // setSupplierarrow("one");
    // for if we want to arrow up and down only onetime click
  };

  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };

  const [totalCount, setTotalCount] = useState(0);

  const handleOnSelectPart = (item, index) => {
    setOrderparts((prevState) => {
      const NewState = [...prevState];
      NewState[index] = {
        OrderQty:
          Number(item.parts[0]?.maxcount) - Number(item.parts[0]?.instock),
        internalId: item.parts[0]?.internalid,
        SupplierCode: item.suppliercode,
        inStock: item.parts[0]?.instock,
        totalPrice:
          item.unitprice * (item.parts[0]?.maxcount - item.parts[0]?.instock),
        allData: item,
        leadtime: parseInt(item.leadtime),
        index: index,
        partid: item.partid,
        selectValue: { lable: item.parts[0]?.partname, value: item.partid },
      };
      return NewState;
    });
    setPartid(item.partid)
    setPartSelectName(item.label)
    setCustomDropId(null)
  };

  useEffect(() => {
    if (orderparts || lastPartsPurchaseList) {
      let totalCount = 0;
      orderparts?.forEach((element) => {
        if (element.totalPrice != undefined) {
          totalCount = Number(totalCount) + parseFloat(element.totalPrice);
        }
      });
      lastPartsPurchaseList?.forEach((element) => {
        totalCount = Number(totalCount) + Number(element.totalPrice);
      });
      setTotalCount(totalCount);
    }
  }, [orderparts, lastPartsPurchaseList]);



  const [comment, setComment] = useState("");
  const [relatedtoid, setRelatedtoid] = useState();
  const [isNegativeQty, setNegativeQty] = useState(false);

  const handleAdd = (e) => {
    let hasNegativeQty = false;
    let lastPurchaseOrder = lastPartsPurchase?.data
    let removeBlank = orderparts.filter(({ OrderQty }) => !(OrderQty === ""))
    if (removeBlank.length > 0 || lastPurchaseOrder.length > 0) {
      let MaxLeadtime = 0
      if (removeBlank.length > 0) {

        const FirstValue = removeBlank.sort((a, b) => b.leadtime - a.leadtime);
        MaxLeadtime = FirstValue[0].leadtime;
        var date = new Date();
        date.setDate(date.getDate() + MaxLeadtime);
      } else {
        const FirstValue = lastPurchaseOrder.sort((a, b) => b.leadtime - a.leadtime);
        MaxLeadtime = FirstValue[0].leadtime;
        let NumberValue = parseInt(MaxLeadtime)
        var date = new Date();
        date.setDate(date.getDate() + NumberValue);
      }

      let partArray = [];
      removeBlank.forEach((elements) => {
        if (parseFloat(elements.OrderQty) < 0) {
          hasNegativeQty = true;
        }
        partArray.push({
          partid: elements.partid,
          orderquantity: parseFloat(elements.OrderQty),
        });
      });

      lastPartsPurchaseList?.forEach((elements) => {
        if (parseFloat(elements.orderquantity) < 0) {
          hasNegativeQty = true;
        }
        partArray.push({
          partid: elements.partid,
          orderquantity: parseFloat(elements.orderquantity),
        });
      });

      removeBlank.forEach((elements) => {
        if (parseFloat(elements.OrderQty) < 0) {
          hasNegativeQty = true;
        }
        partArraypdf.push({

          part: elements.allData.parts[0].partname,
          supplierCode: elements.SupplierCode,
          internalCode: elements.internalId,
          orderQty: europeanFormat(parseFloat(elements.OrderQty)),
        });
      });

      lastPartsPurchaseList.forEach((elements) => {
        if (parseFloat(elements.OrderQty) < 0) {
          hasNegativeQty = true;
        }
        partArraypdf.push({

          part: elements.partname,
          supplierCode: elements.suppliercode,
          internalCode: elements.internalid,
          orderQty: europeanFormat(parseFloat(elements.orderquantity)),
        });
      });
      if (hasNegativeQty) {
        setNegativeQty(true);
        return;
      }
      if (partArray.length > 0) {
        dispatch(
          CreatePurchasingOrders(
            userID,
            totalCount.toString(),
            MaxLeadtime.toString(),
            date.toISOString().toString(),
            supplierDetails.supplierid.toString(),
            "purchase",
            partArray
          )
        );
        setModalShow(true);
      }
    }
  };


  const [values, setValues] = useState(null);
  useEffect(() => {
    if (supplierInfo?.suppliername && supplierInfo?.supplierid) {
      setValues({
        label: supplierInfo?.suppliername.toString(),
        value: supplierInfo?.supplierid.toString(),
      });
      setCustomSupplierName(supplierInfo?.suppliername.toString())
      setSupplierid(supplierInfo?.supplierid)
      setRelatedtoid(supplierInfo?.orderid)
      dispatch(fetchComments(supplierInfo?.orderid, "purchase"));
    }
    if (supplierDetails?.value && supplierDetails?.label) {
      setValues({
        label: supplierDetails?.label.toString(),
        value: supplierDetails?.value.toString(),
      });
      setCustomSupplierName(supplierDetails?.label.toString(),)
      setSupplierid(supplierDetails?.supplierid)
      setRelatedtoid(supplierDetails?.orderid)
      dispatch(fetchComments(supplierDetails?.orderid, "purchase"));
    }

  }, [supplierInfo, supplierDetails]);



  /////////////////////// for pdf start /////////////////////

  let newDate = new Date();
  let newDate2 = moment().format("Do MMM YYYY");
  const pdfdownload = async (orderid, suppliername, address1, address2, deliverydate, email, partarr) => {
    axios({
      method: "post",
      url: `${API_URL}/DocFile`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        orderId: orderid,
        suppliername: suppliername,
        address1: address1,
        address2: address2,
        date: newDate2,
        email: email,
        tableDatas: partarr,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PO - ${orderid}.docx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      // return docFileDownload;
      // }, 1000)
    });
  };

  /////////////////////// for pdf end /////////////////////

  // response start

  const formatDate = (value) => {
    let date = new Date(value);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return day + "." + month + "." + year;
  };

  useEffect(() => {
    if (!initialRender.current) {
      if (CreatePurchasingdata.status == true) {
        setOrderid2(CreatePurchasingdata.data.orderid);
        setTimeout(() => {
          let orderId = CreatePurchasingdata.data.ordercode;
          let suppliername = supplierDetails.name;
          let address1 = supplierDetails?.address1;
          let address2 = supplierDetails?.address2;
          let deliverydate = formatDate(lastPurchaseData?.deliverydate);
          let email = supplierDetails?.email;
          pdfdownload(orderId, suppliername, address1, address2, deliverydate, email, partArraypdf);
        }, 2000);

      } else {
      }
    } else {
      initialRender.current = false;
    }
  }, [CreatePurchasingdata]);

  // response end

  const handleChange = (e) => {
    let hasNegativeQty = false;
    if (orderparts.length > 0 && orderparts[0]?.OrderQty) {
      const FirstValue = orderparts.sort((a, b) => b.leadtime - a.leadtime);
      const MaxLeadtime = FirstValue[0].leadtime;
      var date = new Date();
      date.setDate(date.getDate() + MaxLeadtime);
      let partArray = [];
      orderparts.forEach((elements) => {
        if (parseFloat(elements.OrderQty) < 0) {
          hasNegativeQty = true;
        }
        partArray.push({
          partid: elements.partid,
          orderquantity: parseFloat(elements.OrderQty),
        });
      });
      if (hasNegativeQty) {
        setNegativeQty(true);
        return;
      }
      if (partArray.length > 0) {
        setPartArraypdf(partArray);
        dispatch(
          UpdatePurchasingOrders(
            userID,
            supplierInfo.orderid.toString(),
            MaxLeadtime.toString(),
            totalCount.toString(),
            supplierInfo.supplierid.toString(),
            "08da2f31-46b4-4ce8-80b3-fdffac7a3066",
            date.toISOString().toString(),
            "purchase",
            partArray
          )
        );
        if (comment) {
          dispatch(
            addComments(userID, relatedtoid, comment, "purchase")
          );
        }
        setTimeout(() => {
          setModalShow(false);
          history.push("/inventory");
        }, 1000);
        return setPartArraypdf(partArray);

      }
    }
  };

  const handlerRemove = () => {
    setNegativeQty(false);
    setValues(null);
    setOrderparts([
      {
        partName: "",
        OrderQty: "",
        internalId: "",
        SupplierCode: "",
        inStock: "",
      },
    ]);
    setPartBySupplierData([]);
    setSupplierDetails([]);
    setLastPurchaseData([]);
    setLastPartsPurchaseList([]);
    setModalShow(false);
    setTotalCount(0)
    if (comment) {
      dispatch(
        addComments(userID, relatedtoid, comment, "purchase")
      );
      setComment("")
    }
  };

  const goBackCustom = () => {

    if (comment) {
      dispatch(
        addComments(userID, relatedtoid, comment, "purchase")
      );
    }
    history.goBack()
    setIsShowLastPurchase(false)
    lastPurchase.status = false
  };



  const cancelAction = () => {
    setIsShowLastPurchase(false)
    lastPurchase.status = false
    history.goBack()
  };



  const toggleSelect = (i) => {
    setCustomDrop(true)
  };


  const togglePartSelect = (i, item) => {
    if (i !== null) {
      setCustomDropId(i)
    } else {
      setCustomDrop(true)
    }
  };


  React.useEffect(() => {
    if (props?.match?.params?.id) {
      if (!initialRender.current) {
        if (fetchCommentData != null) {
          if (fetchCommentData.status == true) {
            var date = new Date(fetchCommentData.data[0]?.timestamp)
              .toString()
              .split(" ");
            setcurrentDate(date);
            setComments(fetchCommentData.data);
            setComment(fetchCommentData.data[0]?.message)
            setFirstNameComm(fetchCommentData.data[0]?.firstname);
            setLastNameComm(fetchCommentData.data[0]?.lastname);
          }
        }
      } else {
        initialRender.current = false;
      }
    }
  }, [fetchCommentData, supplierInfo, supplierDetails]);


  return (
    <>
      <Header />
      <div className="container-fluid puchasing-order countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row">
            <h3 className="countFontclr stckFont Inventory_padding">
              Inventory | Purchasing Order
            </h3>
          </div>
          <div className="row pe-0 py-5 pe-md-0" style={{ minWidth: 50 }}>
            <div className="col-lg-9 col-xl-9 col-xxl-9 col-md-12 col-sm-12 col-xs-12 custCardCount p-sm-5 px-5 p-5 p-lg-0">
              <div className="card cardHead">
                <div className="row mx-0">
                  <h5 className="py-4 fntSupp2 text-sm-start text-center">
                    <span className="me-2">
                      <img width={45} src={Stock} className="" />
                    </span>
                    <span>Purchasing Order</span>
                    <span className="ms-3">
                      {supplierInfo?.ordercode}
                    </span>
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px", border: "none" }}
              >
                <div className="row me-lg-4 me-sm-0 ms-sm-auto ms-md-2 me-md-2 mt-4 supp_padding">
                  <div className="col-12 col-md-12 col-lg-5 col-xl-4">
                    <div className="addsuppierbtn">
                      <div className="d-flex">
                        <img src={inventoryIcon} width={25} className="" />
                        <h6 className="ms-3" style={{ fontSize: "20px" }}>Supplier</h6>
                      </div>
                      <Button variant="ghost" className={roleName !== "User" ? "addbtnsupper" : "addbtnsupper d-none"} onClick={() => handleRedirectionWithStateSave("/add-supplier")}>
                        (+) Create Supplier
                      </Button>
                    </div>
                    <div
                      onClick={arrowupDown}

                    >
                      <div className="">
                        <div className={roleName !== "User" ? "customselect kkk po" : "field-disable customselect kkk po"}
                          style={{ position: "relative", marginBottom: "-29px" }}>
                          <Select

                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            classNamePrefix="sk-custom-select"
                            className={
                              supplierarrow == "" ? "sk3-custom-container" : "ArrowUpDownpur sk3-custom-container"
                            }
                            options={supplierDataList}
                            value={values}
                            onChange={(value) => handleOnSelect(value)}
                            styles={colourStyles}
                          />
                        </div>

                        <div className="mt-4">
                          <div className="row px-2">
                            <div className="pt-5 pe-0 col-5 col-xl-5 col-lg-5 col-md-6 col-sm-6">
                              <h6 className="">
                                {supplierDetails?.address1}{" "}
                                {supplierDetails?.address2}
                              </h6>
                            </div>
                            <div className="pt-5 col-7 col-xl-7 col-lg-7 col-md-6 col-sm-6">
                              <h6>{supplierDetails.email}</h6>
                              <h6>{supplierDetails.phonenumber}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-7 col-xl-8 mt-md-3 mt-lg-0 mb-5 mt-0 ">
                    <Row>
                      <Col lg={12} sm={12} md={12} xl={9}>
                        <div className="d-flex gap-2 py-sm-2 py-md-0">
                          <img src={lastsupIcon} width={25} className="" />
                          <h6 className="m-0 leadtime-top">
                            Last Purchase Order
                          </h6>
                        </div>
                        <div className="d-flex flex-wrap suppdivdetails mt-3">
                          {isShowLastPurchase ?
                            <>
                              <h6 className="ms-0 ms-sm-3 ms-md-3 ms-lg-3 ms-xl-3 mt-3">
                                {supplierInfo?.ordercode
                                  ? supplierInfo?.ordercode
                                  : lastPurchaseData?.ordercode}
                              </h6>
                              <h6 className="ms-3 mt-3">
                                {supplierInfo?.deliverydate
                                  ? supplierInfo?.deliverydate
                                    ? formatDate(supplierInfo?.deliverydate)
                                    : ""
                                  : lastPurchaseData?.deliverydate
                                    ? formatDate(lastPurchaseData?.deliverydate)
                                    : ""}
                              </h6>
                              <h6 className="ms-3 mt-3">
                                {decimalSeparator(lastPurchaseData.ordertotal ? lastPurchaseData.ordertotal : totalCount ? isNaN(totalCount) ? 0 : totalCount : 0, ",")}
                              </h6>
                              <div
                                style={{ cursor: "pointer" }}
                                className={roleName !== "User" ? "advispart lastaadicon mt-2" : "field-disable advispart lastaadicon mt-2"}
                                onClick={getLastPart}
                              >
                                +

                              </div>
                            </>
                            : ""}
                        </div>
                      </Col>
                      <Col xl={3} md={12} lg={12} className="mt-md-3 mt-lg-0 py-sm-2 py-md-0">
                        <h6 className="m-0 leadTime ps-3 leadtime-top">Lead Time</h6>
                        <div className="suppdivdetails mt-3 me-xl-3 me-lg-0">
                          {isShowLastPurchase ?
                            <h6
                              style={{ color: "#80CFE6" }}
                              className="ms-xxl-3 mt-3 ms-lg-0"
                            >
                              {supplierInfo?.leadtime
                                ? supplierInfo?.leadtime
                                  ? supplierInfo?.leadtime
                                  : ""
                                : lastPurchaseData?.leadtime
                                  ? lastPurchaseData?.leadtime
                                  : ""}{" "}
                              Days
                            </h6>
                            : ""}
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-1">
                      <textarea
                        id="w3review"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        name="w3review"
                        rows="2"
                        cols="75"
                        placeholder="comment"
                        className={roleName !== "User" ? "suppdivdetails" : "suppdivdetails field-disable"}
                      />
                    </div>
                  </div>
                </div>
                <Row className="ms-2 mt-5 me-sm-0 ms-md-2 me-md-2 d-lg-flex mt-md-3 mt-0">
                  <Col lg={4}>
                    <div className="addsuppierbtn">
                      <div className="d-flex">
                        <img src={inventoryIcon} width={25} className="" />
                        <h6 className="ms-3">Part</h6>
                      </div>
                      {/* <a href="/add-parts" className="addbtnsupper">
                        (+) Create Part
                      </a> */}
                      <Button variant="ghost" className={roleName !== "User" ? "addbtnsupper" : "addbtnsupper d-none"} onClick={() => handleRedirectionWithStateSave("/add-parts")}>(+) Create Part</Button>
                    </div>
                  </Col>
                  <Col lg={2} className="showhide2 text-center d-sm-none d-md-none d-lg-block">
                    <h6 className="fntparttext">Internal ID</h6>
                  </Col>
                  <Col lg={2} className="showhide2 px-0 text-center d-sm-none d-md-none d-lg-block">
                    <h6 className="fntparttext">Supplier Code</h6>
                  </Col>
                  <Col lg={1} className="showhide2 p-0 text-center  d-sm-none d-md-none d-lg-block">
                    <h6 className="fntparttext">In Stock</h6>
                  </Col>
                  <Col lg={1} className="showhide2 p-0 text-center  d-sm-none d-md-none d-lg-block">
                    <h6 className="fntparttext ">Order Qty</h6>
                  </Col>
                  <Col lg={2} className="showhide2 p-0 text-center  d-sm-none d-md-none d-lg-block">
                    <h6 className="fntparttext">Total Price</h6>
                  </Col>
                </Row>

                <form
                  className="App"
                  autoComplete="off"
                >
                  <div className="form-field">
                    {IsLastOrders ? <>
                      {lastPartsPurchaseList?.map((singleService, index) => {
                        return (
                          <div
                            key={index}
                            className="partname"
                            onClick={arrowupDownPart}
                          >
                            <Row className="mx-1 me-sm-1 ms-sm-auto mb-2 ms-md-2 me-md-2">
                              <Col lg={4}>
                                <div className="first-division boxwidth kkk">
                                  <div className="custom-select-box" >
                                    <div className="custom-select-selected" style={{ fontWeight: "500" }}> {singleService.partname}</div>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={2} className="pe-00 internal_gap">
                                <div className="partdivdetail justify-content-between justify-content-sm-between justify-content-lg-center justify-content-md-between px-2">
                                  <div className="d-sm-block d-md-block d-lg-none internalHeads">
                                    Internal ID
                                  </div>
                                  {singleService.internalid}
                                </div>
                              </Col>
                              <Col lg={2} className="pe-00">
                                <div className="partdivdetail justify-content-between justify-content-sm-between justify-content-lg-center justify-content-md-between px-2">
                                  <div className="d-sm-block d-md-block d-lg-none internalHeads">
                                    Supplier Code
                                  </div>
                                  {singleService.suppliercode}
                                </div>
                              </Col>
                              <Col lg={1} className="pe-00 internal_gap">
                                <div className="partdivdetail justify-content-between justify-content-sm-between justify-content-lg-center justify-content-md-between px-2">
                                  <div className="d-sm-block d-md-block d-lg-none internalHeads">
                                    In Stock
                                  </div>
                                  {singleService.instock}
                                </div>
                              </Col>
                              <Col lg={1} className="p-0">
                                <div className="partdivdetail">
                                  <div className="showhide d-sm-block d-md-block d-lg-none flex-grow-1 ps-2 ps-lg-0 ps-md-2 ps-xl-0 ps-sm-2 internalHeads">
                                    Order Qty
                                  </div>
                                  <input
                                    disabled
                                    name="OrderQty"
                                    type="number"
                                    id="OrderQty"
                                    min={0}
                                    // value={singleService.OrderQty}
                                    value={singleService.orderquantity}
                                    onChange={(e) =>
                                      handleServiceChange(e, index)
                                    }
                                    required
                                    className="signinputpart inppInput flex-grow-1"
                                    placeholder="1"
                                  />
                                  {/* {singleService.OrderQty} */}
                                </div>
                              </Col>
                              <Col
                                lg={2}
                                className="pe-00 internal_gap d-flex justify-content-between"
                              >
                                <div className="partdivdetail px-2 justify-content-between justify-content-sm-between justify-content-lg-center justify-content-md-between">
                                  <div className="showhide d-md-block d-lg-none internalHeads">
                                    Total Price
                                  </div>
                                  {europeanFormat(singleService.totalPrice)}
                                  {/* {singleService.totalPrice} */}
                                </div>

                                <Col lg={1}>
                                  <div
                                    className={roleName !== "User" ? "ms-3 mt-1" : "ms-3 mt-1 d-none"}

                                  >
                                    <img
                                      src={Group62}
                                      onClick={() =>
                                        handleServiceRemove2(index)
                                      }
                                      className="remove-btn"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </Col>
                              </Col>
                            </Row>



                          </div>
                        );
                      })}
                    </>
                      : ""}


                    {orderparts?.map((singleService, index) => {
                      return (
                        <div
                          key={index}
                          className="partname"
                          onClick={arrowupDownPart}
                        >
                          <Row className="mx-1 me-sm-1 ms-sm-auto mb-2 ms-md-2 me-md-2">
                            <Col lg={4}>
                              {singleService.isOld ?
                                <div className="first-division kkk po position-relative">
                                  <div className="sk3-custom-container">
                                    <div className="dummy-select-container">
                                      {singleService.partName}
                                    </div>
                                  </div>
                                </div>
                                :
                                <div
                                  className={roleName !== "User" ? "first-division kkk po" : "first-division kkk po field-disable"}
                                  style={{ position: "relative", zIndex: orderparts.length - index }}
                                >
                                  <Select
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    classNamePrefix="sk-custom-select"
                                    className={partarrow == "" ? "sk3-custom-container " : "ArrowUpDownpur sk3-custom-container "}
                                    options={partBySupplierData}
                                    value={partBySupplierData.filter(function (option) { return option.partid === singleService?.partid })}
                                    styles={colourStyles}
                                    onChange={(value) =>
                                      handleOnSelectPart(value, index)
                                    }
                                  />
                                </div>
                              }
                            </Col>
                            <Col lg={2} className="pe-00 internal_gap">
                              <div className="partdivdetail justify-content-sm-between justify-content-lg-center justify-content-md-between justify-content-between px-2">
                                <div className="d-sm-block d-md-block d-lg-none internalHeads">
                                  Internal ID
                                </div>
                                {singleService.internalId}
                              </div>
                            </Col>
                            <Col lg={2} className="pe-00">
                              <div className="partdivdetail justify-content-sm-between justify-content-lg-center justify-content-md-between justify-content-between px-2">
                                <div className="d-sm-block d-md-block d-lg-none internalHeads">
                                  Supplier Code
                                </div>
                                {singleService.SupplierCode}
                              </div>
                            </Col>
                            <Col lg={1} className="pe-00 internal_gap">
                              <div className="partdivdetail justify-content-sm-between justify-content-lg-center justify-content-md-between justify-content-between px-2">
                                <div className="d-sm-block d-md-block d-lg-none internalHeads">
                                  In Stock
                                </div>
                                {singleService.inStock}
                              </div>
                            </Col>
                            <Col lg={1} className="p-0">
                              <div className="partdivdetail">
                                <div className="d-sm-block d-md-block d-lg-none flex-grow-1 ps-2 ps-lg-0 ps-md-2 ps-xl-0 ps-sm-2 internalHeads">
                                  Order Qty
                                </div>
                                <EuropeanInput
                                  hidePreview={true}
                                  value={singleService.OrderQty}
                                  onChange={(value) => handleServiceChange({ target: { name: "OrderQty", value: value } }, index)}
                                  withoutSymbol={true}
                                  className={roleName !== "User" ? "signinputpart inppInput flex-grow-1 " : "signinputpart inppInput flex-grow-1 field-disable "}
                                />
                              </div>
                            </Col>
                            <Col
                              lg={2}
                              className="pe-00 internal_gap d-flex justify-content-between"
                            // style={{width:"175px"}}
                            >
                              <div className="partdivdetail px-2 justify-content-sm-between justify-content-lg-end justify-content-md-between justify-content-between">
                                <div className="d-md-block d-lg-none internalHeads">
                                  Total Price
                                </div>
                                {/* <p className="pt-3 pe-2"> */}
                                {decimalSeparator(isNaN(singleService.totalPrice) ? 0 : singleService.totalPrice, ",")}
                                {/* {singleService.totalPrice} */}
                                {/* </p> */}
                              </div>


                              <Col lg={1}>
                                <div className={roleName !== "User" ? "text-end ms-2" : "text-end ms-2 field-disable"}>
                                  {orderparts.length - 1 === index ? (
                                    <button
                                      type="button"
                                      onClick={handleServiceAdd}
                                      className="me- pt-2 addrowbtn"
                                    >
                                      Add...
                                    </button>
                                  ) : (
                                    <div
                                      className={roleName !== "User" ? "second-division ms-4 mt-2" : "second-division ms-4 mt-2 d-none"}
                                    >
                                      {orderparts.length !== 1 && (
                                        <img
                                          src={Group62}
                                          onClick={() =>
                                            handleServiceRemove(index)
                                          }
                                          className="remove-btn"

                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Col>
                          </Row>

                        </div>
                      );
                    })}
                  </div>
                </form>
                <Row className="me-sm-0 ms-md-2 me-md-2 mt-md-3 total_padding">
                  <Col style={{ visibility: "" }} lg={7}></Col>
                  <Col
                    lg={5}
                    className="suppliertext "

                  >
                    <h6 className="suppliertextTo ">Total Order</h6>
                    <div className="totaletext ms-4">
                      <h6
                        style={{ backgroundColor: "#FAFAFA" }}
                        className="p-2 pt-3 ordertext"
                      >
                        {europeanFormat(totalCount)}
                      </h6>
                    </div>

                  </Col>
                </Row>

                <div className="row ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4 d-flex align-items-end">
                    {isNegativeQty && <span className="text-danger">*Order quntity can not be negative.</span>}
                  </div>
                  <div className="col-12 mt-5 col-lg-8 col-md-12 col-sm-12 col-xl-8">


                    <div className={roleName !== "User" ? "row  justify-content-md-center justify-content-lg-end" : "d-none"}
                    >
                      <div className="col-12 col-sm-12 col-lg-6 col-xl-5 col-xxl-4 col-md-6 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc countBtnchange12 w-100 p-2"
                          onClick={cancelAction}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-6 col-xl-5 col-xxl-4 col-md-6 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc countBtncancBg countBtnchange12 w-100 p-2"

                          onClick={(e) => {

                            if (isUpdatedSupplier) {
                              handleChange();
                            } else {
                              handleAdd();

                            }
                          }}

                        >
                          {isUpdatedSupplier ? "CHANGE" : "ADD"}
                        </button>
                      </div>


                    </div>
                    <div className={roleName === "User" ? "col-12 col-sm-12 col-lg-6 col-xl-5 col-xxl-4 col-md-6 my-1 col-xl-3 ms-auto" : "d-none"}>
                      <button
                        type="button"
                        className="countBtncanc countBtncancBg countBtnchange12 w-100 p-2"
                        onClick={cancelAction}>
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12 ps-xl-0 ps-lg-0 ps-md-5 ps-5">
              <div className="d-flex h-100 w-100 ">
                <Alert show={show} variant="" className="p-0 mt-5">
                  <div className="ms-3 mt-4 pt-2">
                    <div className="pe-5 pb-2">
                      <h6 className="advisetext">Advised Part Purchasing</h6>
                    </div>
                    <div className="advisedpart mt-3">
                      <h6 className="advisesubtext">Part</h6>
                      <h6 className="advisesubtext ">Purchase Qty</h6>
                    </div>
                    {AdvisedPartPurchasingdata.data?.map((iteme, i) => (
                      <div key={i} className="addvisepurch">
                        <h6 className="subparttext pe-2">{iteme.partname}</h6>
                        <h6 className="subpartqty pe-2">{stockQuntityFormat(iteme.purchaseqty)}</h6>
                      </div>
                    ))}
                  </div>


                </Alert>
                <div
                  style={{ cursor: "pointer" }}
                  className=" addvisecol mt-2">
                  <p onClick={() => setShow(!show)}>
                    <i
                      className={
                        show == true
                          ? "bi bi-chevron-left adviseRightTrueIcon advispart "
                          : "bi bi-chevron-right adviseRightIcon ps-2 advispart"
                      }
                    ></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Modal
            size="xl-down"
            show={modalShow}
            backdrop="static"
            keyboard={false}
            onHide={() => setModalShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-model"
          >
            <Modal.Body>
              <p className="contents">
                The{" "}
                <span className="highlighter">
                  Purchase Order ID {createdOrderId}
                </span>{" "}
                was created successfully.
              </p>

              <p>Do you want to Add another Purchase Order?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => goBackCustom()}>NO</Button>
              <Button onClick={() => handlerRemove()}>ADD</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
