import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import rbcelogo from "../../assets/icons/rbcelogo.png";
import "../../styles/local/headersupplier.css";
import { useDispatch, useSelector } from "react-redux";
import { user_logout, fetchSuppliers } from "../../redux/actions/action";
import { cardShow } from "../../pages/static/en/Sidebar";
const Headersupplier = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const expandMenu = useContext(cardShow);
  const userID = window.sessionStorage.getItem("userid");
  const clientname = window.sessionStorage.getItem("clientname");
  const finalClientname = clientname.split(" ");
  const firstName = finalClientname[ 0 ].toString();
  const lastName = finalClientname[ 1 ].toString();
  const logoutData = useSelector((state) => state.user.userLogout);
  const initialRender = React.useRef(true);
  const imageurl = window.sessionStorage.getItem("image");

  // const [querystring, setQuerystring] = useState("");
  const [ error, setError ] = useState(false);
  // const [alert, setAlert] = useState(false);
  const [ msg, setMsg ] = useState("");
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const finDate = new Date(date);
  var date = finDate.toString().split(" ");
  React.useEffect(() => {
    if (!initialRender.current) {
      if (logoutData != null) {
        if (logoutData.status == true) {
          if (logoutData.data == null) {
            history.push("/");
            window.location.reload();
          } else {
            history.push("/");
            window.location.reload();
            window.sessionStorage.clear();
            window.localStorage.clear();
          }
        } else {
          // alert("something went wrong");
          // setAlert(true);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ logoutData ]);

  // useEffect(() => {
  //   dispatch(fetchSuppliers(props.pagenumber, props.querystring));
  // }, [props.querystring]);

  return (
    <>
      <div className="container-fluid px-0 py-4 homeback">
        <div className="row">
          <div
            className="col-12 text-center text-sm-center text-xl-start text-md-center text-lg-start  col-xl-3 col-md-3 col-lg-3 col-sm-12 ps-0 ps-sm-0 ps-xl-3 ps-lg-3 ps-md-3 "
          >
           {!expandMenu && 
            <img src={rbcelogo} alt=""
              onClick={() => history.push("/home")}
            />
          }
          </div>
          <div className="col-12 col-xl-5 col-md-4 col-lg-5 col-sm-12 text-center moblieRes">
            <div className="input-group my-2">
              <input
                type="text"
                className="form-control headersInput p-2 px-4"
                placeholder="Search"
                // onChange={(e) => props.setSuppliersearch(e.target.value)}
                value={props.querystring}
                onChange={(e) => props.setQuerystring(e.target.value)}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <span className="input-group-text headers" id="basic-addon2">
                <i
                  className="bi bi-search pe-2"
                  style={{ color: "#707070" }}
                // onClick={() => {
                //   dispatch(
                //     fetchSuppliers(props.pagenumber, props.querystring)
                //   );
                // }}
                ></i>
              </span>
            </div>
          </div>
          <div className="col-12 pt-5 mt-2  col-xl-4 col-md-5 col-lg-4 col-sm-12 justify-content-center justify-content-md-center justify-content-sm-center  align-self-start pt-2 pt-sm-2 pt-xl-0 pt-lg-0 pt-md-2 d-flex">
            <div className="row ps-0 ms-0 ps-xl-5 ms-xl-5">
              <div className="col-12 col-sm-4 ps-0">
                <div className="pt-3 headerText pe-0 pe-md-0 pe-xl-5 pe-sm-0 pe-lg-3 text-center fw-bold">
                  {date[ 2 ] + "." + date[ 1 ] + "." + date[ 3 ]}
                </div>
              </div>
              <div className="col-2 col-sm-3 col-lg-4 justify-content-center justify-content-xl-start justify-content-md-center justify-content-sm-center d-flex">
                {/* <div className="ps-5 ps-xl-5 pe-0 pe-sm-0   pe-md-0  pt-2">
                  <i className="bi bi-bell headerIcon "></i>
                </div> */}
              </div>
              <div className="col-12 col-sm-4 mt-3 mt-sm-0 px-0 text-center">
                <div>
                  <div className="dropdown ">
                    <button
                      className="btn btn-secondary profileButton dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {/* {clientname == undefined || clientname == null
                        ? "SD"
                        : firstName[ 0 ].toUpperCase() +
                        "" +
                        lastName[ 0 ].toUpperCase()} */}

                      {imageurl == "" ? (
                        clientname == undefined || clientname == null ? (
                          "SD"
                        ) :
                          firstName[ 0 ].toUpperCase() + "" +
                          lastName[ 0 ].toUpperCase()
                      ) : (
                        <img
                          src={imageurl}
                          alt="img"
                          height="71px"
                          width="71px"
                          style={{ position: "relative", right: "14px", borderRadius: "50%", top: "-7px" }}
                        />
                      )}

                    </button>
                    <div
                      className="dropdown-menu drpMenu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        onClick={() => { 
                          history.push("/profile")
                          window.sessionStorage.removeItem("position")
                          window.location.reload();
                        }}
                      >
                        Edit Profile
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          dispatch(user_logout(userID));
                        }}
                      >
                        Log Out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Headersupplier;
