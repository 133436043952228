import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../../redux/actions/action";
import { useHistory } from "react-router-dom";

const MoveCard = (props) => {
  const dispatch = useDispatch();
  const [destination, setDestination] = React.useState("");
  const changeStatusData = useSelector((state) => state.user.changeStatus);
  const initialRender = React.useRef(true);
  const orderId = window.sessionStorage.getItem("orderId");
  const inputRef = useRef(null);
  const history = useHistory();
  var statusId = "";
  if (destination == "Pipeline") {
    statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3058";
  } else if (destination == "Queue") {
    statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3059";
  } else if (destination == "Complete") {
    statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3062";
  } else if (destination == "Ready To Ship") {
    statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3061";
  } else {
    statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3060";
  }

  React.useEffect(() => {
    setDestination(props.statusId);
  }, []);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (changeStatusData != null) {
        if (changeStatusData.status == true) {
          window.location.reload();
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [changeStatusData]);
  const dateClick = () => {
    inputRef.current.focus();
  };
  const clickElement = (ref) => {
    ref.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  };

  const onchangeStatus = (e) => {
    setDestination(e.target.value)
    // props.handleStatusChanged(e.target.value)
  }
  return (
    <div className="min-vh-100">
      <Modal
        animation={false}
        show={true}
        className={
          props.position == true ? "popovermodalCreate" : "popovermodal"
        }
        style={{ width: "18em" }}
        bottom
      >
        <div className="container headerbg pt-3">
          <div className="row">
            <div className="col-9">
              {" "}
              <h4>Move Card</h4>
            </div>
            <div className="col-2 text-end">
              <h4 style={{ cursor: "pointer" }} onClick={props.handleClose}>
                X
              </h4>
            </div>
          </div>
        </div>
        <ModalBody>
          <div className="container text-center">
            <select
              onChange={onchangeStatus}
              value={destination}
              className="form-select"
              aria-label="Default select example"
            >
              <option selected>Select Destination</option>
              <option value="Pipeline">Pipeline</option>
              <option value="Queue">Queue</option>
              <option value="In Progress">In Progress</option>
              {/* <option value="Ready To Ship">Ready To Ship</option> */}
              <option value="Complete">Complete</option>
            </select>
            <button
              type="button"
              onClick={() => {
                // if (props.position == true) {
                //     props.handleClose(statusId, destination)
                // }
                // else {
                //     dispatch(changeStatus(orderId, statusId));
                // }
                props.setStatusId(statusId);
                props.setStatusPopup(false);
                props.handleStatusChanged(destination)
              }}
              className="btn btn-primary mt-5 w-100 px-5 moveBtn"
            >
              {props.position == true ? "Confirm" : "Move"}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default MoveCard;
