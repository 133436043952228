export const API_URL = process.env.REACT_APP_API_BASE_URL;
// export const API_URL = "http://localhost:62794";
export const USER_SIGNUP = "USER_SIGNUP";
export const USER_LOGIN = "USER_LOGIN";
export const SEND_EMAIL = "SEND_EMAIL";
export const FORGOT_PASS = "FORGOT_PASS";
export const GOOGLE_SIGNUP = "GOOGLE_SIGNUP";
export const ORDERSTATUS = "ORDERSTATUS";
export const USER_LOGOUT = "USER_LOGOUT";
export const STOCK_AVAILABLE = "STOCK_AVAILABLE";
export const SHIPMENT_STATUS = "SHIPMENT_STATUS";
export const NEXT_ORDER = "NEXT_ORDER";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const FETCH_ORDERDETAILS = "FETCH_ORDERDETAILS";
export const FETCH_USERDETAILS = "FETCH_USERDETAILS";
export const DEL_USERDETAILS = "DEL_USERDETAILS";
export const UPD_USERDETAILS = "UPD_USERDETAILS";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_TEAM = "FETCH_TEAM";
export const FETCH_AWORDER = "FETCH_AWORDER";
export const FETCH_ADVISED_PART_PURCHASING = "FETCH_ADVISED_PART_PURCHASING";
export const FETCH_PURCHASING_ORDER = "FETCH_PURCHASING_ORDER";
export const DOC_FILE_DOWNLOAD = "DOC_FILE_DOWNLOAD";
export const UPDATE_PART_DATA = "UPDATE_PART_DATA";
export const FETCH_ROLE_MODULE = "FETCH_ROLE_MODULE";
export const FETCH_WAREHOUSE = "FETCH_WAREHOUSE";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const UPDATE_ADD_PART_STOCK = "UPDATE_ADD_PART_STOCK";
export const POST_ORDER = "POST_ORDER";
export const UPDATE_USER = "UPDATE_USER";
export const FETCH_USER = "FETCH_USER";
export const FETCH_CLI = "FETCH_CLI";
export const ADD_CLI = "ADD_CLI";
export const ADD_LOCATION = "ADD_LOCATION";
export const SEARCH_CONTACT = "SEARCH_CONTACT";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const FETCH_PROSTOCK = "FETCH_PROSTOCK";
export const UPDATE_PROSTOCK = "UPDATE_PROSTOCK";
export const UPDATE_PARK_STOCK = "UPDATE_PARK_STOCK";
export const PURCHASE_ORDER_DELIVERED = "PURCHASE_ORDER_DELIVERED";
export const FETCH_COMM = "FETCH_COMM";
export const FETCH_SUPP = "FETCH_SUPP";
export const SUGGEST_PART = "SUGGEST_PART";
export const SUGGEST_PRODUCT = "SUGGEST_PRODUCT";

export const FETCH_SUPP_BY_PART = "FETCH_SUPP_BY_PART";
export const ADD_SUPP = "ADD_SUPP";
export const COM_PROD = "COM_PROD";
export const ADD_COMM = "ADD_COMM";
export const DEL_SUPP = "DEL_SUPP";
export const DEL_PROD = "DEL_PROD";
export const DEL_PART = "DEL_PART";
export const FETCH_SUPPBYID = "FETCH_SUPPBYID";
export const FETCH_PARTBYID = "FETCH_PARTBYID";
export const FETCH_PRODBYID = "FETCH_PRODBYID";
export const DEL_CLI = "DEL_CLI";

export const FETCH_CLIBYID = "FETCH_CLIBYID";
export const UPDATE_CLI = "UPDATE_CLI";
export const UPDATE_SUPP = "UPDATE_SUPP";
export const FETCH_USERS = "FETCH_USERS";
export const UPDATE_PART = "UPDATE_PART";
export const UPDATE_PROD = "UPDATE_PROD";
export const CREATE_PART = "CREATE_PART";
export const UPLOAD_TO = "UPLOAD_TO";
export const LAST_PURCHASE = "LAST_PURCHASE";
export const FETCH_PARTS_LAST_PURCHASE = "FETCH_PARTS_LAST_PURCHASE";
export const FETCH_PARTS = "FETCH_PARTS";
export const ADVISED_PART_PURCHASING = "ADVISED_PART_PURCHASING";
export const CREATE_PURCHASING = "CREATE_PURCHASING";
export const UPDATE_PURCHASING = "UPDATE_PURCHASING";
export const FETCH_USER_BYID = "FETCH_USER_BYID";
export const CHECK_ASSIGNEES = "CHECK_ASSIGNEES";
export const DEDUCT_PART_STOCK = "DEDUCT_PART_STOCK";
export const ADD_ERROR_CODE = "ADD_ERROR_CODE";
export const FETCH_ERROR_CODE = "FETCH_ERROR_CODE";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_USER_VARIFY = "UPDATE_USER_VARIFY";
export const DELETE_ORDER = "DELETE_ORDER";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const CALENDAR_DATE_UPDATE = "CALENDAR_DATE_UPDATE";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const TIMELINE_PROPS = "TIMELINE_PROPS";
