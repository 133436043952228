import React, { useEffect, useMemo, useRef, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import Image from "react-bootstrap/Image";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { useDispatch, useSelector } from "react-redux";
import cardData from "../../lang/carddata.json";
import {
  addLocation,
  fetchClient,
  fetchOrderDetails,
  updateOrderdetails,
  searchContact,
  fetchUserDetails,
  fetchComments,
  addComments,
  deleteComment,
  Timeline_Props,
  fetchErrorCode,
  fetchOrders,
} from "../../redux/actions/action";
import Loader from "../../components/Loader";
import orderIcon from "../../assets/icons/orderIcon.svg";
import clienticon from "../../assets/icons/usericon.png";
import calender from "../../assets/icons/noun-calendar-857968.png";
import warehouseIcon from "../../assets/icons/warehouseIcon.svg";
import stockIcon from "../../assets/icons/stockIcon.svg";
import productsIcon from "../../assets/icons/productionIcon.svg";
import teamIcon from "../../assets/icons/teamIcon.svg";
import Group1087 from "../../assets/icons/Group1087.svg";
import deliveryIcon from "../../assets/icons/deliveryIcon.svg";
import documentIcon from "../../assets/icons/documentIcon.svg";
import installationIcon from "../../assets/icons/installationIcon.svg";
import producticon from "../../assets/icons/producticon.svg";
import speciaIcon from "../../assets/icons/speciaIcon.svg";
import client from "../../assets/icons/Client.svg";
import Autocomplete from "react-google-autocomplete";
import MoveCard from "./MoveCard";
import "../../styles/local/orderPopup.css";
import ClientCard from "./ClientCard";
import axios from "axios";
import { API_URL } from "../../redux/constants/type";
// import calender from "../../assets/icons/noun-calendar-857968.png";
import DatePicker from "react-datepicker";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Calendar } from "react-big-calendar";
import { components } from "react-select";
import moment from "moment";
import leftarrow from "..//..//assets/icons/leftarrow.jpg";
import { useHistory } from "react-router-dom";
import Single from "..//..//assets/icons/singel.svg";
import TeamMemberSelection from "../TeamMemberSelection";
import { stockQuntityFormat } from "../../utils/NumberFormatter";
import { ReactComponent as BinIcon } from "../../assets/icons/Bin.svg";
import DeleteOrderPopup from "./deleteOrder";

const Orderpopup = (props) => {
  const clientname = window.sessionStorage.getItem("clientname");
  const selectRef = useRef();
  const history = useHistory();
  const finalClientname = clientname.split(" ");
  const firstName = finalClientname[ 0 ].toString();
  const lastName = finalClientname[ 1 ].toString();
  const dispatch = useDispatch();
  const [ handleArray, setHandleArray ] = useState([]);

  const [ loading, setLoading ] = useState(true);
  const orderId = window.sessionStorage.getItem("orderId");
  const userId = window.sessionStorage.getItem("userid");
  const userid = window.sessionStorage.getItem("userid");
  const UserID = window.sessionStorage.getItem("userid");
  const roleName = window.sessionStorage.getItem("rolename");
  const [ productName, setProductName ] = useState();
  // const dueDate = window.sessionStorage.getItem("dueDate");
  const orderDetails = useSelector((state) => state.user.fetchOrderDetailsRes);
  // const fetchComment = useSelector((state) => state.user.fetchComment);
  const [ contactId, setContactId ] = useState("");

  const updateOrderData = useSelector((state) => state.user.updateOrder);

  const [ doc, setDoc ] = useState("");
  const [ userArray, setUserArray ] = useState([]);
  const teamid = window.sessionStorage.getItem("teamId");
  const position22 = window.sessionStorage.getItem("position22");
  console.log("pos", position22);
  const [ contactList, setContactList ] = useState([]);
  const [ searchPeople, setSearchPeople ] = useState("");
  const [ assignee, setAssignee ] = useState([]);
  const [ checklist, setChecklist ] = useState([]);
  const [ locationId, setLocationId ] = useState("");
  const [ orderdetailsData, setorderDetailsData ] = useState([]);
  const [ productnames, setProducNames ] = useState();
  const [ currentQty, setcurrentQty ] = useState();
  const [ StatusPopup, setStatusPopup ] = useState(false);
  // const [isConcreteBlock, setisConcreteBlock] = useState(false);
  const [ warehouse, setWarehouse ] = useState();
  const [ teamName, setTeamName ] = useState("");
  const [ name, setName ] = useState("");
  const [ telephone, setTelephone ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ imgurl, setImgurl ] = useState("");
  const [ productname, setProductname ] = useState("");
  const [ orderDocid, setOrderDocid ] = useState("");
  const addLocationData = useSelector((state) => state.user.addLocationData);
  const searchContactData = useSelector((state) => state.user.searchContactData);
  const fetchDeleteCommentData = useSelector((state) => state.user.deleteComment);
  const fetchAddCommentData = useSelector((state) => state.user.addComment);
  const clientData = useSelector((state) => state.user.fetchClient);
  const finalOrderId = orderId.split("-")[ 4 ];
  const initialRender = React.useRef(true);
  const [ usersName, setUsersName ] = useState();
  const [ usersLastName, setUsersLastName ] = useState();
  const [ delLocation, setDelLocation ] = useState(true);
  const [ editLocation, setEditLocation ] = useState(false);
  const [ special, isSpecial ] = useState(false);
  const [ concreteCheck, setConcreteCheck ] = useState(false);
  const [ stockStatus, setStockStatus ] = useState(false);
  const [ clientPopup, setClientPopup ] = useState(false);
  const [ edit, setEdit ] = useState(false);
  const [ filedetails, setFileDetails ] = useState([]);
  const [ installationName, setInstallationName ] = useState("");
  const [ firstname, setfirstname ] = useState("");
  const [ lastname, setLastName ] = useState("");
  const [ filename, setFileName ] = useState("");
  const [ statusId, setStatusId ] = useState("");
  const [ statusCheck, setStatusCheck ] = useState("");
  const [ clientList, setClientList ] = useState([]);
  const [ clientId, setClientId ] = useState("");
  const [ dueDate, setDueDate ] = useState(moment().hours(9).minutes(0).seconds(0).milliseconds(0).toDate());
  const [ dueDate1, setDueDate1 ] = useState(false);
  const [ creationDate, setCreationDate ] = useState("");
  const [ partAllot, setPartAllot ] = useState("");
  const [ partId, setPartId ] = useState("");
  const [ quoteId, setQuoteId ] = useState("");
  const [ finaluserdata, setuserData ] = useState([]);
  const [ companyId, setCompanyId ] = useState([]);
  const [ pagenumber, setPageNumber ] = useState(0);
  const [ querystring, setQuerystring ] = useState("");
  const [ statusName, setStatusName ] = useState("");
  const [ fetchCommitData, setFetchCommitData ] = useState([]);
  const [ fetchMaxCommitData, setFetchMaxCommitData ] = useState([]);

  const [ showMore, setShowMore ] = useState(false);
  const [ showcmnt, setShowcmnt ] = useState(true);
  const [ enablecomm, setEnableComm ] = useState(false);
  const [ commitData, setCommitData ] = useState("");
  const [ partdetails, setPartdetails ] = useState([]);
  const [ teamArr, setTeamArr ] = useState([]);
  const [ attachments, setAttachments ] = useState("");

  const [ selectedMemberId, setSelectedMemeberId ] = useState([]);
  const [ attachedFileName, setAttachedFileName ] = useState("");
  const MAX_ITEMS = 5;
  const userDatas = useSelector((state) => state.user.fetchUserDetails);
  const fetchComment = useSelector((state) => state.user.fetchComment);
  const [ showEmojiPicker, setShowEmojiPicker ] = useState(false);
  const [ val, setVal ] = useState("");
  const [ message, setMessage ] = useState("");
  const inputref = useRef(null);
  const [ checkradio, setCheckradio ] = useState(false);
  const [ show, setShow ] = useState(true);
  const [ contactSelect, setContactSelect ] = useState([]);
  const [ partarrow, setPartarrow ] = useState("");
  const [ searchedItem, setSearchedItem ] = useState("");
  const [ showOptions, setShowOptions ] = useState(false);
  const [ string2, setString2 ] = useState("");


  const handleClose = () => {
    // setStatusId(statusid)
    setStatusPopup(false);
    setClientPopup(false);
    setShow(false);
    // window.location.reload();
    props.handleClose()
  };

  const ClientPopupClose = () => {
    setClientPopup(false);
  };
  const StatusPopupClose = () => {
    setStatusPopup(false);
  };


  const handleClientName = (value) => {
    setInstallationName(value);
    setClientPopup(false);
  };

  const handleStatusChanged = (value) => {
    setStatusName(value)
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(orderId);
    alert("Copy to clipboard");
  };
  const onButtonClick = () => {
    inputref.current.click();
  };
  // useEffect(() => {
  //     var arr = [];
  //     for (var i = 0; i < usersName.length; i++) {
  //
  //     }
  // }, [usersName])
  React.useEffect(() => {
    dispatch(fetchClient(pagenumber, querystring));
  }, [ fetchClient ]);

  useEffect(() => {
    dispatch(fetchComments(orderId, "order"));
  }, []);

  useEffect(() => {
    if (fetchDeleteCommentData && fetchDeleteCommentData.status) {
      dispatch(fetchComments(orderId, "order"));
    }
  }, [ fetchDeleteCommentData ]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchComment != null) {
        if (fetchComment.status == true) {
          let newComment = [];
          fetchComment?.data?.forEach((element) => {
            let fileName = element.attachment?.substring(element.attachment.lastIndexOf('/') + 1);
            newComment.push({
              ...element,
              fileName,
            });
          });
          setFetchCommitData(newComment);
          setFetchMaxCommitData(newComment?.slice(0, MAX_ITEMS));
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ fetchComment ]);

  const handlerSendCommit = () => {
    if (val || attachments) {
      dispatch(addComments(userId, orderId, val, "order", attachments));
    }
    setVal("");
    setAttachedFileName("");
    setAttachments("");
  };

  useEffect(() => {
    if (fetchAddCommentData && fetchAddCommentData.status) {
      dispatch(fetchComments(orderId, "order"));
    }
  }, [ fetchAddCommentData ])

  React.useEffect(() => {
    if (!initialRender.current) {
      if (clientData != null) {
        if (clientData.status == true) {
          setClientList(clientData.data);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ clientData ]);

  useEffect(() => {
    dispatch(fetchUserDetails(0, teamid));
  }, []);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (searchContactData != null) {
        if (searchContactData.status == true) {
          setContactList(
            // searchContactData.data[ 0 ]?.clientcontact[ 0 ]?.contactdetails
            searchContactData.data
          );

          setContactSelect(searchContactData.data[ 0 ]?.contactid);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ searchContactData ]);

  const initialRender2 = React.useRef(true);
  React.useEffect(() => {
    if (!initialRender2.current) {
      if (addLocationData != null) {
        if (addLocationData.status == true) {
          setLocationId(addLocationData.data.locationid);
        } else {
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [ addLocationData ]);
  React.useEffect(() => {
    if (!initialRender.current) {
      if (userDatas != null) {
        if (userDatas.status == true) {
          setuserData(
            userDatas.data.filter((item, userid) => item.userdetails.length > 0)
          );
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ userDatas ]);
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <p className="duedate  mb-0" style={{ color: "rgb(0, 55, 102)" }}>
        <span onClick={props.onClick} ref={ref}>
          <img src={calender} className="calenOrder mx-1 headclr" alt="" />
        </span>
        Due date
      </p>
    );
  });

  const handleChangeDate = (e) => {
    var dt = new Date(e);
    setDueDate(dt);
    setDueDate1(true);
  };

  const [ calid, setCalid ] = useState("")

  const handleOnSearch = (string, results) => {
    dispatch(searchContact(string, clientId));
    // if (results.length == 0) {
    //   setName("");
    //   setContactId("");
    //   setEmail("");
    //   setTelephone("");
    // }
  };
  const handleFileChange = (e) => {
    const target = e.target.files[ 0 ];
    let formData = new FormData();
    formData.append("file", target);

    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setDoc(response.data[ 1 ].data.fileid);
        } else {
        }
      })
      .catch(function (error) { });
  };

  const handleFileChange1 = (e) => {
    const target = e.target.files[ 0 ];
    let formData = new FormData();
    formData.append("file", target);
    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          // setCommentval(val + response.data[0])
          setAttachments(response.data[ 0 ])
          setAttachedFileName(target.name)
          // alert(target.name + " " + "File is Attached in comment box");
        } else {
        }
      })
      .catch(function (error) { });
  };



  const handleAddress = (address) => {
    dispatch(addLocation(userid, address));
    // dispatch(addLocation(userid, "greater noida"))
  };

  React.useEffect(() => {
    if (!initialRender.current) {
      if (updateOrderData != null) {
        if (updateOrderData.status == true) {
          // setTimeout(() => {
          //   setLoading(false);
          //   window.location.href = "/home";
          // }, 2000);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ updateOrderData ]);
  useEffect(async () => {
    var productDetail = [];
    for (var i = 0; i < cardData.pipeline[ 0 ].productdetail.length; i++) {
      productDetail.push(cardData.pipeline[ 0 ].productdetail[ i ].productname);
    }
    await dispatch(fetchOrderDetails(orderId, userid));
    setProductName(productDetail);
  }, []);

  const handelupdateaccrole = () => {
    if (roleName != "user") {
      handleUpdate();
    } else {
      alert("You Don't have access to change");
    }
  };

  const [ destination, setDestination ] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  const handleUpdate = () => {
    var deliveryDate1;
    if (dueDate != "") {
      deliveryDate1 = moment(dueDate).utc(false).toDate();
    }
    let prmTeamMembers = selectedMemberId.map(x => { return { assigneeuserid: x } });
    dispatch(
      updateOrderdetails(
        orderId,
        userid,
        statusId,
        clientId,
        contactId,
        // creationDate,
        locationId,
        installationName,
        deliveryDate1,
        "client",
        concreteCheck == false ? 0 : 1,
        special == false ? 0 : 1,
        stockStatus == false ? 0 : 1,
        quoteId,
        name,
        email,
        telephone,
        orderDocid,
        doc,
        prmTeamMembers,
        partId,
        partAllot
      )
    );

    if (position22 === "/timelineview") {
      console.log("pos22", position22);
      history.push("/timelineview");
    }
    else if (position22 === "/home") {
      console.log("pos22", position22);
      history.push("/home");
    }
    else {
      history.push("/home");
    }
    // dispatch(Timeline_Props("asc"))
    dispatch(fetchErrorCode());
    dispatch(fetchOrders(UserID, destination));
    props.handleClose()
    // window.location.reload();

    // setLoading(true);
  };

  const handleOnSelect = (item) => {
    setName(item.name);
    setContactId(item.contactid);
    setEmail(item.email);
    setTelephone(item.phonenumber);
  };

  // const data1 = [ ...data ].sort((a, b) => {
  //   if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  //   if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  //   return 0;
  // });

  // const options = [...finaluserdata]
  const options = [
    {
      label: "Option 1",
      value: 0,
      image: "https://rbce-dev.s3.eu-west-2.amazonaws.com/t4.png",
    },
    {
      label: "Option 2",
      value: 1,
      image: "https://rbce-dev.s3.eu-west-2.amazonaws.com/t4.png",
    },
  ];

  const { SingleValue, Option } = components;

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <img
        src={props.data.image}
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </SingleValue>
  );

  const IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.image}
        style={{ height: "30px", width: "30px", borderRadius: "50%" }}
      />
      {props.data.label}
    </Option>
  );
  const customStyles = {
    option: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }),
  };

  const handlerAdvisedMore = () => {
    setShowMore((prev) => !prev);
  };
  const onChange = (e) => {
    setVal(e.target.value);
  };

  const [ selectedValues, setSelectedValues ] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedValues([ ...selectedValues, value ]);
    } else {
      setSelectedValues(selectedValues.filter((v) => v !== value));
    }
  };

  const updateSelectedMember = (members) => {
    let oldMembers = selectedMemberId;
    oldMembers = [ ...members ];
    setSelectedMemeberId(oldMembers);
  }

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);

    if (!initialRender.current) {
      if (orderDetails != null) {

        if (orderDetails.status == true) {
          // setCreationDate(orderDetails.data[0].orderdetail[0].createdat)
          setLastName(orderDetails.data[ 0 ]?.userdetails[ 0 ]?.users[ 0 ]?.lastname);
          setfirstname(orderDetails.data[ 0 ]?.userdetails[ 0 ]?.firstname);
          setImgurl(orderDetails.data[ 0 ]?.productdetails?.imageurl);
          setProductname(orderDetails.data[ 0 ]?.productdetails?.productname);
          setPartdetails(orderDetails.data[ 0 ]?.partdetails);
          setPartId(orderDetails.data[ 0 ]?.partdetails?.partid);
          setPartAllot(orderDetails.data[ 0 ]?.partdetails?.orderquantity);
          setStatusName(orderDetails.data[ 0 ]?.statusname);
          setCompanyId(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.contactdetails[ 0 ]?.companyid
          );
          setorderDetailsData(orderDetails.data);
          // setTeamArr(orderDetails.data[0].teamdetails);
          for (let i = 0; i < orderDetails.data[ 0 ]?.teamdetails?.length; i++) {
            teamArr.push({
              assigneeuserid: orderDetails.data[ 0 ]?.teamdetails[ i ]?.teamid,
            });
          }
          setTeamName(orderDetails.data[ 0 ]?.teamdetails[ 0 ]?.teamname);
          setDelLocation(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.locationdetails[ 0 ]
              ?.locationaddress
          );
          setLocationId(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.locationdetails[ 0 ]?.locationid
          );
          setFileDetails(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.filedetails);
          setFileName(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.filedetails[ 0 ]?.filename
          );
          setOrderDocid(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.filedetails[ 0 ]?.orderdocumentid
          );
          setInstallationName(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.installationName
          );
          setContactId(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.contactid);
          setEmail(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.contactdetails[ 0 ]?.email
          );
          setName(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.contactdetails[ 0 ]?.name);
          setTelephone(
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.contactdetails[ 0 ]?.phonenumber
          );
          setDoc(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.filedetails[ 0 ]?.fileid);
          setClientId(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.clientid);
          setStatusId(orderDetails.data[ 0 ]?.statusid);
          setDueDate(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.deliverydate ? moment.utc(orderDetails.data[ 0 ].orderdetail[ 0 ].deliverydate).local().toDate() : "");
          setCreationDate(
            new Date(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.createdat)
          );
          setQuoteId(orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.ordercode);
          setTimeout(() => {
            orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.isSpecialPart == 0
              ? isSpecial(false)
              : isSpecial(true);
          }, 5000);
          orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.isConcreteBlock == 0
            ? setConcreteCheck(false)
            : setConcreteCheck(true);
          orderDetails.data[ 0 ]?.orderdetail[ 0 ]?.isStockStatus == 0
            ? setStockStatus(false)
            : setStockStatus(true);
          // orderDetails.data[0].statusname == "Pipeline" ? setStatusId("08da2f31-46b4-4ce8-80b3-fdffac7a3058") : orderDetails.data[0].statusname == "Queue" ? setStatusId("08da2f31-46b4-4ce8-80b3-fdffac7a3059") : orderDetails.data[0].statusname == "Complete" ? setStatusId("08da2f31-46b4-4ce8-80b3-fdffac7a3062") : orderDetails.data[0].statusname == "Ready To Ship" ? setStatusId("08da2f31-46b4-4ce8-80b3-fdffac7a3061") : setStatusId("08da2f31-46b4-4ce8-80b3-fdffac7a3060")

          // var productName = [];
          // var currentQty = [];
          // var warehouse = [];
          // for (var i = 0; i < orderdetailsData[ 0 ]?.productdetails.length; i++) {
          //   productName.push(
          //     orderdetailsData[ 0 ]?.productdetails[ i ][ 0 ].productname
          //   );
          //   currentQty.push(
          //     orderdetailsData[ 0 ]?.productdetails[ i ][ 0 ].orderquantity
          //   );
          //   warehouse.push(
          //     orderdetailsData[ 0 ]?.productdetails[ i ][ 0 ].warehousename
          //   );
          // }
          setProducNames(productName);
          setcurrentQty(currentQty);
          setWarehouse(warehouse);

          var arr = [];
          var lastArr = [];
          for (var i = 0; i < orderDetails.data[ 0 ]?.userdetails.length; i++) {
            arr.push(
              orderDetails.data[ 0 ]?.userdetails[ i ].users[ 0 ]?.firstname +
              " " +
              orderDetails.data[ 0 ]?.userdetails[ i ].users[ 0 ]?.lastname
            );
            lastArr.push(
              orderDetails.data[ 0 ]?.userdetails[ i ].users[ 0 ]?.lastname
            );
            setUsersName(arr);
            setUsersLastName(lastArr);
          }

          let teamMembers = [];
          if (orderDetails.data[ 0 ]) {
            teamMembers = orderDetails.data[ 0 ].userdetails.map(x => x.userid);
          }
          setSelectedMemeberId(teamMembers)
        } else {
          // setAlert(true);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ orderDetails, orderdetailsData ]);




  const handleOnSearch2 = () => {

    dispatch(searchContact(string2, clientId));

  };
  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };

  return (
    <div>
      {StatusPopup && (
        <MoveCard
          handleClose={StatusPopupClose}
          statusId={statusId}
          setStatusId={setStatusId}
          setStatusPopup={setStatusPopup}
          handleStatusChanged={handleStatusChanged}
        />
      )}
      {clientPopup && (
        <ClientCard
          handleClose={ClientPopupClose}
          handleClientName={handleClientName}
          setInstallationName={setInstallationName}
          installation={installationName}
        />
      )}

      <Modal className={StatusPopup || clientPopup ? "modalcontainer overflow-hidden" : "modalcontainer"} animation={true} show={show} onHide={handleClose} centered>
        <div className="container centeralign headerbg">
          {/* <span className="closeicon" onClick={props.handleClose} >&times;</span> */}
        </div>
        <div
          className={
            concreteCheck
              ? "container centeralign headerbg2"
              : "container centeralign headerbg1"
          }
          style={{
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <div className="row px-2 py-1">
            <div className="col text-start ps-4">
              {/* <i className="bi bi-grid-3x2-gap-fill fs-1"></i> */}
              <img
                src={imgurl}
                alt="img"
                width={80}
                height={50}
                className="pt-2"
              />
              <h6 className="productnameHeader_pop">
                {productname?.length > 20 ? productname.slice(0, 20) + "..." : productname}

                {/* {productname} */}
              </h6>
              {/* <p className="carddata1 themecolor fw-bold fs-5">2x3m</p> */}
            </div>
            <div className={roleName !== "User" ? "col text-end pt-2 pe-5 mt-1" : "field-disable col text-end pt-2 pe-5 mt-1"}>

              {/* <i className="bi bi-calendar2-week fs-1"></i>
                            <p className="carddata themecolor fw-bold fs-5">{dueDate}</p> */}
              <DatePicker
                //   selected={this.state.startDate}
                onChange={handleChangeDate}
                customInput={<CustomInput />}
                placeholderText="due date"
                popperPlacement="bottom-end"
                minDate={new Date()}
                selected={dueDate}
              />
              <h5 className="py-0 my-0 headclr fw-bold">
                {loading == false ? <>
                  {moment(dueDate).format("DD.MMM.YYYY")}
                </>
                  : ""}
              </h5>
            </div>
          </div>
        </div>
        {special ? (
          <img src={speciaIcon} className="position-absolute specImg_pop" />
        ) : (
          ""
        )}

        {loading == true ? (
          <div className="pt-5" style={{ minHeight: "200px" }}>
            <Loader />
          </div>
        ) : (
          <ModalBody className="pb-0 px-4">

            <div className="row mt-3">
              <div className="col-6">
                <div className="row">
                  <div className="col-2 text-start align-self-center ps-3">
                    <img src={calender} className="orderIconPop3 mt-1" />
                  </div>

                  <div className="col-10 align-self-center">
                    <div className="row">
                      <div className="col-6 ps-0 align-self-center">
                        <h4 className="orderPopupHeadText headclr ps-2">
                          Creation Date
                        </h4>
                      </div>
                      <div className="col-6 text-end pe-5">
                        <h4 className="orderPopupHeadText datetext">
                          {creationDate.toString().split(" ")[ 2 ]}.
                          {creationDate.toString().split(" ")[ 1 ]}.
                          {creationDate.toString().split(" ")[ 3 ]}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 align-self-center"></div>
              </div>
              <div className="col-6">

                <div className="row">
                  <div className="col-2 text-start align-self-center pe-0">
                    <img src={Group1087} className="orderIconPop mt-1" />
                  </div>
                  <div className="col-10 align-self-center">
                    <div className="row">
                      <div className="col-5 ps-0 align-self-center">
                        <h4 className="orderPopupHeadText headclr">Quote ID</h4>
                      </div>
                      <div className="col-6 text-end">
                        <input
                          type="text"
                          // className="quoteId backgrnd orderPopupHeadText ps-2 headclr"
                          className={roleName !== "User" ? "quoteId backgrnd orderPopupHeadText ps-2 headclr" : "field-disable quoteId backgrnd orderPopupHeadText headclr"}
                          value={quoteId}
                          onChange={(e) => setQuoteId(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 align-self-center"></div>
              </div>

            </div>

            <div className="select-dropdown-custom mt-3">
              <div className="col-4 editTask">
                <img
                  src={clienticon}
                  className="orderIconPop2 mt-2 "
                /> <span className="clienthead">Client</span>
              </div>
              <div className="col-8 drop-custom border">
                <select
                  className={roleName !== "User" ? "form-select py-3  selectname" : "field-disable form-select py-3  selectname"}

                  aria-label="Default select example"
                  value={clientId}
                  // onClick={fatchclient}
                  onChange={(e) => {
                    setClientId(e.target.value);
                  }}
                >
                  {clientList.map((val) => (
                    <option value={val.clientid}>{val.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-3 ps-5">
              <div className="col-3 ps-0 pe-3">
                {" "}
                <h6 className="orderLableclr my-0 ps-3 contname">Contact Name</h6>
              </div>
              <div className="col-8 ">
                {edit ? (
                  // <div className="searchinput1 ms-2">
                  //   <ReactSearchAutocomplete
                  //     items={contactList}
                  //     onSearch={handleOnSearch}
                  //     onSelect={handleOnSelect}
                  //   />
                  // </div>
                  <div
                    className="ps-2"
                    style={{ position: "relative", width: "104.5%" }}
                    onClick={arrowupDownPart}>
                    <Select
                      ref={selectRef}
                      classNamePrefix="sk-custom-select"
                      className={
                        partarrow == "" ? "asc-custom-container-contact-name" : "ArrowUpDownpur asc-custom-container-contact-name"
                      }
                      defaultValue={contactSelect}
                      options={contactList}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.contactid}
                      onChange={(e) => {
                        setSearchedItem(e.contactid);
                        setShowOptions(true);
                        handleOnSelect(e);
                      }}
                      onFocus={(string, calid) => {
                        console.log("click");
                        handleOnSearch2();
                        // handleOnSearch();
                        setShowOptions(true);
                      }}
                    />
                  </div>
                ) : (
                  //  <input type="name" className="form-control my-2" id="exampleInputEmail1" value={name} onChange={(e) => { setName(e.target.value) }} />
                  <h5 className="orderInputclr ps-3">{name}</h5>
                )}
              </div>
              <div className="col-1 text-end pe-3">
                <i
                  className={roleName !== "User" ? "bi bi-pen-fill" : "bi bi-pen-fill field-disable"}
                  style={{ cursor: "pointer" }}
                  role="button"
                  onClick={() => setEdit(!edit)}
                ></i>
              </div>
            </div>

            <div className="row py-3 mt-2">
              <div className="col-4"></div>
              <div className="col-7 p-0">
                <div className="d-flex gap-3">
                  <div className="col-6 ps-2 backgrnd">
                    <p className="orderLableclr my-0">Telephone</p>
                    {edit ? (
                      <input
                        type="tel"
                        className="form-control my-2"
                        id="exampleInputEmail1"
                        value={telephone}
                        onChange={(e) => {
                          setTelephone(e.target.value);
                        }}
                        placeholder="Phone number"
                      />
                    ) : (
                      <h5 className="orderInputclr pt-1 telphone">{telephone}</h5>
                    )}
                  </div>
                  <div className="col-7 ps-2 backgrnd">
                    <p className="orderLableclr my-0">E-mail</p>
                    {edit ? (
                      <input
                        type="email"
                        className="form-control my-2"
                        id="exampleInputEmail1"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    ) : (
                      <h6 className="orderInputclr pt-1 email">{email}</h6>
                    )}
                  </div>
                </div>
              </div>
              {edit ? (
                <div className="row pe-0 pt-2">
                  <div className="col-6"></div>
                  <div className="col-6 px-0 text-end pe-0">
                    <button
                      onClick={() => setEdit(!edit)}
                      type="button"
                      className="moveBtn text-light px-3 py-1"
                    >
                      Change
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row  pb-1 pt-2">
              <div className="col-1 text-start align-self-center pe-0">
                <img src={producticon} className="orderIconPop mt-2" />
              </div>

              <div className="col-11 align-self-center">
                <div className="row pt-2 ">
                  <div className="col-3 ps-0">
                    <h4 className="orderPopupHeadText headclr ps-3">Product</h4>
                  </div>
                  <div className="col-6 ps-3 text-start align-self-center pe-0 contblck headclr">
                    <h6>Concrete Block</h6>
                  </div>
                  <div className="col-3 col justify-content-end d-flex text-end">
                    <div className="form-check form-switch">
                      <input
                        className={roleName !== "User" ? "form-check-input ordcheck" : "form-check-input ordcheck field-disable"}
                        type="checkbox"
                        checked={concreteCheck}
                        onChange={() => setConcreteCheck(!concreteCheck)}
                        role="switch"
                        id="flexSwitchCheckChecked"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>

            <div className="row  py-1 pb-2 ">
              <div className="col-12 align-self-center">

                <div className="row">
                  <div
                    // className="col-2 ps-1 pe-0"
                    className={
                      concreteCheck == true
                        ? "backOrder2 position-relative col-2 ms-5 ps-"
                        : "backOrder1 position-relative col-2 ms-5 ps-"
                    }
                  >
                    <div className="my-2">
                      <img
                        src={imgurl}
                        alt="img"
                        width={80}
                        height={50}
                        className=""
                      />
                      <h6 className="productname_pop ps-2 pt-1">{productname}</h6>
                    </div>
                  </div>

                  <div className="col-9 ps-4">

                    <div className="row" style={{ color: "#60AAC6" }}>

                      <div className="col-5 ps-5 pe-0">
                        <p>Part</p>
                      </div>
                      <div className="col-4 pe-4 text-center">
                        <p>Qty</p>
                      </div>
                      <div className="col-3 pe-2 text-end">
                        <p>chk</p>
                      </div>
                    </div>

                    {partdetails?.map((item, index) => {
                      return (
                        <div key={index} className="row">

                          <div className={item[ 0 ]?.isInsufficient ? "col-5 ps-5 pe-0 text-danger" : "col-5 ps-5 pe-0"}>
                            <div>{item[ 0 ]?.partname}</div>
                          </div>
                          <div className={item[ 0 ]?.isInsufficient ? "col-4 pe-4 text-center text-danger" : "col-4 pe-4 text-center"}>
                            {/* <div className="input-group"> */}
                            <p>{stockQuntityFormat(item[ 0 ]?.orderquantity)}</p>
                            {/* </div> */}
                          </div>

                          <div className="col-3 pe-2 text-end">
                            {/* <input
                              type="radio"
                            onChange={() => handleChange(index, "check", checkbox)}
                            /> */}
                            {/* <input type="radio" checked={checkradio === ""}
                              onClick={() => { setCheckradio(true); }}
                              onChange={(e) => {
                                if (e.target.checked === true) { setCheckradio("") }
                                else { setCheckradio(e.target.value); };
                              }} /> */}
                            <div className="custom-radio-check-sk">
                              <input type={"checkbox"} />
                            </div>
                          </div>

                          {/* <div className="col-2 align-self-center"></div> */}
                        </div>
                      );
                    })}

                    {/* <div className="col-9 ps-0 pe-0">
                        {productnames == null || productnames == undefined
                          ? productName
                          : productnames.map((item) => <p>{item}</p>)}
                      </div>
                      <div className="col-2 ps-0">
                        {currentQty == null || currentQty == undefined
                          ? "22"
                          : currentQty.map((item) => <p>{item}</p>)}
                      </div>
                      <div className="col-1">
                        {currentQty == null || currentQty == undefined
                          ? ""
                          : currentQty.map((item) => (
                            <p>
                              {" "}
                              <input className="form-check-input" type="radio" />
                            </p>
                          ))}
                      </div> */}

                  </div>
                </div>

                <div className="row ps-3 pt-2 ">
                  <div
                    className="row ps-2 pt-4 pb-3"
                    style={{ color: "#60AAC6" }}
                  >
                    <div className="col-10 ps-0 pe-0">
                      <p className="orderPopupHeadText ps-5">Special Part ?</p>
                    </div>

                    <div className="col-1 ps-3">
                      <div className=
                        {roleName !== "User" ? "form-check form-switch ms-4" : "form-check form-switch ms-4 field-disable"}
                      >
                        <input
                          className="form-check-input ordcheck"
                          checked={special}
                          onChange={() => isSpecial(!special)}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="row  py-1">
              <div className="col-1 text-start align-self-center pe-0">
                <img src={stockIcon} className="orderIconPop mt-2" />
              </div>
              <div className="col-11 align-self-center">
                <div className="row pt-2 ">
                  <div className="col ps-0 align-self-center">
                    <h4 className="ps-3 orderPopupHeadText headclr">
                      Stock Status
                    </h4>
                  </div>
                  <div className="col col justify-content-end d-flex text-end">
                    <div className="form-check form-switch">
                      <input
                        // className="form-check-input ordcheck"
                        className={roleName !== "User" ? "form-check-input ordcheck" : "form-check-input ordcheck field-disable"}
                        checked={stockStatus}
                        onChange={() => setStockStatus(!stockStatus)}
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="row  py-1">
              <div className="col-1 text-start align-self-center pe-0">
                <img src={warehouseIcon} className="orderIconPop mt-2" />
              </div>
              <div className="col-11 align-self-center">
                <div className="row pt-2 ">
                  <div className="col ps-0 align-self-center">
                    <h4 className="ps-3 orderPopupHeadText headclr">Warehouse</h4>
                  </div>
                  <div className="col text-end">
                    {warehouse == undefined || warehouse == null
                      ? ""
                      : warehouse[ 0 ]}
                    <p>Hanger A-20</p>
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="row py-1">
              <div className="col-1 text-start  pe-0">
                <img src={documentIcon} className="orderIconPop mt-2" />
              </div>
              <div className="col-11">
                <div className="row pt-2 ">
                  <div className="col ps-0 ">
                    <h4 className="ps-3 orderPopupHeadText headclr">Documents</h4>
                  </div>
                  <div className=
                    {roleName !== "User" ? "col text-end" : "col text-end field-disable"}>
                    {/* {warehouse == undefined || warehouse == null ? '' : warehouse[0]} */}
                    {filename != undefined || filename != null ? (
                      <p
                        onClick={onButtonClick}
                        role="button"
                        className="my-0"
                      >
                        {filename}
                      </p>
                    ) : (
                      <p
                        onClick={onButtonClick}
                        role="button"
                        className="my-0"
                      >
                        Add+
                      </p>
                    )}
                    {/* {filedetails != undefined || warehouse != null ? filedetails.map((item) => <p onClick={onButtonClick} role="button" className='my-0'>{item.filename}</p>) : ""} */}
                  </div>
                  <input
                    type="file"
                    ref={inputref}
                    className="d-none"
                    onChange={(e) => {
                      setFileName(e.target.files[ 0 ].name);
                      handleFileChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="row  py-1">
              <div className="col-1 text-start align-self-center pe-0">
                <img src={deliveryIcon} className="orderIconPop mt-2" />
              </div>
              <div className="col-11 align-self-center">
                <div className="row pt-2 ">
                  <div className="col ps-0 align-self-center">
                    <h4 className="ps-3 orderPopupHeadText headclr">
                      Delivery Location
                    </h4>
                  </div>
                  <div className="col text-end">
                    <i
                      className={roleName !== "User" ? " bi bi-pen-fill" : "bi bi-pen-fill field-disable"}
                      style={{ cursor: "pointer" }}
                      role="button"
                      onClick={() => setEditLocation(!editLocation)}
                    ></i>
                    {editLocation ? (
                      <Autocomplete
                        apiKey={"AIzaSyBcYRPNB_ePlSHVxC1p5buVrxdL_Uou_bc"}
                        // apiKey={"AIzaSyBkqTYv5DS7unCFoCyRa9K8Uiqy8aJQu_A"}
                        //   onClick={()=>{setEditLocation(!editLocation)}}
                        className="signinputSmall location"
                        style={{ width: "100%" }}
                        // className="location"
                        onPlaceSelected={(place) => {
                          handleAddress(place.formatted_address);
                        }}
                      />
                    ) : (
                      <p>{delLocation}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="row py-1">
              <div className="col-1 text-start align-self-end pe-0">
                <img src={installationIcon} className="orderIconPop mt-2" />
              </div>
              <div className="col-11 align-self-center">
                <div className="row pt-2 ">
                  <div className="col ps-0 align-self-end">
                    <h4 className="ps-3 orderPopupHeadText headclr">
                      Installation
                    </h4>
                  </div>
                  <div className="col text-end">
                    <div className="row">
                      <div className="col-12">
                        {/* {installationName == undefined || installationName == null ? '' : installationName} */}
                      </div>
                      <div className={roleName !== "User" ? "col-12" : "col-12 field-disable"}>
                        <button
                          type="button"
                          onClick={() => setClientPopup(true)}
                          className="btn btn-primary px-4 installBtn"
                        >
                          <span></span>{" "}
                          {installationName == undefined ||
                            installationName == ""
                            ? "Choose"
                            : installationName}
                          <span>


                            <i
                              className="bi bi-arrow-right ps-3"
                              style={{ marginTop: "20px" }}
                            ></i>
                          </span>
                        </button>
                      </div>
                    </div>
                    {/* <div className='col text-end '>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="row  py-1">
              <div className="col-1 text-start align-self-center pe-0">
                <img src={teamIcon} className="orderIconPop mt-3" />
              </div>
              <div
                className="col-11 align-self-center"
              // onClick={() => setShowTeanDrp(true)}
              >
                <div className="row pt-2 ">
                  <div className="col ps-0 align-self-center">
                    <h4 className="ps-3 orderPopupHeadText headclr">Team</h4>
                  </div>
                  <div className={roleName !== "User" ? "col text-end" : "col text-end field-disable"}>
                    <TeamMemberSelection
                      teamid={teamid}
                      defaultSelected={selectedMemberId}
                      onUpdate={(members) => updateSelectedMember(members)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="row  py-1">
              <div className="col-1 text-start align-self-center pe-0">
                <img src={productsIcon} className="orderIconPop mt-2" />
              </div>
              <div className="col-11 align-self-center">
                <div className="row pt-2 ">
                  <div className="col ps-0 align-self-center">
                    <h4 className="ps-3 orderPopupHeadText headclr">Production</h4>
                  </div>
                  <div className="col text-end">
                    <button
                      type="button"
                      onClick={() => setStatusPopup(true)}
                      className="btn btn-primary px-4 moveBtn"
                    >
                      <span></span>
                      {statusName}
                      <span>
                        <i
                          className="bi bi-arrow-right ps-3"
                          style={{ marginTop: "20px" }}
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-center"></div>
            </div>
            <div className="comment-section1 row justify-content-center align-items-center flex-column gap-1">
              {fetchCommitData.length <= 5 && (
                <div className="row commit-title1 mt-3">
                  <div className="col-2"></div>
                  <div
                    className="col-10 text-start"
                    style={{
                      position: "relative",
                      // right: "120px",
                      // right: "158px",
                      marginBottom: "10px"
                    }}
                  >
                    <h2 className="mt-3 mb-0">Comments</h2>
                  </div>
                </div>
              )}
              {fetchCommitData.length > 5 ? (
                <div className="commit w-100">
                  <div className="row commit-title1 mt-3 mb-2">
                    <div className="col-2"></div>
                    <div className="col-5">
                      <h2 className="mt-3 mb-0">Comments</h2>
                    </div>

                    <div className="col-5 text-center">
                      {" "}
                      <h2
                        onClick={() => {
                          setEnableComm(!enablecomm);
                          setShowcmnt(!showcmnt);
                        }}
                        className="mt-3 mb-0"
                        style={{ cursor: "pointer" }}
                      >
                        {/* {showMore
                            ? "Show more details..."
                            : "Show less details..."} */}
                        {enablecomm
                          ? "Show less details..."
                          : "Show details..."}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-center align-items-center commentinput gap-5 p-0">
                <div className="d-flex justify-content-center align-items-center btn btn-secondary profileButton1 dropdown-toggle1">
                  {clientname == undefined || clientname == null
                    ? "SD"
                    : firstName[ 0 ].toUpperCase() +
                    "" +
                    lastName[ 0 ].toUpperCase()}
                </div>
                <form className="d-flex justify-content-center flex-column align-items-center">
                  {/* <input
                      type={"text"}
                      value={commitData}
                      onChange={(e) => setCommitData(e.target.value)}
                      placeholder="write a comment…"
                      className="commentinput"
                    />
                    <input
                      type="file"
                      id="add-attachment"
                   
                      style={{ display: "none" }}
                    />
                    <label htmlFor="add-attachment" className="mt-3 ms-2">
                      <i className="bi bi-emoji-smile"></i>
                      <i className="bi bi-paperclip "></i>
                    </label> */}
                  {false && <div className="attachments-container">
                    <div className="badge badge-secondary">profile.jpg</div>
                    <div className="badge badge-secondary">profile.jpg</div>
                    <div className="badge badge-secondary">profile.jpg</div>
                    <div className="badge badge-secondary">profile.jpg</div>
                    <div className="badge badge-secondary">profile.jpg</div>
                    <div className="badge badge-secondary">profile.jpg</div>
                    <div className="badge badge-secondary">profile.jpg</div>
                  </div>}
                  <div className="d-flex justify-content-center align-items-center gap-4 w-100">
                    <input
                      type="text"
                      className="inputOrdercmnt me-2"
                      placeholder=" write a comment…"
                      onChange={onChange}
                      value={val}
                    // onChange={(e) =>
                    //   setMessage(e.target.value)
                    // }

                    // onKeyPress={(e) => {
                    //   if (e.key === "Enter" && e.shiftKey) {
                    //   } else if (e.key === "Enter") {
                    //     // message();
                    //     // handlerSendCommit();
                    //   } else {
                    //     return;
                    //   }
                    // }}
                    />

                    <div role={"button"} className="sendbtn position-relative">
                      <p>
                        <span className="ps-1 attachment">
                          <input
                            type="file"
                            id="add-attachment"
                            // onChange={(e) => {
                            //   handleFileattech(e);
                            // }}

                            onChange={(e) => {
                              handleFileChange1(e);
                            }}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="add-attachment">
                            <i
                              className="bi bi-paperclip "
                              style={{
                                cursor: "pointer",
                                position: "relative",
                                left: "57px",
                                top: "43px",
                              }}
                            ></i>
                          </label>
                          <i
                            className="bi bi-emoji-smile"
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            style={{
                              position: "relative",
                              left: "40px",
                              cursor: "pointer",
                              top: "20px",
                            }}
                          ></i>
                          {showEmojiPicker && (
                            <div className="emoji-picker-style1">
                              <EmojiPicker
                                emojiStyle="twitter"
                                onEmojiClick={(e) => {
                                  setVal((val) => val + e.emoji);
                                }}
                              />
                            </div>
                          )}
                        </span>
                      </p>
                    </div>
                    <img
                      src={leftarrow}
                      className="me-3 mt-4"
                      width="22px"
                      alt="leftarrow"
                      style={{ cursor: "pointer" }}
                      onClick={() => handlerSendCommit()}
                    />
                  </div>

                </form>

              </div>

              <div className="d-flex justify-content-start align-items-center commentinput gap-1">
                {attachedFileName.length ?
                  <span
                    role="button"
                    style={{
                      color: "rgb(0, 55, 102)",
                      fontWeight: "500",
                      marginLeft: "100px",
                      // marginTop: "-12px"
                    }}
                  >
                    {attachedFileName.length > 20 ? attachedFileName.slice(0, 20) + "..." : attachedFileName}
                  </span>
                  : ""}

              </div>


              {showcmnt
                ? fetchMaxCommitData.map((comment, index) => (
                  <div className="d-flex justify-content-center align-items-center w-100 gap-5 p-0 mb-2" key={`order-${orderId}-comment-${index}`}>
                    <div className="d-flex justify-content-center align-items-center btn btn-secondary profileButton1 custom-ci-size1 dropdown-toggle1">
                      {comment.lastname ?
                        `${comment.firstname.charAt(0).toUpperCase()}${comment.lastname.charAt(0).toUpperCase()}`
                        :
                        `${comment.firstname.charAt(0).toUpperCase()}${comment.firstname.charAt(1).toUpperCase()}`
                      }
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-2" style={{ width: "80%" }}>
                      {comment.isDeleted ?
                        <div className="commit-description commit-description1" style={{ color: "#A5A5A5" }}>
                          <div>This message was deleted</div>
                        </div>
                        :
                        <div className="commit-description commit-description1">
                          <div>{comment?.message}</div>
                          {comment?.attachment && <div>
                            <a target={"_blank"} href={comment?.attachment} style={{ color: "#003766" }} >{comment?.fileName}</a>
                          </div>}
                          <div className="d-flex align-items-end justify-content-end">
                            <div className="timeh5">
                              {moment(comment?.timestamp).format("DD.MM.YYYY HH:mm")}H
                            </div>
                            <UserDeleteAction comment={comment} />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                ))
                : ""}



              {enablecomm
                ? fetchCommitData.map((comment, index) => (
                  <div className="d-flex justify-content-center align-items-center w-100 gap-5 p-0 mb-2" key={`order-${orderId}-comment-${index}`}>
                    <div className="d-flex justify-content-center align-items-center btn btn-secondary profileButton1 custom-ci-size1 dropdown-toggle1">
                      {comment.lastname ?
                        `${comment.firstname.charAt(0).toUpperCase()}${comment.lastname.charAt(0).toUpperCase()}`
                        :
                        `${comment.firstname.charAt(0).toUpperCase()}${comment.firstname.charAt(1).toUpperCase()}`
                      }
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-2" style={{ width: "80%" }}>
                      {comment.isDeleted ?
                        <div className="commit-description commit-description1" style={{ color: "#A5A5A5" }}>
                          <div>This message was deleted</div>
                        </div>
                        :
                        <div className="commit-description commit-description1">
                          <div>{comment?.message}</div>
                          {comment?.attachment && <div>
                            <a target={"_blank"} href={comment?.attachment} style={{ color: "#003766" }} >{comment?.fileName}</a>
                          </div>}
                          <div className="d-flex align-items-end justify-content-end">
                            <div className="timeh5">
                              {moment(comment?.timestamp).format("DD.MM.YYYY HH:mm")}H
                            </div>
                            <UserDeleteAction comment={comment} />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                ))
                : ""}

            </div>
            <div className="row mt-4 px-0">
              <div className="px-0 footerline"></div>
              <div className="row justify-content-center ps-3 ms-1">
                <div className="col-6 pe-0 text-center">
                  <button
                    className="ordercancelbtn"
                    style={{ fontWeight: "500" }}
                    type="button"
                    onClick={() => props.handleClose()}
                  >
                    CANCEL
                  </button>
                </div>
                {/* <div className=   {roleName !== "User" ? "col-6 ps-0 text-center" : "field-disable col-6 ps-0 text-center"}> */}
                <div className="col-6 ps-0 text-center">
                  <button
                    onClick={handelupdateaccrole}
                    className="orderaddbtn fw-bold"
                    type="button"
                  >
                    CHANGE
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

export default Orderpopup;


const UserDeleteAction = ({ comment }) => {
  const dispatch = useDispatch();
  const currentUserId = window.sessionStorage.getItem("userid");
  const isVisible = useMemo(() => {
    return currentUserId == comment.userid;
  }, [ comment ]);
  const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false);

  const handleDeleteConfirmation = (confirmation) => {
    if (confirmation) {
      dispatch(deleteComment(comment.commentid));
    }
    setShowConfirmationPopup(false)
  }

  if (!isVisible) return null;
  return (
    <div style={{ marginLeft: "17.56px", cursor: "pointer" }}>
      {showConfirmationPopup &&
        <DeleteOrderPopup
          open={showConfirmationPopup}
          onClose={(confirmation) => handleDeleteConfirmation(confirmation)}
          backdropClassName="modal-over-modal-backdrop"
        />
      }
      <BinIcon onClick={() => setShowConfirmationPopup(true)} />
    </div>
  )
}