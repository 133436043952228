import React, { useRef, useState } from "react";
import googleIcon from "../../assets/icons/google_icon.png";
import facebookIcon from "../../assets/icons/facebook-icon.png";
import appleIcon from "../../assets/icons/apple-icon1.png";
import linkedinIcon from "../../assets/icons/linkedin-icon3.png";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

import { useDispatch, useSelector } from "react-redux";
import {
  userSignup,
  googleSignup,
  fetchUserDetails,
  fetchUserTeam,
} from "../../redux/actions/action";
import { useHistory } from "react-router-dom";
import "../../styles/local/signup.css";
import Alert from "../../components/dialogs/SuccessfullAlert";

const SignUp = (props) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [equalPassword, setEqualPassword] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [terms, setTerms] = useState(true);
  const [alert, setAlert] = useState(false);
  const [phone, setPhone] = useState("");
  const [slctRole, setSlctRole] = useState("0");
  const [slctTeam, setSlctTeam] = useState("0");
  const [teamdata, setTeamdata] = useState([]);
  const [teamArr, setTeamArr] = useState([]);
  const [teamidArr, setTeamIdArr] = useState([]);
  const [teamid, setTeamId] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchTeamData = useSelector((state) => state.user.fetchTeamDetails);

  const [msg, setMsg] = useState("");
  const history = useHistory();
  const optValue = useRef(true);
  const va = optValue.current;

  const signupData = useSelector((state) => state.user.signupData);
  const googleSignupData = useSelector((state) => state.user.googleSignupData);
  const dispatch = useDispatch();
  const teamId = window.sessionStorage.getItem("teamID");
  const companyId = window.sessionStorage.getItem("compId");

  const initialRender = React.useRef(true);
  React.useEffect(() => {
    if (!initialRender.current) {
      if (signupData != null) {
        if (signupData.status == true) {
          //props are coming from landigPage.js
          // props.clickLogin();
          // props.handleClose();
          setLoading(false);
          props.handleAdded();
        } else {
          // alert(signupData.message);
          setMsg(signupData.message);
          setAlert(true);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [signupData]);

  React.useEffect(() => {
    if (slctRole === "0" || slctTeam === "0") {
      setTerms(false);
    } else {
      if (slctTeam === "spire") {
        setTeamId("08da2f31-46b4-4ce8-80b3-fdffac7a2011");
      } else if (slctTeam === "rbce") {
        setTeamId("08da2f31-46b4-4ce8-80b3-fdffac7a2010");
      } else {
        setTeamId("0");
      }
      setTerms(true);
    }
  }, [slctRole, slctTeam]);
  React.useEffect(() => {
    dispatch(fetchUserDetails(1));
  }, [signupData]);

  React.useEffect(() => {
    dispatch(fetchUserTeam());
  }, [fetchUserTeam]);

  const initialRender1 = React.useRef(true);
  React.useEffect(() => {
    if (!initialRender1.current) {
      if (googleSignupData != null) {
        window.open(googleSignupData.data);
      }
    } else {
      initialRender1.current = false;
    }
  }, [googleSignupData]);
  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchTeamData != null) {
        if (fetchTeamData.status == true) {
          setTeamdata(fetchTeamData.data);
          let arr = [];
          let arr1 = [];
          for (var i = 0; i < fetchTeamData.data.length; i++) {
            arr.push(fetchTeamData.data[i].teamname);
            arr1.push(fetchTeamData.data[i].teamid);
          }
          setTeamArr(arr);
          setTeamIdArr(arr1);
        } else {
          // alert(fetchTeamData.message);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchTeamData]);

  const handleSubmit = (e) => {
    setLoading(true);
    if (
      email == "" ||
      password == "" ||
      fname == "" ||
      lname == "" ||
      repeatPassword == ""
    ) {
      e.preventDefault();

      setError(true);
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      e.preventDefault();
      setEmailValid(true);
    } else if (password != repeatPassword) {
      e.preventDefault();
      setEmailValid(false);
      setEqualPassword(true);
    } else {
      // user signup data api post call
      dispatch(
        userSignup(
          fname,
          lname,
          email,
          password,
          phone,
          teamid,
          slctRole,
          companyId
        )
      );

      e.preventDefault();
      // alert("signup successfull");
      // props.clickLogin();
    }
  };

  const togglePasssword = () => {
    setShowPassword(!showPassword);
  };
  const togglePasssword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleAlert = () => {
    setAlert(false);
  };
  const handlePhone = (eve) => {
    setPhone(eve.target.value);
  };

  return (
    <div>
      <Modal animation={false} show={true} centered>
        {alert == true ? (
          <div className="row justify-content-center">
            <div
              className="alert alert-danger col mt-3 alert-dismissible fade show alertmsg"
              role="alert"
            >
              {msg}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={handleAlert}
              ></button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="container centeralign headerbg">
          {/* props are coming from landingpage.js */}
          <span className="closeicon" onClick={props.handleClose}>
            &times;
          </span>
        </div>

        <div className="container centeralign headerbg pt-3">
          <h1 className="heading">Add User</h1>
          {/* <h1 className="subheading">You`re on your way to connecting with great talent!</h1> */}
        </div>

        <ModalBody className="my-0 py-3">
          <div className="container">
            <form>
              <div className="container">
                {/* <h1 className="heading">Get in-demant resources<br /> to  <b>innovate your world</b>
                </h1>
                <h1 className="subheading">You`re on your way to connecting with great talent!</h1> */}
                {/* <div className="row justify-content-center">
                  <img className="socialmediaicon" src={googleIcon} alt="Google" onClick={() => {
                    dispatch(googleSignup());
                  }} />
                  <img className="socialmediaicon" src={facebookIcon} alt="Facebook" />
                  <img className="socialmediaicon" src={appleIcon} alt="Apple" onClick={() => { */}
                {/* props are coming from landingpage.js */}
                {/* props.appleWelcome();
                    props.handleClose();
                  }} />
                  <img className="socialmediaicon" src={linkedinIcon} alt="Linkedin" />
                </div> */}
              </div>
              {/* <div >
                <h6 className="line2 "><span className="linetext">or</span></h6>
              </div> */}

              <div className="container ">
                <div className="row inputsection">
                  <div className="col">
                    {fname == "" && error == true && (
                      <div className="errormsg">Please fill in this field</div>
                    )}
                    <input
                      type="text"
                      id="userinput"
                      className={
                        fname == "" && error == true
                          ? "form-control inputtext showerror"
                          : "form-control inputtext"
                      }
                      value={fname}
                      onChange={(e) => {
                        setFname(e.target.value);
                      }}
                      placeholder="First name"
                    />
                  </div>
                  <div className="col">
                    {lname == "" && error == true && (
                      <div className="errormsg">Please fill in this field</div>
                    )}
                    <input
                      type="text"
                      id="userinput"
                      className={
                        lname == "" && error == true
                          ? "form-control inputtext showerror"
                          : "form-control inputtext"
                      }
                      value={lname}
                      onChange={(e) => {
                        setLname(e.target.value);
                      }}
                      placeholder="Last name"
                    />
                  </div>
                </div>

                <div className="col-sm-12 inputsection">
                  {email == "" && error == true && (
                    <div className="errormsg">Please fill in this field</div>
                  )}
                  {emailValid == true && (
                    <div className="errormsg">This email is invalid</div>
                  )}
                  <input
                    type="email"
                    id="userinput"
                    className={
                      email == "" && error == true
                        ? "form-control inputtext showerror"
                        : "form-control inputtext"
                    }
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email"
                  />
                </div>
                <div className="col-sm-12 input-group inputsection">
                  {password == "" && error == true && (
                    <div className="errormsg">Please fill in this field</div>
                  )}
                  <input
                    id="userinput"
                    type={showPassword ? "text" : "password"}
                    className={
                      password == "" && error == true
                        ? "form-control inputtext showerror"
                        : "form-control inputtext"
                    }
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Create Password"
                  />
                  <span style={{ marginLeft: "92%" }} className="passwordicon1">
                    <i
                      className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"}
                      id="togglePassword"
                      onClick={togglePasssword}
                    ></i>
                  </span>
                </div>

                <div className="col-sm-12 input-group inputsection">
                  {repeatPassword == "" && error == true && (
                    <div className="errormsg">Please fill in this field</div>
                  )}
                  {equalPassword == true && (
                    <div className="errormsg">Passwords do not match</div>
                  )}
                  <input
                    id="userinput"
                    type={showPassword1 ? "text" : "password"}
                    className={
                      repeatPassword == "" && error == true
                        ? "form-control inputtext showerror"
                        : "form-control inputtext"
                    }
                    value={repeatPassword}
                    onChange={(e) => {
                      setRepeatPassword(e.target.value);
                    }}
                    placeholder="Repeat Password"
                  />
                  <span style={{ marginLeft: "92%" }} className="passwordicon1">
                    <i
                      className={showPassword1 ? "bi bi-eye-slash" : "bi bi-eye"}
                      id="togglePassword"
                      onClick={togglePasssword1}
                    ></i>
                  </span>
                </div>
                {/* <div className="col-sm-12 form-check checksection" >
                  <input type="checkbox" className="form-check-input checkbox1" id="exampleCheck1" value={terms} onChange={(e) => { setTerms(e.target.checked) }} />
                  <h5 className="subheading">Yes, I understand and agree to the <span className="textgreen">Terms of Service</span>, including the  <span className="textgreen">User Agreement</span> and  <span className="textgreen">Privacy Policy.</span></h5>
                </div> */}
                <input
                  type="tel"
                  id="userinput"
                  value={phone}
                  className="form-control inputtext mt-3"
                  onChange={(eve) => {
                    handlePhone(eve);
                  }}
                  placeholder="Phone"
                />

                <select
                  onChange={(e) => {
                    setSlctTeam(e.target.value);
                  }}
                  className="form-select selectTeam mt-3"
                  aria-label="Default select example"
                >
                  <option value="0" selected>
                    Select Team
                  </option>
                  {teamArr.map((item) => (
                    <option value={item}>{item.toUpperCase()}</option>
                  ))}
                </select>
                <select
                  onChange={(e) => {
                    setSlctRole(e.target.value);
                  }}
                  className="form-select selectTeam mt-3"
                  aria-label="Default select example"
                >
                  <option value="0" selected>
                    Select Role
                  </option>
                  <option value="08da2f30-beca-4c07-8e42-1311289b9e4a">
                    Admin
                  </option>
                  <option value="08da2f30-beca-4c07-8e42-1311289b9e4b">
                    User
                  </option>
                </select>
                <div className="row btncontainer">
                  <div>
                    <button
                      type="button"
                      className={terms ? "createbutton" : "createbuttonfalse"}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={!terms || loading}
                    >
                      Create Account{" "}
                      {loading == true && (
                        <div
                          className="spinner-border spinner-border-sm ms-1"
                          role="status"
                        ></div>
                      )}
                    </button>
                  </div>
                  {/* <div className="btnmargin"> */}
                  {/* props are coming from landingpage.js */}
                  {/* <button className="btn loginbutton" onClick={props.clickLogin} >Log in</button>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SignUp;
