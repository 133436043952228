import { memo } from "react"

export const DeleteIcon = memo(({ fill }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.257" height="24.158" viewBox="0 0 19.257 24.158">
            <g id="Group_62" data-name="Group 62" transform="translate(-196 -86.804)">
                <path id="Path_148" data-name="Path 148" d="M228.207,143.64l-.649,17.509a.59.59,0,0,1-.577.555h-11.2a.591.591,0,0,1-.577-.555l-.649-17.509a.875.875,0,0,0-1.749.065l.648,17.509a2.341,2.341,0,0,0,2.327,2.241h11.2a2.34,2.34,0,0,0,2.327-2.241l.648-17.509a.875.875,0,0,0-1.749-.065Z" transform="translate(-15.75 -52.492)" fill={fill} />
                <path id="Path_149" data-name="Path 149" d="M338.8,202.3v11.9a.7.7,0,1,0,1.4,0V202.3a.7.7,0,1,0-1.4,0Z" transform="translate(-133.872 -107.62)" fill={fill} />
                <path id="Path_150" data-name="Path 150" d="M277.2,202.323l.35,11.9a.7.7,0,0,0,1.4-.041l-.35-11.9a.7.7,0,0,0-1.4.041Z" transform="translate(-76.119 -107.62)" fill={fill} />
                <path id="Path_151" data-name="Path 151" d="M395.154,202.278l-.35,11.9a.7.7,0,1,0,1.4.041l.35-11.9a.7.7,0,0,0-1.4-.041Z" transform="translate(-186.373 -107.617)" fill={fill} />
                <path id="Path_152" data-name="Path 152" d="M196.875,136.151h17.507a.875.875,0,1,0,0-1.751H196.875a.875.875,0,1,0,0,1.751Z" transform="translate(0 -44.62)" fill={fill} />
                <path id="Path_153" data-name="Path 153" d="M282.221,90.371l.364-1.276a.849.849,0,0,1,.717-.54h3.157a.848.848,0,0,1,.717.54l.364,1.276,1.683-.481-.364-1.276a2.587,2.587,0,0,0-2.4-1.81H283.3a2.587,2.587,0,0,0-2.4,1.81l-.364,1.276Z" transform="translate(-79.252)" fill={fill} />
            </g>
        </svg>
    )
})