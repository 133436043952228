import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import "../../styles/local/alert.css";
const Discard = (props) => {
  return (
    <div>
      <Modal
        className="createordermodal"
        // style={{ width: "20em" }}
        animation={false}
        show={true}
        centered
      >
        <ModalBody className="px-3 pb-0 modalbody">
          <div className="container px-5">
            <div className="row text-center">
              <p>{props.msg}</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="modalfooter">
          <div className="row justify-content-center">
            <div className="col-6 pe-0 text-center">
              <button
                className=" fw-bold nobtn"
                type="button"
                onClick={props.handleCancel}
              >
                No
              </button>
            </div>
            <div className="col-6 ps-0 text-center">
              <button
                className=" addbtn fw-bold"
                type="button"
                onClick={(e) => {
                  props.handleOk();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default Discard;
