import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";

const PageNotFound = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      
      setLoading(false);
    },1500);
  });
  return (
    <>
      <div>
        <Header />
        {loading == true ? "":
        <div className="container-fluid ">
          <div className="row">
            <h2>page not found ! 404</h2>
          </div>
        </div>
}
      </div>
    </>
  );
};

export default PageNotFound;
