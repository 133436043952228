import React, { useEffect, useState, memo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../redux/actions/action";
import "../styles/local/TeamMemberSelection.css";

const TeamMemberSelection = ({ teamid, defaultSelected, onUpdate }) => {
    const dispatch = useDispatch();
    const containerRef = useRef();
    const [ open, setOpen ] = useState(false);
    const [ memberList, setMemberList ] = useState([]);
    const [ selectedMembers, setSelectedMembers ] = useState([]);
    const fetchTramMembers = useSelector((state) => state.user.fetchUserDetails);
    const colors = {
        "Driver": "orange",
        "Mechanic": "#003766",
        "Mechanic on site": "#80cfe6"
    };

    useEffect(() => {
        if (open) {
            window.addEventListener("mousedown", handleMouseDown)
        } else {
            window.removeEventListener("mousedown", handleMouseDown);
        }
        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
        }
    }, [ open ]);

    useEffect(() => {
        if (defaultSelected) {
            let users = defaultSelected.map(y => memberList.find(x => y === x.userid));
            setSelectedMembers(users)
        }
    }, [ defaultSelected ]);

    useEffect(() => {
        dispatch(fetchUserDetails(0, teamid));
    }, [ dispatch ]);

    const handleMouseDown = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (fetchTramMembers && fetchTramMembers.status) {
            const selectedItems = [];
            const list = fetchTramMembers.data.map(x => {
                if (x.userdetails.length > 0) {
                    let item = {
                        userid: x.userid,
                        email: x.userdetails[ 0 ].email,
                        firstname: x.userdetails[ 0 ].firstname,
                        lastname: x.userdetails[ 0 ].lastname,
                        phonenumber: x.userdetails[ 0 ].phonenumber,
                        photo: x.userdetails[ 0 ].photo,
                        roleid: x.userdetails[ 0 ].roleid,
                        initial: `${x.userdetails[ 0 ].firstname.charAt(0)}${x.userdetails[ 0 ].lastname.charAt(0)}`,
                        jobtitle: x.userdetails[ 0 ].jobtitle,
                        color: x.userdetails[ 0 ].jobtitle ? colors[ x.userdetails[ 0 ].jobtitle ] : ""
                    }
                    if (defaultSelected.some(x => x === item.userid)) {
                        selectedItems.push(item);
                    }
                    return item;
                }
            });
            setSelectedMembers(selectedItems)
            setMemberList(list);
        }
    }, [ fetchTramMembers ]);

    const handleSelectUser = (item) => {
        const old = selectedMembers;
        const oldIndex = old.findIndex(x => x.userid === item.userid);
        if (oldIndex === -1) {
            old.push(item);
        } else {
            old.splice(oldIndex, 1);
        }
        onUpdate(old.map(x => x.userid));
    }

    const ViewSelectedUser = ({ item }) => {
        return (
            <div className="selected-user-container" style={{ "--color": item?.color }}>
                {item?.photo ? <img src={item?.photo} alt={item?.initial} width="100%" height="100%" /> : item?.initial}
            </div>
        )
    }

    const DisplayUser = ({ item }) => {
        const isSelected = selectedMembers.some(x => x.userid === item.userid);
        return (
            <div className={`dd-display-user ${isSelected ? 'selected' : ''}`} style={{ "--color": item.color }} onClick={() => handleSelectUser(item)}>
                <div className="dd-user-image">
                    {item.photo ? <img src={item.photo} alt={item.initial} width="100%" height="100%" /> : item.initial}
                </div>
                <div className="d-flex flex-column align-items-start">
                    <span className="dd-user-name">{item.firstname} {item.lastname}</span>
                    <span className="dd-user-role">{item.jobtitle}</span>
                </div>
            </div>
        )
    }

    return (
        <div className="position-relative" ref={containerRef}>
            <div className="d-flex justify-content-end gap-2">
                {selectedMembers.map((item, index) => <ViewSelectedUser item={item} key={`selected-user-${index}`} />)}
                <div className="selected-user-container bg-dark bg-opacity-25" onClick={() => setOpen(prev => !prev)}>
                    <i className="fa fa-plus"></i>
                </div>
            </div>
            {
                open &&
                <div className="user-selection-popup">
                    {memberList.map((item, index) => <DisplayUser item={item} key={`member-item-${index}`} />)}
                </div>
            }
        </div>
    )
}

export default memo(TeamMemberSelection);