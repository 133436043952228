import Header from "../../components/Header";
import "../../styles/local/composeProduct.css";
import producticon from "../../assets/icons/producticon.svg";
import AddComposeImg from "../../assets/icons/AddComposeImg.svg";
import Group62 from "../../assets/icons/Group62.svg";
import axios from "axios";
import { API_URL } from "../../../src/redux/constants/type";
import { useEffect, useState, useRef } from "react";
import {
  fetchProductById,
  fetchProductStock,
  fetchProductSuggest,
  ComposeProducts,
  UploadToS3,
  fetchParts,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { Link, useHistory } from "react-router-dom";
import Cancel from "../../components/dialogs/Cancel";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { useQuery } from "../../utils/appHooks";
import EuropeanInput from "../../utils/EuropeanInput";

const Composeadd = () => {
  const refNewStandardWageInput = useRef(null);
  const selectRef = useRef();
  const inputFile = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const UserId = window.sessionStorage.getItem("userid");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [contact, setContact] = useState([]);
  const initialRender = React.useRef(true);
  const fetchproductstock = useSelector(
    (state) => state.user.fetchProductStock
  );
  const fetchpartsbyid = useSelector((state) => state.user.fetchProductById);
  const [part, setPart] = useState([]);
  const [productId, setProductId] = useState("");
  const [productdescription, setProductdescription] = useState("zero");
  const [unitprice, setUnitprice] = useState("");
  const [internalid, setInternalid] = useState("");
  const [totalcurrentquantity, setTotalcurrentquantity] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  let [imageurl, setImageurl] = useState("");
  const [partid, setPartid] = useState("");
  const [allotedquantity, setAllotedquantity] = useState("");
  const fetchsuppliers = useSelector((state) => state.user.fetchParts);
  const suggestProduct = useSelector((state) => state.user.suggestProduct);
  const [partList, setPartList] = useState([]);
  const [partName, setPartName] = useState("");
  const [customDrop, setCustomDrop] = useState(false);
  const [customDropId, setCustomDropId] = useState("");
  const [partarrow, setPartarrow] = useState("");
  const queryParams = useQuery();
  //   const productparts = [partid, allotedquantity];
  //   contact.forEach((item) => productparts.push({ typeid: item }));
  //   const productparts = [];
  //   contact.forEach((item) =>
  //     productparts.push({
  //       partid: item.partid,
  //       allotedquantity: item.allotedquantity,
  //     })
  //   );


  //////// Response from product.js screen for internal id , start ///////////

  const [pagenumber, setPageNumber] = useState(1);

  const [data2, setData2] = useState([]);

  React.useEffect(() => {
    if (fetchproductstock != null) {
      if (fetchproductstock.status == true) {
        setData2(fetchproductstock.data);
      }
    }
    else {
      initialRender.current = false;
    }
  }, [fetchproductstock]);

  useEffect(() => {
    // dispatch(fetchProductStock(pagenumber));
    // BELOW FOR GETING ALL INTERNAL ID not only according to the pagenumber
    dispatch(fetchProductStock());
  }, []);

  console.log("data2", data2)

  ///////////// Response from product.js screen internal id , end ///////////

  /////// for suggest product start ///////

  const initialRender2 = React.useRef(true);
  const [suggestProductData, setSuggestProductData] = useState([]);
  const [show, setShow] = useState(true);
  const [productname, setProductname] = useState("");
  const [productcode, setProductcode] = useState("");
  const [partarrow2, setPartarrow2] = useState("");

  useEffect(() => {
    dispatch(fetchProductSuggest(productname));
  }, [productname]);

  useEffect(() => {
    if (!initialRender2.current) {
      if (suggestProduct != null) {
        if (suggestProduct.status == true) {
          setSuggestProductData(suggestProduct.data);
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [suggestProduct]);

  // console.log("SuggestedData", suggestProduct)

  const arrowupDownPart2 = () => {
    setPartarrow2((prev) => !prev);
  };



  useEffect(() => {
    const isCon = queryParams.get("v")
    if (isCon === "compose") {
      const obj = window.sessionStorage.getItem("p-form");
      const jsnObj = JSON.parse(obj);
      setProductId(jsnObj.productId);
      setProductname(jsnObj.productname);
      setProductcode(jsnObj.productcode);
      setProductdescription(jsnObj.productdescription);
      setUnitprice(jsnObj.unitprice);
      setInternalid(jsnObj.internalid);
      setPartList(jsnObj.partList);
      setImageurl(jsnObj.imageurl ?? "");
      setShow(false);
    }
  }, [])

  const handleCreatePartClick = () => {
    const currentObj = {
      productId,
      productname,
      productcode,
      productdescription,
      unitprice,
      internalid,
      partList,
      imageurl
    }
    window.sessionStorage.setItem("p-form", JSON.stringify(currentObj));
    history.replace({ pathname: history.location.pathname, search: "?v=compose" })
    history.push({ pathname: "/add-parts" })
  }

  const onChangeHandler2 = (e) => {
    if (e != null) {
      setProductId(e.productid);
      setProductname(e.productname);
      setShow(false);
      setCustomDrop(false)
    }
  };

  ////// for suggest product end //////////

  ///////// for internalid validation start ///////

  const [showError, setShowError] = useState(false);

  const onChangeInternalid = (e) => {

    if (e != "") {
      // let isExited = suggestProductData.findIndex((item) => item.productcode == e)
      let isExited = data2.findIndex((item) => item.internalid == e)
      if (isExited !== -1) {
        setShowError(true)
      } else {
        setShowError(false)
      }
    } else {
      setShowError(false)
    }

  };

  //////// for internalid validation end ///////


  const handleAdd = (e) => {
    // const productparts = [];
    // partList.forEach((item) =>
    //   productparts.push({
    //     partid: item.partid,
    //     allotedquantity: item.allotedquantity,
    //   })
    // );

    if (imageurl == "") {
      // imageurl = "https://rbce-dev.s3.eu-west-2.amazonaws.com/AddComposeImg.svg"
      imageurl = AddComposeImg
      // console.log("img", AddComposeImg)
    }

    dispatch(
      ComposeProducts(
        UserId,
        productname,
        productdescription,
        internalid,
        unitprice,
        totalcurrentquantity,
        min,
        max,
        imageurl,
        partList.filter(({ partName, allotedquantity, partid }) => !(partName === "" && allotedquantity === "" && partid === ""))
      )
    );
    setTimeout(() => {
      history.goBack()
    }, 1000);

    e.preventDefault();
  };

  const handleAddimage = () => {
    dispatch(UploadToS3(imageurl));
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  useEffect(() => {
    dispatch(fetchParts());
    // setIsCalled(false);
    // setIsCalled1(false);
  }, []);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchsuppliers != null) {
        if (fetchsuppliers.status == true) {
          setData(fetchsuppliers.data);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchsuppliers]);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleFileChange = (e) => {
    const target = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target);
    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setImageurl(response.data[0]);
        } else {
        }
      })
      .catch(function (error) { });
  };

  const toggleSelect = (i) => {
    if (i !== null) {
      setCustomDropId(i)
    } else {
      setCustomDrop(true)
    }
  };

  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };

  const onChangeHandler = (e) => {
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[index];
    // const option = el.getAttribute("id");
    // setPartid(option);
    // setPartName(el.innerText);
    if (e != null) {
      setPartid(e.partid);
      setPartName(e.partname);
      setCustomDrop(false)
    }
  };

  const handleAddPart = () => {
    setPartList([...partList, { partName, allotedquantity, partid }]);
    setPartid("");
    setPartName("");
    setAllotedquantity("");
    selectRef.current.clearValue()
  };

  const Imagedlt = () => {
    setImageurl("");
  };

  return (
    <>
      {cancel && (
        <Cancel
          handleCancel={() => {
            setCancel(false);
          }}
          handleOk={() => {
            history.goBack();
          }}
        />
      )}
      <Header />
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-4 pb-5">
            <h3 className="py-3 ps-2 themecolor header-title-sk">  Products & Parts</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-12 custCardCount shadow p-0">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5
                    className="py-4 cntStockfont"
                    style={{ fontSize: "22px" }}
                  >
                    <span className="me-4">
                      <img className="countProicon" src={producticon} />
                    </span>
                    Compose Product
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row ps-5 ">
                  <div className="col-11" style={{ width: "95%" }}>
                    <div className="row">
                      <div className="col-xl-5 col-12 pt-5">
                        <div className="row">
                          <h5 className=" cntStockfont">
                            <span className="me-4 ">
                              <img className="countProicon" src={producticon} />
                            </span>
                            Product Name
                          </h5>
                        </div>
                        <div className="row ps-xl-5 pe-3 pe-xl-0 ms-1 ">
                          {/* <input
                            className=" form-control suppInput fntSupp4"
                            style={{ height: "51px", background: "#fafafa" }}
                            onChange={(e) => {
                              setShow(true);
                              setProductname(e.target.value)
                            }}
                            type="text"
                            value={productname}
                          />
                          {suggestProductData != null && show && suggestProductData.map((item) => {
                            return (
                              <>
                                <ul className="list-group">
                                  <li className="list-group ps-2"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className=""
                                      onClick={() => {
                                        setProductname(item.productname)
                                        setShow(false)
                                      }}
                                    >
                                      {item.productname}
                                    </div>
                                    <div className="custom-select-selected" > {item.productcode}</div>
                                    <div className="custom-select-selected" > {item.productid}</div>
                                  </li>
                                </ul>
                              </>
                            );
                          })} */}

                          {/* New Select start */}
                          <div style={{ position: "relative" }}>
                            <div className="custom-select-box custom-with-input" style={{ position: "absolute", width: "92%" }} >
                              {/* {!customDrop ? <div className="custom-select-selected" onClick={() => toggleSelect(null)} > {partname ? partname : "Select..."}</div> : ""} */}

                              <input
                                className=" form-control suppInput fntSupp4"
                                style={{ height: "51px" }}
                                placeholder="Type a Product name"
                                onChange={(e) => {
                                  setShow(true);
                                  setProductname(e.target.value);
                                }}
                                type="text"
                                value={productname}
                              />

                              {show ?
                                <div className={`custom-select-outer`}>
                                  {suggestProductData != null && suggestProductData.map((item, index) => {
                                    return (
                                      <>
                                        <div className={`list-select-box ${item.productId === productId ? 'active' : ""}`} key={index} id={item.partid} onClick={() => onChangeHandler2(item)}>
                                          {item.productname}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                                : ""}
                            </div>
                          </div>
                          {/* New Select end */}

                        </div>
                      </div>

                      <div className="col-xl-2 col-md-6  text-center">
                        <div className="row text-center">
                          <div className="col-md-12 pt-xl-5 mt-xl-2 mt-4 ps-md-4  ">
                            <div
                              className="addProductFnt mt-xl-1 pt-xl-0 mb-3"
                              style={{
                                color: "#80cfe6",
                                fontSize: "17px",
                              }}
                            >
                              Unit Price
                            </div>
                            <EuropeanInput
                              className=" form-control suppInput1 fntSupp4 mt-xl-0 centretext"
                              style={{ background: "#fafafa" }}
                              value={unitprice}
                              onChange={(newValue) => setUnitprice(newValue)}
                            />

                          </div>
                        </div>
                      </div>
                      <div className="col-3 col-xl-3 col-md-6 col-12 pt-xl-5 mt-xl-2 mt-4 pe-md-4 pe-xl-3 text-center">
                        <div
                          className="addProductFnt mt-xl-1 pt-xl-0 mb-3"
                          style={{ color: "#80cfe6", fontSize: "17px" }}
                        >
                          Internal ID
                        </div>
                        <input
                          className=" form-control suppInput1 fntSupp4 mt-xl-0 centretext"
                          style={{ background: "#fafafa" }}
                          // onChange={(e) => setInternalid(e.target.value)}
                          value={internalid}
                          onChange={(e) => {
                            setInternalid(e.target.value);
                            onChangeInternalid(e.target.value);
                          }}
                          type="text"
                        />

                        <p className="text-danger">
                          {showError ? "This ID already exists" : ""}
                        </p>

                      </div>
                      <div className="col-xl-2 col-12 pt-xl-5 mt-xl-2 mt-4 text-center">
                        <div
                          className="addProductFnt mt-xl-0 pt-xl-1"
                          style={{
                            color: "#80cfe6",
                            fontSize: "17px",
                            paddingLeft: "30px",
                          }}
                        >
                          Image
                          <img
                            src={Group62}
                            className="icons mx-3"
                            onClick={Imagedlt}
                            alt="dlt"
                            height="15px"
                            width="15px"
                          />
                        </div>
                        <div>
                          <div className="mt-xl-3 fs-1 proCardBtn">
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                              style={{ display: "none" }}
                            />
                            {imageurl === "" ? (
                              // <h3
                              //   style={{
                              //     cursor: "pointer",
                              //   }}
                              //   onClick={onButtonClick}
                              // >
                              //   +
                              // </h3>

                              <img
                                className="w-75"
                                src={AddComposeImg}
                                alt=""
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={onButtonClick}
                              >
                              </img>

                            ) : (
                              <img
                                src={imageurl}
                                height="65px"
                                width="74px"
                                alt="im"
                              ></img>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row ps-5 ">
                  <div className="col-11" style={{ width: "95%" }}>
                    <div className="row">
                      <div className="col-xl-5 pt-2">
                        <div className="row">
                          <div className="col-md-8 col-sm-7 col-xl-7 col-12">
                            <h5 className=" cntStockfont">
                              <span className="me-4 ">
                                <img
                                  className="countProicon"
                                  src={producticon}
                                />
                              </span>
                              Parts
                            </h5>
                          </div>
                          <div className="col-md-4 col-sm-5 col-xl-5 col-12 text-sm-end addProductFnt2 pe-5 pe-xl-0 pt-1">
                            <Button
                              variant="ghost"
                              onClick={handleCreatePartClick}
                              style={{
                                color: "#80cfe6",
                              }}
                            >
                              {" "}
                              (+) Create Part
                            </Button>
                          </div>
                        </div>

                        {partList.map((item) => {
                          return (
                            <>
                              <div className="row ps-xl-5 pe-5 pe-xl-0 mb-2 mt-xl-0" style={{ marginLeft: "-8px" }}>
                                {/* <select
                                  className="form-select form-select-lg "
                                  aria-label=".form-select-lg example"
                                  style={{
                                    color: "#003766",
                                    fontWeight: "500",
                                  }}
                                >
                                  <option>{item.partName}</option>
                                </select> */}

                                <div className="custom-select-box ms-4"
                                  style={{ width: "90%", height: "51px" }}>
                                  <div className="custom-select-selected" > {item.partName}</div>
                                </div>
                              </div>
                            </>
                          );
                        })}

                        <div className="row ps-xl-5 pe-5 pe-xl-0 ms-xl-3  ms-1 mt-2 mt-xl-0 sup12"
                          style={{ position: "relative" }}
                          onClick={arrowupDownPart}>
                          {/* <select
                            className="form-select form-select-lg"
                            aria-label=".form-select-lg example"
                            style={{
                              color: "#003766",
                              fontWeight: "700",
                            }}
                            onChange={onChangeHandler}
                            value={partid}
                          >
                            <option selected disabled value={""}>
                              Select a Part
                            </option>
                            {data.map((supp, index) => {
                              return (
                                <>
                                  <option key={index} id={supp.partid} value={supp.partid}>
                                    {supp.partname}
                                  </option>
                                </>
                              );
                            })}
                          </select> */}

                          <Select
                            ///menuIsOpen={true}
                            classNamePrefix="sk-custom-select"
                            className={
                              partarrow == "" ? "sk-custom-container-comp  padding-height" : "ArrowUpDownpur sk-custom-container-comp  padding-height"
                            }
                            ref={selectRef}
                            placeholder={"Type a  Part name"}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={data}
                            getOptionLabel={(option) => option.partname}
                            getOptionValue={(option) => option.partid}
                            onChange={(value) => onChangeHandler(value)}
                          // styles={colourStyles}
                          // defaultValue={}
                          />



                          {/* <div className="custom-select-box" >
                            {!customDrop ? <div className="custom-select-selected" onClick={() => toggleSelect(null)} > {partName ? partName : "Select..."}</div> : ""}
                            {customDrop ?
                              <div className={`custom-select-outer`}>
                                {data.map((supp, index) => {
                                  return (
                                    <>
                                      <div className={`list-select-box ${supp.partid === partid ? 'active' : ""}`} key={index} id={supp.partid} onClick={() => onChangeHandler(supp)}>
                                        {supp.partname}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                              : ""}
                          </div> */}
                        </div>
                      </div>
                      <div className="col-xl-2  col-sm-9 col-12 pt-xl-0 mt-xl-3 mt-3 ps-lg-4 pe-5 pe-sm-2 text-center">
                        <div
                          className="addProductFnt1 mt-1  mb-3"
                          style={{ color: "#80cfe6", fontSize: "17px" }}
                        >
                          Qty
                        </div>
                        {partList.map((item) => {
                          return (
                            <>
                              <EuropeanInput
                                hidePreview={true}
                                value={item.allotedquantity}
                                withoutSymbol={true}
                                className=" form-control suppInput1 fntSupp4 mt-2 "
                                style={{
                                  textAlign: "center",
                                  background: "#fafafa",
                                }}
                              />
                            </>
                          );
                        })}

                        <EuropeanInput
                          hidePreview={true}
                          value={allotedquantity}
                          withoutSymbol={true}
                          onChange={(value) => setAllotedquantity(value)}
                          className=" form-control suppInput1 fntSupp4 mt-2 "
                          style={{
                            textAlign: "center",
                            background: "#fafafa",
                          }}
                        />
                      </div>
                      <div className="col-sm-3 col-12 text-start pt-xl-5 mt-xl-3 mt-sm-3 ps-4 ps-xl-3">
                        {partList.map((item, index) => {
                          return (
                            <>
                              <div
                                className="addProductFnt3"
                                style={{ cursor: "pointer", height: "51px", marginTop: "8px" }}
                              >
                                <img
                                  src={Group62}
                                  className="icons"
                                  alt="dlt"
                                  onClick={() => {
                                    let data = [...partList];
                                    data.splice(index, 1);
                                    setPartList(data);
                                  }}
                                />
                              </div>
                            </>
                          );
                        })}

                        <div
                          className="mt-1 pt-2 addProductFnt3"
                          onClick={handleAddPart}
                        >
                          <button className={partName === "" ? "add_button" : "add_button addProductFnt3"}
                            disabled={
                              partName === ""
                              // partList.length === 0
                            }
                          >
                            Add...
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* <div className="col-8 my-2">
                    {partList.map((item) => {
                      return (
                        <>
                          <div className="col-6 box">
                            {item.partName}&nbsp;
                            {item.allotedquantity}
                          </div>
                          <div className="col-2 box"></div>
                        </>
                      );
                    })}
                  </div> */}
                </div>

                <div className="row mt-5 pt-5 ps-4 ms-1 pe-5  pb-5 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={() => {
                            setCancel(true);
                          }}
                          className="countBtncanc21 w-100 h-100 p-2"
                        >
                          CANCEL
                        </button>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            productname == "" ||
                              unitprice == "" ||
                              internalid == "" ||
                              showError ||
                              imageurl == ""
                              ? "countBtnchange22 w-100 h-100 p-2"
                              : "countBtnchange w-100 h-100 p-2"
                          }
                          onClick={(e) => {
                            handleAdd(e);
                          }}
                          disabled={
                            productname == "" ||
                            unitprice == "" ||
                            internalid == "" ||
                            showError ||
                            // imageurl == "" ||
                            partList.length == 0
                          }
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
export default Composeadd;
