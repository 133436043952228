import React, { useState } from "react";
import googleIcon from "../../assets/icons/google_icon.png";
import facebookIcon from "../../assets/icons/facebook-icon.png";
import appleIcon from "../../assets/icons/apple-icon1.png";
import linkedinIcon from "../../assets/icons/linkedin-icon3.png";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  userLogin,
  googleSignup,
  FetchOrdersbyStatus,
} from "../../redux/actions/action";
import { useHistory } from "react-router-dom";
import userImage from "../../assets/icons/userimage.png";
import "../../styles/local/login.css";
import Sidebar from "../../pages/static/en/Sidebar";
import Loader from "../../components/Loader";

const LogIn = (props) => {
  const emailStore = window.localStorage.getItem("email");
  const passwordStore = window.localStorage.getItem("password");

  const [ password, setPassword ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ error, setError ] = useState(false);
  const [ emailInvalid, setEmailInvalid ] = useState(false);
  const [ alert, setAlert ] = useState(false);
  const [ msg, setMsg ] = useState("");
  const [ showPassword, setShowPassword ] = useState(false);
  const [ loginTxt, setLoginTxt ] = useState("");
  const [ checkbox, setCheckbox ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ loading1, setLoading1 ] = useState(false);
  const [ com, setCom ] = useState("");

  const loginData = useSelector((state) => state.user.loginData);

  const googleSignupData = useSelector((state) => state.user.googleSignupData);
  const profilePic = useSelector((state) => state.user.profilePicData);

  const dispatch = useDispatch();
  const history = useHistory();
  const [ sidebarCall, setsideBarcall ] = useState(false);

  React.useEffect(() => {
    if (emailStore && passwordStore !== null) {
      setLoading1(true);
      setPassword(passwordStore);
      setEmail(emailStore);
      setCheckbox(true);
      dispatch(userLogin(emailStore, passwordStore));
    } else {
      setPassword("");
      setEmail("");
    }
  }, []);

  const initialRender = React.useRef(true);
  const initialRender2 = React.useRef(true);

  // React.useEffect(() => {

  //   if (initialRender2.current == true) {

  //     if (profilePic != null) {

  //       if (profilePic.status == true) {

  //         window.sessionStorage.setItem("userpic", profilePic.data == null ? userImage : profilePic.data);

  //       }

  //     }
  //   } else {
  //     initialRender2.current = false;
  //   }
  // }, [profilePic])

  React.useEffect(() => {
    if (!initialRender.current) {
      if (loginData != null) {
        if (loginData.status == true) {
          if (loginData.data == null) {
            history.push("/home");
            //clear all initial sessions
            window.sessionStorage.clear();
            setsideBarcall(true);
            window.location.reload();
          } else {
            window.sessionStorage.setItem("userid", loginData.data[ 0 ].userid);
            window.sessionStorage.setItem("image", loginData.data[ 0 ].photo);
            window.sessionStorage.setItem(
              "compId",
              loginData.data[ 0 ]?.companyid
            );
            window.sessionStorage.setItem(
              "roleid",
              loginData.data[ 1 ][ 0 ]?.roleid
            );
            window.sessionStorage.setItem(
              "rolename",
              loginData.data[ 1 ][ 0 ]?.rolename
            );
            setCom(loginData.data[ 0 ]?.companyid);

            window.sessionStorage.setItem(
              "teamId",
              loginData.data[ 3 ][ 0 ]?.teamid
            );

            window.sessionStorage.setItem(
              "clientname",
              loginData.data[ 0 ].firstname + " " + loginData.data[ 0 ].lastname
            );
            window.sessionStorage.setItem("position", "/home");
            if (
              window.sessionStorage.getItem("userid") ===
              "08da3ed3-a421-45c5-83a7-b665ddb4a6f9"
            ) {
              window.sessionStorage.setItem(
                "teamId",
                loginData.data[ 3 ][ 0 ].teamid
              );
            } else {
              // window.sessionStorage.setItem("teamId","team id is null for this acnt ")
            }
            // dispatch(fetchProfilePic(loginData.data.userid, "1"));
            setLoading(true);

            history.push("/home");

            setsideBarcall(true);
            window.location.reload();
          }
        } else {
          setMsg(loginData.message);
          setAlert(true);
          // alert("something went wrong");
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ loginData ]);
  const initialRender1 = React.useRef(true);
  React.useEffect(() => {
    if (!initialRender1.current) {
      if (googleSignupData != null) {
        //open google signup page url
        window.open(googleSignupData.data);
      }
    } else {
      initialRender1.current = false;
    }
  }, [ googleSignupData ]);

  const handleSubmit = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // setLoading(true);
    if (email == "" || password == "") {
      e.preventDefault();
      setError(true);
    } else if (!re.test(email)) {
      setEmailInvalid(true);
    } else {
      //user login api call
      if (checkbox == true) {
        window.localStorage.setItem("email", email);
        window.localStorage.setItem("password", password);
      } else {
        window.localStorage.clear();
      }
      dispatch(userLogin(email, password));

      e.preventDefault();
      // alert("Login successfull");
    }
  };

  const togglePasssword = () => {
    setShowPassword(!showPassword);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <div>
      {loading1 == true ? (
        <Loader />
      ) : sidebarCall == false ? (
        <Modal
          animation={false}
          show={true}
          centered
          style={{ backgroundColor: "#808080" }}
        >
          {alert == true ? (
            <div className="row justify-content-center ">              
            </div>
          ) : (
            ""
          )}
          <div className="container centeralign headerbg">          
          </div>
          <div className="container centeralign headerbg pt-3 ">
            <h1 className="heading">LogIn</h1>
          </div>
          <ModalBody className="my-0  pb-3">
            <div>
              <form>
                <div className="container ">
                  <div className="container pt-2">
                    <div className="col-sm-12 inputsection">
                      {email == "" && error == true && (
                        <div className="errormsg">
                          Please fill in this field
                        </div>
                      )}
                      <input
                        id="userinput"
                        type="email"
                        className={
                          email == "" && error == true
                            ? "form-control inputtext showerror"
                            : "form-control inputtext"
                        }
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailInvalid(false);
                          setAlert(false);
                
                        }}
                        placeholder="Email"
                      />
                    </div>

                    {alert == true ? (
                      <div
                        className=" alert-danger newlogin"
                      // role="alert"
                      >
                        {msg}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-sm-12 input-group inputsection">
                      {password == "" && error == true && (
                        <div className="errormsg">
                          Please fill in this field
                        </div>
                      )}

                      {emailInvalid == true && (
                        <div className="errormsg">Email is invalid</div>
                      )}

                      <input
                        id="userinput"
                        type={showPassword ? "text" : "password"}
                        className={
                          password == "" && error == true
                            ? "form-control inputtext showerror"
                            : "form-control inputtext"
                        }
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setEmailInvalid(false);
                          setAlert(false);
                        }}
                        placeholder="Password"
                      />
                      <span
                        style={{ marginLeft: "92%" }}
                        className="passwordicon1"
                      >
                        <i
                          className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"}
                          id="togglePassword"
                          onClick={togglePasssword}
                        ></i>
                      </span>
                    </div>

                    <div className="row checksection1">
                      <div className="col form-check news">
                        <input
                          checked={checkbox}
                          type="checkbox"
                          onChange={() => setCheckbox(!checkbox)}
                          className="form-check-input blueCheck checkbox1"
                          id="exampleCheck1"
                        />

                        <h5 className="subheading ">Keep me logged in</h5>
                      </div>
                      <div className="col rightalign ">
                        {/* props are coming from landingpage.js */}
                        <h1 className="subheading  ">
                          <b>
                            <a
                              className="linkstyle"
                              onClick={props.clickForgetPassword}
                            >
                              Forgot password?
                            </a>
                          </b>
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div className="container btncontainer ">
                    <button
                      className="btn createbutton"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={loading}
                    >
                      Login
                      {loading == true && (
                        <div
                          className="spinner-border spinner-border-sm ms-1"
                          role="status"
                        ></div>
                      )}
                    </button>
                  </div>
                </div>
                {/* <div className="container">
                  <h6 className="line">
                    <span className="linetext">or</span>
                  </h6>
                </div>
                <div className="row justify-content-center">
                  <img
                    className="socialmediaicon"
                    src={googleIcon}
                    alt="Google"
                    onClick={() => {
                      dispatch(googleSignup());
                    }}
                  />
                  <img
                    className="socialmediaicon"
                    src={facebookIcon}
                    alt="Facebook"
                  />
                  <img
                    className="socialmediaicon"
                    src={appleIcon}
                    alt="Apple"
                    onClick={() => {
 
                      props.appleWelcome();
                      props.handleClose();
                    }}
                  />
                  <img
                    className="socialmediaicon"
                    src={linkedinIcon}
                    alt="Linkedin"
                  />
                </div> */}
                {/* <div className="container">
                  <h6 className="line1"></h6>
                </div>
                <div className="row inputsection centeralign">
                  <h1 className="subheading mt-sm-2">Don`t have account?</h1>
                </div> */}
                {/* <div className="btncontainer container py-0 my-0 px-4"> */}
                {/* props are coming from landingpage.js */}
                {/* <button
                    className="btn loginbutton mb-sm-4"
                    onClick={props.clickSignup}
                  >
                    Sign Up
                  </button>
                </div> */}
              </form>
            </div>
          </ModalBody>
        </Modal>
      ) : (
        <Sidebar />
      )}
    </div>
  );
};

export default LogIn;
