import React, { useEffect, useState, createContext } from "react";
import { useHistory } from "react-router-dom";
import Router from "../../../routes/Router";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { fetchRoleModule } from "../../../redux/actions/action";
import { Link } from "react-router-dom";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "../../../styles/local/sidebar.css";
import rbcelogo from "../../../assets/icons/rbcelogorem.png";
import orderIcon from "../../../assets/icons/order.svg";
import clientIcon from "../../../assets/icons/clienticons.png";
import stockIcon from "../../../assets/icons/Stock.svg";
import productsIcon from "../../../assets/icons/Products.svg";
import teamIcon from "../../../assets/icons/team.svg";
import warehouseIcon from "../../../assets/icons/warehouse.svg";
import labelIcon from "../../../assets/icons/label.svg";
import settingsIcon from "../../../assets/icons/settings.svg";
import boardIcon from "../../../assets/icons/board-icon.png";
import Group763 from "../../../assets/icons/Group763.svg";
import chartbar from "../../../assets/icons/chartbar.svg";
import downlinelist from "../../../assets/icons/downlinelist.svg";
import cart from "../../../assets/icons/cart.svg";
import grppeople from "../../../assets/icons/grppeople.svg";
import profile from "../../../assets/icons/profile.svg";
import singel from "../../../assets/icons/singel.svg";
import windowssss from "../../../assets/icons/windowssss.svg";
import Group1095 from "../../../assets/icons/Group1095.svg";
import Path557 from "../../../assets/icons/Path557.svg";
import Group1089 from "../../../assets/icons/Group1089.svg";
import Group1091 from "../../../assets/icons/Group1091.svg";
import Group1093 from "../../../assets/icons/Group1093.svg";
import Group1097 from "../../../assets/icons/Group1097.svg";
import Group1099 from "../../../assets/icons/Group1099.svg";
import { sub } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
export const cardShow = createContext();

const Sidebar = () => {
  const dispatch = useDispatch();
  const [ news, setNews ] = useState("");
  const roleId = window.sessionStorage.getItem("roleid");
  const roleName = window.sessionStorage.getItem("rolename");
  const [ isActive, setIsActive ] = useState("");
  const [ roleModuleList, setRoleModuleList ] = useState([]);
  const [ product, setProduct ] = useState(false);
  const [ order, setOrder ] = useState(false);
  const [ user, setUser ] = useState(false);
  const [ board, setBoard ] = useState(false);
  const [ client, setClient ] = useState(false);
  const [ stock, setStock ] = useState(false);
  const [ supplier, setSupplier ] = useState(false);
  const [ position, setPosition ] = React.useState(
    window.sessionStorage.getItem("position")
  );
  const roleModuleData = useSelector((state) => state.user.roleModuleData);
  // const reqData = useSelector((state) => state);
  const [ expandMenu, setExpandMenu ] = React.useState(false);
  const [ subsidebar, setSubsidebar ] = useState(false);
  const [ subpart, setSubpart ] = useState(false);
  const [ disableSidebar, setDisableSidebar ] = React.useState(true);
  const history = useHistory();
  // const toggleBtn = window.sessionStorage.getItem("toggleBtn");
  // const handleMenuToggle = () => {
  //   const reqData = window.sessionStorage.getItem("toggleBtn");
  //   window.sessionStorage.setItem(
  //     "toggleBtn",
  //     reqData == "true" ? "false" : "true"
  //   );
  // };

  // const path = window.sessionStorage.getItem("path");
  // const handleSidebar=(value)=>{
  //   setDisableSidebar(value);
  // }
  // useEffect(() => {
  //   window.sessionStorage.setItem("toggleBtn", "false");
  // }, []);

  history.listen((location, action) => {
    if (location.pathname == "/home") {
      setPosition("/home")
      setIsActive("one");
    }
  })

  useEffect(() => {
    if (roleId !== null && roleId !== "") {
      dispatch(fetchRoleModule(roleId));
    }
    // window.sessionStorage.setItem("position", "/planboard");
  }, []);

  const initialRender1 = React.useRef(true);

  React.useEffect(() => {
    if (!initialRender1.current) {
      if (roleModuleData.status == true) {
        setRoleModuleList(roleModuleData.data);
        for (let i = 0; i < roleModuleData.data.length; i++) {
          if (
            roleModuleData.data[ i ].moduleid ==
            "08da2f30-beca-4c07-8e42-1311289b9e91" &&
            (roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df320" ||
              roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df321")
          ) {
            setProduct(true);
          } else if (
            roleModuleData.data[ i ].moduleid ==
            "08da2f30-beca-4c07-8e42-1311289b9e92" &&
            (roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df320" ||
              roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df321")
          ) {
            setIsActive("two");
            setOrder(true);
          } else if (
            roleModuleData.data[ i ].moduleid ==
            "08da2f30-beca-4c07-8e42-1311289b9e93" &&
            (roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df320" ||
              roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df321")
          ) {
            setUser(true);
          } else if (
            roleModuleData.data[ i ].moduleid ==
            "08da2f30-beca-4c07-8e42-1311289b9e94" &&
            (roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df320" ||
              roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df321")
          ) {
            setBoard(true);
          } else if (
            roleModuleData.data[ i ].moduleid ==
            "08da2f30-beca-4c07-8e42-1311289b9e95" &&
            (roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df320" ||
              roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df321")
          ) {
            setClient(true);
          } else if (
            roleModuleData.data[ i ].moduleid ==
            "08da2f30-beca-4c07-8e42-1311289b9e96" &&
            (roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df320" ||
              roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df321")
          ) {
            setStock(true);
          } else if (
            roleModuleData.data[ i ].moduleid ==
            "08da2f30-beca-4c07-8e42-1311289b9e97" &&
            (roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df320" ||
              roleModuleData.data[ i ].accesslevelsid ==
              "08da33e9-5a64-4f8c-8d88-49e4982df321")
          ) {
            setSupplier(true);
          }
        }
      }
      // else {
      // }
    } else {
      initialRender1.current = false;
    }
  }, [ roleModuleData ]);


  return (
    <>
      <cardShow.Provider value={expandMenu}>

        <div
          className="container-fluid overflow-hidden"
          style={{ background: "#FAFAFA" }}
        >
          <div className="row">

            <div className="col-3">
              <SideNav
                expanded={expandMenu}

                className="sideBar ps-0 pt-5"
                onSelect={(selected) => {
                  const to = "/" + selected;
                  history.push(to);
                  window.sessionStorage.setItem("position", to);
                  setPosition(to);
                }}
              >
                {expandMenu &&
                  <div className="logoBarSK">
                    <a href="/home" onClick={() => {
                      setIsActive("one");
                      window.sessionStorage.setItem("position", "/home");
                    }} className="mb-4 pt-5 navBarlogo">
                      <img src={rbcelogo} alt="EBCE Logo" />
                    </a>
                  </div>
                }
                <div
                  style={{ cursor: "pointer", marginTop: "114px", position: "absolute", right: "-17px", zIndex: "99" }}
                  onClick={() => setExpandMenu(!expandMenu)}

                >
                  <i
                    className={

                      expandMenu == true
                        ? "bi bi-chevron-left navRightTrueIcon "
                        : "bi bi-chevron-right navRightIcon ps-2"
                    }
                  ></i>
                </div>

                <SideNav.Nav
                  className="MainMenu"
                  style={{
                    background: "#daf4fa",
                    height: "calc(100vh - 190px)",
                    marginTop: "52px",
                    overflow: "hidden",
                    overflowY: "auto"
                  }}
                >


                  {board == true && (
                    <NavItem
                      eventKey="home"
                      className={
                        position == "/home"
                          ? " navBackg highlight mb-4 mt-5"
                          : " mb-4 mt-5"
                      }
                      style={subsidebar ? { height: "25px" } : { height: "15px" }}
                    >
                      <NavIcon
                        className="navIconBack "
                        onClick={() => {
                          setIsActive("one");

                        }}
                      >
                        {isActive == "one" ? (
                          <img
                            alt=""
                            src={Path557}
                            className={
                              isActive == "one"
                                ? " orderIcon"
                                : " orderIcon imgicon"
                            }
                          />
                        ) : position == "/home" ? (
                          <img src={Path557} alt="" className={" orderIcon imgicon"}
                            onClick={() => {
                              window.sessionStorage.setItem("position22", "/home");
                            }}
                          />
                        ) : (
                          <img src={chartbar} alt="" className={" orderIcon sd imgicon"}
                            onClick={() => {
                              window.sessionStorage.setItem("position22", "/home");
                            }}
                          />
                        )}

                      </NavIcon>
                      <NavText className={position == "/home" ? " " : " "}>
                        <p
                          onClick={() => {
                            setIsActive("one");
                          }}
                          className={
                            isActive == "one"
                              ? " sidenav---navtext---1AE_f1 navText"
                              : "sidenav---navtext---1AE_f1 navText"
                          }
                        >
                          Board
                          <span>
                            {" "}
                            <div className="dropdown ms-4">
                              <div
                                className="dropbtn px-4"

                                style={{ height: "32px" }}
                              >
                                {subsidebar ? (
                                  <i
                                    className="bi bi-chevron-up"
                                    onClick={() => setSubsidebar(!subsidebar)}
                                  ></i>
                                ) : (
                                  <i
                                    className=" bi bi-chevron-down dropdown-arrow"
                                    onClick={() => setSubsidebar(!subsidebar)}
                                  ></i>
                                )}
                              </div>
                              <div
                                className={
                                  subsidebar
                                    ? "dropdown-content has-submenu"
                                    : "dropdown-content d-none"
                                }
                              >
                                <a href="/timelineview"
                                  onClick={() => {
                                    window.sessionStorage.setItem("position22", "/timelineview");
                                  }}
                                >
                                  Timeline View
                                </a>
                              </div>
                            </div>
                          </span>
                        </p>
                      </NavText>
                    </NavItem>
                  )}


                  {order == true && (
                    <NavItem
                      eventKey="order"
                      className={
                        position == "/order"
                          ? "navBackg highlight mb-4 mt-5"
                          : "mb-4 mt-5 "
                      }
                      // style={{ paddingTop: "14px" }}
                      onClick={() => {
                        setIsActive("two");
                      }}
                    >
                      <NavIcon className="navIconBack">
                        {isActive == "two" ? (
                          <img
                            src={Group1089}
                            className={
                              isActive == "two"
                                ? " orderIcon1"
                                : " orderIcon1 imgicon"
                            }
                          />
                        ) : (
                          <img
                            src={downlinelist}
                            className={
                              isActive == "two"
                                ? " orderIcon1"
                                : " orderIcon1 imgicon"
                            }
                          />
                        )}


                      </NavIcon>

                      <NavText className={position == "/order" ? " " : " "}>
                        <p
                          className={
                            isActive == "two"
                              ? " sidenav---navtext---1AE_f1 navText "
                              : "sidenav---navtext---1AE_f1 navText"
                          }
                        >
                          Order
                        </p>
                      </NavText>
                    </NavItem>
                  )}

                  {client == true && (
                    <NavItem
                      eventKey="client"
                      className={
                        position == "/client"
                          ? "navBackg highlight mb-4 mt-5"
                          : "mb-4 mt-5"
                      }
                      onClick={() => {
                        setIsActive("seven");
                      }}
                    >
                      <NavIcon className="navIconBack">

                        {isActive == "seven" ? (
                          <img
                            src={clientIcon}
                            className={
                              isActive == "seven"
                                ? " iconClient2"
                                : " iconClient imgicon"
                            }
                          />
                        ) : (
                          <img
                            src={profile}
                            className={
                              isActive == "seven"
                                ? " iconClient"
                                : " iconClient imgicon"
                            }
                          />
                        )}


                      </NavIcon>
                      <NavText className={position == "/client" ? " " : " "}>
                        <p
                          className={
                            isActive == "seven"
                              ? "sidenav---navtext---1AE_f1 navText"
                              : "sidenav---navtext---1AE_f1 "
                          }
                        >
                          Client
                        </p>
                      </NavText>
                    </NavItem>
                  )}
                  {stock == true && (
                    <NavItem
                      eventKey="Inventory"
                      className={
                        position == "/Inventory"
                          ? "navBackg highlight mb-4 mt-5"
                          : "mb-4 mt-5"
                      }
                      onClick={() => {
                        setIsActive("four");
                      }}
                    >
                      <NavIcon className="navIconBack">

                        {isActive == "four" ? (
                          <img
                            src={Group1093}
                            className={
                              isActive == "four"
                                ? " inventoryIcon2"
                                : " inventoryIcon imgicon"
                            }
                          />
                        ) : (
                          <img
                            src={cart}
                            className={
                              isActive == "four"
                                ? " inventoryIcon"
                                : " inventoryIcon imgicon"
                            }
                          />
                        )}


                      </NavIcon>

                      <NavText className={position == "/Inventory" ? " " : " "}>
                        <p
                          className={
                            isActive == "four"
                              ? " sidenav---navtext---1AE_f1 navText "
                              : "sidenav---navtext---1AE_f1 "
                          }
                        >
                          Stock
                        </p>
                      </NavText>
                    </NavItem>
                  )}
                  {product == true && (
                    <NavItem
                      eventKey="products"
                      className={
                        position == "/products"
                          ? "navBackg highlight mb-4 mt-5"
                          : "mb-4 mt-5"
                      }
                      onClick={() => {
                        setIsActive("fifth");
                      }}
                      style={subpart ? { height: "55px" } : { height: "15px" }}
                    >
                      <NavIcon className="navIconBack">
                        {isActive == "fifth" ? (
                          <img
                            src={Group1095}
                            className={
                              isActive == "fifth"
                                ? " productsIcon2"
                                : " productsIcon imgicon"
                            }
                          />
                        ) : position == "/products" ? (
                          <img src={Group1095}
                            className={" productsIcon imgicon"} />
                        ) : (
                          <img
                            src={windowssss}
                            className={" productsIcon sd imgicon"}
                          />
                        )}

                      </NavIcon>
                      <NavText className={position == "/products" ? " " : " "}>
                        <p
                          className={
                            isActive == "fifth"
                              ? " sidenav---navtext---1AE_f1 navText "
                              : "sidenav---navtext---1AE_f1 navText"
                          }
                        >
                          Products
                          <span>
                            {" "}
                            <div className="dropdown ms-4">
                              <div
                                className={
                                  roleName !== "User" ? "dropbtn" : "d-none"
                                }
                                style={{ height: "32px" }}
                              >
                                {subpart ? (
                                  <i
                                    className="bi bi-chevron-up"
                                    onClick={() => setSubpart(!subpart)}
                                  ></i>
                                ) : (
                                  <i
                                    className=" bi bi-chevron-down dropdown-arrow"
                                    onClick={() => setSubpart(!subpart)}
                                  ></i>
                                )}
                              </div>
                              <div
                                className={
                                  subpart
                                    ? "dropdown-content has-submenu"
                                    : "dropdown-content d-none"
                                }
                              >
                                <a href="/add-parts">Add Part</a>

                                <a href="/compose-add" className="my-2">
                                  Compose
                                </a>
                              </div>
                            </div>
                          </span>
                        </p>
                      </NavText>

                    </NavItem>
                  )}
                  {user == true && (
                    <NavItem
                      eventKey="team"
                      className={
                        position == "/team"
                          ? "navBackg highlight mb-4 mt-5"
                          : "mb-4 mt-5"
                      }
                      // style={{ paddingTop: "20px" }}
                      onClick={() => {
                        setIsActive("six");
                      }}
                    >
                      <NavIcon className="navIconBack">

                        {isActive == "six" ? (
                          <img
                            src={Group1097}
                            className={
                              isActive == "six"
                                ? " teamIcon2"
                                : " teamIcon imgicon"
                            }
                          />
                        ) : (
                          <img
                            src={grppeople}
                            className={
                              isActive == "six"
                                ? " teamIcon"
                                : " teamIcon imgicon"
                            }
                          />
                        )}
                        {/* <img
                    src={Group1097}
                    className={
                      isActive == "six" ? " orderIcon " : "orderIcon imgicon"
                    }
                  /> */}
                      </NavIcon>
                      <NavText className={position == "/team" ? " " : " "}>
                        <p
                          className={
                            isActive == "six"
                              ? "sidenav---navtext---1AE_f1 navText"
                              : "sidenav---navtext---1AE_f1 "
                          }
                        >
                          Team
                        </p>
                      </NavText>
                    </NavItem>
                  )}

                  {/* <NavItem eventKey="timelineview" className={position == "/timelineview" ? "navBackg highlight mb-4 mt-5" : "mb-4 mt-5"} onClick={() => { setIsActive("seven") }} >
                <NavIcon className="navIconBack">
                  <i className="bi bi-windows sideNavFont"></i>
                  <img src={Group1099} className={isActive == "seven" ? ' orderIcon ' : 'orderIcon imgicon'} />
                </NavIcon>
                <NavText className="navText">Warehouse</NavText>
              </NavItem> */}

                  {/* <NavItem eventKey="timelineview" className={position == "/timelineview" ? "navBackg highlight mb-4 mt-5" : "mb-4 mt-5"} onClick={() => { setIsActive("eight") }} >
                <NavIcon className="navIconBack">
                 
                  <img src={Path559} className={isActive == "eight" ? ' orderIcon ' : 'orderIcon imgicon'} />
                </NavIcon>
                <NavText className="navText">Labels</NavText>
              </NavItem> */}
                  {supplier == true && (
                    <NavItem
                      eventKey="supplier"
                      className={
                        position == "/supplier"
                          ? "navBackg highlight mb-4 mt-5"
                          : "mb-4 mt-5"
                      }
                      onClick={() => {
                        setIsActive("nine");
                      }}
                    >
                      <NavIcon className="navIconBack">
                        {isActive == "nine" ? (
                          <img
                            src={Group763}
                            className={
                              isActive == "nine"
                                ? " supplierIcon2"
                                : " supplierIcon imgicon"
                            }
                          />
                        ) : (
                          <img
                            src={singel}
                            className={
                              isActive == "nine"
                                ? " supplierIcon"
                                : " supplierIcon imgicon"
                            }
                          />
                        )}

                        {/* <img
                    src={Group763}
                    className={
                      isActive == "nine" ? " orderIcon " : "orderIcon imgicon"
                    }
                  /> */}
                      </NavIcon>
                      <NavText>
                        <p
                          className={
                            isActive == "nine"
                              ? "sidenav---navtext---1AE_f1 navText"
                              : "sidenav---navtext---1AE_f1 "
                          }
                        >
                          Supplier
                        </p>
                      </NavText>
                    </NavItem>
                  )}

                  {/* <NavItem eventKey="supplier" className={position == "/supplier" ? "navBackg highlight mb-4 mt-5" : "mb-4 mt-5"} onClick={() => { setIsActive("ten") }} >
                <NavIcon className="navIconBack">
                  <img src={Path561} className={isActive == "ten" ? ' orderIcon ' : 'orderIcon imgicon'} />
                </NavIcon>
                <NavText className="navText">Settings</NavText>
              </NavItem> */}

                  {/* <NavItem defaultSelected eventKey='' className="mb-4" >
                <NavIcon className="navIconBack">
                  <img src={clientIcon} className="orderIcon" />
                </NavIcon>
                <NavText className="navText">
                  Client
                </NavText>
              </NavItem> */}

                  {/* <NavItem eventKey="settings" className="mb-4" >
                <NavIcon className="navIconBack">
                  <img src={productsIcon} className="orderIcon" />
                </NavIcon>
                <NavText className="navText">
                  Products
                </NavText>
              </NavItem> */}

                  {/* <NavItem eventKey="products" className="mb-4">
                <NavIcon className="navIconBack">
                  <img src={Group1095} className="orderIcon" />
                </NavIcon>
                <NavText className="navText">
                  <p>
                    <button
                      className="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseWidthExample"
                      aria-expanded="false"
                      aria-controls="collapseWidthExample"
                    >
                      Toggle width collapse
                    </button>
                  </p>
                  <div style={{ minHeight: "120px" }}>
                    <div className="collapse width" id="collapseWidthExample">
                      <div className="card card-body" style={{ width: "320px" }}>
                        This is some placeholder content for a horizontal
                        collapse. It's hidden by default and shown when
                        triggered.
                      </div>
                    </div>
                  </div>
                </NavText>
              </NavItem> */}

                  {/* team and user */}

                  {/* <NavItem eventKey="team" className="mb-4">
                <NavIcon className="navIconBack">
                  <img src={teamIcon} className="orderIcon" />
                </NavIcon>
                <NavText className="navText">Team & User</NavText>
              </NavItem> */}

                  {/* <NavItem eventKey="settings" className="mb-4" >
                <NavIcon className="navIconBack">
                  <img src={warehouseIcon} className="orderIcon" />
                </NavIcon>
                <NavText className="navText">
                  Warehouse
                </NavText>
              </NavItem> */}

                  {/* ///////////// New policy Start ///////////// */}

                  {/* <NavItem eventKey="new-policy" className="mb-4" >
                    <NavIcon className={position == "/new-policy" ? "navIconBack" : "navIconBack"}>
                      <img src={labelIcon} className="orderIcon" />
                    </NavIcon>
                    <NavText className={position == "/new-policy" ? "navText" : "navText"}>
                      New Policy
                    </NavText>
                  </NavItem> */}

                  {/* ///////////// New policy End ///////////// */}

                  {/* <NavItem eventKey="settings" className="mb-4" >
                    <NavIcon className="navIconBack">
                      <img src={settingsIcon} className="orderIcon" />
                    </NavIcon>
                    <NavText className="navText">
                      Settings
                    </NavText>
                  </NavItem> */}

                </SideNav.Nav>
              </SideNav>
            </div>
            {/* <div className={expandMenu? '':'ps-5 ms-2'} style={expandMenu ? {marginLeft:"0px"}:{}}>
          <div className="ps-2"  >
           
            <Router />
 
          </div>
        </div> */}
            <div
              // className={
              //   window.sessionStorage.getItem("toggleBtn") === "true"
              //     ? ""
              //     : "ps-5 ms-2"
              // }
              // style={
              //   window.sessionStorage.getItem("toggleBtn") === "true"
              //     ? { marginLeft: "0px" }
              //     : {}
              // }
              className={expandMenu ? "" : "ps-5 ms-2"}
              style={expandMenu ? { marginLeft: "0px" } : {}}
            >
              <div className="ps-2" style={expandMenu ? { width: "99.5%" } : {}}>
                {/* <div
            className="ps-2"
            style={
              window.sessionStorage.getItem("toggleBtn") === "true"
                ? { width: "99.5%" }
                : {}
            }
          > */}
                <div
                  className="row"
                  style={expandMenu ? { marginLeft: "200px" } : {}}
                // style={
                //   window.sessionStorage.getItem("toggleBtn") === "true"
                //     ? { marginLeft: "200px" }
                //     : {}
                // }
                >
                  <Router />
                </div>
              </div>
            </div>
          </div>
        </div>
      </cardShow.Provider>


    </>
  );
};

export default Sidebar;
