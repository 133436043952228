import React, { useState, useEffect, useCallback, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Holder from "../../assets/icons/pole.svg";
import Ellipse11 from "../../assets/icons/Ellipse11.svg";
import leftarrow from "..//..//assets/icons/leftarrow.jpg";
import moment from "moment";
import "../../styles/local/warehouse.css";
import warehouseIcon from "../../assets/icons/warehouseIcon.svg";
import Advisedpart from "../../assets/icons/Advisedpart.svg";
import Group1093 from "../../assets/icons/Group1089.svg";
import EmojiPicker from "emoji-picker-react";
import calender from "../../assets/icons/calender.svg";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../redux/constants/type";
import axios from "axios";
import {
  CreateOrder,
  fetchAwaitingOrder,
  fetchPurchasingOrders,
  fetchUserDetails,
  fetchwarehouse,
  nextOrder,
  stockAvailable,
  UpdatePartData,
  fetchSuppliers,
  FetchParts,
  deleteComment,
  addComments,
  fetchComments,
  UpdateStore,
  AddPartToPurchaseOrder,
  PurchaseOrderDelivered,
} from "../../redux/actions/action";
import MoveCard from "../../components/dialogs/MoveCard";
import Loader from "../../components/Loader";
import Header from "../../components/Header";

import OrderCreated from "../../components/dialogs/OrderCreated";
import orderIcon from "../../assets/icons/order.svg";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Fragment } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import EuropeanInput from "../../utils/EuropeanInput";
import DltIcon from "../../assets/icons/Group62.svg";
import DeleteCommentPopup from "../../components/dialogs/DeleteCommentPopup";


const itemsFromBackend = [ { id: "1", content: "First task" } ];
const clientname = window.sessionStorage.getItem("clientname");

const formatDate = (value) => {
  let date = new Date(value);
  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.toLocaleString("default", { year: "numeric" });
  return day + "." + month + "." + year;
};
const MAX_ITEMS = 5;
function Warehouse() {
  const history = useHistory();
  const selectRef = useRef();
  const clientname = window.sessionStorage.getItem("clientname");
  const finalClientname = clientname.split(" ");
  const firstName = finalClientname[ 0 ].toString();
  const lastName = finalClientname[ 1 ].toString();
  const [ columns, setColumns ] = useState([]);
  const [ showEmojiPicker, setShowEmojiPicker ] = useState(false);
  const [ val, setVal ] = useState("");
  const [ columns1, setColumns1 ] = useState([]);
  const [ columns2, setColumns2 ] = useState([]);
  const stockData = useSelector((state) => state.user.stockAvailable);
  const createOrder = useSelector((state) => state.user.createOrder);
  const dispatch = useDispatch();
  const userId = window.sessionStorage.getItem("userid");
  const [ awaitData, setAwaitData ] = useState([]);
  const [ waredata, setWaredata ] = useState([]);
  const [ searchinventory, setSearchinventory ] = useState("");
  const initialRender = React.useRef(true);
  const [ productdetails, setProductdetails ] = useState();
  const teamid = window.sessionStorage.getItem("teamId");
  const [ userdata, setuserdata ] = useState();
  const userDatas = useSelector((state) => state.user.fetchUserDetails);
  const warehouseDetails = useSelector((state) => state.user.fetchWarehouse);
  const fetchComment = useSelector((state) => state.user.fetchComment);
  const fetchDeleteCommentData = useSelector((state) => state.user.deleteComment);
  const [ finaluserdata, setuserData ] = useState([]);
  const [ wareArray, setWareArray ] = useState([]);
  const [ iteratorVal, setIteratorVal ] = useState(0);
  const [ StatusPopup, setStatusPopup ] = useState(false);
  const [ productAdded, setProductAdded ] = useState([]);
  const [ userArray, setUserArray ] = useState([]);
  const [ dueDate, setDueDate ] = useState(new Date());
  const [ deliveryDate, setDeliveryDate ] = useState("");
  const [ loading, setLoading ] = useState(true);
  const [ loading1, setLoading1 ] = useState(false);
  const [ statusId, setStatusId ] = useState("");
  const [ orderCreated, setOrderCreated ] = useState(false);
  const [ createdOrderId, setCreatedOrderId ] = useState("");
  const [ createdStatusId, setCreatedStatusId ] = useState("");
  const [ searchPartValue, setSearchPartValue ] = useState("");
  const [ qtyValueAddPart, setQtyValueAddPart ] = useState("");
  const [ modifiedContacts, setModifiedContacts ] = useState([]);
  const [ attachments, setAttachments ] = useState("");
  const [ attachedFileName, setAttachedFileName ] = useState("");
  const [ draggableId, setDraggableId ] = useState("");
  const [ selectedPart, setSelectedPart ] = useState("");




  const [ customPartList, setCustomPartList ] = useState([
    {
      partid: "",
      name: "",
      instock: "",
      partname: "",
      orderquantity: 0,
      isChecked: false
    },
  ]);




  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const finDate = new Date(date);
  var date = finDate.toString().split(" ");
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[ source.droppableId ];

      const destColumn = columns[ destination.droppableId ];
      const sourceItems = [ ...sourceColumn.items ];
      const addedArr = [ ...productAdded ];
      var count = 0;
      setInputFields([]);
      setFetchCommitData([]);
      if (count == 0) {
        const destItems = [ ...destColumn.items ];
        addedArr.push(sourceItems[ source.index ]);
        addedArr[ addedArr.length - 1 ].quantity = 0;
        const removed = sourceItems.splice(source.index, 1);
        let NewDate = sourceItems.filter((i) => i.orderid !== removed.orderid);

        setColumns({
          ...columns,
          [ destination.droppableId ]: {
            // ...destColumn,
            items: addedArr,
          },
          // [source.droppableId]: {
          //     // ...destColumn,
          //     items: NewDate,
          // },
        });
      }

      const destItems = [ ...destColumn.items ];
      const removed = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
    }
    // else {
    //     const column = columns[source.droppableId];

    //     const copiedItems = [...column.items];
    //     const [removed] = copiedItems.splice(source.index, 1);
    //     copiedItems.splice(destination.index, 0, removed);
    //     setColumns({
    //         ...columns,
    //         [source.droppableId]: {
    //             // ...column,
    //             items: copiedItems,
    //         },
    //     });
    // }
    setDraggableId(result.draggableId)
  };
  const pagenumber = "0";
  useEffect(() => {
    dispatch(fetchwarehouse());
    dispatch(fetchUserDetails(1, teamid));
    dispatch(stockAvailable("08da2f31-46b4-4ce8-80b3-fdffac7a3080"));
    dispatch(fetchSuppliers());
    dispatch(FetchParts());
  }, [ stockAvailable, fetchwarehouse, fetchSuppliers, FetchParts ]);

  const [ fetchCommitData, setFetchCommitData ] = useState([]);

  useEffect(() => {
    if (columns[ 1 ]?.items?.length > 0) {
      dispatch(fetchComments(columns[ 1 ].items[ 0 ].orderid, "purchase"));
      // setTimeout(() => {
      setFetchCommitData(fetchComment?.data);
      // }, 1000);
    } else {
      setFetchCommitData([]);
    }
  }, [ columns ]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (createOrder != null) {
        if (createOrder.status == true) {
          setLoading1(false);
          setOrderCreated(true);
          setCreatedOrderId(createOrder.data.orderid.split("-")[ 4 ]);
          setCreatedStatusId(createOrder.data.statusid);
        } else {
          setLoading1(false);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ createOrder ]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (userDatas != null) {
        if (userDatas.status == true) {
          setuserData(
            userDatas.data.filter((item, userid) => item.userdetails.length > 0)
          );
          const columnsFromBackend = {
            [ "1" ]: {
              name: "Requested",
              items: [],
            },
            [ "2" ]: {
              name: "To do",

              items: userDatas.data.filter(
                (item, userid) => item.userdetails.length > 0
              ),
            },
          };
          setColumns2(columnsFromBackend);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ userDatas ]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (warehouseDetails != null) {
        if (warehouseDetails.status == true) {
          const columnsFromBackend = {
            [ "1" ]: {
              name: "Requested",
              items: [],
            },
            [ "2" ]: {
              name: "To do",

              items: warehouseDetails.data,
            },
          };
          setColumns1(columnsFromBackend);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ warehouseDetails ]);

  const PurchasingOrders = useSelector(
    (state) => state.user.fetchPurchasingOrder
  );

  const [ purchaseOrders, setPurchaseOrders ] = useState([]);

  useEffect(() => {
    dispatch(fetchPurchasingOrders(searchinventory));
  }, [ fetchPurchasingOrders ]);

  useEffect(() => {
    if (!initialRender.current) {
      if (stockData != null) {
        if (stockData.status == true) {
          setProductdetails(stockData.data);

          const columnsFromBackend = {
            [ "1" ]: {
              name: "Requested",
              items: [],
            },
            [ "2" ]: {
              name: "To do",
              items: stockData.data,
            },
          };
          setColumns(columnsFromBackend);
        }
        setLoading(false);
      } else {
      }

      if (PurchasingOrders != null) {
        if (PurchasingOrders.status == true) {
          const columnsFromBackend = {
            [ "1" ]: {
              name: "Requested",
              items: [],
            },
            [ "2" ]: {
              name: "To do",
              items: PurchasingOrders.data,
            },
          };
          setColumns(columnsFromBackend);
          // setPurchaseOrders(PurchasingOrders.data);
        }
        setLoading(false);
      } else {
      }
    } else {
      initialRender.current = false;
    }
  }, [ stockData, PurchasingOrders ]);

  const handleClose = (statusId, destination) => {
    setStatusPopup(false);
    setStatusId(statusId);
  };

  const handleClose1 = () => {
    setOrderCreated(false);
    history.push("/home");
  };
  const handleAddOrder = () => {
    setOrderCreated(false);
    setProductAdded([]);
    setUserArray([]);
    setWareArray([]);
    setStatusId("");
    setDeliveryDate("");
  };

  const handlecheckbox = (e, item, i, mainItems) => {
    if (e.target.checked === true) {
      setColumns((prevState) => {
        const NewState = { ...prevState };
        const columnData = NewState[ 1 ].items[ 0 ].partdetails[ i ];
        NewState[ 1 ].items[ 0 ].partdetails[ i ] = {
          ...columnData,
          instock: columnData.instock + columnData.orderquantity,
          isChecked: true,
        };
        return NewState;
      });

      dispatch(UpdateStore(item.partid.toString(), item.orderquantity));
    } else {
      setColumns((prevState) => {
        const NewState = { ...prevState };
        const columnData = NewState[ 1 ].items[ 0 ].partdetails[ i ];
        NewState[ 1 ].items[ 0 ].partdetails[ i ] = {
          ...columnData,
          instock: columnData.instock - columnData.orderquantity,
          isChecked: false,
        };
        return NewState;
      });
      dispatch(UpdateStore(item.partid.toString(), -item.orderquantity));
    }
  };

  const [ inputFields, setInputFields ] = useState([]);
  const FetchPartsdata = useSelector((state) => state.user.fetchParts);
  const [ searchsupplier, setSearchsupplier ] = useState([]);
  const [ supplier, setSupplier ] = useState("");
  const [ supplierDetails, setSupplierDetails ] = useState([]);

  const [ partData, setPartData ] = useState([]);

  useEffect(() => {
    let NewData = [];
    FetchPartsdata?.data?.forEach((element) => {
      NewData.push({
        ...element,
        id: element.partid,
        name: element.partname,
      });
    });
    setPartData(NewData);
  }, [ FetchPartsdata ]);




  const [ showMore, setShowMore ] = useState(false);
  const [ commitData, setCommitData ] = useState("");

  const getRenderedItems = () => {
    let newComment = [];
    fetchCommitData?.forEach((element) => {
      let fileName = element?.attachment?.substring(element.attachment.lastIndexOf('/') + 1);
      newComment.push({
        ...element,
        fileName,
      });
    });
    if (showMore) {
      return newComment;
    }
    return newComment?.slice(0, MAX_ITEMS);
  };

  const handlerAdvisedMore = () => {
    setShowMore((prev) => !prev);
  };

  const hanlderCancel = () => {
    history.push("/Inventory");
  };

  const handlerSendCommit = () => {
    let trimText = commitData.trim();
    if (trimText !== "" || attachments !== "" && columns[ 1 ].items.length > 0) {
      dispatch(
        addComments(userId, columns[ 1 ].items[ 0 ].orderid, commitData, "purchase", attachments)
      );

      setTimeout(() => {
        dispatch(fetchComments(columns[ 1 ].items[ 0 ].orderid, "purchase"));
      }, 1000);
      setFetchCommitData(fetchComment?.data);
    }
    setCommitData("");
    setVal("")
    setAttachedFileName("")
    setAttachments("")
  };

  useEffect(() => {
    setFetchCommitData(fetchComment?.data);
  }, [ fetchComment ]);

  const hanlderClick = () => {
    if (columns[ 1 ].items.length > 0) {
      const OrderData = columns[ 1 ].items[ 0 ];
      const PartData = OrderData.partdetails;
      let isAllCheck = PartData.filter((i) => i.isChecked === true);

      if (isAllCheck.length > 0 && isAllCheck.length === PartData.length) {
        dispatch(
          PurchaseOrderDelivered(
            OrderData.orderid,
            "08da2f31-46b4-4ce8-80b3-fdffac7a3065"
          )
        );
        hanlderCancel();
      }
    }
  };

  const searchPart = (e) => {
    setSearchPartValue(e.target.value)
  }

  const onChangeHandler = (e, mainItems) => {
    setSelectedPart(e)
  };

  const submitPartNew = (e, mainItems) => {
    let isExited = customPartList.findIndex(item => item.partid === selectedPart.partid);
    if (isExited === -1) {
      let payload = {
        partid: selectedPart.partid,
        name: selectedPart.name,
        instock: selectedPart.instock,
        partname: selectedPart.partname,
        orderquantity: qtyValueAddPart ? qtyValueAddPart : 0,
        isChecked: false
      };
      setCustomPartList([ ...customPartList, payload ])
    } else {
      let payload = {
        partid: selectedPart.partid,
        name: selectedPart.name,
        instock: selectedPart.instock,
        partname: selectedPart.partname,
        orderquantity: qtyValueAddPart ? qtyValueAddPart : 0,
        isChecked: false
      };
      customPartList.splice(isExited, 1, payload);
    }

    dispatch(AddPartToPurchaseOrder(userId, mainItems[ 1 ]?.items[ 0 ]?.orderid, selectedPart.partid.toString(), parseInt(qtyValueAddPart)));
    setSearchPartValue("")
    setQtyValueAddPart("")
    selectRef.current.clearValue()
  };



  const handlecheckboxAddPart = (e, item, i, mainItems) => {
    if (e.target.checked === true) {
      const columnData = customPartList[ i ];
      customPartList[ i ] = {
        ...columnData,
        instock: columnData.instock + parseInt(columnData.orderquantity),
        isChecked: true,
      };
      let newData = [ ...customPartList ]
      setCustomPartList(newData)
      dispatch(UpdateStore(item.partid.toString(), parseInt(item.orderquantity)));

    } else {
      const columnData = customPartList[ i ];
      customPartList[ i ] = {
        ...columnData,
        instock: columnData.instock - parseInt(columnData.orderquantity),
        isChecked: false,
      };
      let newData = [ ...customPartList ]
      setCustomPartList(newData)
      dispatch(UpdateStore(item.partid.toString(), parseInt(-item.orderquantity)));

    }
  };


  const handleFileChange1 = (e) => {
    const target = e.target.files[ 0 ];
    let formData = new FormData();
    formData.append("file", target);
    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setAttachments(response.data[ 0 ])
          setAttachedFileName(target.name)
        } else {
        }
      })
      .catch(function (error) { });
  };

  const [ commentDltPopup, setCommentDltPopup ] = useState(false);
  const [ commentid, setCommentid ] = useState("");

  const handleClose2 = () => setCommentDltPopup(false);

  const handleCommentDelete = (val) => {
    setCommentDltPopup(false);
    dispatch(deleteComment(commentid));

    // history.goBack();
  };

  useEffect(() => {
    if (columns[ 1 ]?.items?.length > 0) {
      dispatch(fetchComments(columns[ 1 ].items[ 0 ].orderid, "purchase"));
      // setTimeout(() => {
      setFetchCommitData(fetchComment?.data);
      // }, 1000);
    } else {
      setFetchCommitData([]);
    }
  }, [ fetchDeleteCommentData.status === true ]);


  return (
    <div className="container-fluid Warehouse">
      <Header />
      {commentDltPopup && (
        <DeleteCommentPopup
          show={commentDltPopup}
          // onHide={() => setModalShow(false)}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          // handleCancel={handlePopupCancel}
          handleDelete={handleCommentDelete}
        />
      )}

      <Row className="justify-content-md-center">
        <Col lg={1}></Col>
        <Col lg={11} md={8}>
          <h3 className="py-3 ps-2 themecolor header-title-sk">    Inventory | Add to Warehouse</h3>
          {loading == true ? (
            <Loader />
          ) : (
            <div
              className="row justify-content-sm-center justify-content-center"
              style={
                productAdded.length < 3
                  ? {
                    display: "flex",
                    //   justifyContent: "center",
                    maxHeight: "100%",
                  }
                  : {
                    display: "flex",
                    //   justifyContent: "center",
                    maxHeight: productAdded.length * 25 + 239,
                  }
              }
            >
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
              >
                {Object.entries(columns).map(([ columnId, column ], index) => {
                  return (
                    <div
                      className={
                        index === 1
                          ? "col-lg-6 col-xl-5 col-xxl-8 col-md-12 col-sm-10 col-10"
                          : "col-lg-6 col-xl-5 col-xxl-4 col-md-12 col-sm-10 col-10"
                      }
                      key={columnId}>
                      <div style={{ margin: 8 }}>
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}

                              >
                                {index == 0 && (
                                  <div className="row pe-0">
                                    <div className="backOrder flex-wrap justify-content-md-center justify-content-lg-between pe-5 align-items-center position-relative">
                                      <div className="d-flex backOrder-title gap-3 align-items-center">
                                        <img src={warehouseIcon} className="" />
                                        <h3>Warehouse</h3>
                                      </div>
                                      <div className="backOrder-right-side">
                                        <h3>Hangar 01</h3>
                                      </div>
                                    </div>
                                    <div className="card pb-3  px-4 cardBorder-data cardBorder1">
                                      <div className="col-12 align-self-center custom-row-warhorse">
                                        <div
                                          className="row"
                                          style={{
                                            paddingBlock: "30px",
                                          }}
                                        >
                                          {column?.items.length === 0 && (
                                            <div className="w-100 ps-5 ps-backOrder pe-5 d-flex align-items-center justify-content-between" >
                                              <div className="d-flex ms- ps-2 backOrder-title justify-content-between align-items-center" >
                                                <img
                                                  style={{
                                                    width: "31px",
                                                    height: "31px",
                                                  }}
                                                  src={Advisedpart}
                                                />
                                                <h3 className="ps-3">
                                                  Purchase Orders
                                                </h3>
                                              </div>
                                              <div className="backOrder-right-side-data"></div>
                                            </div>
                                          )}
                                          {column?.items?.map((item) => {
                                            return (
                                              <>
                                                <div className="w-100 ps-5 ps-backOrder pe-5 d-flex align-items-center justify-content-between">
                                                  <div className="d-flex backOrder-title justify-content-between align-items-center">
                                                    <img
                                                      style={{
                                                        width: "35px",
                                                      }}
                                                      src={orderIcon}
                                                    />
                                                    <h3>Purchase Orders</h3>
                                                  </div>
                                                  <div className="backOrder-right-side-data">
                                                    <h3>{item.ordercode}</h3>
                                                  </div>
                                                </div>
                                                <div className="part-section col-10" style={{
                                                  marginTop: "30px",
                                                }}>
                                                  <div className="heading-title">
                                                    <h2 className="heading">
                                                      Parts
                                                    </h2>
                                                    <div className="part-calc">
                                                      <h4>Qty</h4>
                                                      <h4>chk</h4>
                                                      <h3>Stock</h3>
                                                    </div>
                                                  </div>
                                                  {item.partdetails.map(
                                                    (part, i) => {
                                                      return (
                                                        <div
                                                          key={part.partid}
                                                          className="part-contents"
                                                        >
                                                          <div className="heading-title">
                                                            <h2 className="heading">
                                                              {part.partname}
                                                            </h2>
                                                            <div className="part-calc">
                                                              <h4 className="asc55 ps-3">
                                                                {/* {part.orderquantity} */}
                                                                <EuropeanInput
                                                                  hidePreview={true}
                                                                  withoutSymbol={true}
                                                                  value={part.orderquantity}
                                                                  className="asc66"
                                                                />
                                                              </h4>
                                                              <div className="custom-radio ps-4">
                                                                <input
                                                                  type={
                                                                    "checkbox"
                                                                  }
                                                                  checked={
                                                                    part?.isChecked
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handlecheckbox(
                                                                      e,
                                                                      part,
                                                                      i,
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                              <h3>
                                                                {/* {part.instock} */}
                                                                <EuropeanInput
                                                                  hidePreview={true}
                                                                  withoutSymbol={true}
                                                                  value={part.instock}
                                                                  className="asc77 ps-4"
                                                                />
                                                              </h3>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}

                                                </div>



                                              </>
                                            );
                                          })}
                                          {columns[ 1 ]?.items.length > 0 ?
                                            <div className="add-part-filter col-10">
                                              {customPartList.length ? customPartList && customPartList.map((part, i) => {
                                                return (
                                                  part.name !== "" ?
                                                    <div className="part-contents">
                                                      <div className="heading-title">
                                                        <h2 className="heading">{part.name}</h2>
                                                        <div className="part-calc">
                                                          <h4>
                                                            {part.orderquantity}
                                                          </h4>
                                                          <div className="custom-radio">
                                                            <input type={"checkbox"} checked={part?.isChecked ? true : false} onChange={(e) => handlecheckboxAddPart(e, part, i, columns)} />
                                                          </div>
                                                          <h3>
                                                            {part.instock}
                                                          </h3>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    : ""
                                                );
                                              }
                                              ) : ""}
                                              <div className="part-contents">
                                                <div className="heading-title">
                                                  <h2 className="select-part">
                                                    <Select
                                                      ref={selectRef}

                                                      classNamePrefix="sk-custom-select"
                                                      placeholder={"Select part..."}
                                                      className={"sk-custom-container"}
                                                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                      options={partData}
                                                      getOptionLabel={(option) => option.name}
                                                      getOptionValue={(option) => option.id}
                                                      onChange={(value) => onChangeHandler(value)}

                                                    />
                                                  </h2>
                                                  <div className="part-calc">
                                                    <h4>
                                                      <input type="number" value={qtyValueAddPart} onChange={(e) => setQtyValueAddPart(e.target.value)} style={{
                                                        width: "40px",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        color: "#003766",
                                                        textAlign: "center",
                                                        border: "none"
                                                      }} />
                                                    </h4>
                                                    <h3>

                                                    </h3>
                                                  </div>
                                                  <span className="submitPartNew" onClick={(e) => submitPartNew(e, columns)}>Add</span>
                                                </div>
                                              </div>

                                              {searchPartValue ?
                                                <div className="custom-select-box" >
                                                  <div className={`custom-select-outer`}>
                                                    {partData.filter((col) => col?.name.match(new RegExp(searchPartValue, "i"))).map((col, i) => {
                                                      return (
                                                        <>
                                                          <div className={`list-select-box`} key={index} onClick={() => onChangeHandler(col, columns)}>
                                                            {col?.name}
                                                          </div>
                                                        </>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                                : ""}
                                            </div>
                                            : ""}
                                        </div>

                                        <div className="comment-section1 d-flex justify-content-center align-items-center flex-column gap-3">
                                          <div className="comment-section d-flex justify-content-center align-items-center flex-column commentinput gap-0">

                                            {getRenderedItems() != null ? (


                                              <div className="commit w-100">

                                                {columns[ 1 ]?.items.length > 0 ?
                                                  <>
                                                    <div className="commit-title">
                                                      <h2>Comments </h2>
                                                      <p
                                                        onClick={() =>
                                                          handlerAdvisedMore()
                                                        }
                                                      >
                                                        {showMore
                                                          ? "show less details…"
                                                          : "Show more details…"}
                                                      </p>
                                                    </div>


                                                    <div className="d-flex justify-content-center align-items-center w-100 gap-4 mb-2">
                                                      <div className="d-flex justify-content-center align-items-center btn profileButton12 me-1 ">
                                                        {clientname == undefined ||
                                                          clientname == null
                                                          ? "SD"
                                                          : firstName[ 0 ].toUpperCase() +
                                                          "" +
                                                          lastName[ 0 ].toUpperCase()}
                                                      </div>
                                                      <form className="d-flex justify-content-center align-items-center gap-2">
                                                        <input
                                                          type={"text"}

                                                          value={val}
                                                          onChange={(e) => {
                                                            setCommitData(
                                                              e.target.value
                                                            );
                                                            setVal(e.target.value);
                                                          }}
                                                          placeholder="Write a comment…"
                                                          onKeyPress={(e) => {
                                                            if (
                                                              e.key === "Enter" &&
                                                              e.shiftKey
                                                            ) {
                                                            } else if (e.key === "Enter") {

                                                            } else {
                                                              return;
                                                            }
                                                          }}
                                                        ></input>
                                                        <i
                                                          className="bi bi-emoji-smile"
                                                          onClick={() =>
                                                            setShowEmojiPicker(
                                                              !showEmojiPicker
                                                            )
                                                          }
                                                          style={{
                                                            position: "relative",

                                                            left: "10px",
                                                            top: 8,
                                                            cursor: "pointer",
                                                          }}
                                                        ></i>
                                                        {showEmojiPicker && (
                                                          <div className="ttt emoji-picker-style">
                                                            <EmojiPicker
                                                              emojiStyle="twitter"
                                                              onEmojiClick={(e) => {
                                                                setVal(
                                                                  (val) =>
                                                                    val + e.emoji
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                        <span className="ps-1">
                                                          <input
                                                            type="file"
                                                            id="add-attachment"
                                                            onChange={(e) => {
                                                              handleFileChange1(e);
                                                            }}
                                                            style={{ display: "none" }}
                                                          />
                                                          <label
                                                            htmlFor="add-attachment"
                                                            className="mt-3 ms-2 CrsrPointr"
                                                          >
                                                            <i className="bi bi-paperclip "></i>
                                                          </label>
                                                        </span>
                                                        <img
                                                          src={leftarrow}
                                                          className="me-3 mt-4 CrsrPointr"
                                                          width="22px"
                                                          alt="leftarrow"
                                                          onClick={() =>
                                                            handlerSendCommit()
                                                          }
                                                        />

                                                      </form>
                                                    </div>
                                                  </>
                                                  : ""}

                                                <div className="d-flex justify-content-start align-items-center commentinput gap-4">
                                                  {attachedFileName.length ?
                                                    <span
                                                      role="button"
                                                      style={{
                                                        color: "rgb(0, 55, 102)",
                                                        fontWeight: "500",
                                                        marginLeft: "135px",
                                                        marginTop: "-5px",
                                                        marginBottom: "10px"
                                                      }}
                                                    >
                                                      {attachedFileName.length > 20 ? attachedFileName.slice(0, 20) + "..." : attachedFileName}
                                                    </span>
                                                    : ""}
                                                </div>

                                                {getRenderedItems().map(
                                                  (comment) => {
                                                    return (

                                                      <div className="d-flex justify-content-center align-items-center w-100 gap-4 mb-2">
                                                        <div className="d-flex justify-content-center align-items-center btn profileButton12  me-1">
                                                          {clientname ==
                                                            undefined ||
                                                            clientname == null
                                                            ? "SD"
                                                            : comment.firstname[ 0 ].toUpperCase() +
                                                            "" +
                                                            comment.lastname[ 0 ].toUpperCase()}
                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center gap-2"
                                                          style={{ width: "63%" }}>
                                                          {comment?.message ?
                                                            <div className="commit-description commit-description1">
                                                              <h2 className="d-flex justify-content-between">
                                                                {
                                                                  comment?.message
                                                                }

                                                                <div className="ps-">
                                                                  {userId === comment.userid && (
                                                                    <img
                                                                      src={DltIcon}
                                                                      className="count_Dlt"
                                                                      alt="pensil"
                                                                      onClick={() => {
                                                                        // dispatch(deleteComment(commentid))
                                                                        console.log("tes2", comment.commentid)
                                                                        setCommentid(comment.commentid);
                                                                        setCommentDltPopup(true);
                                                                      }}
                                                                    />
                                                                  )}
                                                                </div>

                                                              </h2>
                                                              <p><a target={"_blank"} href={comment?.attachment} style={{ color: "#003766" }} >{comment?.fileName}</a></p>
                                                              <h5 className="timeh5">
                                                                {moment(
                                                                  comment?.timestamp
                                                                ).format(
                                                                  "DD.MM.YYYY"
                                                                ) +
                                                                  " " +
                                                                  moment(
                                                                    comment?.timestamp
                                                                  ).format(
                                                                    "HH:mm"
                                                                  ) +
                                                                  "H"}
                                                              </h5>
                                                            </div>
                                                            :
                                                            <div className="col-12 col-xl-112 col-lg-12 col-md-12 col-sm-12 align-self-center comment_Deleted"
                                                            >
                                                              <p className="mb-0 p-2">
                                                                This message was deleted
                                                              </p>
                                                            </div>
                                                          }

                                                        </div>

                                                      </div>

                                                    );
                                                  }
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                          </div>
                                        </div>

                                      </div>
                                      <div className="comment-section d-flex justify-content-center align-items-center">
                                        <button
                                          className=""
                                          onClick={() => hanlderCancel()}
                                        >
                                          CANCEL
                                        </button>
                                        <button onClick={() => hanlderClick()}>
                                          ADD
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                                }

                                {index == 1 && (
                                  <div className="row me-3 ms-3 ms-xl-4 purchasingOrder-section table-responsive">
                                    <div
                                      className="row warehouses-table-data"
                                      style={{
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <h4>
                                        Purchasing Orders awaiting Delivery
                                      </h4>
                                      <table className="table scroll">
                                        <thead></thead>
                                        <tbody>
                                          {column.items.map((item, index) => {

                                            return (
                                              <Draggable
                                                key={item?.orderid}
                                                draggableId={item?.orderid?.toString()}
                                                index={index}
                                              >
                                                {(provided, snapshot) => {

                                                  return (
                                                    <div
                                                      className={`"tableHover fntClr" ${item?.orderid == draggableId ? "draggedOn" : ""}`}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={{
                                                        ...provided
                                                          .draggableProps.style,
                                                        background:
                                                          snapshot.isDragging
                                                            ? "#daf4fa"
                                                            : "",
                                                        boxShadow:
                                                          snapshot.isDragging
                                                            ? "0px 5px 15px #ddd"
                                                            : "unset",
                                                        borderRadius:
                                                          snapshot.isDragging
                                                            ? "5px"
                                                            : "0px",
                                                        margin:
                                                          snapshot.isDragging
                                                            ? "12px"
                                                            : "0px",
                                                      }}
                                                    >
                                                      <tr className="tableHover fntClr">
                                                        <td className="text-start">
                                                          <div className="d-flex align-items-center w-max-content">
                                                            <img
                                                              style={{
                                                                width: "27px",
                                                              }}
                                                              src={Group1093}
                                                            />
                                                            <h6>
                                                              Purchase Order
                                                            </h6>
                                                          </div>
                                                        </td>
                                                        <td
                                                          className=""
                                                          style={{
                                                            minWidth: "174px",
                                                          }}
                                                        >
                                                          <div className="w-max-content">
                                                            {item?.ordercode}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="w-max-content">
                                                            <h5
                                                              className="m-0 textDeco"
                                                              data-toggle="tooltip"
                                                              data-placement="right"
                                                              title="Copy to Clipboard"
                                                            >
                                                              {
                                                                item?.suppliername
                                                              }
                                                            </h5>
                                                          </div>
                                                        </td>
                                                        <td className="">
                                                          <div className="w-max-content">
                                                            <h5 className="m-0">
                                                              <span>
                                                                <img
                                                                  src={calender}
                                                                  className="calInv"
                                                                />
                                                              </span>{" "}
                                                              {formatDate(
                                                                item?.deliverydate
                                                              )}
                                                            </h5>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </div>
                                                  );
                                                }}
                                              </Draggable>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </div>
                  );
                })}
              </DragDropContext>
            </div>
          )
          }
        </Col >
      </Row >
      {StatusPopup && (
        <MoveCard handleClose={handleClose} position={true} statusId="" />
      )}
      {
        orderCreated && (
          <OrderCreated
            handleCancel={handleClose1}
            handleAdd={handleAddOrder}
            orderId={createdOrderId}
            statusId={createdStatusId}
          />
        )
      }
    </div >
  );
}

export default Warehouse;

