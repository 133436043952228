import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Group91 from "../../assets/icons/Group91.png";
import "../../styles/local/addSupplier.css";
import Group62 from "../../assets/icons/Group62.svg";
import { addSupplier } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DiscardAlert from "../../components/dialogs/Discard";
let c = 0;
const AddSupplier = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ show, setShow ] = useState([ { contact: "", id: 1 } ]);
  const [ display, setDisplay ] = useState([ { contact: "", id: 1 } ]);
  const UserId = window.sessionStorage.getItem("userid");
  const companyId = window.sessionStorage.getItem("compId");
  const [ Name, setName ] = useState("");
  const [ contactCount, setContactCount ] = useState(1);
  const [ address1, setAddress1 ] = useState("");
  const [ address2, setAddress2 ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const [ notes, setNotes ] = useState("");

  const [ contactNumber, setContactNumber ] = useState("");
  const [ contactName, setContactName ] = useState("");
  const [ contactPhoneNumber, setContactPhoneNumber ] = useState("");
  const [ contactEmail, setContactEmail ] = useState("");
  const [ emailInvalid, setEmailInvalid ] = useState(false);
  const [ emailInvalid2, setEmailInvalid2 ] = useState(false);

  const [ contacts, setContacts ] = useState([
    {
      name: "",
      phonenumber: "",
      email: "",
      companyid: companyId,
    },
  ]);

  const handleChange = (id, field, val) => {
    const modifiedContacts = contacts;
    modifiedContacts[ id ][ field ] = val;

    setContacts(modifiedContacts);
  };

  const handleClick = () => {
    setShow([ ...show, { contact: "", id: contactCount + 1 } ]);
    setContactCount(contactCount + 1);
    setContactNumber(contactNumber + 1);

    const modifiedContacts = contacts;

    modifiedContacts.push({
      name: "",
      phonenumber: "",
      email: "",
      companyid: companyId,
    });

    setContacts(modifiedContacts);
  };

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function hasNonBlankValidEmail(contacts) {
    for (const contact of contacts) {
      if (!contact.email || !isValidEmail(contact.email)) {
        return false; // Return false if the email is blank or invalid
      }
    }
    return true; // Return true if all emails are non-blank and valid
  }

  const handleAdd = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isAllContactValidate = hasNonBlankValidEmail(contacts);

    if (isAllContactValidate) {

      if (!re.test(email)) {
        setEmailInvalid(true);
      }

      // if (!re.test(email)){
      //   setEmailInvalid2(true);
      // }

      else {
        dispatch(
          addSupplier(
            UserId,
            Name,
            address1,
            address2,
            email,
            phoneNumber,
            notes,
            contacts.filter(({ name, phonenumber, email }) => !(name === "" && phonenumber === "" && email === ""))
          )
        );
        setTimeout(() => {
          history.goBack();
          // window.location.reload();
        }, 1000);

        e.preventDefault();
      }
    }
  };
  useEffect(() => {
    setContacts(contacts);
  }, [ contacts ]);

  useEffect(() => {
    setDisplay(show);
  }, [ show ]);

  const [ cancel, setCance ] = useState(false);
  const [ msg, setMsg ] = useState("");
  //////// ASC Without change START /////////////////
  const [ change, setChange ] = useState("");
  const [ changeComp, setChangeComp ] = useState("");
  //////// ASC Without change end ///////////////////

  const handleCancelBtn = () => {
    if (change !== changeComp) {
      setCance(true);
    } else {
      history.goBack();
      // window.location.reload();
      // setPopups(false);
    }
    // setCance(true);
    setMsg(
      "The entered data will not be saved. Do you want to cancel adding new Supplier? "
    );
  };


  return (
    <>
      <Header />

      {cancel && (
        <DiscardAlert
          msg={<p style={{ paddingTop: "33px" }}>{msg}</p>}
          handleCancel={() => {
            setCance(false);
          }}
          handleOk={() => {
            // history.push("/supplier");
            history.goBack();
          }}
        />
      )}

      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-5">
            <h3 className="py-3 ps-2 themecolor header-title-sk">Suppliers</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-11 custCardCount">
              <div className="card cardHead">
                <div className="row px-4">
                  <h5 className="py-4 fntSupp2 text-sm-start text-center">
                    <span className="me-4">
                      <img src={Group91} className="countProicon" />
                    </span>
                    <span className="sup14">Suppliers</span>
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <div className="row ms-sm-4 me-sm-2 px-5">
                      <h3 className="mt-5 fntSupp1">Name</h3>
                      <input
                        className=" form-control suppInput mt-2 fntSupp4"
                        onChange={(e, value) => {
                          setName(e.target.value);
                          setChangeComp(e.target.value);
                        }}
                        type="text"
                      />

                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName mt-2 mt-xl-1 str12">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp22 ">
                              Street, House number
                            </span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => {
                              setAddress1(e.target.value);
                              setChangeComp(e.target.value);
                            }}
                          />
                        </div>
                        <div className=" box-contactName mt-2 mt-xl-1 str12">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp22 ">
                              Postal ZIP Code, Place
                            </span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => {
                              setAddress2(e.target.value);
                              setChangeComp(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName mt-2 mt-xl-1 str13">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp22">E-mail</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setChangeComp(e.target.value);
                              setEmailInvalid(false);
                            }}
                          />
                        </div>

                        <div className="ps-4 mt-2 mt-xl-1">
                          {emailInvalid == true && (
                            <p className="ms-1 ps-2" style={{ color: "red", fontSize: "14px" }}>
                              Email is invalid
                            </p>
                          )}
                        </div>

                        <div className=" box-contactName mt-2 mt-xl-1 str13">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp22">Telephone</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                              setChangeComp(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {contacts.map((item, index) => {

                      const validEmail = new RegExp(
                        "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
                      )

                      return (
                        <div
                          // key={item.phonenumber}
                          className="row ms-sm-4 me-sm-2 px-5"
                        >
                          <div className="col-12 col-sm-9 text-sm-start mt-sm-5 pt-sm-4">
                            <h3 className="   fntSupp33">Contact Name</h3>
                          </div>

                          <div className="col-12 col-sm-3 text-sm-end mt-sm-5 pt-sm-4 ">
                            {contacts.length - 1 === index ? (
                              <div
                                className="fntSupp32"
                                onClick={handleClick}
                                style={{ cursor: "pointer" }}
                              >
                                Add..
                              </div>
                            ) : (
                              <img
                                src={Group62}
                                onClick={() => {
                                  let data = [ ...contacts ];
                                  data.splice(index, 1);
                                  setContacts(data);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </div>

                          <input
                            className=" form-control suppInput mt-2 fntSupp4"
                            type="text"
                            onChange={(e) => {
                              handleChange(index, "name", e.target.value);
                              setContactName(e.target.value);
                              setChangeComp(e.target.value);
                            }}
                            value={item.name || ""}
                          // defaultValue={item.name}
                          />

                          <div className="col-12 col-lg-6">
                            <div className=" box-contactName mt-2 mt-xl-1 str14">
                              <div className="d-flex align-items-start">
                                <span className="fntSupp22">Telephone</span>
                              </div>

                              <input
                                type="text"
                                className="fntSupp4"
                                onChange={(e) => {
                                  handleChange(
                                    index,
                                    "phonenumber",
                                    e.target.value
                                  );
                                  setContactPhoneNumber(e.target.value);
                                  setChangeComp(e.target.value);
                                }}
                                value={item.phonenumber || ""}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className=" box-contactName mt-2 mt-xl-1 str13">
                              <div className="d-flex align-items-start">
                                <span className="fntSupp22">E-mail</span>
                              </div>

                              <input
                                type="text"
                                className="fntSupp4"
                                onChange={(e) => {
                                  handleChange(index, "email", e.target.value);
                                  setContactEmail(e.target.value);
                                  setChangeComp(e.target.value);
                                  setEmailInvalid2(false);
                                }}
                                value={item.email || ""}
                              />
                            </div>

                            <div className="ps-4 mt-2 mt-xl-1">
                              {/* {emailInvalid2 == true && (
                                <p className="ms-2 ps-2" style={{ color: "red", fontSize: "14px" }}>
                                  Email is invalid
                                </p>
                              )} */}

                              {!validEmail?.test(item.email) && item.email ?
                                <div className="ps-2" style={{ color: "red", fontSize: "14px" }}>
                                  Email is invalid
                                </div> : ""}

                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="col-12 col-lg-5">
                    <div className="row ps-4 ps-lg-0">
                      <h3 className="mt-5 fntSupp3">Notes</h3>
                      <div className="pe-5">
                        <textarea
                          className="form-control notesForm mt-2 fntSupp4"
                          onChange={(e) => {
                            setNotes(e.target.value);
                            setChangeComp(e.target.value);
                          }}
                          rows="14"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4 ">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 mt-5 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc w-100 h-100 p-2 "
                          style={{ fontSize: "15px" }}
                          // onClick={() => history.goBack()}
                          onClick={handleCancelBtn}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            Name == "" ||
                              address1 == "" ||
                              address2 == "" ||
                              email == "" ||
                              phoneNumber == "" ||
                              // for second Field
                              contactName == "" ||
                              contactEmail == ""
                              ? // notes == ""
                              "countBtnchange1 w-100 p-2"
                              : "countBtnchange w-100 p-2"
                          }
                          style={{ backgroundColor: "#003766", color: "white" }}
                          onClick={(e) => {
                            handleAdd(e);
                          }}
                          disabled={
                            Name == "" ||
                            address1 == "" ||
                            address2 == "" ||
                            email == "" ||
                            phoneNumber == "" ||
                            // for second Field
                            contactName == "" ||
                            contactEmail == ""
                            // notes == ""
                          }
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSupplier;
