import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../../components/Header";
import "../../styles/local/supplier.css";
import "../../styles/local/teamuser.css";
import teamMember from "../../assets/icons/teamMember.png";
import arrowsort from "../../assets/icons/arrowsort.png";
import pencil_icon from "../../assets/icons/pencil_icon.svg";
import Group62 from "../../assets/icons/Group62.svg";
import duplicate from "../../assets/icons/duplicate.svg";
import { fetchUsers, userSignup } from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteCard from "../../components/dialogs/DeleteCard";
import arrowup from "../../assets/icons/arrowup.svg";
import DeleteUser from "../../components/dialogs/UserDelete";
import Headerteam from "../../components/Headerteam";
import Pagination from "../../components/Pagination";
import { europeanFormat } from "../../utils/NumberFormatter";

const TeamUser = (props) => {
  const refNewStandardWageInput = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const UserId = window.sessionStorage.getItem("userid");
  const roleName = window.sessionStorage.getItem("rolename");
  const fetchUsersdata = useSelector((state) => state.user.fetchUsers);
  // const fetchUsersdata = useSelector((state) => state.user.fetchUsers);
  const fetchsuppliersbyid = useSelector(
    (state) => state.user.fetchSuppliersById
  );
  const [data, setData] = useState([]);
  const [teamsearch, setTeamsearch] = useState("");
  const [sortDate, setSortDate] = useState(true);
  const [pagenumber, setPageNumber] = useState(1);
  const [searchteamlist, setSearchteamlist] = useState("");
  const [supplier, setSupplier] = useState([]);
  const initialRender = React.useRef(true);
  const initialRender1 = React.useRef(true);
  const [contacts, setContacts] = useState([]);
  const [StatusPopup, setStatusPopup] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [isCalled, setIsCalled] = useState(false);
  const [isCalled1, setIsCalled1] = useState(false);
  const [data3, setData2] = useState([]);
  const [userDeleteid, setUserDeleteid] = useState("");
  const [sortarrow, setSortarrow] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const ROLES = useMemo(() => {
    const roles = {
      User: "08da2f30-beca-4c07-8e42-1311289b9e4b",
      Owner: "08da2f30-beca-4c07-8e42-1311289b9e4d",
      Manager: "08da2f30-beca-4c07-8e42-1311289b9e4c",
    };
    return roles;
  }, [])


  const sorting = () => {
    const data1 = [...data].sort((a, b) =>
      a.firstname < b.firstname ? -1 : 1
    );
    setData(data1);
    // dispatch(fetchSuppliers(setData(data)));
  };

  // const unsorting = () => {
  //   dispatch(fetchUsers(pagenumber));
  // };

  // useEffect(() => {
  //   dispatch(fetchUsers(pagenumber, searchteamlist));
  //   setIsCalled(false);
  //   setIsCalled1(false);
  // }, [isCalled, isCalled1]);

  useEffect(() => {
    if (searchteamlist >= 0) {
      dispatch(fetchUsers(pagenumber, searchteamlist));
    } else {
      dispatch(fetchUsers(0, searchteamlist));
    }
    // setIsCalled(false);
    // setIsCalled1(false);
  }, [searchteamlist]);

  const handleClose = () => {
    setStatusPopup(false);
  };

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchUsersdata != null) {
        if (fetchUsersdata.status == true) {
          setData(fetchUsersdata.data);
          setTotalCount(fetchUsersdata.totalCounts);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchUsersdata]);

  //   React.useEffect(() => {
  //     if (!initialRender1.current) {
  //       if (fetchsuppliersbyid != null) {
  //         if (fetchsuppliersbyid.status == true) {
  //           setSupplier(fetchsuppliersbyid.data);
  //         }
  //       }
  //     } else {
  //       initialRender1.current = false;
  //     }
  //   }, [fetchsuppliersbyid]);

  const [isActive2, setIsActive2] = useState("");

  const handlerSort = () => {
    setSortDate((prev) => !prev);
    setSortarrow((prev) => !prev);
    // setSortarrow("one"); for if we want to arrow up and down only onetime click
    handlerSortName();
  };

  const handlerSortName = () => {

    if (sortDate) {
      const sortedData = [...data].sort((a, b) => a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1,)

      setData(sortedData);
    }
    else {
      const sortedData = [...data].reverse((a, b) => a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1,)

      // or we can do it for desending order with another way
      // const sortedData = [ ...data ].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,)

      setData(sortedData);
      // dispatch(fetchProductStock(pagenumber));
    }
  };

  const HandelroleAddteam = () => {
    if (roleName !== "User") {
      history.push("/add-team-member");
    } else {
      alert("You Don't have access to Add Team member");
    }
  };

  const getPermission = (item) => {
    let permission = false;
    let sameUser = window.sessionStorage.getItem("userid") === item.userid;
    if (sameUser) {
      permission = true;
    } else {
      let currentRole = window.sessionStorage.getItem("roleid");
      switch (currentRole) {
        case ROLES.User:
          permission = false;
          break;
        case ROLES.Owner:
          permission = true;
          break;
        case ROLES.Manager:
          permission = item.roleid === ROLES.User
          break;
        default:
          break;
      }
    }
    return permission;
  }

  const isLeaveUserFun = (item) => {
    if (item.isavailabilityblock === 1) {
      const now = new Date()
      now.setHours(0, 0, 0, 0);
      now.getTime()
      // console.log("today date", now);

      //  item.useravailabilities?.map((val, index) => {
      //   let dd = new Date(val.startingdate).getTime()
      //   console.log( dd,"p.startingdate")
      // })


      //  let endingdate  =   new Date("2023-05-29T00:00:00").getTime() 
      //   let startingdate  =  new Date("2023-05-26T00:00:00").getTime()
      //  let ff = startingdate < now && now <=  endingdate

      const isValid = item.useravailabilities.filter((p) => (
        new Date(p.startingdate).getTime() <= now && now <= new Date(p.endingdate).getTime()))

      // const isValid = item.useravailabilities.filter(
      //   p => {
      //           new Date(p.startingdate) <= now && new Date(p.endingdate) >= now
      //   } );

      if (isValid.length > 0) {
        return true
      } else {
        return false
      }

    } else {
      return false
    }


  }


  return (
    <>
      {StatusPopup && (
        <DeleteUser
          handleClose={handleClose}
          userDeleteid={userDeleteid}
          pagenumber={pagenumber}
          // iscalled={isCalled}
          setIsCalled1={setIsCalled1}
        />
      )}
      <Headerteam
        setTeamsearch={setTeamsearch}
        searchteamlist={searchteamlist}
        setSearchteamlist={setSearchteamlist}
        pagenumber={pagenumber}
        data={data}
      />
      <div className="parent">
        <div className="outer mb-3">
          <div className={roleName !== "User" ? "add-supplier-row" : "user-add-supplier-row"}>
            <div className={roleName !== "User" ? "rounded-circle" : "d-none"}
              // onClick={() => history.push("/add-team-member")}
              onClick={HandelroleAddteam}
            >
              <img src={teamMember} className="Add1" />
              {/* <h5 className="text-white">ADD</h5> */}
            </div>
            <div className={roleName !== "User" ? "image-heading countFontclr" : "user-image-heading countFontclr"}>
              <h2 style={{ fontSize: "32px" }}>Team Member </h2>
            </div>
          </div>
          <div className="tableWrapper ">
            <table className="table table-hover ">
              <thead>
                <tr style={{ borderBottom: "1px solid #8080807a" }}>
                  <th className="fnt2">
                    <img
                      src={arrowsort}
                      alt="arrowicon"
                      className={sortarrow == "" ? "" : "ArrowUpDown13"}
                      height="14px"
                      width="4px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handlerSort()}
                    />
                    &nbsp; <span
                      // className={isActive2 === "one" || "" ? "fnt2 fw-bold toggelsort " : "fnt2 fnt12"}
                      className="fnt2"
                      onClick={() => {
                        dispatch(fetchUsers(pagenumber, searchteamlist));
                        setIsActive2("");
                      }}
                    >
                      Name
                    </span>

                  </th>
                  <th className="fnt1">Telephone</th>
                  <th className="fnt1">E-mail</th>

                  {/* {roleName != "user" &&
                    <th className="fnt1 ">Cost Hour </th>
                  } */}

                  <th className="fnt1 ">
                    {roleName !== "User" &&
                      <> Cost Hour </>
                    }
                  </th>

                  <th className="fnt1 ">Availability</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              {data
                // .filter(
                //   (item) =>
                //     item.firstname.toLowerCase().includes(teamsearch) ||
                //     item.phonenumber.toLowerCase().includes(teamsearch) ||
                //     item.email.toLowerCase().includes(teamsearch)
                // )
                .map((item, index) => {
                  let prmsn = getPermission(item);
                  let isLeaveUser = isLeaveUserFun(item);
                  console.log("prmsn", prmsn)
                  return (
                    <tbody key={`tbl-row-${index}`} className={`tabLine ${isLeaveUser}`}>
                      <tr>
                        <td
                          className={
                            // item.isavailabilityblock === 0
                            isLeaveUser
                              ? "fnttableuser1 ps-3"
                              : "fnttableuser ps-3"
                          }
                        >
                          {item.firstname + " " + item.lastname}
                        </td>
                        <td
                          className={
                            isLeaveUser
                              ? "fnttableuser1"
                              : "fnttableuser"
                          }
                        >
                          {item.phonenumber}
                        </td>
                        <td
                          className={
                            isLeaveUser
                              ? "fnttableuser1"
                              : "fnttableuser"
                          }
                        >
                          {item.email}
                        </td>

                        {/* {prmsn && roleName != "user" &&
                          <td
                            className={
                              item.isavailabilityblock === 0
                                ? "fnttableuser1 cost12"
                                : "fnttableuser cost12"
                            }
                          >
                            {item.costhour} EUR
                          </td>
                        } */}

                        <td
                          className={
                            isLeaveUser
                              ? "fnttableuser1 cost12"
                              : "fnttableuser cost12"
                          }
                        >
                          {prmsn && roleName !== "User" &&
                            <p>{europeanFormat(item.costhour)} EUR </p>
                          }

                        </td>

                        <td
                          className={
                            isLeaveUser
                              ? "fnttableuser1 cost13"
                              : "fnttableuser cost13"
                          }
                        >
                          {/* {item.isavailabilityblock} */}
                          {!isLeaveUser && (
                            <button className="type-bubbles-sm useravileble"></button>
                          )}
                          {isLeaveUser && (
                            <button className="type-bubbles-sm usernonavileble"></button>
                          )}
                        </td>

                        <td>
                          <div className="row">
                            <div className="col-9 col-xxl-7 fnttable ps-xxl-3">
                              {/* {item.phonenumber} */}
                            </div>
                            <div className="col-3 col-xxl-5">
                              {item.isavailabilityblock > 1 ? (
                                <span
                                  className={
                                    item.isavailabilityblock - 1 > 10
                                      ? "bubble1 fnt3"
                                      : "bubble fnt3"
                                  }
                                >
                                  +{item.isavailabilityblock - 1}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          {/* =====================USER ROLE START================================ */}

                          {/* {prmsn && item.roleid &&
                            <img
                              alt=""
                              src={pencil_icon}
                              className={roleName !== "User" ? "px-2" : "d-none"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // dispatch(fetchSuppliersById(item.supplierid));
                                window.sessionStorage.setItem(
                                  "memberid",
                                  item.userid
                                );
                                history.push("/update-team-member");
                              }}
                            />
                          } */}

                          {roleName === "Owner" &&
                            <img
                              alt=""
                              src={pencil_icon}
                              className="px-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // dispatch(fetchSuppliersById(item.supplierid));
                                window.sessionStorage.setItem(
                                  "memberid",
                                  item.userid
                                );
                                history.push("/update-team-member");
                              }}
                            />
                          }

                          {roleName === "User" &&
                            <img
                              alt=""
                              src={pencil_icon}
                              className={roleName === "User" ? "d-none" : "px-2"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // dispatch(fetchSuppliersById(item.supplierid));
                                window.sessionStorage.setItem(
                                  "memberid",
                                  item.userid
                                );
                                history.push("/update-team-member");
                              }}
                            />
                          }

                          {roleName === "Manager" &&
                            <img
                              alt=""
                              src={pencil_icon}
                              className={item.roleid === "08da2f30-beca-4c07-8e42-1311289b9e4c" ? "d-none" :
                                item.roleid === "08da2f30-beca-4c07-8e42-1311289b9e4d" ? "d-none" : "px-2"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // dispatch(fetchSuppliersById(item.supplierid));
                                window.sessionStorage.setItem(
                                  "memberid",
                                  item.userid
                                );
                                history.push("/update-team-member");
                              }}
                            />
                          }

                          {/* =====================USER ROLE END================================ */}

                          {/* =====================DELETE START================================== */}

                          {/* {prmsn && item.roleid &&
                            <img
                              alt=""
                              type="button"
                              src={Group62}
                              className={roleName != "User" ? "" : "d-none"}
                              // onClick={() => {
                              //   dispatch(fetchSuppliersById(item.supplierid));
                              // }}
                              onClick={() => {
                                setStatusPopup(true);
                                setUserDeleteid(item.userid);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          } */}


                          {roleName === "Owner" &&
                            <img
                              alt=""
                              type="button"
                              src={Group62}
                              className=""
                              // onClick={() => {
                              //   dispatch(fetchSuppliersById(item.supplierid));
                              // }}
                              onClick={() => {
                                setStatusPopup(true);
                                setUserDeleteid(item.userid);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          }

                          {roleName === "User" &&
                            <img
                              alt=""
                              type="button"
                              src={Group62}
                              className={roleName === "User" ? "d-none" : "px-2"}
                              // onClick={() => {
                              //   dispatch(fetchSuppliersById(item.supplierid));
                              // }}
                              onClick={() => {
                                setStatusPopup(true);
                                setUserDeleteid(item.userid);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          }

                          {roleName === "Manager" &&
                            <img
                              alt=""
                              type="button"
                              src={Group62}
                              className={item.roleid === "08da2f30-beca-4c07-8e42-1311289b9e4c" ? "d-none" :
                                item.roleid === "08da2f30-beca-4c07-8e42-1311289b9e4d" ? "d-none" : "px-2"
                              }
                              // onClick={() => {
                              //   dispatch(fetchSuppliersById(item.supplierid));
                              // }}
                              onClick={() => {
                                setStatusPopup(true);
                                setUserDeleteid(item.userid);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          }

                          {/* =====================DELETE END================================== */}
                        </td>
                      </tr>
                    </tbody>
                  )
                })}
            </table>
          </div>
          <Pagination
            currentPage={pagenumber}
            totalCount={totalCount}
            onPageChange={(page) => {
              dispatch(fetchUsers(page, searchteamlist))
              setPageNumber(page)
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TeamUser;
