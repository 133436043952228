import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Router from "./routes/Router";
import store from "./redux/store/store";
import { Provider } from "react-redux";
import Sidebar from "./pages/static/en/Sidebar";
import "./styles/global/style.css";
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import Landingpage from "./pages/static/en/LandinPage";

const App = () => {
  const [path, setPath] = useState("");
  const history = useHistory();
  useEffect(() => {
    setPath(history.location.pathname);
  }, []);
  return (
    <Provider store={store}>
      {path === "/" ? <Route exact path="/" component={Landingpage} /> : <Sidebar />}


    </Provider>
  );
};

export default App;
