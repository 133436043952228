export const europeanFormat = (value) => {
    if (!value) return "";
    return new Intl.NumberFormat('nl-NL', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value);
}

export const europeanFormatWithSymbol = (value) => {
    if (!value) return "";
    return new Intl.NumberFormat('nl-NL', { style: "currency", currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value);
}

export const stockQuntityFormat = (value) => {
    if (!value) return "";
    return new Intl.NumberFormat('nl-NL', { currency: 'EUR', minimumFractionDigits: 0 }).format(value);
}