import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import "../styles/local/cardorder.css";
import Orderpopup from "./dialogs/Orderpopup";
import speciaIcon from "../assets/icons/speciaIcon.svg";
import clienticon from "../assets/icons/usericon.png";
import Group1087 from "../assets/icons/Group1087.svg";
import calender from "../assets/icons/noun-calendar-857968.png";
import { DeleteIcon } from "../assets/icons";
import DeleteOrderPopup from "./dialogs/deleteOrder";
import { useDispatch, useSelector } from "react-redux";
import { deleteOrder } from "../redux/actions/action";

function CardOrder(props) {

  const { id, index, data, droppableId, error, noDraggable } = props;
  const dispatch = useDispatch();
  const deletedOrder = useSelector(s => s.user.deleteOrder);
  // const roleName = window.sessionStorage.getItem("rolename");
  const [cardPopup, setCardPopup] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [showDeleteButton, setDeleteButton] = useState(false);
  const [showDeletePopup, setDeletePopup] = useState(false);
  const isDeleteAccess = useMemo(() => {
    const roleName = window.sessionStorage.getItem("rolename");
    return roleName && droppableId != "Complete" && (roleName.toLowerCase() === "owner" || roleName.toLowerCase() === "manager");
  }, [droppableId])


  const toggleDeleteButton = (e) => {
    if (isDeleteAccess) {
      e?.stopPropagation();
      setDeleteButton((prev) => !prev);
    }
  };

  const handleDeleteClick = (e) => {
    e?.stopPropagation();
    if (isDeleteAccess) {
      setDeletePopup(true)
    }
  }

  const handleDeleteConfirmation = (confirm) => {
    if (confirm) {
      dispatch(deleteOrder(data.orderid));
    } else {
      setDeletePopup(false);
      setDeleteButton(false);
    }
  }

  useEffect(() => {
    if (deletedOrder && deletedOrder.status) {
      setDeletePopup(false);
    }
  }, [deletedOrder]);

  const handleClose = () => {
    setCardPopup(false);
  };

  const dateFormat = new Date(data.duedate);

  const finalDate =
    dateFormat.toString().slice(8, 10) +
    "." +
    dateFormat.toString().slice(4, 7) +
    "." +
    dateFormat.toString().slice(11, 15);

  const HandelRolespop = () => {
    if (!showDeleteButton) {
      window.sessionStorage.removeItem("orderId");
      window.sessionStorage.setItem("orderId", data.orderid);
      window.sessionStorage.setItem("dueDate", finalDate);
      setCardPopup(true);
    }
  };

  const handleClickOnCard = (e) => {
    if (e.type === 'contextmenu') {
      e.preventDefault();
      toggleDeleteButton();
    }
  }

  return (
    <>
      {cardPopup && (
        <Orderpopup handleClose={handleClose} droppableId={droppableId} />
      )}

      <DeleteOrderPopup open={showDeletePopup} onClose={(confirm) => handleDeleteConfirmation(confirm)} />

      <Draggable draggableId={id} index={index} type="TASK" isDragDisabled={noDraggable || showDeleteButton}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            draggable={!showDeleteButton || !noDraggable}
          >
            <div
              className="card ordersbg  m-2"
              onDoubleClick={HandelRolespop}
              onContextMenu={handleClickOnCard}
            >
              {showDeleteButton &&
                <div className="delete-container" onClick={toggleDeleteButton}>
                  <div className="delete-button" onClick={handleDeleteClick}>
                    <DeleteIcon fill="#003766" />
                    Delete this Project
                  </div>
                </div>
              }
              <div
                style={{ position: "relative" }}
                className={
                  data.isconcreteblock == 0
                    ? "card-header py-0 orderheaderbg orderheader"
                    : "card-header py-0 themebg  orderheader"
                }
              >
                {error && (
                  <div
                    className="assignee-error-icon"
                    onMouseEnter={() => setShowError(true)}
                    onMouseLeave={() => setShowError(false)}
                  >
                    !
                  </div>
                )}
                {error && showError && (
                  <div className="error-message">
                    <p className="error-msg-style">
                      Stock or Assignees <br /> need your attention!
                    </p>
                  </div>
                )}
                <div className="row pt-1">
                  <div className="col-4">
                    {/* <i className="bi bi-box homeBox1 "></i> */}
                    <img
                      src={data?.productsdetails[0]?.imgurl}
                      alt="img"
                      width={50}
                      height={30}
                      className="pt-2"
                    />
                  </div>
                  <div className="col-8 text-end">
                    <span className="carddata themecolor ">
                      {/* <i className="bi bi-box homeBox1 pe-1"></i> */}
                      <img
                        src={calender}
                        className="pe-1"
                        height="16px"
                        width="20px"
                      />
                      Due date
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {/* <p className="carddata themecolor fw-bold">2x3m</p> */}
                    <p className="carddata themecolor fw-bold">
                      {data?.productsdetails[0]?.name?.length > 15 ? data?.productsdetails[0]?.name.slice(0, 15) + "..." : data?.productsdetails[0]?.name}

                      {/* {data?.productsdetails[ 0 ]?.name} */}
                    </p>
                  </div>
                  <div className="col text-end">
                    <p className="carddata themecolor fw-bold">{finalDate}</p>
                  </div>
                </div>
              </div>
              {data.isspecialpart ? (
                <img src={speciaIcon} className="position-absolute specImg" />
              ) : (
                ""
              )}
              <div className="card-body orderbody pb-0">
                <div className="row">
                  <div className="col carddata themecolor">
                    <p className="carddata themecolor fw-bold">
                      {/* <i className="bi bi-box homeBox1 pe-1"></i> */}
                      <img
                        src={Group1087}
                        className="orderIconPop "
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                      &nbsp;&nbsp;&nbsp;Quote ID
                    </p>
                  </div>
                  <div className="col text-end carddata themecolor text-end">
                    <p className="carddata themecolor ">
                      {/* {data.orderdetail[0]?.ordercode} */}
                      {/* {data.orderid.split("-")[4]} */}
                      {/* ordercode */}
                      {data.ordercode}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col carddata themecolor">
                    <p className="carddata themecolor ">
                      {/* <i className="bi bi-box homeBox1 pe-1"></i> */}
                      <img
                        src={clienticon}
                        className="orderIconPop "
                        alt=""
                        style={{ width: "15px", height: "15px" }}
                      />
                      &nbsp;&nbsp;&nbsp; {data?.clientname}
                    </p>
                  </div>
                  <div className="col text-end carddata themecolor text-end"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
}

export default memo(CardOrder);
