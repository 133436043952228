import React from "react";
import { Droppable } from "react-beautiful-dnd";
import CardOrder from "./CardOrder";
import "../styles/local/cardstatus.css";
import { useHistory } from "react-router-dom";

function CardStatus(props) {
  const { droppableId, list, type, errorId, noDraggable } = props;
  const history = useHistory();
  const roleName = window.sessionStorage.getItem("rolename");

  const HandelroleAddBtn = () => {
    if (roleName !== "User") {
      history.push("/order");
    } else {
      alert("You Don't have access to Add Order");
    }
  };

  return (
    <Droppable droppableId={droppableId} type={type}>
      {(provided) => (
        <div id="border-remove"
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="columnsbg card mt-2 "
        >
          <div className="row ">
            {droppableId == "Pipeline" ? (
              // <p role="button" className="my-0" onClick={()=>history.push("/order")}>+Add order</p>
              <div className="row pb-3">
                <div
                  className={roleName !== "User" ? "col-2 ps-4 pt-4" : "d-none"}
                >
                  <button
                    type="button"
                    className="addBtn"
                    onClick={() => {
                      HandelroleAddBtn();
                      // history.push("/order");
                    }}
                  >
                    +
                  </button>
                </div>
                <div className={roleName !== "User" ? "col-4 pt-4" : "d-none"}>
                  Add Card
                </div>
                <div className="col text-end columnsheader pt-3">Pipeline</div>
              </div>
            ) : (
              <div
                className={
                  droppableId == "Pipeline"
                    ? ""
                    : "pt-3 columnsheader text-end pe-5 pb-3"
                }
              >
                {droppableId}
              </div>
            )}
            {/* <div className="col text-end">
              <p className={droppableId == "Pipeline" ? "columnsheader":"pt-4 columnsheader"}>{droppableId}</p>
            </div> */}
          </div>
          {list?.map((val, index) => {
            return (
              <CardOrder
                id={val.orderid}
                key={val.orderid}
                index={index}
                data={val}
                droppableId={droppableId}
                error={errorId.some(x => x === val.orderid)}
                noDraggable={noDraggable}
              />
            )
          })
          }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default CardStatus;
