import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Group91 from "../../assets/icons/Group91.png";
import editclientlogo from "../../assets/icons/editclientlogo.svg";
// import "../../styles/local/addSupplier.css";
import "../../styles/local/UpdateClient.css";
import Group62 from "../../assets/icons/Group62.svg";
import { addClient, updateClient } from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchClientsById } from "../../redux/actions/action";

const Contact = (props) => {
  const defaultContact = props.contact;
  const [ name, setName ] = useState(defaultContact.name);
  const [ phone, setPhone ] = useState(defaultContact.phonenumber);
  const [ email, setEmail ] = useState(defaultContact.email);
  const { index, handleChange, contacts, setContacts, handleClick } = props;
  const [ emailInvalid2, setEmailInvalid2 ] = useState(false);


  useEffect(() => {
    setName(defaultContact.name);
    setPhone(defaultContact.phonenumber);
    setEmail(defaultContact.email);

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(email)) {
      props.setEmailInvalid2(true);
    } else {
      props.setEmailInvalid2(false);

    }

    if (defaultContact.name == "" || defaultContact.phonenumber == "" || defaultContact.email == "") {
      props.setErrorInvalid(true)
    } else {
      props.setErrorInvalid(false)
    }
  }, [ contacts, name, phone, email, props.contact ]);


  return (
    <React.Fragment>
      <div className="row ms-sm-4 me-sm-2 px-5">
        <div className="col-12 col-sm-9 mt-2">
          <h3 className="mt-4 pt-4 ps-2 fntSupp1">Contact Name</h3>
        </div>

        <div className="col-12 col-sm-3 text-sm-end mt-sm-5 pt-sm-1 ">
          {contacts.length - 1 === index ? (
            <span
              type="button"
              className="fntSupp11"
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            >
              Add...
            </span>
          ) : (
            <img
              src={Group62}
              onClick={() => {
                let data = [ ...contacts ];
                data.splice(index, 1);
                setContacts(data);
              }}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>

        <input
          className=" form-control suppInput mt-2 ps-3 ms-2 fntSupp4"
          type="text"
          onChange={(e) => {
            handleChange(index, "name", e.target.value);
            setName(e.target.value);
          }}
          value={name}
        />

        <div className="col-12 col-lg-6">
          <div className=" box-contactName upclient mt-2 mt-xl-1">
            <div className="d-flex align-items-start">
              <span className="fntSuppclient">Telephone</span>
            </div>

            <input
              type="text"
              // style={{color:"#003766"}}
              className="fntSupp4"
              onChange={(e) => {
                handleChange(index, "phonenumber", e.target.value);
                setPhone(e.target.value);
              }}
              value={phone}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className=" box-contactName new3 mt-2 mt-xl-1">
            <div className="d-flex align-items-start">
              <span className="fntSuppclient">E-mail</span>
            </div>

            <input
              type="text"
              className="fntSupp4"
              onChange={(e) => {
                handleChange(index, "email", e.target.value);
                setEmail(e.target.value);
              }}
              value={email}
            />
          </div>

          <div className="mt-2 ps-2 mt-xl-1">
            {!props.validEmail?.test(email) && email ?
              <div className="" style={{ color: "red", fontSize: "14px" }}>
                Email is invalid
              </div> : ""}
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

const UpdateClient = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ show, setShow ] = useState([ { contact: "", id: 1 } ]);
  const [ display, setDisplay ] = useState([ { contact: "", id: 1 } ]);
  const UserId = window.sessionStorage.getItem("userid");
  const fetchclientsbyid = useSelector((state) => state.user.fetchClientsById);
  const companyId = window.sessionStorage.getItem("compId");
  const [ Name, setName ] = useState("");
  const [ contactCount, setContactCount ] = useState(1);
  const [ address1, setAddress1 ] = useState("");
  const [ address2, setAddress2 ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phoneNumber, setPhoneNumber ] = useState("");
  const [ notes, setNotes ] = useState("");
  const [ contactNumber, setContactNumber ] = useState("");
  const [ client, setClient ] = useState([]);
  const initialRender = React.useRef(true);
  const [ contactName, setContactName ] = useState("");
  const [ contactPhoneNumber, setContactPhoneNumber ] = useState("");
  const [ contactEmail, setContactEmail ] = useState("");
  const [ emailInvalid, setEmailInvalid ] = useState(false);
  const [ emailInvalid2, setEmailInvalid2 ] = useState(false);
  const [ errorInvalid, setErrorInvalid ] = useState(false);
  const [ clientId, setClientId ] = useState("");
  const [ contacts, setContacts ] = useState([
    {
      // contactid: "",
      name: "",
      phonenumber: "",
      email: "",
    },
  ]);
  const handleChange = (id, field, val) => {
    const modifiedContacts = contacts;
    modifiedContacts[ id ][ field ] = val;

    setContacts(modifiedContacts);
  };

  const handleClick = () => {
    setShow([ ...show, { contact: "", id: contactCount + 1 } ]);
    setContactCount(contactCount + 1);
    setContactNumber(contactNumber + 1);

    const modifiedContacts = contacts;

    modifiedContacts.push({
      // contactid: "",
      name: "",
      phonenumber: "",
      email: "",
    });

    setContacts(modifiedContacts);
  };

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function hasNonBlankValidEmail(contacts) {
    for (const contact of contacts) {
      if (!contact.email || !isValidEmail(contact.email)) {
        return false; // Return false if the email is blank or invalid
      }
    }
    return true; // Return true if all emails are non-blank and valid
  }

  const handleAdd = (e) => {
    let listContatcts = contacts;
    listContatcts = listContatcts.filter(x => x.name !== "" && x.email !== "" && x.phonenumber !== "");
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isAllContactValidate = hasNonBlankValidEmail(contacts);

    if (isAllContactValidate) {

      if (!re.test(email)) {
        setEmailInvalid(true);
      } else {
        dispatch(
          updateClient(
            UserId,
            clientId,
            Name,
            address1,
            address2,
            email,
            phoneNumber,
            notes,
            listContatcts
          )
        );
        setTimeout(() => {
          history.goBack();
          // history.push("/client");
          // window.location.reload();
        }, 1000);

        e.preventDefault();
      }
    }
  };
  useEffect(() => {
    setContacts(contacts);
  }, [ contacts, client ]);
  useEffect(() => {
    dispatch(fetchClientsById(window.sessionStorage.getItem("clientid")));
  }, []);
  let helper = [];

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchclientsbyid != null) {
        if (fetchclientsbyid.status == true) {
          helper = fetchclientsbyid.data[ 0 ]?.contactdetails;
          setClient(fetchclientsbyid.data);
          setClientId(fetchclientsbyid.data[ 0 ]?.clientid);
          setName(fetchclientsbyid.data[ 0 ]?.name);
          setAddress1(fetchclientsbyid.data[ 0 ]?.address1);
          setAddress2(fetchclientsbyid.data[ 0 ]?.address2);
          setEmail(fetchclientsbyid.data[ 0 ]?.email);
          setPhoneNumber(fetchclientsbyid.data[ 0 ]?.phonenumber);
          setNotes(fetchclientsbyid.data[ 0 ]?.notes);
          let formatContacts = fetchclientsbyid.data[ 0 ]?.clientcontact.map(
            (contact) => {
              return {
                contactid: contact?.contactdetails[ 0 ].contactid,
                name: contact?.contactdetails[ 0 ].name,
                phonenumber: contact?.contactdetails[ 0 ].phonenumber,
                email: contact?.contactdetails[ 0 ].email,
                subject: "",
                notes: "",
                isdeleted: 0,
              };
            }
          );
          setContacts(formatContacts);

          {
            fetchclientsbyid[ 0 ]?.contactdetails.map(
              (item) => (
                setShow([ ...show, { contact: "", id: contactCount + 1 } ]),
                setContactCount(contactCount + 1)
              )
            );
          }
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ fetchclientsbyid ]);
  return (
    <>
      <Header />
      <div className="container-fluid countFontclr pb-5 ">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-4">
            <h3 className="py-3 ps-2 themecolor header-title-sk">Clients</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-11 custCardCount">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5 className="my-4 text-sm-start text-center fntSupp22">
                    {/* comment this class above line <h5> tag - "fntSupp2" */}
                    <span className="me-4">
                      <img src={editclientlogo} className="addclienicon" />
                    </span>
                    <span className="client">Clients</span>
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row">
                  <div className="col-12 col-lg-7">
                    <div className="row ms-sm-4 me-sm-2 px-5">
                      <h3 className="mt-5 ps-4 fntSupp1">Client Name</h3>
                      <input
                        className=" form-control suppInput mt-2 ms-2 fntSupp4"
                        defaultValue={client[ 0 ]?.name}
                        onChange={(e, value) => setName(e.target.value)}
                        type="text"
                      />

                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName new asc mt-2 mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">
                              Street, House number
                            </span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            defaultValue={client[ 0 ]?.address1}
                            onChange={(e) => setAddress1(e.target.value)}
                          />
                        </div>
                        <div className=" box-contactName new mt-2 mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">
                              Postal ZIP Code, Place
                            </span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            defaultValue={client[ 0 ]?.address2}
                            onChange={(e) => setAddress2(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className=" box-contactName new1 asc1 mt-2 mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">E-mail</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            defaultValue={client[ 0 ]?.email}
                            onChange={(e) => {
                              setEmail(e.target.value)
                              setEmailInvalid(false)
                            }}
                          />
                        </div>
                        <div className="ps-3 mt-2 mt-xl-1">
                          {emailInvalid == true && (
                            <div className="ps-3" style={{ color: "red", fontSize: "14px" }}>
                              Email is invalid
                            </div>
                          )}
                        </div>
                        <div className=" box-contactName new1 mt-2 mt-xl-1">
                          <div className="d-flex align-items-start">
                            <span className="fntSuppclient">Telephone</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            defaultValue={client[ 0 ]?.phonenumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {contacts.map((item, index) => {
                      const validEmail = new RegExp(
                        "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
                      )
                      return (
                        <Contact
                          index={index}
                          contact={item}
                          contacts={contacts}
                          setContacts={setContacts}
                          handleChange={handleChange}
                          handleClick={handleClick}
                          setErrorInvalid={setErrorInvalid}
                          emailInvalid2={emailInvalid2}
                          setEmailInvalid2={setEmailInvalid2}
                          validEmail={validEmail}
                        />
                      )
                    })}
                  </div>

                  <div className="col-12 col-lg-5">
                    <div className="row ps-4 ps-lg-0">
                      <h3 className="mt-5 fntSupp3">Notes</h3>
                      <div className="pe-5">
                        <textarea
                          className="form-control notesForm2 mt-2 fntSupp4"
                          defaultValue={client[ 0 ]?.notes}
                          onChange={(e) => setNotes(e.target.value)}
                          rows="14"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4 ">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 mt-5 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc cancel w-100 p-2"
                          onClick={() => history.push("/client")}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            Name == "" ||
                              address1 == "" ||
                              address2 == "" ||
                              email == "" ||
                              phoneNumber == ""
                              ? // notes == ""
                              "countBtnchange1 w-100 h-100 p-2"
                              : "countBtnchange w-100 h-100 p-2"
                          }
                          onClick={(e) => {
                            handleAdd(e);
                          }}
                          disabled={
                            errorInvalid ||
                            emailInvalid2 ||
                            Name == "" ||
                            client.name == "" ||
                            client.email == "" ||
                            // client.phoneNumber==""||
                            client.address1 == "" ||
                            client.address2 == "" ||
                            client.phonenumber == "" ||
                            address1 == "" ||
                            address2 == "" ||
                            email == "" ||
                            phoneNumber == ""
                          }
                        >
                          CHANGE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateClient;
