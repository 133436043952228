export default function decimalSeparator(unit, value) {
    if(unit !== undefined) {
        if (value === ".") {
            let unitComma = unit.toFixed(2).replace(",", ".")
            let num_parts = unitComma.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return num_parts
        } else {
            
            let unitComma = parseInt(unit).toFixed(2).replace(".", ",")
            let num_parts = unitComma.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return num_parts
        }
    } else {
        return 0
    }

};