import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchOrdersbyStatus,
  changeStatus,
  fetchErrorCode,
  updateOrdersIndexPositions,
} from "../redux/actions/action";
import Modal from "react-bootstrap/Modal";
import CardStatus from "./CardStatus";
import "../styles/local/draggablecard.css";
import Loader from "./Loader";
import { DELETE_ORDER } from "../redux/constants/type";

function DraggableCard(props) {

  const dispatch = useDispatch();
  const UserID = window.sessionStorage.getItem("userid");
  const [taskList, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorList, setErrorList] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [lastMovedTicket, setLastMovedTicket] = useState({
    draggableId: "",
    source: {},
    destination: null,
    destinationName: ""
  })

  const deletedOrder = useSelector(s => s.user.deleteOrder);
  const fetchOrderStatusData = useSelector((state) => state.user.fetchOrderStatus);
  const fetchErrorCodeResponse = useSelector((state) => state.user.fetchErrorCodeResponse);
  const updateOrderData = useSelector((state) => state.user.updateOrder);
  const orderStatusChange = useSelector((state) => state.user.changeStatus);

  React.useEffect(() => {
    dispatch(FetchOrdersbyStatus(UserID));
    dispatch(fetchErrorCode());
  }, [updateOrderData]);

  useEffect(() => {
    if (deletedOrder && deletedOrder.status) {
      dispatch({ type: DELETE_ORDER, data: {} });
      dispatch(FetchOrdersbyStatus(UserID));
      setLoading(true);
    }
  }, [deletedOrder]);

  useEffect(() => {
    dispatch(fetchErrorCode());
  }, []);

  useEffect(() => {
    if (orderStatusChange && orderStatusChange.data) {
      if (orderStatusChange.data.hasError && lastMovedTicket.destinationName) {
        setShowErrorModal(true);
      }
      dispatch(fetchErrorCode());
    }
  }, [orderStatusChange])

  useEffect(() => {
    if (fetchErrorCodeResponse.status === true) {
      const queue = fetchErrorCodeResponse?.data[0]?.queueErrorIds.split(",");
      const progress = fetchErrorCodeResponse?.data[0]?.progressErrorIds.split(",");
      setErrorList([...progress, ...queue]);
    }
  }, [fetchErrorCodeResponse]);

  React.useEffect(() => {
    if (fetchOrderStatusData != null && fetchOrderStatusData.status) {
      let initialState = [
        {
          groupName: "Pipeline",
          tasks: fetchOrderStatusData.data[0]?.orderstatus[0]?.orderdetails,
        },
        {
          groupName: "Queue",
          tasks: fetchOrderStatusData.data[0]?.orderstatus[1].orderdetails,
        },
        {
          groupName: "In Progress",
          tasks: fetchOrderStatusData.data[0]?.orderstatus[2].orderdetails,
        },
        {
          groupName: "Ready To Ship",
          tasks: fetchOrderStatusData.data[0]?.orderstatus[3].orderdetails,
        },
        {
          groupName: "Complete",
          tasks: fetchOrderStatusData.data[0]?.orderstatus[4].orderdetails,
        },
      ];
      setTasks(initialState);
      setLoading(false);
    }
  }, [fetchOrderStatusData]);

  const onDragEnd = (val) => {
    const { draggableId, source, destination } = val;

    if (destination == null) {
      return;
    }

    const oldObject = [...taskList];
    const sourceIndex = oldObject.findIndex((column) => column.groupName == source.droppableId);
    const sourceTasks = oldObject[sourceIndex].tasks;
    const destinationIndex = oldObject.findIndex((column) => column.groupName == destination.droppableId);
    const destinationTasks = oldObject[destinationIndex].tasks;

    if (source.droppableId === destination.droppableId) {
      const newArray = reorderTickets(sourceTasks, source.index, destination.index);
      oldObject[sourceIndex].tasks = newArray;
      updateOrderIndexPositions(newArray);
      setTasks([...oldObject]);
      return;
    }

    if ((source.droppableId === "Pipeline" && destination.droppableId === "Queue") || (source.droppableId === "Queue" && destination.droppableId !== "Complete") || (source.droppableId === "In Progress" && destination.droppableId !== "Pipeline")) {
      setLastMovedTicket({ destination: source, draggableId, source: destination, destinationName: destination.droppableId });
      const newItems = transferAndReorderTickets(draggableId, sourceTasks, destinationTasks, source.index, destination.index);
      oldObject[sourceIndex].tasks = newItems.sourceTasks;
      oldObject[destinationIndex].tasks = newItems.destinationTasks;
      const statusId = getOrderStatusByName(destination.droppableId);
      dispatch(changeStatus(draggableId, statusId));
      if (statusId == "08da2f31-46b4-4ce8-80b3-fdffac7a3061") {
        props.handleShipment();
      }
      updateOrderIndexPositions([...newItems.sourceTasks, ...newItems.destinationTasks]);
      setTasks([...oldObject]);
      return
    }
  }

  const reorderTickets = (arrTasks, startIndex, endIndex) => {
    const reordered = Array.from(arrTasks);
    reordered.forEach(x => x.oldIndexPosition = x.indexPosition);
    if (startIndex != null && endIndex != null) {
      const [removed] = reordered.splice(startIndex, 1);
      reordered.splice(endIndex, 0, removed);
    }
    reordered.forEach((item, index) => item.indexPosition = ((reordered.length - 1) - index))
    return reordered;
  }

  const transferAndReorderTickets = (orderid, sourceTasks, destinationTasks, sourceIndex, destinationIndex) => {
    const [movingTask] = sourceTasks.filter((t) => t.orderid === orderid);
    sourceTasks.splice(sourceIndex, 1);
    destinationTasks.splice(destinationIndex, 0, movingTask);
    return {
      sourceTasks: reorderTickets(sourceTasks, null, null),
      destinationTasks: reorderTickets(destinationTasks, null, null)
    }
  }

  const updateOrderIndexPositions = (arr) => {
    const olnyUpdatedTasks = Array.from(arr).filter(x => x.oldIndexPosition !== x.indexPosition);
    const payload = olnyUpdatedTasks.map(item => {
      return { orderid: item.orderid, indexPosition: item.indexPosition }
    });
    dispatch(updateOrdersIndexPositions(payload));
  }

  const getOrderStatusByName = (name) => {
    let statusId = "";
    switch (name) {
      case "Pipeline":
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3058"
        break;
      case "Queue":
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3059"
        break;
      case "Complete":
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3062"
        break;
      case "Ready To Ship":
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3061"
        break;
      default:
        statusId = "08da2f31-46b4-4ce8-80b3-fdffac7a3060"
        break;
    }
    return statusId;
  }

  if (loading) {
    return <Loader />
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="row scrollwidth">
        <div className="col-3 ms-5 mt-4 mt-xl-0 mt-md-0 mt-sm-0 mt-lg-0">
          <CardStatus
            className="column"
            droppableId="Pipeline"
            list={taskList[0]?.tasks}
            type="TASK"
            errorId={errorList}
          />
        </div>
        <div className="col-3 ms-1 mt-4 mt-xl-0 mt-md-0 mt-sm-0 mt-lg-0">
          <CardStatus
            className="column"
            droppableId="Queue"
            list={taskList[1]?.tasks}
            type="TASK"
            errorId={errorList}
          />
        </div>
        <div className="col-3 ms-1 mt-4 mt-xl-0 mt-md-0 mt-sm-0 mt-lg-0">
          <CardStatus
            className="column"
            droppableId="In Progress"
            list={taskList[2]?.tasks}
            type="TASK"
            errorId={errorList}
          />
        </div>
        <div className="col-2 ms-1 mt-4 mt-xl-0 mt-md-0 mt-sm-0 mt-lg-0">
          <CardStatus
            className="column"
            droppableId="Complete"
            list={taskList[4]?.tasks}
            type="TASK"
            errorId={errorList}
            noDraggable={true}
          />
        </div>
        <Modal
          animation={false}
          show={showErrorModal}
          centered
          dialogClassName="modal-90w"
          id="pipeline-modal"
        >
          <Modal.Body className="p-0">
            <div className="modal-main-div">
              {lastMovedTicket.destinationName === "In Progress" && (
                <div>
                  You haven't assigned this order to team member. Do you still
                  want to move the order to the In Progress?
                </div>
              )}
              {lastMovedTicket.destinationName === "Queue" && (
                <div>
                  You don't have enough parts to complete the order. Do you
                  still want to move the order to the Queue?
                </div>
              )}
            </div>
            <div className="modal-main-btns">
              <div className="cancel-btn-style"
                onClick={() => {
                  if (lastMovedTicket.draggableId) {
                    onDragEnd(lastMovedTicket);
                    setShowErrorModal(false);
                  } else {
                    setShowErrorModal(false);
                  }
                }}
              >
                {lastMovedTicket.destinationName === "Queue" ? "Keep in Pipeline" : "Keep in Queue"}
              </div>
              <div className="btn-line"></div>
              <div className="cancel-btn-style ok-btn" onClick={() => { setShowErrorModal(false) }}>
                {lastMovedTicket.destinationName === "Queue" ? "Move to Queue" : "Move to In Progress"}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </DragDropContext>
  );
}
export default DraggableCard;
