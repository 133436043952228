import Header from "../../components/Header";
import "../../styles/local/composeProduct.css";
import axios from "axios";
import { API_URL, FETCH_PARTBYID } from "../../../src/redux/constants/type";
import producticon from "../../assets/icons/producticon.svg";
import AddComposeImg from "../../assets/icons/AddComposeImg.svg";
import Group62 from "../../assets/icons/Group62.svg";
import { useEffect, useState, useRef } from "react";
import {
  addComments,
  fetchComments,
  fetchProductStock,
  updateProductStock,
  updateProduct,
  fetchProductById,
  fetchParts,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { useQuery } from "../../utils/appHooks";
import EuropeanInput from "../../utils/EuropeanInput";
const ComposeProduct = () => {
  const refNewStandardWageInput = useRef(null);
  const selectRef = useRef();
  const inputFile = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const initialRender = React.useRef(true);
  const roleName = window.sessionStorage.getItem("rolename");
  const fetchpartsbyid = useSelector((state) => state.user.fetchProductById);
  const fetchsuppliers = useSelector((state) => state.user.fetchParts);
  const [data, setData] = useState([]);
  const UserId = window.sessionStorage.getItem("userid");
  const productid = window.sessionStorage.getItem("productID");
  const [contact, setContact] = useState([]);
  const [part, setPart] = useState([]);
  const [productId, setProductId] = useState("");
  const [partid, setPartid] = useState("");
  const [productName, setProductName] = useState("");
  const [unitprice, setUnitprice] = useState("");
  const [internalid, setInternalid] = useState("");
  const [imageurl, setImageurl] = useState("");
  const [partname, setPartname] = useState("");
  const [allotedquantity, setAllotedquantity] = useState("");
  const [partList, setPartList] = useState([]);
  const [customDrop, setCustomDrop] = useState(false);
  const [customDropId, setCustomDropId] = useState("");
  const [partarrow, setPartarrow] = useState("");
  const queryParams = useQuery();
  useEffect(() => {
    const isCon = queryParams.get("u")
    if (isCon === "ucform") {
      const obj = window.sessionStorage.getItem("ucform");
      const jsnObj = JSON.parse(obj);
      setPart(jsnObj.part);
      setProductId(jsnObj.productId);
      setProductName(jsnObj.productName);
      setUnitprice(jsnObj.unitprice);
      setInternalid(jsnObj.internalid);
      setImageurl(jsnObj.imageurl);
      setPartList(jsnObj.partList || []);
    } else {
      dispatch(fetchProductById(window.sessionStorage.getItem("productID")));
    }
  }, []);

  const handleCreatePartClick = () => {
    const currentObj = {
      part,
      productId,
      productName,
      unitprice,
      internalid,
      imageurl,
      partList,
    }
    window.sessionStorage.setItem("ucform", JSON.stringify(currentObj));
    history.replace({ pathname: history.location.pathname, search: "?u=ucform" })
    history.push({ pathname: "/add-parts" })
  }

  let helper = [];
  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchpartsbyid != null) {
        if (fetchpartsbyid.status == true) {
          helper = fetchpartsbyid.data[0];
          setPart(fetchpartsbyid.data);
          setProductId(fetchpartsbyid.data[0]?.productId);
          setProductName(fetchpartsbyid.data[0]?.productname);
          setUnitprice(fetchpartsbyid.data[0]?.unitprice);
          setInternalid(fetchpartsbyid.data[0]?.productcode);
          setImageurl(fetchpartsbyid.data[0]?.imageurl);
          // setPartname(fetchpartsbyid.data[0]?.productparts[0]?.partname);
          // setPartid(fetchpartsbyid.data[0]?.productparts[0]?.partid);
          // setAllotedquantity(
          //   fetchpartsbyid.data[0]?.productparts[0]?.allotedquantity
          // );
          setPartList(fetchpartsbyid.data[0]?.productparts || []);
          dispatch({
            type: FETCH_PARTBYID,
            data: {},
          });
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchpartsbyid]);

  useEffect(() => {
    dispatch(fetchParts());
    // setIsCalled(false);
    // setIsCalled1(false);
  }, []);
  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchsuppliers != null) {
        if (fetchsuppliers.status == true) {
          setData(fetchsuppliers.data);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchsuppliers]);

  const productpart = [
    {
      partid: partid,
      allotedquantity: allotedquantity,
    },
  ];
  contact.forEach((item) =>
    productpart.push({
      partid: item.partid,
      allotedquantity: item.allotedquantity,
    })
  );

  const handleAdd = (e) => {


    dispatch(
      updateProduct(
        UserId,
        productid,
        productName,
        unitprice,
        internalid,
        imageurl == "" ? AddComposeImg : imageurl,
        partList
      )
    );
    setTimeout(() => {
      history.push("/products");
      window.location.reload();
    }, 1000);

    e.preventDefault();
  };



  const toggleSelect = (i, item) => {
    if (i !== null) {
      setCustomDropId(i)
      setPartid(item.partid);
    } else {
      setCustomDrop(true)
    }



  };


  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };


  const onChangeHandler = (e) => {
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[index];
    // const option = el.getAttribute("id");
    if (e != null) {
      setPartid(e.partid);
      setPartname(e.partname);
      setCustomDrop(false)
    }
  };


  const onChangeHandlerDrop = (e, i) => {
    setPartList([
      ...partList.slice(0, i),
      {
        ...partList[i],
        partname: e.partname,
        partid: e.partid
      },
      ...partList.slice(i + 1),
    ]);
    // setPartid(e.partid);
    setCustomDropId(null)
  };


  const handleAddPart = () => {
    setPartList([...partList, { partname, allotedquantity, partid }]);
    setPartid("");
    setPartname("");
    setAllotedquantity("");
    selectRef.current.clearValue()
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleFileChange = (e) => {
    const target = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target);
    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setImageurl(response.data[0]);
        } else {
        }
      })
      .catch(function (error) { });
  };

  const Imagedlt = () => {
    setImageurl("");
  };


  const handleKeyPress = (e) => {
    // console.log("key")
  }

  //// * ASC kypressevent send Start *////

  useEffect(() => {
    // window.addEventListener('keydown', keyDownHandler, true)
    document.addEventListener('keydown', keyDownHandler, true)
  }, [])

  const keyDownHandler = (e) => {
    // console.log("pressed", e.key)
    // if (e.key === "Enter") {
    //   // handleSignup();
    //   // e.preventDefault();
    //   // setTimeout(() => {
    //   //   // window.location.reload();
    //   // }, 500);
    // }
    // setUnitprice(e.target.value);
  }
  //// * ASC kypressevent send End *////

  return (
    <>
      <Header />
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-4 pb-5">
            <h3 className="py-3 ps-2 themecolor header-title-sk">  Products & Parts</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="col-12 custCardCount">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5 className="py-4 cntStockfont">
                    <span className="me-4">
                      <img className="countProicon" src={producticon} />
                    </span>
                    Compose Product
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row ps-5 ">
                  <div className="col-11">
                    <div className="row">
                      <div className="col-xl-5 col-12 pt-5">
                        <div className="row">
                          <h5 className=" cntStockfont">
                            <span className="me-4 ">
                              <img className="countProicon" src={producticon} />
                            </span>
                            Product Name
                          </h5>
                        </div>
                        <div className="row ps-xl-5 pe-3 pe-xl-0 ms-1 ">
                          <input className={roleName !== "User" ? "form-control suppInput fntSupp4" : "form-control suppInput fntSupp4 field-disable1"}
                            onChange={(e) => setProductName(e.target.value)}
                            style={{ height: "51px" }}
                            // value={part[0]?.productname}
                            value={productName}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-xl-2 col-md-6  text-center">
                        <div className="row text-center">
                          <div className="col-md-12    pt-xl-5 mt-xl-2 mt-4 ps-md-4  ">
                            <div
                              className="addProductFnt mt-xl-0 pt-xl-1 mb-3"
                              style={{ color: "#80cfe6", fontSize: "17px" }}
                            >
                              Unit Price
                            </div>
                            <EuropeanInput
                              className={roleName !== "User" ? "form-control suppInput1 fntSupp4 centertext" : "form-control suppInput1 fntSupp4 centertext field-disable1"}
                              onChange={(newValue) => setUnitprice(newValue)}
                              value={unitprice}
                            />

                          </div>
                        </div>
                      </div>
                      <div className="col-3 col-xl-3 col-md-6 col-12 pt-xl-5 mt-xl-2 mt-4 pe-md-4 pe-xl-3 text-center">
                        <div
                          className="addProductFnt mt-xl-0 pt-xl-1 mb-3"
                          style={{ color: "#80cfe6", fontSize: "17px" }}
                        >
                          Internal ID
                        </div>
                        <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-xl-2 centertext" : "form-control suppInput1 fntSupp4 mt-xl-2 centertext field-disable1"}
                          onChange={(e) => setInternalid(e.target.value)}
                          // value={part[0]?.unitprice}
                          value={internalid}
                          type="text"
                        />
                      </div>
                      <div className="col-xl-2 col-12 pt-xl-5 mt-xl-2 mt-4 text-center">
                        <div className={roleName !== "User" ? "addProductFnt mt-xl-0 pt-xl-1 imghead" : "addProductFnt mt-xl-0 pt-xl-1 imghead2"}
                        >
                          Image
                          <img
                            src={Group62}
                            className={roleName !== "User" ? "icons mx-3" : "icons mx-3 d-none"}
                            onClick={Imagedlt}
                            alt="dlt"
                            height="15px"
                            width="15px"
                          />
                        </div>
                        <div>
                          <div className="mt-xl-2 fs-1 proCardBtn">
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                              style={{ display: "none" }}
                            />
                            {imageurl === "" ? (
                              // <h3
                              //   style={{
                              //     cursor: "pointer",
                              //   }}
                              //   onClick={onButtonClick}
                              // >
                              //   +
                              // </h3>
                              <img
                                className="w-75"
                                src={AddComposeImg}
                                alt=""
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={onButtonClick}
                              >
                              </img>
                            ) : (
                              <img
                                src={imageurl}
                                height="65px"
                                width="74px"
                                alt="im"
                              ></img>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row ps-5 ">
                  <div className="col-11">
                    <div className="row">
                      <div className="col-xl-5 pt-2">
                        <div className="row">
                          <div className="col-md-8 col-sm-7 col-xl-7 col-12">
                            <h5 className=" cntStockfont">
                              <span className="me-4 ">
                                <img
                                  className="countProicon"
                                  src={producticon}
                                />
                              </span>
                              Parts
                            </h5>
                          </div>
                          <div className={roleName !== "User" ? "col-md-4 col-sm-5 col-12 text-sm-end addProductFnt2 pe-5 pe-xl-0 pt-3" : "col-md-4 col-sm-5 col-12 text-sm-end addProductFnt2 pe-5 pe-xl-0 pt-3 d-none"}
                          >
                            <Button
                              variant="ghost"
                              onClick={handleCreatePartClick}
                              style={{
                                color: "#80cfe6",
                              }}
                            >
                              {" "}
                              (+) Create Part
                            </Button>
                          </div>
                        </div>

                        {partList.map((item, i) => {
                          return (
                            <>
                              <div className={roleName !== "User" ? "row ps-xl-5 pe-5 pe-xl-0 ms-1 mb-2 mt-xl-0 sup12 po composeProduct-drop" : "row ps-xl-5 pe-5 pe-xl-0 ms-1 mb-2 mt-xl-0 sup12 field-disable1 po composeProduct-drop"}
                                style={{ position: "relative", height: "51px", zIndex: partList.length - i }}
                              >
                                {/* <select
                                  className="form-select form-select-lg"
                                  aria-label=".form-select-lg example"
                                  style={{
                                    color: "#003766",
                                    fontWeight: "500",
                                  }}
                                  onChange={(e) => {
                                    setPartList([
                                      ...partList.slice(0, i),
                                      {
                                        ...partList[i],
                                        partname: e.target.value,
                                      },
                                      ...partList.slice(i + 1),
                                    ]);
                                  }}
                                >
                        
                                  <option selected disabled>
                                    {item.partname}
                                  </option>
                                  {data.map((supp, index) => {
                                    return (
                                      <>
                                        <option key={index} id={supp.partid}>
                                          {supp.partname}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select> */}

                                {/* <div className="custom-select-box" >
                                  <div className="custom-select-selected" > {item.partname}</div>
                                </div> */}


                                {/* <div style={{ position: "relative" }}> */}

                                <Select
                                  menuIsOpen={false}
                                  classNamePrefix="sk-custom-select"
                                  className="sk3-custom-container padding-height"
                                  value={data.filter(function (option) { return option.partid === item?.partid })}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                  options={data}
                                  getOptionLabel={(option) => option.partname}
                                  getOptionValue={(option) => option.partid}
                                  onChange={(value) => onChangeHandler(value)}
                                />

                                {/* <div className="custom-select-box" >
                                  {i !== customDropId ? <div className="custom-select-selected" onClick={() => toggleSelect(i, item)} > {item.partname ? item.partname : "Select..."}</div> : ""}
                                  {i === customDropId ?
                                    <div className={`custom-select-outer`}>
                                      {data.map((supp, index) => {
                                        return (
                                          <>
                                            <div className={`list-select-box ${supp.partid === partid ? 'active' : ""}`} key={index} id={supp.partid} onClick={() => onChangeHandlerDrop(supp, i)}>
                                              {supp.partname}
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                    : ""}
                                </div> */}
                                {/* </div> */}
                              </div>
                            </>
                          );
                        })}

                        <div className={roleName !== "User" ? "row ps-xl-5 pe-5 pe-xl-0 ms-1 mt-2 mt-xl-0 po position-reletive composeProduct-drop" : "row ps-xl-5 pe-5 pe-xl-0 ms-1 mt-2 mt-xl-0 field-disable1 po position-reletive composeProduct-drop"}
                        >
                          {/* <select
                            //  multiple
                            className="form-select form-select-lg"
                            aria-label=".form-select-lg example"
                            style={{
                              color: "#003766",
                              fontWeight: "500",
                            }}
                            onChange={onChangeHandler}
                          >
                            <option selected disabled>
                              {partname}
                            </option>
                            {data.map((supp, index) => {
                              return (
                                <>
                                  <option key={index} id={supp.partid}>
                                    {supp.partname}
                                  </option>
                                </>
                              );
                            })}
                          </select> */}
                          {/* <div style={{ position: "relative" }}> */}
                          <Select
                            ///menuIsOpen={true}
                            ref={selectRef}
                            classNamePrefix="sk-custom-select"
                            className="sk3-custom-container padding-height "
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={data}
                            getOptionLabel={(option) => option.partname}
                            getOptionValue={(option) => option.partid}
                            onChange={(value) => onChangeHandler(value)}
                          // styles={colourStyles}
                          // defaultValue={}
                          />

                          {/* </div> */}


                          {/* <div className="custom-select-box">
                            {!customDrop ? <div className="custom-select-selected" onClick={() => toggleSelect(null)} > {partname ? partname : "Select..."}</div> : ""}
                            {customDrop ?
                              <div className={`custom-select-outer`}>
                                {data.map((supp, index) => {
                                  return (
                                    <>
                                      <div className={`list-select-box ${supp.partid === partid ? 'active' : ""}`} key={index} id={supp.partid} onClick={() => onChangeHandler(supp)}>
                                        {supp.partname}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                              : ""}
                          </div> */}

                        </div>
                      </div>
                      <div className="col-xl-2  col-sm-9 col-12 pt-xl-2 mt-xl-0 mt-3 ps-lg-4 pe-5 pe-sm-2 text-center">
                        <div
                          className="addProductFnt1 mt-2 pt-1 mb-3"
                          style={{ color: "#80cfe6", fontSize: "17px" }}
                        >
                          Qty
                        </div>
                        {partList.map((item, i) => {
                          return (
                            <>
                              <EuropeanInput
                                hidePreview={true}
                                value={item.allotedquantity}
                                onChange={(value) => {
                                  setPartList([
                                    ...partList.slice(0, i),
                                    {
                                      ...partList[i],
                                      allotedquantity: value,
                                    },
                                    ...partList.slice(i + 1),
                                  ]);
                                }}
                                withoutSymbol={true}
                                className={roleName !== "User" ? " form-control suppInput1 fntSupp4 mt-2 mb-2 centertext" : " form-control suppInput1 fntSupp4 mt-2 mb-2 centertext field-disable1"}
                              />
                            </>
                          );
                        })}
                        <EuropeanInput
                          hidePreview={true}
                          value={allotedquantity}
                          onChange={(value) => setAllotedquantity(value)}
                          withoutSymbol={true}
                          className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-2 centertext" : "form-control suppInput1 fntSupp4 mt-2 centertext field-disable1"}
                        />
                      </div>
                      <div className="col-sm-3 col-12 text-start pt-xl-5 mt-xl-2 mt-sm-3 ps-4 ps-xl-3">
                        {partList.map((item, index) => {
                          return (
                            <>
                              <div className={roleName !== "User" ? "addProductFnt3 mt-2 mb-2" : "addProductFnt3 mt-2 mb-2 d-none"}
                                style={{ cursor: "pointer", height: "51px", display: "flex", alignItems: "center", }}
                              >
                                <img
                                  src={Group62}
                                  className="icons"
                                  alt="dlt"
                                  onClick={() => {
                                    let data = [...partList];
                                    data.splice(index, 1);
                                    setPartList(data);
                                  }}
                                />
                              </div>
                            </>
                          );
                        })}

                        <div className={roleName !== "User" ? "mt-1 pt-3 addProductFnt3" : "mt-1 pt-3 addProductFnt3 d-none"}
                          onClick={handleAddPart}
                        >
                          <button className={partname === "" ? "add_button" : "add_button addProductFnt3"}
                            disabled={
                              partname === ""
                              // partList.length === 0
                            }
                          >
                            Add...
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                {/* <div>
                  {partList.map((item) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "40px",
                        }}
                      >
                        <div>{item.partname}</div>
                        <div>{item.allotedquantity}</div>
                      </div>
                    );
                  })}
                </div> */}

                <div className="row mt-5 pt-5 ps-4 ms-1 pe-5  pb-5 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className={roleName !== "User" ? "row justify-content-end" : "d-none"}>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={() => history.push("/products")}
                          className="countBtncanc w-100 h-100 p-2"
                        >
                          CANCEL
                        </button>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={handleAdd}
                          className="countBtnchange w-100 p-2"
                        >
                          CHANGE
                        </button>
                      </div>
                    </div>

                    <div className={roleName === "User" ? "row justify-content-end" : "d-none"}>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={() => history.push("/products")}
                          className="countBtnchange w-100 p-2"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ComposeProduct;
