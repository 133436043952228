import axios from "axios";
import {
  USER_SIGNUP,
  USER_LOGIN,
  SEND_EMAIL,
  FORGOT_PASS,
  GOOGLE_SIGNUP,
  API_URL,
  ORDERSTATUS,
  USER_LOGOUT,
  STOCK_AVAILABLE,
  SHIPMENT_STATUS,
  NEXT_ORDER,
  CHANGE_STATUS,
  FETCH_ORDERDETAILS,
  FETCH_USERDETAILS,
  DEL_USERDETAILS,
  UPD_USERDETAILS,
  FETCH_ORDER,
  FETCH_TEAM,
  FETCH_AWORDER,
  FETCH_ROLE_MODULE,
  FETCH_WAREHOUSE,
  POST_ORDER,
  UPDATE_USER,
  FETCH_USER,
  FETCH_CLI,
  ADD_CLI,
  ADD_LOCATION,
  SEARCH_CONTACT,
  UPDATE_ORDER,
  FETCH_PROSTOCK,
  UPDATE_PROSTOCK,
  ADD_COMM,
  FETCH_COMM,
  FETCH_SUPP,
  FETCH_PARTS,
  SUGGEST_PART,
  SUGGEST_PRODUCT,
  ADD_SUPP,
  COM_PROD,
  DEL_SUPP,
  DEL_PROD,
  DEL_PART,
  FETCH_SUPPBYID,
  FETCH_PARTBYID,
  FETCH_PRODBYID,
  UPDATE_SUPP,
  FETCH_USERS,
  UPDATE_PART,
  UPDATE_PROD,
  CREATE_PART,
  UPLOAD_TO,
  FETCH_PARTS_LAST_PURCHASE,
  FETCH_CLIBYID,
  UPDATE_CLI,
  DEL_CLI,
  FETCH_CLIENT,
  LAST_PURCHASE,
  ADVISED_PART_PURCHASING,
  CREATE_PURCHASING,
  FETCH_ADVISED_PART_PURCHASING,
  FETCH_PURCHASING_ORDER,
  UPDATE_PART_DATA,
  UPDATE_STOCK,
  UPDATE_ADD_PART_STOCK,
  PURCHASE_ORDER_DELIVERED,
  FETCH_SUPP_BY_PART,
  UPDATE_PURCHASING,
  FETCH_USER_BYID,
  CHECK_ASSIGNEES,
  DEDUCT_PART_STOCK,
  ADD_ERROR_CODE,
  FETCH_ERROR_CODE,
  DOC_FILE_DOWNLOAD,
  FETCH_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PARK_STOCK,
  UPDATE_USER_VARIFY,
  DELETE_ORDER,
  DOWNLOAD_FILE,
  CALENDAR_DATE_UPDATE,
  DELETE_COMMENT,
  TIMELINE_PROPS,

} from "../constants/type";

export const userSignup =
  (
    firstname,
    lastname,
    email,
    address1,
    password,
    phoneNumber,
    costHour,
    imgUrl,
    availability,
    userstate,
    jobtitle,
    notes,
    teamid,
    role,
    companyId,
    contacts
  ) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/User/Create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          address: address1,
          password: password,
          phone: phoneNumber,
          isverified: "0",
          isagreetermcondition: "1",
          isdeleted: "0",
          costHour: costHour,
          imgUrl: imgUrl,
          isavailabilityblock: availability,
          userstate: userstate,
          currency: "",
          costHour: costHour,
          photo: imgUrl,
          jobtitle: jobtitle,
          notes: notes,
          teamid: teamid,
          roleid: role,
          companyid: companyId,
          useravailabilies: contacts,
        },
      }).then((response) => {
        dispatch({
          type: USER_SIGNUP,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const userLogin = (email, password) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/User/Login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email: email,
      password: password,
    },
  }).then((response) => {
    dispatch({
      type: USER_LOGIN,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const emailSend =
  (email, firstname, subject, eventtype) => (dispatch) => {
    axios({
      method: "post",
      url: `${API_URL}/api/User/SendEmail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        // subject: "Update Password",
        subject: subject,
        to: email,
        // eventtype: "Successfully Update Password",
        eventtype: eventtype,
        name: firstname,
      },
    }).then((response) => {
      dispatch({
        type: SEND_EMAIL,
        data: response.data,
      });
      return Promise.resolve([]);
    });
  };
export const forgotPassword =
  (userid, password, confirmPassword) => (dispatch) => {
    axios({
      method: "post",
      url: `${API_URL}/api/User/ForgotPassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userid: userid,
        password: password,
        confirmPassword: confirmPassword,
      },
    }).then((response) => {
      dispatch({
        type: FORGOT_PASS,
        data: response.data,
      });
      return Promise.resolve([]);
    });
  };

export const googleSignup = () => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/User/GoogleSignup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      client_id: "",
      redirect_uri: "",
    },
  }).then((response) => {
    dispatch({
      type: GOOGLE_SIGNUP,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const FetchOrdersbyStatus = (userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Board/FetchOrdersbyStatus`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      // "userid":"08da2f31-13c2-4839-8760-720f5f2c8262"
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: ORDERSTATUS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const user_logout = (userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/User/Logout`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: USER_LOGOUT,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const stockAvailable = (partid) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Board/StockAvailable`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      partid: partid,
    },
  }).then((response) => {
    dispatch({
      type: STOCK_AVAILABLE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const shipmentStatus = (statusId) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Board/OrdersCountByStatus`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      status: statusId,
    },
  }).then((response) => {
    dispatch({
      type: SHIPMENT_STATUS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const nextOrder = (userid) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Board/OrdersForNext30Days`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userid,
    },
  }).then((response) => {
    dispatch({
      type: NEXT_ORDER,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const changeStatus = (orderId, statusId) => async (dispatch) => {
  dispatch({ type: ORDERSTATUS, data: null });
  await axios({
    method: "post",
    url: `${API_URL}/api/Board/ChangeStatus`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderid: orderId,
      statusid: statusId,
    },
  }).then((response) => {
    dispatch({
      type: CHANGE_STATUS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const fetchOrderDetails = (orderID, userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Order/FetchOrdersbyId`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderid: orderID,
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_ORDERDETAILS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const fetchUserDetails = (pagenum, teamid) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Order/FetchUserByTeamId`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      teamid: teamid,
      pagenumber: pagenum,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_USERDETAILS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const deleteUserDetails = (userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/User/Delete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: DEL_USERDETAILS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
// export const editUserDetails =
//   (firstname,
//     lastname,
//     email,
//     address1,
//     phoneNumber,
//     UserId,
//     costHour,
//     imgUrl,
//     jobtitle,
//     notes,
//     teamid,
//     roleId,
//     companyId,
//     contacts) => async (dispatch) => {
//     await axios({
//       method: "post",
//       url: `${API_URL}/api/User/Update`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: {
//         userid: userID,
//         firstname: firstname,
//         lastname: lastname,
//         phone: phone,
//         teamid: teamid,
//       },
//     }).then((response) => {
//       dispatch({
//         type: UPD_USERDETAILS,
//         data: response.data,
//       });
//       return Promise.resolve([]);
//     });
//   };
export const fetchOrders = (userID, statusId) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Order/FetchOrderByUser`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userID,
      statusid: statusId,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_ORDER,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const fetchUserTeam = () => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Order/FetchTeam`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  }).then((response) => {
    dispatch({
      type: FETCH_TEAM,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const fetchAwaitingOrder = (userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Order/OrderAwaitingStock`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_AWORDER,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const fetchPurchasingOrders = (searchinventory) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Stock/FetchPurchasingOrders`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      querystring: "",
      pagenumber: 0
      // userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_PURCHASING_ORDER,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const FetchAdvisedPartPurchasing = (userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Stock/AdvisedPartPurchasing`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_ADVISED_PART_PURCHASING,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const fetchRoleModule = (roleId) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/User/FetchRolemodule`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      roleid: roleId,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_ROLE_MODULE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const fetchwarehouse = () => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Order/FetchWarehouse`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  }).then((response) => {
    dispatch({
      type: FETCH_WAREHOUSE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const CreateOrder =
  (
    userid,
    ordercode,
    warehouseid,
    statusid,
    clientId,
    contactId,
    valuelocation,
    installationName,
    deliverydate,
    type,
    isConcreteBlock,
    isspecialPart,
    isStockStatus,
    productid,
    product,
    teamuserid,
    orderDocument
  ) =>
    async (dispatch) => {
      await axios({
        method: "post",
        url: `${API_URL}/api/Order/CreateOrders`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: userid,
          ordercode: ordercode,
          warehouseid: warehouseid,
          statusid: statusid,
          clientid: clientId,
          contactid: contactId,
          deliverylocationid: valuelocation,
          installationname: installationName,
          deliverydate: deliverydate,
          type: type,
          isconcreteblock: isConcreteBlock,
          isspecialpart: isspecialPart,
          isstockstatus: isStockStatus,
          productid: productid,
          part: product,
          team: teamuserid,
          orderdocument: orderDocument,
        },
      }).then((response) => {
        dispatch({
          type: POST_ORDER,
          data: response.data,
        });

        return Promise.resolve([]);
      });
    };

export const updateUserDetails =
  (
    memberId,
    firstname,
    lastname,
    decodedPassword,
    phoneNumber,
    address1,
    costHour,
    notes,
    imgUrl,
    jobtitle,
    availability,
    userstate,
    teamid,
    role,
    contacts
  ) =>
    async (dispatch) => {
      await axios({
        method: "post",
        url: `${API_URL}/api/User/Update`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: memberId,
          firstname: firstname,
          lastname: lastname,
          password: decodedPassword,
          phone: phoneNumber,
          dob: "",
          address: address1,
          city: "",
          country: "",
          postalcode: "",
          costhour: costHour,
          notes: notes,
          photo: imgUrl,
          jobtitle: jobtitle,
          isavailabilityblock: availability,
          userstate: userstate,
          teamid: teamid,
          roleid: role,
          useravailability: contacts,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_USER,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const fetchUser = (userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/User/FetchUserById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_USER,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const fetchClient = (pagenumber, querystring) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Client/FetchClients`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      pagenumber: pagenumber,
      querystring: querystring,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_CLI,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const addClient =
  (userId, Name, address1, address2, email, phoneNumber, notes, contacts) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Client/AddClients`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: userId,
          name: Name,
          address1: address1,
          address2: address2,
          email: email,
          phonenumber: phoneNumber,
          notes: notes,
          isdeleted: 0,
          clientcontact: contacts,
        },
      }).then((response) => {
        dispatch({
          type: ADD_CLI,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const updateClient =
  (userid, clientId, Name, address1, address2, email, phoneNumber, notes, contacts) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Client/UpdateClients`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid,
          clientid: clientId,
          name: Name,
          address1: address1,
          address2: address2,
          email: email,
          phonenumber: phoneNumber,
          notes: notes,
          contact: contacts,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_CLI,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };
export const fetchClientsById = (clientid) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Client/FetchClientsById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      clientid: clientid,
    },
  }).then((response) => {
    const data = response.data;

    dispatch({
      type: FETCH_CLIBYID,
      data,
    });

    return Promise.resolve([]);
  });
};

export const deleteClients = (clientid) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Client/DeleteClients`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      clientid: clientid,
    },
  }).then((response) => {
    dispatch({
      type: DEL_CLI,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const addLocation = (userId, locationAddress) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Order/AddLocation`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userId,
      locationname: "",
      locationtype: "",
      locationaddress: locationAddress,
      city: "",
      state: "",
      country: "",
      streetname: "",
      pincode: "",
    },
  }).then((response) => {
    dispatch({
      type: ADD_LOCATION,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const searchContact = (search, calid) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Client/SearchClientContacts`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      querystring: search,
      clientid: calid,
    },
  }).then((response) => {
    dispatch({
      type: SEARCH_CONTACT,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const updateOrderdetails =
  (
    orderid,
    userid,
    statusid,
    clientid,
    contactid,
    // creationDate,
    deliverylocationid,
    installationname,
    deliverydate,
    type,
    isconcreteblock,
    isspecialpart,
    isstockstatus,
    orderCode,
    name,
    email,
    phonenumber,
    orderdocumentid,
    fileid,
    handleArray,
    partId,
    partAllot
  ) =>
    async (dispatch) => {
      await axios({
        method: "post",
        url: `${API_URL}/api/Order/UpdateOrder`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          orderid: orderid,
          userid: userid,
          statusid: statusid,
          clientid: clientid,
          contactid: contactid,
          // createdat: creationDate,
          deliverylocationid: deliverylocationid,
          installationname: installationname,
          ordercode: orderCode,
          deliverydate: deliverydate,
          type: type,
          isconcreteblock: isconcreteblock,
          isspecialpart: isspecialpart,
          isstockstatus: isstockstatus,
          name: name,
          email: email,
          phonenumber: phonenumber,
          orderdocs: fileid ? [
            {
              orderdocumentid: orderdocumentid,
              fileid: fileid,
            },
          ] : [],
          teams: handleArray,
          orderpart: [
            {
              partid: partId,
              quantity: partAllot,
              check: 0,
            },
          ],
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_ORDER,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };
export const fetchProductStock = (pagenumber, querystring, type) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/FetchProductParts`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      pagenumber: pagenumber,
      querystring: querystring,
      type: type,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_PROSTOCK,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const updateProductStock = (productArray) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/UpdateProductStock`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { products: productArray, parts: [] },
  }).then((response) => {
    dispatch({
      type: UPDATE_PROSTOCK,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const UpdatePartStock = (productArray) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/UpdatePartStock`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { parts: productArray },
  }).then((response) => {
    dispatch({
      type: UPDATE_PARK_STOCK,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const addComments =
  (userid, relatedtoid, message, type, attachments) => (dispatch) => {
    dispatch({ type: ADD_COMM, data: {} });
    axios({
      method: "post",
      url: `${API_URL}/api/Order/AddComments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userid: userid,
        relatedtoid: relatedtoid,
        message: message,
        type: type ? type : "product",
        attachments: attachments,
      },
    }).then((response) => {
      dispatch({
        type: ADD_COMM,
        data: response.data,
      });
      return Promise.resolve([]);
    });
  };

export const fetchComments = (relatedtoid, type) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Order/FetchComments`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      relatedtoid: relatedtoid,
      type: type ? type : "product",
    },
  }).then((response) => {
    dispatch({
      type: FETCH_COMM,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const UpdateStore = (partid, qty) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Stock/UpdateStock`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      partid: partid,
      qty: qty,
    },
  }).then((response) => {
    dispatch({
      type: UPDATE_STOCK,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const AddPartToPurchaseOrder = (userId, orderid, partid, orderqty) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Stock/AddPartToPurchaseOrder`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userId,
      orderid: orderid,
      addPartToPurchaseOrder: [
        {
          partid: partid,
          orderqty: orderqty
        }
      ]

    },
  }).then((response) => {
    dispatch({
      type: UPDATE_ADD_PART_STOCK,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};


export const PurchaseOrderDelivered = (orderid, statusid) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Stock/PurchaseOrderDelivered`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderid: orderid,
      statusid: statusid,
    },
  }).then((response) => {
    dispatch({
      type: PURCHASE_ORDER_DELIVERED.toString(),
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

// suggest part start

export const fetchPartSuggest = (querystring) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/SuggestedPart`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      querystring: querystring,
    },
  }).then((response) => {
    dispatch({
      type: SUGGEST_PART,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

// suggest part end

// suggest product start

export const fetchProductSuggest = (querystring) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/SuggestedProduct`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      querystring: querystring,
    },
  }).then((response) => {
    dispatch({
      type: SUGGEST_PRODUCT,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

// suggest product end


export const fetchSuppliers = (pagenumber, querystring) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Supplier/FetchSuppliers`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      pagenumber: pagenumber,
      querystring: querystring,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_SUPP,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const FetchPartsBySupplier = (supplierid) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Stock/FetchPartsBySupplier`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      supplierid: supplierid,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_SUPP_BY_PART,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const fetchParts = () => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/FetchParts`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  }).then((response) => {
    dispatch({
      type: FETCH_PARTS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

// export const fetchUsers = () => (dispatch) => {
export const fetchUsers = (pagenumber, searchteamlist) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/User/FetchUsers`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      pagenumber: pagenumber,
      querystring: searchteamlist,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_USERS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const addSupplier =
  (userId, Name, address1, address2, email, phoneNumber, notes, contacts) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Supplier/AddSupplier`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: userId,
          name: Name,
          address1: address1,
          address2: address2,
          email: email,
          phonenumber: phoneNumber,
          notes: notes,
          isdeleted: 0,
          contacts: contacts,
        },
      }).then((response) => {
        dispatch({
          type: ADD_SUPP,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };
export const ComposeProducts =
  (
    productId,
    productName,
    productdescription,
    internalid,
    unitprice,
    totalcurrentquantity,
    min,
    max,
    imageurl,
    productparts
  ) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Products/ComposeProducts`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: productId,
          productname: productName,
          productdescription: productdescription,
          productcode: internalid,
          unitprice: unitprice,
          totalcurrentquantity: totalcurrentquantity,
          mincount: min,
          maxcount: max,
          imageurl: imageurl,
          productparts: productparts,
        },
      }).then((response) => {
        dispatch({
          type: COM_PROD,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const CreatePart =
  (UserId, partName, internalid, instock, max, min, partsuppliers) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Products/CreatePart`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: UserId,
          partname: partName,
          internalid: internalid,
          instock: instock,
          maxcount: max,
          mincount: min,
          partsuppliers: partsuppliers,
        },
      }).then((response) => {
        dispatch({
          type: CREATE_PART,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };
// export const addSupplier =
//   (userId, Name, address1, address2, email, phoneNumber, notes, contacts) =>
//   (dispatch) => {
//     axios({
//       method: "post",
//       url: `${API_URL}/api/Orders/AddSupplier`,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: {
//         userid: userId,
//         name: Name,
//         address1: address1,
//         address2: address2,
//         email: email,
//         phonenumber: phoneNumber,
//         notes: notes,
//         isdeleted: 0,
//         contacts: contacts,
//       },
//     }).then((response) => {
//       dispatch({
//         type: ADD_SUPP,
//         data: response.data,
//       });
//       return Promise.resolve([]);
//     });
//   };

export const deleteSuppliers = (supplierId) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Supplier/DeleteSupplier`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      supplierid: supplierId,
    },
  }).then((response) => {
    dispatch({
      type: DEL_SUPP,

      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const deleteProduct = (productId) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/DeleteProduct`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      productid: productId,
    },
  }).then((response) => {
    dispatch({
      type: DEL_PROD,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const deletePart = (productId) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/DeletePart`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      partid: productId,
      userid: window.sessionStorage.getItem("userid")
    },
  }).then((response) => {
    dispatch({
      type: DEL_PART,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const fetchSuppliersById = (supplierId) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Supplier/FetchSuppliersById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      supplierid: supplierId,
    },
  }).then((response) => {
    const data = response.data;

    dispatch({
      type: FETCH_SUPPBYID,
      data,
    });

    return Promise.resolve([]);
  });
};
export const updateSupplier =
  (supplierId, Name, address1, address2, email, phoneNumber, notes, contacts) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Supplier/UpdateSuppliers`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          supplierid: supplierId,
          name: Name,
          address1: address1,
          address2: address2,
          email: email,
          phonenumber: phoneNumber,
          notes: notes,

          contactdetails: contacts,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_SUPP,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const LastPurchase = (id) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Stock/LastPurchase`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      supplierid: id,
    },
  }).then((response) => {
    dispatch({
      type: LAST_PURCHASE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const FetchPurchasingOrdersById = (id) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Stock/FetchPurchasingOrdersById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      purchaseorderid: id,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_PARTS_LAST_PURCHASE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};


export const UploadToS3 = (imageurl) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Files/UploadToS3`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      image: imageurl,
    },
  }).then((response) => {
    dispatch({
      type: UPLOAD_TO,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const updatePart =
  (UserId, partid, name, internal, instock, max, min, partsuppliers) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Products/UpdatePart`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: UserId,
          partid: partid,
          partname: name,
          internalid: internal,
          instock: instock,
          maxcount: max,
          mincount: min,
          partsupplier: partsuppliers,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_PART,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };
export const updateProduct =
  (
    UserId,
    productid,
    productName,
    unitprice,
    internalid,
    imageurl,
    productpart
  ) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Products/UpdateProduct`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: UserId,
          productid: productid,
          productname: productName,
          unitprice: unitprice,
          productcode: internalid,
          imageurl: imageurl,
          productpart: productpart,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_PROD,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };
export const fetchPartsById = (partID) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/FetchPartsById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      partid: partID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_PARTBYID,
      data: response.data,
    });

    return Promise.resolve([]);
  });
};
export const fetchProductById = (partID) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/FetchProductById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      productid: partID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_PRODBYID,
      data: response.data,
    });

    return Promise.resolve([]);
  });
};

export const FetchParts = () => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Products/FetchParts`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  }).then((response) => {
    dispatch({
      type: FETCH_PARTS,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const AdvisedPartPurchasing = () => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Stock/AdvisedPartPurchasing`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  }).then((response) => {
    dispatch({
      type: ADVISED_PART_PURCHASING,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const CreatePurchasingOrders =
  (userID, ordercode, leadtime, deliverydate, supplierid, type, orderparts) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Stock/CreatePurchasingOrders`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: userID,
          // ordercode: ordercode,
          leadtime: leadtime,
          ordertotal: ordercode,
          supplierid: supplierid,
          deliverydate: deliverydate,
          type: type,
          // orderparts: orderparts,
          orderparts: orderparts,
          // orderparts:[{partid:partid,
          //   orderquantity:orderquantity}]
        },
      }).then((response) => {
        dispatch({
          type: CREATE_PURCHASING,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const UpdatePurchasingOrders =
  (
    userID,
    orderid,
    leadtime,
    ordertotal,
    supplierid,
    statusid,
    deliverydate,
    type,
    orderparts
  ) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Stock/UpdatePurchasingOrder`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: userID,
          orderid: orderid,
          leadtime: leadtime,
          ordertotal: ordertotal,
          statusid: statusid,
          supplierid: supplierid,
          deliverydate: deliverydate,
          type: type,
          orderpart: orderparts,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_PURCHASING,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const UpdatePartData =
  (
    userID,
    partid,
    partname,
    internalid,
    instock,
    maxcount = 0,
    mincount = 0,
    supplierid,
    ordercode,
    unitprice = 0,
    leadtime = new Date().toISOString().toString()
  ) =>
    (dispatch) => {
      axios({
        method: "post",
        url: `${API_URL}/api/Products/UpdatePart`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: userID.toString(),
          partid: partid.toString(),
          partname: partname.toString(),
          internalid: internalid.toString(),
          instock: instock.toString(),
          maxcount: maxcount.toString(),
          mincount: mincount.toString(),
          partsupplier: [
            {
              supplierid: supplierid.toString(),
              suppliercode: ordercode.toString(),
              unitprice: unitprice.toString(),
              leadtime: leadtime,
            },
          ],
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_PART_DATA,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const FetchUserById = (memberId) => (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/User/FetchUserById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: memberId,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_USER_BYID,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const chechAssignees = (orderid) => async (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Board/Assignees`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderid: orderid,
    },
  }).then((response) => {
    dispatch({
      type: CHECK_ASSIGNEES,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const deductPartStock = (orderid) => async (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Board/DeductPartStock`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderid: orderid,
    },
  }).then((response) => {
    dispatch({
      type: DEDUCT_PART_STOCK,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};
export const addErrorCode =
  (progressErrorIds, queueErrorIds) => async (dispatch) => {
    axios({
      method: "post",
      url: `${API_URL}/api/Board/AddErrorCode`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        progressErrorIds: progressErrorIds,
        queueErrorIds: queueErrorIds,
      },
    }).then((response) => {
      dispatch({
        type: ADD_ERROR_CODE,
        data: response.data,
      });
      return Promise.resolve([]);
    });
  };

export const fetchErrorCode = () => async (dispatch) => {
  axios({
    method: "post",
    url: `${API_URL}/api/Board/FetchErrorCode`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  }).then((response) => {
    dispatch({
      type: FETCH_ERROR_CODE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const docfiledownload =
  (orderId, suppliername, address1, address2, date, email, part) =>
    async (dispatch) => {
      await axios({
        method: "post",
        url: `${API_URL}/DocFile`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          orderId: orderId,
          suppliername: suppliername,
          address1: address1,
          address2: address2,
          date: date,
          email: email,
          tableDatas: part,
        },
      }).then((response) => {
        dispatch({
          type: DOC_FILE_DOWNLOAD,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export default function App() {
  return (
    <input
      type="number"
      min={0}
      step={1}
      onKeyPress={(event) => {
        if (event.charCode < 48) {
          event.preventDefault();
        }
      }}
    />
  );
}
export const fetchprofilebyid = (userID) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/User/FetchProfileById`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userid: userID,
    },
  }).then((response) => {
    dispatch({
      type: FETCH_PROFILE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const updateprofile =
  (
    memberId,
    firstname,
    lastname,
    phone,
    address,
    // decodedPassword,
    // phoneNumber,
    // address1,
    // costHour,
    // notes,
    // imgUrl,
    // jobtitle,
    // availability,
    // teamid,
    // role,
    // contacts
  ) =>
    async (dispatch) => {
      await axios({
        method: "post",
        url: `${API_URL}/api/User/UpdateProfile`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: memberId,
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          address: address,
          // phone: phoneNumber,
          // dob: "",
          // address: address1,
          // city: "",
          // country: "",
          // postalcode: "",
          // costhour: costHour,
          // notes: notes,
          // photo: imgUrl,
          // jobtitle: jobtitle,
          // isavailabilityblock: availability,
          // teamid: teamid,
          // roleid: role,
          // useravailability: contacts,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_PROFILE,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };


export const updateUserVerifySignup =
  (
    userId,

  ) =>
    async (dispatch) => {
      await axios({
        method: "post",
        url: `${API_URL}/api/User/VerifySignup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userid: userId,
          isverified: 1,
        },
      }).then((response) => {
        dispatch({
          type: UPDATE_USER_VARIFY,
          data: response.data,
        });
        return Promise.resolve([]);
      });
    };

export const deleteOrder = (orderId) => (dispatch) => {
  axios.delete(`${API_URL}/api/order/${orderId}`).then((response) => {
    dispatch({
      type: DELETE_ORDER,
      data: response.data,
    });
  });
};

export const DownloadFile = (fileid) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/Files/DownloadFile`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      fileid: fileid
    },
  }).then((response) => {
    dispatch({
      type: DOWNLOAD_FILE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const CalendarDataUpdate = (orderid, type, statusid, duedatetime) => async (dispatch) => {
  await axios({
    method: "post",
    url: `${API_URL}/api/User/CalendarDataUpdate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      orderid: orderid,
      type: type,
      statusid: statusid,
      duedatetime: duedatetime,
    },
  }).then((response) => {
    dispatch({
      type: CALENDAR_DATE_UPDATE,
      data: response.data,
    });
    return Promise.resolve([]);
  });
};

export const deleteComment = (commentid) => (dispatch) => {
  dispatch({ type: DELETE_COMMENT, data: {} });
  axios.post(`${API_URL}/api/Order/DeleteComment`, { commentid }).then((response) => {
    dispatch({ type: DELETE_COMMENT, data: response.data });
  });
};

export const updateOrdersIndexPositions = (orders) => (dispatch) => {
  axios.post(`${API_URL}/api/Board/UpdateIndexPositions`, orders).then((response) => {
  });
};

// ASC REDUX PROPS START //
export const Timeline_Props = (timeline_props) => {
  return {
    type: TIMELINE_PROPS,
    data: timeline_props
  };
};
// ASC REDUX PROPS END //