import { DELETE_COMMENT, DELETE_ORDER } from "../constants/type";

const initialState = {
  signupData: {},
  loginData: null,
  forgotPassEmail: {},
  changePassword: {},
  googleSignupData: {},
  fetchOrderStatus: null,
  userLogout: {},
  stockAvailable: null,
  nextOrder: {},
  shipmentStatus: null,
  changeStatus: null,
  fetchOrderDetailsRes: {},
  fetchUserDetails: {},
  delUserDetails: {},
  updUserDetails: {},
  fetchOrder: {},
  fetchTeamDetails: {},
  fetchAwaitorder: {},
  fetchRoleModule: {},
  fetchWarehouse: {},
  createOrder: {},
  updateUserData: {},
  fetchUserData: {},
  fetchClientData: {},
  fetchClient: {},
  addClient: {},
  addLocationData: {},
  searchContactData: {},
  updateOrder: {},
  fetchStockData: {},
  updateStockData: {},
  updateAddPartStockData: {},
  fetchComment: {},
  addComment: {},
  fetchSuppliers: {},
  fetchUsers: {},
  fetchParts: {},
  CreatePart: {},
  fetchProductStock: {},
  addSupplier: {},
  ComposeProducts: {},
  deleteSuppliers: {},
  deleteProduct: {},
  deletePart: {},
  fetchSuppliersById: {},
  fetchPartsById: {},
  fetchProductById: {},
  updateSupplier: {},
  updatePart: {},
  updateProduct: {},
  UploadToS3: [],
  deleteClients: {},
  suggestPart: {},
  suggestProduct: {},
  fetchSuppliersById: {},
  fetchClientsById: {},
  updateSupplier: {},
  updateClient: {},
  fetchAdvisedPartOrder: {},
  fetchPurchasingOrder: {},
  docFileDownload: {},
  updatePartOrder: {},
  updateStock: {},
  purchaseOrderDelivered: {},
  fetchPartBySupplier: {},
  lastPurchase: {},
  AdvisedPartPurchasing: {},
  CreatePurchasingOrder: {},
  FetchUserById: {},
  checkAssignessResponse: {},
  deductPartStockResponse: {},
  addErrorCodeResponse: {},
  fetchErrorCodeResponse: {},
  fetchProfileData: {},
  updateProfileData: {},
  updateUserVarify: {},
  deleteOrder: {},
  downloadFile: {},
  calendarDateUpdate: {},
  deleteComment: {},
  timeline_props: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_SIGNUP":
      return {
        ...state,
        signupData: action.data,
      };
    case "USER_LOGIN":
      return {
        ...state,
        loginData: action.data,
      };
    case "SEND_EMAIL":
      return {
        ...state,
        forgotPassEmail: action.data,
      };
    case "FORGOT_PASS":
      return {
        ...state,
        changePassword: action.data,
      };
    case "GOOGLE_SIGNUP":
      return {
        ...state,
        googleSignupData: action.data,
      };
    case "ORDERSTATUS":
      return {
        ...state,
        fetchOrderStatus: action.data,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        userLogout: action.data,
      };
    case "STOCK_AVAILABLE":
      return {
        ...state,
        stockAvailable: action.data,
      };
    case "SHIPMENT_STATUS":
      return {
        ...state,
        shipmentStatus: action.data,
      };
    case "NEXT_ORDER":
      return {
        ...state,
        nextOrder: action.data,
      };
    case "CHANGE_STATUS":
      return {
        ...state,
        changeStatus: action.data,
      };
    case "FETCH_ORDERDETAILS":
      return {
        ...state,
        fetchOrderDetailsRes: action.data,
      };
    case "FETCH_USERDETAILS":
      return {
        ...state,
        fetchUserDetails: action.data,
      };
    case "DEL_USERDETAILS":
      return {
        ...state,
        delUserDetails: action.data,
      };
    case "UPD_USERDETAILS":
      return {
        ...state,
        updUserDetails: action.data,
      };
    case "FETCH_ORDER":
      return {
        ...state,
        fetchOrder: action.data,
      };
    case "FETCH_TEAM":
      return {
        ...state,
        fetchTeamDetails: action.data,
      };
    case "FETCH_AWORDER":
      return {
        ...state,
        fetchAwaitorder: action.data,
      };
    case "FETCH_ADVISED_PART_PURCHASING":
      return {
        ...state,
        fetchAdvisedPartOrder: action.data,
      };
    case "FETCH_PURCHASING_ORDER":
      return {
        ...state,
        fetchPurchasingOrder: action.data,
      };
    case "DOC_FILE_DOWNLOAD":
      return {
        ...state,
        docFileDownload: action.Data,
      };
    case "UPDATE_PART_DATA":
      return {
        ...state,
        updatePartOrder: action.data,
      };
    case "FETCH_ROLE_MODULE":
      return {
        ...state,
        roleModuleData: action.data,
      };
    case "FETCH_WAREHOUSE":
      return {
        ...state,
        fetchWarehouse: action.data,
      };
    case "POST_ORDER":
      return {
        ...state,
        createOrder: action.data,
      };
    case "UPDATE_USER":
      return {
        ...state,
        updateUserData: action.data,
      };
    case "FETCH_USER":
      return {
        ...state,
        fetchUserData: action.data,
      };

    case "FETCH_CLIENT":
      return {
        ...state,
        fetchClientData: action.data,
      };

    case "ADD_LOCATION":
      return {
        ...state,
        addLocationData: action.data,
      };
    case "SEARCH_CONTACT":
      return {
        ...state,
        searchContactData: action.data,
      };
    case "UPDATE_ORDER":
      return {
        ...state,
        updateOrder: action.data,
      };
    // case "FETCH_PROSTOCK":
    //   return {
    //     ...state,
    //     fetchStockData: action.data,
    //   };
    case "UPDATE_PROSTOCK":
      return {
        ...state,
        updateStockData: action.data,
      };
    case "FETCH_COMM":
      return {
        ...state,
        fetchComment: action.data,
      };
    case "UPDATE_PARK_STOCK":
      return {
        ...state,
        updateStockData: action.data,
      };
    case "ADD_COMM":
      return {
        ...state,
        addComment: action.data,
      };
    case "FETCH_SUPP":
      return {
        ...state,
        fetchSuppliers: action.data,
      };

    case "SUGGEST_PART":
      return {
        ...state,
        suggestPart: action.data,
      };

    case "SUGGEST_PRODUCT":
      return {
        ...state,
        suggestProduct: action.data,
      };

    case "FETCH_USERS":
      return {
        ...state,
        fetchUsers: action.data,
      };

    case "FETCH_PARTS":
      return {
        ...state,
        fetchParts: action.data,
      };
    case "FETCH_PROSTOCK":
      return {
        ...state,
        fetchProductStock: action.data,
      };
    case "CREATE_PART":
      return {
        ...state,
        CreatePart: action.data,
      };
    case "ADD_SUPP":
      return {
        ...state,
        addSupplier: action.data,
      };
    case "COM_PROD":
      return {
        ...state,
        ComposeProducts: action.data,
      };
    case "DEL_SUPP":
      return {
        ...state,
        deleteSuppliers: action.data,
      };
    case "DEL_PROD":
      return {
        ...state,
        deleteProduct: action.data,
      };
    case "DEL_PART":
      return {
        ...state,
        deletePart: action.data,
      };
    case "FETCH_SUPPBYID":
      return {
        ...state,
        fetchSuppliersById: action.data,
      };
    case "FETCH_PARTBYID":
      return {
        ...state,
        fetchPartsById: action.data,
      };
    case "FETCH_PRODBYID":
      return {
        ...state,
        fetchProductById: action.data,
      };
    case "UPDATE_SUPP":
      return {
        ...state,
        updateSupplier: action.data,
      };
    case "UPDATE_PART":
      return {
        ...state,
        updatePart: action.data,
      };
    case "UPDATE_PROD":
      return {
        ...state,
        updateProduct: action.data,
      };
    case "UPLOAD_TO":
      return {
        ...state,
        UploadToS3: action.data,
      };
    case "UPDATE_CLI":
      return {
        ...state,
        updateClient: action.data,
      };
    case "FETCH_CLI":
      return {
        ...state,
        fetchClient: action.data,
      };
    case "ADD_CLI":
      return {
        ...state,
        addClient: action.data,
      };
    case "DEL_CLI":
      return {
        ...state,
        deleteClients: action.data,
      };
    case "FETCH_CLIBYID":
      return {
        ...state,
        fetchClientsById: action.data,
      };

    //   default:
    //     return { ...state };
    // }

    case "PURCHASE_ORDER_DELIVERED":
      return {
        ...state,
        purchaseOrderDelivered: action.data,
      };
    case "UPDATE_STOCK":
      return {
        ...state,
        updateStockData: action.data,
      };
    case "UPDATE_ADD_PART_STOCK":
      return {
        ...state,
        updateAddPartStockData: action.data,
      };

    case "FETCH_SUPP_BY_PART":
      return {
        ...state,
        fetchPartBySupplier: action.data,
      };

    case "LAST_PURCHASE":
      return {
        ...state,
        lastPurchase: action.data,
      };
    case "FETCH_PARTS_LAST_PURCHASE":
      return {
        ...state,
        lastPartsPurchase: action.data,
      };

    case "ADVISED_PART_PURCHASING":
      return {
        ...state,
        AdvisedPartPurchasing: action.data,
      };
    case "CREATE_PURCHASING":
      return {
        ...state,
        CreatePurchasingOrder: action.data,
      };
    case "UPDATE_PURCHASING":
      return {
        ...state,
        updatePurchasing: action.data,
      };
    case "FETCH_USER_BYID":
      return {
        ...state,
        FetchUserById: action.data,
      };
    case "CHECK_ASSIGNEES":
      return {
        ...state,
        checkAssignessResponse: action.data,
      };
    case "DEDUCT_PART_STOCK":
      return {
        ...state,
        deductPartStockResponse: action.data,
      };
    case "ADD_ERROR_CODE":
      return {
        ...state,
        addErrorCodeResponse: action.data,
      };
    default:
      return { ...state };
    case "FETCH_ERROR_CODE":
      return {
        ...state,
        fetchErrorCodeResponse: action.data,
      };

    case "FETCH_PROFILE":
      return {
        ...state,
        fetchProfileData: action.data,
      };

    case "UPDATE_PROFILE":
      return {
        ...state,
        updateProfileData: action.data,
      };

    case "UPDATE_USER_VARIFY":
      return {
        ...state,
        updateUserVarify: action.data,
      };
    case DELETE_ORDER:
      return {
        ...state,
        deleteOrder: action.data,
      }

    case "DOWNLOAD_FILE":
      return {
        ...state,
        downloadFile: action.data,
      }

    case "CALENDAR_DATE_UPDATE":
      return {
        ...state,
        calendarDateUpdate: action.data,
      }
    case DELETE_COMMENT:
      return {
        ...state,
        deleteComment: action.data,
      }

    // ASC REDUX PROPS START //
    case "TIMELINE_PROPS":
      return {
        ...state,
        timeline_props: action.data,
      };
    // ASC REDUX PROPS END //
  }
};

export default userReducer;
