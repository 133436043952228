import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/local/ordercreated.css";
const OrderCreated = (props) => {
  const [status, setStatus] = useState("");

  React.useEffect(() => {
    if (props.statusId == "08da2f31-46b4-4ce8-80b3-fdffac7a3058") {
      setStatus("Pipeline");
    } else if (props.statusId == "08da2f31-46b4-4ce8-80b3-fdffac7a3059") {
      setStatus("Queue");
    } else if (props.statusId == "08da2f31-46b4-4ce8-80b3-fdffac7a3062") {
      setStatus("Complete");
    } else if (props.statusId == "08da2f31-46b4-4ce8-80b3-fdffac7a3061") {
      setStatus("Ready To Ship");
    } else {
      setStatus("In Progress");
    }
  }, []);
  return (
    <div>
      <Modal
        className="createordermodalone"
        animation={false}
        show={true}
        centered
      >
        <ModalBody className="px-3 pb-0 modalbody">
          <div className="container px-5">
            <div className="row text-center">
              <p>
                The <span className="fw-bold">Quote ID {props.quoteId}</span> was
                created
                <br /> successfully and moved to Pipeline.
              </p>
              <p className="pt-2 pb-5">Do you want to Add another Order?</p>
            </div>
          </div>
          {/* <div className='row text-center px-0 mt-2'  style={{backgroundColor: "white"}}>
                <div className='col px-0 '><button className='w-100 fw-bold' style={{height:"65px",backgroundColor: "white" ,color:"#003766"}} type='button' onClick={props.handleCancel}>No</button></div>
                        <div className='col px-0 '><button className='w-100 aDDbtn fw-bold' style={{height:"65px",backgroundColor: "white" ,color:"#003766"}} type='button' onClick={(e)=>{props.handleAdd()}}>Add</button></div>

                    </div> */}
        </ModalBody>
        <ModalFooter className="modalfooter">
          <div className="row ">
            <div className="col-6 pe-0 text-center">
              <button
                className="nobtn"
                style={{ fontWeight: "500" }}
                type="button"
                onClick={props.handleCancel}
              >
                NO
              </button>
            </div>
            <div className="col-6 ps-0 text-center">
              <button
                className=" addbtn fw-bold"
                type="button"
                onClick={(e) => {
                  props.handleAdd();
                }}
              >
                ADD
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default OrderCreated;
