import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import warehouseIcon from "../../assets/icons/warehouseIcon.svg";
import ReturnWarehouse from "../../assets/icons/ReturnWarehouse.svg";
import Stock from "../../assets/icons/Stock.svg";
import PurchasingOrder from "../../assets/icons/PurchasingOrder.svg";
import onetwo from "../../assets/icons/onetwo.svg";
import "../../styles/local/inventory.css";
import orderIcon from "../../assets/icons/order.svg";
import Advisedpart from "../../assets/icons/Advisedpart.svg";
import calender from "../../assets/icons/calender.svg";
import Arrow from "../../assets/icons/arrow.svg";
import ArrowCh from "../../assets/icons/arrowch.svg";
import PDFIcon from "../../assets/icons/Docx.svg"
import Shelter from "../../assets/icons/blockimg.png";
import awaitingorder from "../../lang/awaitingOrder.json";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchAdvisedPartPurchasing,
  fetchAwaitingOrder,
  fetchPurchasingOrders,
  stockAvailable,
  docfiledownload,
} from "../../redux/actions/action";
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import Headerinventory from "../../components/dialogs/Headerinventory";
import { API_URL } from "..//..//redux/constants/type";
import { saveAs } from "file-saver";
import { europeanFormat, stockQuntityFormat } from "../../utils/NumberFormatter";

const MAX_ITEMS = 5;
const MAX_ITEMS1 = 10;
const formatDate = (value) => {
  let date = new Date(value);
  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.toLocaleString("default", { year: "numeric" });
  return day + "." + month + "." + year;
};

const Inventory = () => {
  const stockData = useSelector((state) => state.user.stockAvailable);
  // const docFileDownload = useSelector((state) => state.user.docFileDownload);
  const dispatch = useDispatch();
  const userId = window.sessionStorage.getItem("userid");
  const roleName = window.sessionStorage.getItem("rolename");
  const AwaitOrder = useSelector((state) => state.user.fetchAwaitorder);
  const PurchasingOrders = useSelector(
    (state) => state.user.fetchPurchasingOrder
  );

  const docfile = useSelector((state) => state.user.docFileDownload);

  const AdvisedPartOrder = useSelector(
    (state) => state.user.fetchAdvisedPartOrder
  );
  const history = useHistory();
  const initialRender = React.useRef(true);
  const finDate = new Date(date);
  var date = finDate.toString().split(" ");
  const [ awaitData, setAwaitData ] = useState([]);
  const [ inventorysearch, setInventorysearch ] = useState("");
  const [ searchinventory, setSearchinventory ] = useState("");
  const [ advisedOrder, setAdvisedOrder ] = useState([]);
  const [ stockavailable1, setStockavailable1 ] = useState([]);
  const [ purchaseOrders, setPurchaseOrders ] = useState([]);
  const [ docfiledata, setDocfiledata ] = useState([]);
  const [ createdate, setCreateDate ] = useState([]);
  const [ showMore, setShowMore ] = useState(false);
  const [ showMore1, setShowMore1 ] = useState(false);
  const [ sortDate, setSortDate ] = useState(false);
  const [ sortId, setSortId ] = useState(false);
  const [ sortorder, setSortOrder ] = useState(false);
  const [ suppliername, setSuppliername ] = useState("");
  const [ orderId, setOrderId ] = useState("");
  const [ address1, setAddress1 ] = useState("");
  const [ address2, setAddress2 ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ supplierCode, setSupplierCode ] = useState("");
  const [ internalCode, setInternalCode ] = useState("");
  const [ orderQty, setOrderQty ] = useState("");
  const [ part, setPart ] = useState("");
  const [ createdat, setCreatedat ] = useState([]);
  console.log("createdat", createdat)

  useEffect(() => {
    dispatch(stockAvailable("08da2f31-46b4-4ce8-80b3-fdffac7a3080"));
    dispatch(fetchAwaitingOrder(userId));
    dispatch(FetchAdvisedPartPurchasing(userId));
    dispatch(fetchPurchasingOrders(searchinventory));
  }, [
    fetchAwaitingOrder,
    stockAvailable,
    FetchAdvisedPartPurchasing,
    fetchPurchasingOrders,
  ]);

  useEffect(() => {
    if (!initialRender.current) {
      if (AwaitOrder != null) {
        if (AwaitOrder.status == true) {
          //props are coming from landigPage.js
          // props.clickLogin();

          setAwaitData(AwaitOrder.data);
          for (var i = 0; i < AwaitOrder.data.length; i++) {
            var arr = [];
            arr.push(AwaitOrder.data[ i ]);
          }

          // setCreateDate(AwaitOrder.data);
          // setMsg("user successfully created");
        } else {
          // alert(AwaitOrder.message);
          // setMsg(AwaitOrder.message);
          // setAlert(true);
        }
      }
      if (AdvisedPartOrder != null) {
        if (AdvisedPartOrder.status == true) {
          //props are coming from landigPage.js
          // props.clickLogin();
          setAdvisedOrder(AdvisedPartOrder.data);
        } else {
          // alert(AwaitOrder.message);
          // setMsg(AwaitOrder.message);
          // setAlert(true);
        }
      }

      if (stockData != null) {
        if (stockData.status == true) {
          //props are coming from landigPage.js
          // props.clickLogin();
          setStockavailable1(stockData.data);
        } else {
          // alert(AwaitOrder.message);
          // setMsg(AwaitOrder.message);
          // setAlert(true);
        }
      }

      if (PurchasingOrders != null) {
        if (PurchasingOrders.status == true) {
          //props are coming from landigPage.js
          // props.clickLogin();
          setPurchaseOrders(PurchasingOrders.data);
          setCreatedat(PurchasingOrders.data[ 0 ].createdat)
        } else {
          // alert(AwaitOrder.message);
          // setMsg(AwaitOrder.message);
          // setAlert(true);
        }
      }

      if (docfile != null) {
        if (docfile.status == true) {
          //props are coming from landigPage.js
          // props.clickLogin();
          setDocfiledata(docfile.data);
        } else {
          // alert(AwaitOrder.message);
          // setMsg(AwaitOrder.message);
          // setAlert(true);
        }
      }
    } else {
      initialRender.current = false;
    }
  });

  const handlerAdvisedMore = () => {
    setShowMore((prev) => !prev);
  };

  const getRenderedItems = () => {
    if (showMore) {
      return advisedOrder;
    }
    return advisedOrder?.slice(0, MAX_ITEMS);
  };

  const handlerStockMore = () => {
    setShowMore1((prev) => !prev);
  };

  const getRenderedstockItems = () => {
    if (showMore1) {
      return stockavailable1;
    }
    return stockavailable1?.slice(0, MAX_ITEMS1);
  };

  const handlerSort = () => {
    setSortDate((prev) => !prev);
    handlerSortDate();
  };

  // const handlerSortDate = () => {
  //   if (sortDate) {
  //     let SortASC = purchaseOrders.sort(function (a, b) {
  //       return new Date(b.deliverydate) - new Date(a.deliverydate);
  //     });
  //     dispatch(fetchPurchasingOrders(searchinventory));
  //     // setPurchaseOrders(SortASC);
  //   } else {
  //     let SortASC = purchaseOrders.sort(function (a, b) {
  //       return new Date(a.deliverydate) - new Date(b.deliverydate);
  //     });
  //     setPurchaseOrders(SortASC);
  //   }
  // };

  const handlerSortDate = () => {
    if (sortDate) {
      let SortASC = purchaseOrders.sort(function (a, b) {
        return new Date(a.deliverydate) - new Date(b.deliverydate);
      });
      setPurchaseOrders(SortASC);
      // setPurchaseOrders(SortASC);
    } else {
      let SortASC = purchaseOrders.sort(function (a, b) {
        return new Date(a.deliverydate) - new Date(b.deliverydate);
      });
      setPurchaseOrders(SortASC);
    }
  };

  const handlerSortOrderId = () => {
    setSortOrder((prev) => !prev);
    handlerSortOrder();
  };

  const handlerSortOrder = () => {
    if (sortorder) {
      let SortASC = purchaseOrders.sort(function (a, b) {
        // if (b.ordercode?.includes("|")) {
        //   return parseFloat(b.ordercode.replace("|", "."))?.localeCompare(
        //     parseFloat(a.ordercode.replace("|", "."))
        //   );
        // }
        // return parseFloat(b.ordercode)?.localeCompare(parseFloat(a.ordercode));
        return b.ordercode?.localeCompare(a.ordercode);
      });
      setPurchaseOrders(SortASC);
    } else {
      let SortASC = purchaseOrders.sort(function (a, b) {
        // if (b.ordercode?.includes("|")) {
        //   return parseFloat(a.ordercode.replace("|", "."))?.localeCompare(
        //     parseFloat(b.ordercode.replace("|", "."))
        //   );
        // }
        // return parseFloat(a.ordercode)?.localeCompare(parseFloat(b.ordercode));
        return a.ordercode?.localeCompare(b.ordercode);
        // a.orderid - b.orderid ||
      });
      setPurchaseOrders(SortASC);
    }
  };

  const handlerSortId = () => {
    setSortId((prev) => !prev);
    handlerSortById();
  };

  const handlerSortById = () => {
    if (sortId) {
      let SortASC = purchaseOrders.sort(function (a, b) {
        return a.suppliername?.localeCompare(b.suppliername);
      });
      setPurchaseOrders(SortASC);
      // setSortId((prev) => !prev);
    } else {
      let SortASC = purchaseOrders.sort(function (a, b) {
        return a.suppliername?.localeCompare(b.suppliername);
        // a.orderid - b.orderid ||
      });
      setPurchaseOrders(SortASC);
      // setSortId((prev) => !prev);
    }
  };

  const handlerSelectChange = (select) => {
    if (select === "supplier_name") {
      setSortId((prev) => !prev);
      handlerSortById();
    } else if (select === "supplier_date") {
      setSortDate((prev) => !prev);
      handlerSortDate();
    } else {
      setSortOrder((prev) => !prev);
      // handlerSortOrderId();
      // handlerSortOrder();
      dispatch(fetchPurchasingOrders(searchinventory));
    }
  };

  const handlerClickData = (item) => {
    if (roleName !== "user") {
      history.push({
        pathname: `/add-puchasing-order/${item.orderid}`,
        state: {
          supplierData: item,
        },
      });
    }
    // else {
    //   alert("You Don't have access");
    // }
  };

  const pdfdownload = (event, message) => {
    const partarr = [];

    for (let i = 0; i < message.partdetails.length; i++) {
      partarr.push({
        part: message.partdetails[ i ].partname,
        supplierCode: message.partdetails[ i ].suppliercode,
        internalCode: message.partdetails[ i ].internalid,
        orderQty: europeanFormat(message.partdetails[ i ].orderquantity),
      });
    }

    axios({
      method: "post",
      url: `${API_URL}/DocFile`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        orderId: message.ordercode,
        suppliername: message.suppliername,
        address1: message.address1,
        address2: message.address2,
        // date: message.deliverydate,
        // date: formatDate(message.deliverydate),
        date: formatDate(message.createdat),
        email: message.email,
        tableDatas: partarr,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([ response.data ]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PO - ${message.ordercode}.docx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      // return docFileDownload;
      // }, 1000)
    });
  };

  const HandelRolebtn = () => {
    if (roleName !== "User") {
      history.push("/add-puchasing-order");
    } else {
      alert("You Don't have access to Add purchasing order");
    }
  };
  const HandelRolebtnone = () => {
    if (roleName !== "User") {
      history.push("/warehouse");
    } else {
      alert("You Don't have access to Add warehouse");
    }
  };
  const HandelRolebtntwo = () => {
    if (roleName !== "User") {
      history.push("/count-stock");
    } else {
      alert("You Don't have access to open count stock");
    }
  };

  return (
    <>
      <Headerinventory
        setInventorysearch={setInventorysearch}
        searchinventory={searchinventory}
        setSearchinventory={setSearchinventory}
      />
      <Row>
        <Col lg={1}></Col>
        <Col lg={11}>
          <div className="container-fluid fntClr inventory">
            <h3 className="pt-3 ps-2 header-title-sk">Inventory</h3>
            <div className="row pt-3">
              {/* <div className='col-xl-1 col-lg-0 col-0 '></div> */}
              <div className="col-xl-9 pe-4 col-lg-12 col-12">
                <div className="row ps-0 inventory-card">
                  <div
                    className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-4"
                  // onClick={() =>  history.push("/add-puchasing-order")}
                  >
                    <div className="card-body text-center p-sm-0"
                      onClick={HandelRolebtn}
                    >
                      <div
                        className={
                          roleName !== "User" ? "col card  pt-3 pb-1  homeCardLastcol" : "d-none"
                        }>
                        <img
                          src={PurchasingOrder}
                          className="wareimgInv PurchaseOrder2"
                        />
                        <h5 className="card-title pb-3">
                          Add
                          <br />
                          Purchasing order
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-4">
                    <div className="card-body text-center p-sm-0 ">
                      <div
                        // className="col card  pt-3 pb-1  homeCardLastcol"

                        className={roleName !== "User" ? "col card  pt-3 pb-1  homeCardLastcol" : "d-none"}
                        // onClick={() => history.push("/warehouse")}
                        onClick={HandelRolebtnone}
                      >
                        <img
                          src={warehouseIcon}
                          className="wareimgInv warehouse2"
                        />
                        <h5 className="card-title pb-3">
                          Add to <br />
                          Warehouse
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-6 col-lg-6  col-xl-3 mt-4'>
                            <div className="card-body text-center">
                                <div className="col card px-4 pt-3 pb-1  homeCardLastcol">
                                    <img src={ReturnWarehouse} className="wareimgInv" />
                                    <h5 className="card-title pb-3">Return to <br /> Warehouse</h5>
                                </div>
                            </div>
                        </div> */}
                  <div
                    className="col-12 col-sm-4 col-md-4 col-lg-4  col-xl-4  mt-4"
                    role="button"
                    // onClick={() => history.push("/count-stock")}
                    onClick={HandelRolebtntwo}
                  >
                    <div className="card-body text-center p-sm-0 ">
                      <div className={roleName !== "User" ? "col card pb-2 homeCardLastcol" : "d-none"}>
                        <img
                          src={onetwo}
                          className="wareimgInv img-fluid pt-4 ps-3 "
                        />
                        <h5 className="card-title pt-3 pb-3">
                          Count
                          <br />
                          Stock
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row me-3  purchasingOrder-section table-responsive">
                  <div className="d-flex justify-content-between flex-wrap">
                    <h4 className="purchase-title">
                      Purchasing Orders awaiting Delivery
                    </h4>
                    <div className="d-flex justify-content-between sort-section flex-wrap">
                      <div className="sort-order">
                        Sort by
                        {/* <img
                          src={Arrow}
                          alt="Arrow"
                          className={`${sortDate ? "asc" : ""}`}
                        /> */}
                      </div>
                      <div
                        className="date-order"
                      // onClick={() => handlerSortId()}
                      >
                        {/* <div
                          className="sort-order"
                          onClick={() => handlerSort()}
                        >
                          <img
                            style={{ marginLeft: "200%" }}
                            src={Arrow}
                            alt="Arrow"
                            className={`${sortDate ? "asc" : ""}`}
                          />
                        </div> */}

                        <select
                          className="custom-select"
                          onChange={(e) => handlerSelectChange(e.target.value)}
                        >
                          <option value={"order"}>Latest</option>
                          <option value={"supplier_name"}>Supplier Name</option>
                          <option value={"supplier_date"}>Delivery Date</option>
                        </select>
                        {/* Order ID */}
                        {/* <img
                                                    src={ArrowCh}
                                                    alt="ArrowCh"
                                                    className={`${sortId ? "asc" : ""
                                                        }`}
                                                /> */}
                      </div>
                    </div>
                  </div>
                  <table
                    className="table "
                    style={{
                      height: "715px",
                      width: "98%",
                      display: "block",
                      overflowY: "auto",
                      overflowx: "auto"
                    }}
                  >
                    <thead></thead>
                    <tbody>
                      {purchaseOrders != null &&
                        purchaseOrders
                          .filter(
                            (item) =>
                              item.ordercode
                                .toLowerCase()
                                .includes(inventorysearch) ||
                              // item.suppliername
                              //   .toLowerCase()
                              //   .includes(inventorysearch) ||
                              item?.suppliername?.toLowerCase().startsWith(inventorysearch?.toLowerCase()) ||
                              formatDate(item.deliverydate)
                                .toLowerCase()
                                .toString()
                                .includes(inventorysearch)
                          )
                          .map((item, i) => (
                            <tr key={i} className="tableHover fntClr">
                              <td scope="row" className="text-start pe-xl-5 px-lg-0 px-md-0 px-sm-0 wallres">
                                <div className="d-flex align-items-center w-max-content">
                                  <img
                                    style={{
                                      width: "35px",
                                    }}
                                    src={orderIcon}
                                  />
                                  <h6>Order ID</h6>
                                </div>
                              </td>
                              <td
                                className="px-xl-5 px-lg-0 px-md-0 px-sm-0 wallres"
                                onClick={() => handlerClickData(item)}
                              >
                                <div className="w-max-content">
                                  {item.ordercode}
                                </div>
                              </td>
                              <td className="px-xl-5 px-lg-0 px-md-0 px-sm-0" style={{ width: "38%" }}
                                onClick={() => handlerClickData(item)}>
                                <div className="w-max-content">
                                  <h5
                                    // onClick={() => {
                                    //     window.navigator.clipboard.writeText(
                                    //         item.orderid
                                    //     );
                                    //     alert(
                                    //         "Copy to clipboard"
                                    //     );
                                    // }}
                                    className="pt-2 textDeco"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Copy to Clipboard"
                                  >
                                    {item.suppliername}
                                  </h5>
                                </div>
                              </td>
                              <td
                                className="px-xl-5 px-lg-0 px-md-0 px-sm-0 wallres"
                                onClick={() => handlerClickData(item)}
                              >
                                <div className="w-max-content">
                                  <h5 className="inventory-table">
                                    <span>
                                      <img src={calender} className="calInv" />
                                    </span>{" "}
                                    {formatDate(item.deliverydate)}
                                  </h5>
                                </div>
                              </td>
                              <td className="px-xl-5 px-lg-0 px-md-0 px-sm-0 wallres">
                                <img
                                  onClick={(event) => pdfdownload(event, item)}
                                  src={PDFIcon}
                                  style={{
                                    width: "24.5px",
                                    cursor: "pointer",
                                  }}
                                // className="calInv"
                                />
                              </td>
                            </tr>
                          ))}
                      {purchaseOrders.length === 0 && (
                        <span>No records found to display!</span>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-xl-3 ps-4 ps-xl-0 ps-lg-0 col-lg-12 col-12  mt-4 text-center stockavInv fntClr">
                <div className="row justify-content-md-evenly custom-justify-table gap-4">
                  <div className="col-xl-10 col-xxl-9 col-md-6 col-sm-12 col-lg-6 card homeCardLastcol">
                    <div className="">
                      {/* card-body text-center */}
                      <div className="row card-body text-center">
                        {/* <div className="col pe-0"> pt-3 mb-3 pb-1
                                            </div> */}
                        <div className="col text-start stock-avail">
                          <img
                            src={PurchasingOrder}
                            alt=""
                            className="stockavailable"
                          />
                          <h5 className="card-title ps-3 pt-2 commtxtsize">
                            Total Stock Available
                          </h5>
                          {/* <div
                            // className="show_more"
                            className="show_more_stock mt-3"
                            onClick={() => handlerStockMore()}
                          >
                            {showMore1 ? "Show less" : "Show more"}
                          </div> */}
                        </div>
                      </div>
                      <div
                        // className="show_more"
                        className="show_more_stock"
                        onClick={() => handlerStockMore()}
                      >
                        {showMore1 ? "Show less" : "Show more"}
                      </div>

                      <div className="row py-4 gap-2 px-1">
                        <div className="row">
                          <h6 className="arrivingtext1 ps-4 col-7 col-lg-11 col-md-9 col-sm-7 ">
                            Arriving
                          </h6>
                        </div>

                        {getRenderedstockItems()?.map((val, i) => (
                          <div key={i} className="">
                            <div className="d-flex pe-0">
                              <div className="homeCardDigit col-2 ms-4">
                                {stockQuntityFormat(val?.instock)}
                              </div>
                              <div className="plussSign ms-1">+</div>
                              <div className="arrivingtext2 col-2">
                                {stockQuntityFormat(val?.arriving)}
                              </div>
                              <div
                                className="homeCardText col-7 mx-1 "
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  fontSize: "13px",
                                }}
                              >
                                {val?.partname}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-10 col-xxl-9 col-md-5 col-sm-12 col-lg-5 card pt-3 mb-3 pb-1  homeCardLastcol-advised">
                    <div className="card-body text-center m-1">
                      <div className="row text-center">
                        <div className="col text-start  justify-content-xxl-between align-items-end stock-avail flex-wrap">
                          <div className="d-flex justify-content-center ml-lg-2 ml-xxl-0 mb-lg-2 mb-xxl-0 align-items-center gap-2 stock-avail-title-in">
                            <img
                              className="ms-"
                              style={{
                                width: "31px",
                                height: "31px",
                              }}
                              src={Advisedpart}
                            />
                            <h5 className="card-title m-0 commtxtsize">
                              Advised Part Purchasing
                            </h5>
                            {/* <div
                              className="show_more mt-5"
                              onClick={() => handlerAdvisedMore()}
                            >
                              {showMore ? "Show less" : "Show more"}
                            </div> */}
                          </div>
                          <div
                            className="show_more2 mt-3"
                            onClick={() => handlerAdvisedMore()}
                          >
                            {showMore ? "Show less" : "Show more"}
                          </div>
                        </div>
                      </div>
                      <div className="row pt-4 gap-2 advised-section">
                        <div className="advised-heading">
                          <div className="d-flex justify-content-between">
                            <p>Part</p>
                            <p>Purchase Qty</p>
                          </div>
                        </div>
                        <div className="advised-contents">
                          {getRenderedItems() != null &&
                            getRenderedItems()?.map((part) => {
                              return (
                                <div
                                  className="d-flex align-items-center"
                                  key={part.partid}
                                >
                                  <p>{part.partname}</p>
                                  <h5>{stockQuntityFormat(part.purchaseqty)}</h5>
                                </div>
                              );
                            })}

                          {/* <div className="d-flex justify-content-between">
                                                        <p>Purchase Qty</p>
                                                        <h6>50</h6>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Inventory;
