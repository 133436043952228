import Modal from "react-bootstrap/Modal";

const DeleteOrderPopup = ({ open, onClose, backdropClassName = "" }) => {

    const onDeleteConfirm = () => {
        onClose(true)
    }

    const onCancel = () => {
        onClose(false)
    }

    return (
        <Modal
            animation={false}
            show={open}
            centered
            size="sm"
            backdropClassName={backdropClassName}
        >
            <div className="container popheaderbg1 pt-3 pb-2 ps-3">
                <div className="row text-center my-3 mx-2">
                    You will delete this information and all the information related
                    with it.
                </div>
                <div className="row justify-content-center my-2 mb-4">
                    Do you want to proceed?
                </div>
            </div>
            <div className="">
                <div className="row justify-content-center ">
                    <div className="col-6 pe-0 text-center">
                        <button
                            className=" popcancelbtn"
                            type="button"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6 ps-0 text-center">
                        <button
                            className="popaddbtn "
                            type="button"
                            onClick={onDeleteConfirm}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteOrderPopup;