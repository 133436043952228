import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";

// import styles from "../../styles/style.css";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../redux/actions/action";

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState(false);
  const [equalPassword, setEqualPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [email, setemail] = useState("");
  const changpassword = useSelector((state) => state.user.changePassword);
  const userId = props.match.params.userid;
  const initialRender = React.useRef(true);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState("");

  const handleAlert = () => {
    setAlert(false);
  };

  React.useEffect(() => {
    if (!initialRender.current) {
      if (changpassword.status != null) {
        if (changpassword.status == true) {
          props.history.push("/");
        } else {
          setAlert(true);
          setMsg(changpassword.message);
          // props.history.push("/")
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [changpassword]);
  const dispatch = useDispatch();
  // function for password validation
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password == "" || repeatPassword == "") {
      e.preventDefault();
      setError(true);
    } else if (password != repeatPassword) {
      e.preventDefault();
      setEqualPassword(true);
    } else {
      dispatch(forgotPassword(userId, password, repeatPassword));
    }
  };

  const togglePasssword = () => {
    setShowPassword(!showPassword);
  };
  const togglePasssword1 = () => {
    setShowPassword1(!showPassword1);
  };
  return (
    <div className="container">
      <Modal
        className="changedialog border-0"
        animation={false}
        show={true}
        centered
      >
        {alert == true ? (
          <div className="row justify-content-center ">
            <div
              className="alert alert-danger col mt-3 alert-dismissible fade show alertmsg"
              role="alert"
            >
              {msg}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={handleAlert}
              ></button>
            </div>
          </div>
        ) : (
          ""
        )}
        <ModalBody className="changedialog border-0">
          <div className="container ">
            <form>
              <div className=" centeralign">
                <h1 className="heading">
                  Get in-demant resources
                  <br /> to <b>innovate your world</b>
                </h1>
                <h1 className="subheading">
                  You`re on your way to connecting with great talent!
                </h1>
              </div>
              <div className="container mt-sm-5 ">
                <div className="col-sm-12 input-group inputsection">
                  {password == "" && error == true && (
                    <div className="errormsg">Please fill in this field</div>
                  )}
                  <input
                    type={showPassword ? "text" : "password"}
                    className={
                      password == "" && error == true
                        ? "form-control inputtext showerror"
                        : "form-control inputtext"
                    }
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Create Password"
                  />
                  <span style={{ marginLeft: "92%" }} className="passwordicon1">
                    <i
                      className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"}
                      id="togglePassword"
                      onClick={togglePasssword}
                    ></i>
                  </span>
                </div>

                <div className="col-sm-12 input-group inputsection">
                  {repeatPassword == "" && error == true && (
                    <div className="errormsg">Please fill in this field</div>
                  )}
                  {equalPassword == true && (
                    <div className="errormsg">Passwords do not match</div>
                  )}
                  <input
                    type={showPassword1 ? "text" : "password"}
                    className={
                      repeatPassword == "" && error == true
                        ? "form-control inputtext showerror"
                        : "form-control inputtext"
                    }
                    value={repeatPassword}
                    onChange={(e) => {
                      setRepeatPassword(e.target.value);
                    }}
                    placeholder="Repeat Password"
                  />
                  <span style={{ marginLeft: "92%" }} className="passwordicon1">
                    <i
                      className={showPassword1 ? "bi bi-eye-slash" : "bi bi-eye"}
                      id="togglePassword"
                      onClick={togglePasssword1}
                    ></i>
                  </span>
                </div>

                <div className="row btncontainer mt-sm-4">
                  <div>
                    <button
                      className="btn createbutton"
                      onClick={(e) => {
                        handleSubmit(e, props);
                      }}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangePassword;
