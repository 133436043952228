import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../../redux/actions/action";
import { useHistory } from "react-router-dom";

const ClientCard = (props) => {
  const dispatch = useDispatch();
  const [ destination, setDestination ] = React.useState("");
  const changeStatusData = useSelector((state) => state.user.changeStatus);
  const initialRender = React.useRef(true);
  const orderId = window.sessionStorage.getItem("orderId");
  const history = useHistory();
  React.useEffect(() => {
    setDestination(props.installation);
  }, []);

  return (
    <div className="min-vh-100">
      <Modal
        animation={false}
        show={true}
        className={
          props.position == true ? "popovermodalCreate" : "popovermodal"
        }
        style={{ width: "18em" }}
        bottom
      >
        <div className="container headerbg pt-3">
          <div className="row">
            <div className="col-9">
              {" "}
              <h4>Client Card</h4>
            </div>
            <div className="col-2 text-end">
              <h4 style={{ cursor: "pointer" }} onClick={props.handleClose}>
                X
              </h4>
            </div>
          </div>
        </div>
        <ModalBody>
          <div className="container text-center">
            <select
              placeholder="Select Client"
              onChange={(e) => {
                setDestination(e.target.value);
                props.setInstallationName(e.target.value);
              }}
              value={destination}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="RBCE">RBCE</option>
              <option value="Client">Client</option>
              <option value="Other">Other</option>
            </select>
            <button
              type="button"
              onClick={() => {
                destination == ""
                  ? props.handleClose()
                  : props.handleClientName(destination);
              }}
              className="btn btn-primary mt-5 w-100 px-5 moveBtn"
            >
              Confirm
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ClientCard;
