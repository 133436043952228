import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../styles/local/supplier.css";
import Add1 from "../../assets/icons/Add1.svg";
import arrowsort from "../../assets/icons/arrowsort.png";
import pencil_icon from "../../assets/icons/pencil_icon.svg";
import Group62 from "../../assets/icons/Group62.svg";
import duplicate from "../../assets/icons/duplicate.svg";
import {
  fetchSuppliers,
  deleteSuppliers,
  fetchSuppliersById,
  addSupplier,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteCard from "../../components/dialogs/DeleteCard";
import arrowup from "../../assets/icons/arrowup.svg";
import Headersupplier from "../../components/dialogs/Headersupplier";
import Pagination from "../../components/Pagination";
import { ADD_SUPP } from "../../redux/constants/type";

const Supplier = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const UserId = window.sessionStorage.getItem("userid");
  const roleName = window.sessionStorage.getItem("rolename");
  const fetchsuppliers = useSelector((state) => state.user.fetchSuppliers);
  const newSupplierAdded = useSelector((state) => state.user.addSupplier);
  const fetchsuppliersbyid = useSelector(
    (state) => state.user.fetchSuppliersById
  );
  const [ data, setData ] = useState([]);
  const [ suppliersearch, setSuppliersearch ] = useState("");
  const [ sortDate, setSortDate ] = useState(true);
  const [ pagenumber, setPageNumber ] = useState(1);
  const [ querystring, setQuerystring ] = useState("");
  const [ supplier, setSupplier ] = useState([]);
  const initialRender = React.useRef(true);
  const initialRender1 = React.useRef(true);
  const [ contacts, setContacts ] = useState([]);
  const [ StatusPopup, setStatusPopup ] = useState(false);
  const [ supplierId, setSupplierId ] = useState(null);
  const [ isCalled, setIsCalled ] = useState(false);
  const [ isCalled1, setIsCalled1 ] = useState(false);
  const [ data3, setData2 ] = useState([]);
  const [ totalCount, setTotalCount ] = useState(0);
  const [ highlightClient, setHighlightClient ] = useState("");

  const sorting = () => {
    const data1 = [ ...data ].sort((a, b) => (a.name < b.name ? -1 : 1));
    setData(data1);
  };
  const [ sortarrow, setSortarrow ] = useState("");


  useEffect(() => {
    if (newSupplierAdded && newSupplierAdded.status) {
      dispatch(fetchSuppliers(pagenumber));
      setHighlightClient(newSupplierAdded.data.supplierid);
      setTimeout(() => {
        dispatch({ type: ADD_SUPP, data: null });
      }, 3000);
    } else {
      if (highlightClient) {
        setHighlightClient("");
      }
    }
  }, [ dispatch, newSupplierAdded ]);

  useEffect(() => {
    if (querystring >= 0) {
      dispatch(fetchSuppliers(pagenumber));
    } else {
      dispatch(fetchSuppliers(pagenumber, querystring));
    }

  }, [ querystring ]);

  const handleClose = () => {
    setStatusPopup(false);
  };

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchsuppliers != null) {
        if (fetchsuppliers.status == true) {
          setData(fetchsuppliers.data);
          setTotalCount(fetchsuppliers.totalCounts);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [ fetchsuppliers ]);

  React.useEffect(() => {
    if (!initialRender1.current) {
      if (fetchsuppliersbyid != null) {
        if (fetchsuppliersbyid.status == true) {
          setSupplier(fetchsuppliersbyid.data);
        }
      }
    } else {
      initialRender1.current = false;
    }
  }, [ fetchsuppliersbyid ]);

  const handlerSort = () => {
    setSortDate((prev) => !prev);
    setSortarrow((prev) => !prev);
    handlerSortName();
  };

  const handlerSortName = () => {
    if (sortDate) {
      const sortedData = [ ...data ].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
      setData(sortedData);
    } else {
      dispatch(fetchSuppliers(pagenumber));
    }
  };

  const HandelroleAdd = () => {
    if (roleName !== "User") {
      history.push("/add-supplier");
    } else {
      alert("You Don't have access to Add Suppliers");
    }
  };

  const pageNumbers = [ ...Array(pagenumber + 2).keys() ].slice(1);

  return (
    <>
      {StatusPopup && (
        <DeleteCard
          handleClose={handleClose}
          supplierId={supplierId}
          pagenumber={pagenumber}
          setIsCalled1={setIsCalled1}
        />
      )}
      <Headersupplier
        setQuerystring={setQuerystring}
        querystring={querystring}
        pagenumber={pagenumber}
      />
      <div className="parent">
        <div className="outer mb-3">
          <div className={roleName !== "User" ? "add-supplier-row" : "user-add-supplier-row"}>
            <div className={roleName !== "User" ? "" : "d-none"}>
              <img
                src={Add1}
                className="Add2"
                onClick={HandelroleAdd}
              />
            </div>
            <div className={roleName !== "User" ? "image-heading countFontclr" : "user-image-heading countFontclr"}>
              <h2 className="sup12">Suppliers </h2>
            </div>
          </div>

          <div className="tableWrapper">
            <table className="table supp-table-hover">
              <thead>
                <tr>
                  <th className="fnt2">
                    <img
                      src={arrowsort}
                      alt="arrowicon"
                      className={sortarrow == "" ? "" : "ArrowUpDown12"}
                      height="14px"
                      width="4px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handlerSort()}
                    />
                    &nbsp;Company Name
                  </th>
                  <th className="fnt1">Telephone</th>
                  <th className="fnt1">E-mail</th>
                  <th className="fnt1">Contact Name</th>
                  <th className="fnt1">Contact Telephone</th>
                  <th></th>
                </tr>
              </thead>

              {data
                .map((item, index) => (
                  <tbody className="tabLine">
                    <tr className={highlightClient === item.supplierid ? "new-row" : ""}>
                      <td className="fnttable ps-3">{item.name}</td>
                      <td className="fnttable">{item.phonenumber}</td>
                      <td className="fnttable">{item.email}</td>
                      <td>
                        <div className="row">
                          <div className="col-9 col-xxl-7 fnttable ps-xxl-3">
                            {item.contactdetails[ 0 ]?.contacts[ 0 ]?.name}
                          </div>
                          <div className="col-3 col-xxl-5">
                            {item.contactcount > 1 ? (
                              <span
                                className={
                                  item.contactcount - 1 > 10
                                    ? "bubble1 fnt3"
                                    : "bubble fnt3"
                                }
                              >
                                +{item.contactcount - 1}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="fnttable">{item.contactdetails[ 0 ]?.contacts[ 0 ]?.phonenumber}</td>
                      <td>
                        <img
                          alt="pencil icon"
                          src={pencil_icon}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.sessionStorage.setItem(
                              "supplierid",
                              item.supplierid
                            );
                            history.push("/update-supplier/");
                          }}
                        />
                        <img
                          alt="duplicate icon"
                          src={duplicate}
                          className={roleName != "User" ? "px-3" : "d-none"}
                          onClick={() => {

                            {
                              item.contactdetails.map((val) =>
                                contacts.push({
                                  name: val.contacts[ 0 ].name,
                                  companyid:
                                    "3ba6542d-de49-4ffd-bdc5-e5d53313b309",
                                  phonenumber: val.contacts[ 0 ].phonenumber,
                                  email: val.contacts[ 0 ].email,
                                })
                              );
                            }

                            dispatch(
                              addSupplier(
                                UserId,
                                item.name + " Copy",
                                item.address1,
                                item.address2,
                                item.email,
                                item.phonenumber,
                                item.notes,
                                contacts
                              )
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <img
                          alt=""
                          src={Group62}
                          className={roleName != "User" ? "" : "d-none"}
                          onClick={() => {
                            setStatusPopup(true);
                            setSupplierId(item.supplierid);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
            {data.length === 0 && <span>No records found to display!</span>}
          </div>
          <Pagination
            currentPage={pagenumber}
            totalCount={totalCount}
            onPageChange={(page) => {
              dispatch(fetchSuppliers(page))
              setPageNumber(page)
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Supplier;
