import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

import { useDispatch, useSelector } from "react-redux";
import { emailSend } from "../../redux/actions/action";
import { useHistory } from "react-router-dom";

const ForgotPassword = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState("");
  const [name, setName] = useState("payal");
  const [subject, setSubject] = useState("Forgot Password");
  const [eventtype, setEventtype] = useState("Successfully Forgot Password");
  const dispatch = useDispatch();

  const sendEmailData = useSelector((state) => state.user.forgotPassEmail);

  const forgotPassEmail = useSelector((state) => state.user.forgotPassEmail);

  const initialRender = React.useRef(true);

  const [sendemailshow, setSendemailshow] = useState(true);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (sendEmailData != null) {
        if (sendEmailData.status == true) {
          //props are coming from changePassword.jd and landigPage.js
          // props.sendEmail();
        } else {
          // setMsg(sendEmailData.message);
          setAlert(true);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [sendEmailData]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (forgotPassEmail != null) {
        if (forgotPassEmail.status == true) {
          setMsg(forgotPassEmail.message);
          setAlert(true);
          setTimeout(() => {
            history.push("/");
            window.location.reload(); 
          }, 2000);
        } else {
          setMsg(forgotPassEmail.message);
          setTimeout(() => {
            setAlert(false);
          }, 5000);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [forgotPassEmail]);

  const sendEmail = (e) => {
    setSendemailshow(sendemailshow);
    if (email == "") {
      e.preventDefault();
      setError(true);
      setEmailValid(false);
      setTimeout(() => {
        setError(false);
      }, 5000);
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test) {
      e.preventDefault();
      setEmailValid(true);
      setError(false);
      setTimeout(() => {
        setEmailValid(false);
      }, 5000);
    } else {
      setEmailValid(false);
      dispatch(emailSend(email, name, subject, eventtype));
      e.preventDefault();
    }
  };
  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <div>
      <Modal
        animation={false}
        show={true}
        centered
        style={{ backgroundColor: "#808080" }}
      >
        {/* {alert == true ?
          <div className="row justify-content-center">
 
            <div className="alert alert-danger col mt-3 alert-dismissible fade show alertmsg" role="alert">
              {msg}
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlert}></button>
            </div>
          </div>
          : ''} */}
        <div className="container centeralign headerbg">
          {/* comment for not showing cross button on top */}
          {/* <span className="closeicon" onClick={props.handleClose} >&times;</span> */}
        </div>
        <div className="container centeralign headerbg">
          <div className="row mt-5">
            <h1 className="heading">Recover Password</h1>
          </div>
          <div className="row mt-sm-3 mb-sm-2">
            <h1 className="subheading">Enter your email address</h1>
          </div>
        </div>
        <ModalBody>
          <div className="container">
            <form>
              <div className="container mb-xl-5">
                <div className="row mt-sm-4">
                  <div className="col-sm-12">
                    {error &&   (
                      <div className="errormsg">Please fill in this field</div>
                    )}
                    {emailValid &&  (
                      <div className="errormsg">This email is invalid</div>
                    )}
                    <input
                      id="userinput"
                      type="email"
                      className={
                        email == "" && error == true
                          ? "form-control inputtext showerror"
                          : "form-control inputtext"
                      }
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="Email"
                    />

                 
                    <p className="pt-3" style={{ color: "green" }}>
                    {alert && msg }
                    </p>
                   
                  </div>
                </div>
                <div className="col mb-xl-5 btnmargin1 btncontainer">
                  <div>
                    <button
                      className="btn createbutton"
                      onClick={(e) => {
                        sendEmail(e);
                      }}
                    >
                      Recover Password
                    </button>
                  </div>
                  <div className="btnmargin">
                    <button
                      className="btn loginbutton"
                      onClick={props.handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
