import React, { useEffect, useState, memo } from "react"
import Pagination from "react-js-pagination";
import "../styles/local/pagination.css";

const PaginationComponent = ({ currentPage, totalCount, onPageChange }) => {

    const [ totalPages, setTotalPages ] = useState(0);
    const [ selected, setSelected ] = useState(0);

    useEffect(() => {
        if (totalCount) {
            const pages = Math.ceil(totalCount / 10);
            setTotalPages(pages)
        }
    }, [ totalCount ]);

    useEffect(() => {
        if (currentPage) {
            setSelected(currentPage);
        }
    }, [ currentPage ]);

    const _onPageClick = (pageNumber) => {
        onPageChange(pageNumber);
    }


    return (
        <div className="resPagination" style={{ position: "relative", left: "91.5%" }}>
            <Pagination
                activePage={selected}
                itemsCountPerPage={20}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                onChange={_onPageClick}
                // itemClass="btnPage"
                linkClass="btnPage"
                activeLinkClass="btnPage-active"
                itemClassNext="next-btn"
                itemClassPrev="pre-btn"
                prevPageText="<"
                nextPageText=">"
                hideDisabled={true}
            />

            {/* <>{renderPageNumbers}</> */}
            {/* {Array.from(Array(totalPages), (x, index) => {
                let thisPage = index + 1;
                let clsName = selected === thisPage ? "btnPage-active" : "btnPage";
                return (
                    <button key={`pagination-item${thisPage}`} type="button" className={clsName} onClick={() => _onPageClick(thisPage)}>
                        {thisPage}
                    </button>
                )
            })}   */}
        </div>
    );
}

export default memo(PaginationComponent);