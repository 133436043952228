import Header from "../../components/Header";
import "../../styles/local/addProducts.css";
import producticon from "../../assets/icons/producticon.svg";
import Group62 from "../../assets/icons/Group62.svg";
import { useEffect, useState, useRef } from "react";
import {
  addComments,
  fetchComments,
  fetchProductStock,
  updateProductStock,
  fetchPartsById,
  updatePart,
  fetchSuppliers,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useQuery } from "../../utils/appHooks";
import { Button } from "react-bootstrap";
import { FETCH_PARTBYID } from "../../redux/constants/type";
import EuropeanInput from "../../utils/EuropeanInput";

const UpdateProducts = () => {
  const refNewStandardWageInput = useRef(null);
  const selectRef = useRef();
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const initialRender = React.useRef(true);
  const UserId = window.sessionStorage.getItem("userid");
  const partid = window.sessionStorage.getItem("partID");
  const roleName = window.sessionStorage.getItem("rolename");
  const [pagenumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [contact, setContact] = useState([]);
  const [partList, setPartList] = useState([]);
  const [name, setName] = useState("");
  const [internal, setInternal] = useState("");
  const [instock, setInstock] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const fetchpartsbyid = useSelector((state) => state.user.fetchPartsById);
  const fetchsuppliers = useSelector((state) => state.user.fetchSuppliers);
  const [part, setPart] = useState([]);
  const [partId, setPartId] = useState("");
  const [supplierid, setSupplierid] = useState("");
  const [suppliername, setSuppliername] = useState("");
  const [suppliercode, setSuppliercode] = useState("");
  const [unitprice, setUnitprice] = useState("");
  const [leadtime, setLeadtime] = useState("");
  const [contacts, setContacts] = useState([
    {
      partId: "",
      name: "",
      internal: "",
      instock: "",

      // companyid: companyId,
    },
  ]);
  const [customDrop, setCustomDrop] = useState(false);
  const [partarrow, setPartarrow] = useState("");
  useEffect(() => {
    dispatch(fetchSuppliers());
    // setIsCalled(false);
    // setIsCalled1(false);
  }, []);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchsuppliers != null) {
        if (fetchsuppliers.status == true) {
          setData(fetchsuppliers.data);
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchsuppliers]);

  const partsuppliers = [
    {
      supplierid: supplierid,
      suppliercode: suppliercode,
      unitprice: unitprice,
      leadtime: leadtime,
    },
  ];
  contact.forEach((item) =>
    partsuppliers.push({
      supplierid: item.supplierid,
      suppliercode: item.suppliercode,
      unitprice: item.unitprice,
      leadtime: item.leadtime,
    })
  );

  const handleAdd = (e) => {
    dispatch(
      updatePart(UserId, partid, name, internal, instock, max, min, partList)
    );
    setTimeout(() => {
      history.push("/products");
      window.location.reload();
    }, 1000);

    e.preventDefault();
  };

  useEffect(() => {
    setContacts(contacts);
  }, [contacts, part]);

  // useEffect(() => {
  //   setName();
  //   setInternal();
  //   setInstock();
  //   setMin();
  //   setMax();
  //   setUnitprice();
  // }, []);

  useEffect(() => {
    const isCon = queryParams.get("u")
    if (isCon === "upform") {
      const obj = window.sessionStorage.getItem("upform");
      const jsnObj = JSON.parse(obj);
      setPart(jsnObj.part);
      setPartId(jsnObj.partid);
      setName(jsnObj.name);
      setInternal(jsnObj.internal);
      setInstock(jsnObj.instock);
      setMin(jsnObj.min);
      setMax(jsnObj.max);
      setPartList(jsnObj.partList || []);
    } else {
      dispatch(fetchPartsById(window.sessionStorage.getItem("partID")));
    }
  }, []);

  const handleCreateSupplierClick = () => {
    const currentObj = {
      part,
      partid,
      name,
      internal,
      instock,
      min,
      max,
      partList
    }
    window.sessionStorage.setItem("upform", JSON.stringify(currentObj));
    history.replace({ pathname: history.location.pathname, search: "?u=upform" })
    history.push({ pathname: "/add-supplier" })
  }

  let helper = [];
  React.useEffect(() => {
    if (!initialRender.current) {
      if (fetchpartsbyid != null) {
        if (fetchpartsbyid.status == true) {
          helper = fetchpartsbyid.data[0];
          setPart(fetchpartsbyid.data);
          setPartId(fetchpartsbyid.data[0]?.partId);
          setName(fetchpartsbyid.data[0]?.partname);
          setInternal(fetchpartsbyid.data[0]?.internalid);
          setInstock(fetchpartsbyid.data[0]?.instock);
          // setMin(fetchpartsbyid.data[0]?.maxcount);
          // setMax(fetchpartsbyid.data[0]?.mincount);
          setMin(fetchpartsbyid.data[0]?.mincount);
          setMax(fetchpartsbyid.data[0]?.maxcount);
          // setSuppliername(
          //   fetchpartsbyid.data[0]?.partsuppliers[0]?.suppliername
          // );
          // setSupplierid(fetchpartsbyid.data[0]?.partsuppliers[0]?.supplierid);
          // setSuppliercode(
          //   fetchpartsbyid.data[0]?.partsuppliers[0]?.suppliercode
          // );
          // setUnitprice(fetchpartsbyid.data[0]?.partsuppliers[0]?.unitprice);
          // setLeadtime(fetchpartsbyid.data[0]?.partsuppliers[0]?.leadtime);

          setPartList(fetchpartsbyid.data[0]?.partsuppliers || []);
          dispatch({
            type: FETCH_PARTBYID,
            data: {},
          });
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchpartsbyid]);

  const toggleSelect = (i) => {
    setCustomDrop(true)
  };

  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };


  const onChangeHandler = (e) => {
    // const index = e.target.selectedIndex;
    // const el = e.target.childNodes[index];
    // const option = el.getAttribute("id");
    // setSupplierid(option);
    // setSuppliername(el.innerText);
    if (e != null) {
      setSupplierid(e.supplierid);
      setSuppliername(e.name);
      setCustomDrop(false)
    }
  };

  const handleAddPart = () => {
    setPartList([
      ...partList,
      { suppliername, suppliercode, unitprice, leadtime, supplierid },
    ]);
    setSupplierid("");
    setSuppliername("");
    setSuppliercode("");
    setUnitprice("");
    setLeadtime("");
    selectRef.current.clearValue()
  };

  return (
    <>
      <Header />
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-4 pb-5">
            <h3 className="py-3 ps-2 themecolor header-title-sk">  Products & Parts</h3>
          </div>
          <div className="row ps-4 pe-0 py-4">
            <div className="col-12 custCardCount shadow p-0">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5
                    className="py-4 cntStockfont"
                    style={{ fontSize: "22px" }}
                  >
                    <span className="me-4">
                      <img className="countProicon" src={producticon} />
                    </span>
                    Product/Part
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row ps-5 ">
                  <div className="col-11">
                    <div className="row">
                      <div className="col-xl-7 col-12 pt-5">
                        <div className="row">
                          <h5 className=" cntStockfont">
                            <span className="me-4 ">
                              <img className="countProicon" src={producticon} />
                            </span>
                            Part Name
                          </h5>
                        </div>
                        <div className="row ps-xl-5 pe-3 pe-xl-0 ms-1 ">
                          <input className={roleName !== "User" ? "form-control suppInput2 fntSupp4" : "form-control suppInput2 fntSupp4 field-disable1"}
                            // defaultValue={part[0]?.partname}
                            onChange={(e, value) => setName(e.target.value)}
                            // value={part[0]?.partname}
                            style={{ height: "49px" }}
                            value={name}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-xl-2 col-md-6  text-center">
                        <div className="row text-center">
                          <div className="col-md-12    pt-xl-5 mt-xl-2 mt-4 ps-md-4 pe-4 pe-xl-2">
                            <div className="row">
                              <div className="addProductFnt mt-xl-1 mb-1">
                                Internal ID
                              </div>
                            </div>
                            <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-xl-2 centretext background12" : "form-control suppInput1 fntSupp4 mt-xl-2 centretext background12 field-disable1"}
                              style={{ background: "#fafafa" }}
                              onChange={(e) => setInternal(e.target.value)}
                              // value={part[0]?.internalid}
                              value={internal}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3 col-xl-3 col-md-6 col-12">
                        <div className="row  text-center">
                          <div className="col-xl-4 col-md-4 col-sm-4 col-6 pt-xl-5 mt-xl-2 mt-4  text-center">
                            <div className="addProductFnt mt-xl-1 mb-1">
                              In Stock
                            </div>
                            <EuropeanInput
                              hidePreview={true}
                              value={instock}
                              onChange={(value) => setInstock(value)}
                              withoutSymbol={true}
                              className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-xl-2 centretext background12" : "form-control suppInput1 fntSupp4 mt-xl-2 centretext background12 field-disable1"}
                              style={{ background: "#fafafa" }}
                            />
                            {/* <input type="number" id="quantity" name="quantity" min="1" max="5"> */}
                          </div>
                          <div className="col-xl-4 col-md-4 col-sm-4 col-6 pt-xl-5 mt-xl-2 mt-4 pe-4 pe-md-2 text-center">
                            <div className="addProductFnt mt-xl-1 mb-1">
                              MIN
                            </div>

                            <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-xl-2 centretext background12" : "form-control suppInput1 fntSupp4 mt-xl-2 centretext background12 field-disable1"}
                              style={{ background: "#fafafa" }}
                              onChange={(e) => setMin(e.target.value)}
                              // value={part[0]?.mincount}
                              value={min}
                              type="number"
                              min={0}
                            />
                          </div>
                          <div className="col-xl-4 col-md-4 col-sm-4 col-12 pt-xl-5 mt-xl-2 mt-4 pe-4 pe-md-2 text-center">
                            <div className="addProductFnt mt-xl-1 mb-1">
                              MAX
                            </div>
                            <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-xl-2 centretext " : "form-control suppInput1 fntSupp4 mt-xl-2 centretext  field-disable1"}
                              style={{ background: "#fafafa" }}
                              onChange={(e) => setMax(e.target.value)}
                              // value={part[0]?.maxcount}
                              value={max}
                              type="number"
                              min={0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row ps-5 ">
                  <div className="col-xl-11">
                    <div className="row">
                      <div className="col-xl-7 pt-5">
                        <div className="row">
                          <div className="col-md-8 col-sm-7 col-12">
                            <h5 className=" cntStockfont">
                              <span className="me-4 ">
                                <img
                                  className="countProicon"
                                  src={producticon}
                                />
                              </span>
                              Supplier
                            </h5>
                          </div>
                          <div className={roleName !== "User" ? "col-md-4 col-sm-5 col-12 text-sm-end addProductFnt2 pe-5 pe-xl-0 pt-1" : "col-md-4 col-sm-5 col-12 text-sm-end addProductFnt2 pe-5 pe-xl-0 pt-1 d-none"}
                          >
                            <Button
                              variant="ghost"
                              onClick={handleCreateSupplierClick}
                              style={{
                                color: "#80cfe6",
                              }}
                            >
                              {" "}
                              (+) Create Supplier
                            </Button>
                          </div>
                        </div>
                        {partList.map((item, i) => {
                          return (
                            <>
                              <div className={roleName !== "User" ? "row ps-xl-5 pe-5 pe-xl-0 ms-1 mb-2 mt-xl-0 sup12 po update-products-drop" : "row ps-xl-5 pe-5 pe-xl-0 ms-1 mb-2 mt-xl-0 sup12 field-disable1 po update-products-drop"}
                                style={{ position: "relative", height: "51px", zIndex: partList.length - i }}
                              >
                                {/* <select
                                  className="form-select form-select-lg"
                                  aria-label=".form-select-lg example"
                                  style={{
                                    color: "#003766",
                                    fontWeight: "700",
                                  }}
                                >
                                  <option>{item.suppliername}</option>
                                </select> */}

                                <Select
                                  menuIsOpen={false}
                                  classNamePrefix="sk-custom-select"
                                  className="sk3-custom-container  padding-height"
                                  value={data.find(function (option) { return option.supplierid == item.supplierid })}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                  options={data}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.supplierid}
                                  onChange={(value) => onChangeHandler(value)}

                                />



                                {/* <div className="custom-select-box" >
                                  <div className="custom-select-selected" > {item.suppliername}</div>
                                </div>  */}
                              </div>
                            </>
                          );
                        })}

                        <div style={{ position: "relative" }} className={roleName !== "User" ? "row ps-xl-5 pe-5 pe-xl-0 ms-1 mt-2 mt-xl-0 sup12" : "row ps-xl-5 pe-5 pe-xl-0 ms-1 mt-2 mt-xl-0 sup12 field-disable1"}
                        >
                          {/* <select
                            className="form-select form-select-lg"
                            aria-label=".form-select-lg example"
                            style={{
                              color: "#003766",
                              fontWeight: "700",
                            }}
                            onChange={onChangeHandler}
                            value={supplierid}
                          >
                            <option selected disabled value={""}>
                              Select a Supplier
                            </option>
                            {data.map((supp, index) => {
                              return (
                                <>
                                  <option key={index} id={supp.supplierid} value={supp.supplierid}>
                                    {supp.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>  */}

                          <Select
                            ///menuIsOpen={true}
                            ref={selectRef}
                            classNamePrefix="sk-custom-select"
                            className="sk-custom-container  sk-custom-container-comp with-width-93 padding-height"
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            options={data}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.supplierid}
                            onChange={(value) => onChangeHandler(value)}
                          // styles={colourStyles}
                          // defaultValue={}
                          />

                          {/* <div className="custom-select-box" >
                            {!customDrop ? <div className="custom-select-selected" onClick={() => toggleSelect(null)} > {suppliername ? suppliername : "Select..."}</div> : ""}
                            {customDrop ?
                              <div className={`custom-select-outer ${customDrop ? 'active' : ""}`}>
                                {data.map((supp, index) => {
                                  return (
                                    <>
                                      <div className={`list-select-box ${supp.supplierid === supplierid ? 'active' : ""}`} key={index} id={supp.supplierid} onClick={() => onChangeHandler(supp)}>
                                        {supp.name}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                              : ""}
                          </div> */}
                        </div>
                      </div>
                      <div className="col-xl-2 col-md-5 col-12 pt-xl-5 mt-xl-1 mt-4 ps-lg-4 pe-5 pe-md-2 text-center">
                        <div className="addProductFnt1 mt-2 pt-0 mb-3">
                          Supplier Code
                        </div>
                        {partList.map((item, i) => {
                          return (
                            <>
                              <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-2" : "form-control suppInput1 fntSupp4 mt-2 field-disable1"}
                                style={{
                                  textAlign: "center",
                                  background: "#fafafa",
                                }}
                                onChange={(e) => {
                                  setPartList([
                                    ...partList.slice(0, i),
                                    {
                                      ...partList[i],
                                      suppliercode: e.target.value,
                                    },
                                    ...partList.slice(i + 1),
                                  ]);
                                }}
                                value={item.suppliercode}
                                type="text"
                              />
                            </>
                          );
                        })}
                        <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-2 centretext" : "form-control suppInput1 fntSupp4 mt-2 centretext field-disable1"}

                          // value={part[0]?.partsuppliers[0]?.suppliercode}
                          style={{ background: "#fafafa" }}
                          onChange={(e) => setSuppliercode(e.target.value)}
                          value={suppliercode}
                          type="text"
                        />
                      </div>
                      <div className="col-xl-3 col-md-7 col-12 pe-5 pe-xl-0">
                        <div className="row">
                          <div className="col-sm-5 col-6 pt-xl-5 mt-xl-1 mt-4  text-center">
                            <div className="addProductFnt1 mt-2 pt-0 mb-3">
                              Unit Price
                            </div>
                            {partList.map((item, i) => {
                              return (
                                <>
                                  <EuropeanInput
                                    className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mb-2" : "form-control suppInput1 fntSupp4 mb-2 field-disable1"}
                                    style={{
                                      textAlign: "center",
                                      background: "#fafafa",
                                    }}
                                    onChange={(newValue) => {
                                      setPartList([
                                        ...partList.slice(0, i),
                                        {
                                          ...partList[i],
                                          unitprice: newValue,
                                        },
                                        ...partList.slice(i + 1),
                                      ]);
                                    }}
                                    value={item.unitprice}
                                    min={0}
                                  />

                                </>
                              );
                            })}
                            <EuropeanInput
                              className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mb-2 centretext" : "form-control suppInput1 fntSupp4 mb-2 centretext field-disable1"}
                              style={{ background: "#fafafa" }}
                              onChange={(newValue) => setUnitprice(newValue)}
                              value={unitprice}
                              min={0}
                            />

                          </div>
                          <div className="col-sm-7 col-6 pt-xl-5 mt-xl-1 mt-4 text-center">
                            <div className="addProductFnt1 mt-2 pt-0 mb-3">
                              Lead time
                            </div>
                            {partList.map((item, i) => {
                              return (
                                <>
                                  <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-2" : "form-control suppInput1 fntSupp4 mt-2 field-disable1"}
                                    style={{
                                      textAlign: "center",
                                      background: "#fafafa",
                                    }}
                                    onChange={(e) => {
                                      setPartList([
                                        ...partList.slice(0, i),
                                        {
                                          ...partList[i],
                                          leadtime: e.target.value,
                                        },
                                        ...partList.slice(i + 1),
                                      ]);
                                    }}
                                    value={item.leadtime}
                                    type="number"
                                    min={0}
                                  />
                                </>
                              );
                            })}
                            <input className={roleName !== "User" ? "form-control suppInput1 fntSupp4 mt-2 centretext" : "form-control suppInput1 fntSupp4 mt-2 centretext field-disable1"}
                              style={{ background: "#fafafa" }}
                              // value={part[0]?.partsuppliers[0]?.leadtime}
                              onChange={(e) => setLeadtime(e.target.value)}
                              value={leadtime}
                              type="number"
                              min={0}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-1 text-start pt-xl-5 mt-xl-3 ps-4 ps-xl-3">
                    {partList.map((item, index) => {
                      return (
                        <>
                          <div
                            className="mb-2 addProductFnt3 addProductFnt4New"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={Group62}
                              className={roleName !== "User" ? "icons pt-2" : "icons pt-2 d-none"}
                              alt="dlt"
                              onClick={() => {
                                let data = [...partList];
                                data.splice(index, 1);
                                setPartList(data);
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                    <div className={roleName !== "User" ? "mt-4 pt-3 addProductFnt3" : "mt-4 pt-3 addProductFnt3 d-none"}
                      onClick={handleAddPart}
                      style={{ cursor: "pointer" }}
                    >
                      Add...
                    </div>
                  </div>
                </div>

                <div className="row mt-5 pt-5 ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className={roleName !== "User" ? "row justify-content-end" : "d-none"}>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={() => history.push("/products")}
                          className="countBtncanc21 w-100 h-100 p-2"
                        >
                          CANCEL
                        </button>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={handleAdd}
                          className="countBtnchange w-100 h-100 p-2"
                        >
                          CHANGE
                        </button>
                      </div>
                    </div>

                    <div className={roleName === "User" ? "row justify-content-end" : "d-none"}>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          onClick={() => history.push("/products")}
                          className="countBtnchange w-100 p-2"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProducts;
