import { createStore, combineReducers, applyMiddleware} from "redux";
//import { composeWithDevTools } from "redux-devtools-extension";
import userReducer from "../reducer/userReducer";
import letReducer from "../reducer/letReducer";
import thunk from 'redux-thunk';
const mainReducer = combineReducers({
    user:userReducer,
    let: letReducer
})
const store = createStore(mainReducer,applyMiddleware(thunk))

export default store;