import React, { useState, useEffect, useRef, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import Holder from "../../assets/icons/pole.svg";
import "../../styles/local/order.css";
import EmojiPicker from "emoji-picker-react";
import warehouseIcon from "../../assets/icons/warehouseIcon.svg";
import calender from "../../assets/icons/calender.svg";
import stockIcon from "../../assets/icons/stockIcon.svg";
import productsIcon from "../../assets/icons/productionIcon.svg";
import teamIcon from "../../assets/icons/teamIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import leftarrow from "../../assets/icons/commentArrow.svg";
import leftarrow1 from "..//../assets/icons/OrderArrow.svg";
import download from "..//../assets/icons/download.svg";
import {
  DownloadFile,
  CreateOrder,
  fetchAwaitingOrder,
  fetchUserDetails,
  fetchwarehouse,
  nextOrder,
  stockAvailable,
  fetchClient,
  addLocation,
  searchContact,
  fetchProductStock,
  addComments,
} from "../../redux/actions/action";
import MoveCard from "../../components/dialogs/MoveCard";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
// import ProductDnd from "../../components/ProductDnd";
// import TeamUserDnd from "../../components/TeamUserDnd";
// import WarehouseDnd from "../../components/WarehouseDnd";
import DatePicker from "react-datepicker";
import OrderCreated from "../../components/dialogs/OrderCreated";
import { useHistory } from "react-router-dom";
// import arrowicon from "../../assets/icons/arrow.svg";
// import orderidicon from "../../assets/icons/orderIcon.svg";
import clienticon from "../../assets/icons/usericon.png";
import Group1087 from "../../assets/icons/Group1087.svg";
import axios from "axios";
import { API_URL } from "../../redux/constants/type";
import specialPartIcon from "../../assets/images/productpart.svg";
// import Autocomplete from "react-google-autocomplete";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import stockIcon from '../../assets/icons/stockIcon.svg';
import productIcon from "../../assets/icons/producticon.svg";
// import teamIcon from '../../assets/icons/teamIcon.svg';
import deliveryIcon from "../../assets/icons/deliveryIcon.svg";
import documentIcon from "../../assets/icons/documentIcon.svg";
import installationIcon from "../../assets/icons/installationIcon.svg";
// import producticon from "../../assets/icons/producticon.svg";
// import speciaIcon from "../../assets/icons/speciaIcon.svg";
// import singel from "../../assets/icons/singel.svg";
// import toggelcircle from "..//..//assets/icons/toggelcircle.png";
import Select from "react-select";
import { id } from "date-fns/locale";
import { FileMimeType } from "../../utils/contentType";
import { stockQuntityFormat } from "../../utils/NumberFormatter";
import moment from "moment";
const itemsFromBackend = [{ id: "1", content: "First task" }];
const clientname = window.sessionStorage.getItem("clientname");

function Order() {
  const selectRef = useRef();
  const selectContactRef = useRef();
  const history = useHistory();
  const clientname = window.sessionStorage.getItem("clientname");
  const finalClientname = clientname.split(" ");
  const firstName = finalClientname[0].toString();
  const lastName = finalClientname[1].toString();
  const [columns, setColumns] = useState([]);
  const [columns1, setColumns1] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [imgUrl1, setImgurl1] = useState("");
  const stockData = useSelector((state) => state.user.stockAvailable);
  const clientData = useSelector((state) => state.user.fetchClient);
  const createOrder = useSelector((state) => state.user.createOrder);
  const addLocationData = useSelector((state) => state.user.addLocationData);
  const fetchProductdata = useSelector((state) => state.user.fetchProductStock);
  const downloadFile = useSelector((state) => state.user.downloadFile);
  const searchContactData = useSelector(
    (state) => state.user.searchContactData
  );
  const dispatch = useDispatch();
  const userId = window.sessionStorage.getItem("userid");
  const [awaitData, setAwaitData] = useState([]);
  const [pagenumber, setPageNumber] = useState(0);
  const [waredata, setWaredata] = useState([]);
  const initialRender = React.useRef(true);
  const [productdetails, setProductdetails] = useState();
  const teamid = window.sessionStorage.getItem("teamId");
  const [userdata, setuserdata] = useState();
  const userDatas = useSelector((state) => state.user.fetchUserDetails);
  const warehouseDetails = useSelector((state) => state.user.fetchWarehouse);
  const [finaluserdata, setuserData] = useState([]);
  const [wareArray, setWareArray] = useState([]);
  const [val, setVal] = useState("");
  // const [commentval, setCommentval] = useState(val + imgUrl1);
  const [attachments, setAttachments] = useState("");

  const [iteratorVal, setIteratorVal] = useState(0);
  const [StatusPopup, setStatusPopup] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [productAdded, setProductAdded] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [dueDate, setDueDate] = useState(moment().hours(9).minutes(0).seconds(0).milliseconds(0).toDate());
  const [deliveryDate, setDeliveryDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [statusId1, setStatusId1] = useState("");
  const [orderCreated, setOrderCreated] = useState(false);
  const [createdOrderId, setCreatedOrderId] = useState("");
  const [createdStatusId, setCreatedStatusId] = useState("");
  // const [createdOrderFullId, setCreatedOrderFullId] = useState("");
  const [clientList, setClientList] = useState([]);
  const [orderCode, setOrderCode] = useState(
    `2022/${Math.floor(Math.random() * 100000 + 1)}`
  );
  const [clientId, setClientId] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactId, setContactId] = useState("");
  const [isConcrete, setIsConcrete] = useState(true);
  const [specialPart, setSpecialPart] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [stockStatus, setStockStatus] = useState(true);
  const [doc, setDoc] = useState("");
  const [documentid, setDocumentid] = useState("");
  console.log("doc", doc)
  console.log("doc2", documentid)
  const [installation, setInstallation] = useState("");
  const [location, setLocation] = useState("");
  const [contactList, setContactList] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [fileName, setFileName] = useState("");
  const [attachedFileName, setAttachedFileName] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [productdata, setProductdata] = useState([]);
  const [message, setMessage] = useState("");
  const [messagetype, setMessagetype] = useState("order");
  const [imgUrl, setImgUrl] = useState("");
  const [productId, setProductId] = useState("");
  const [querystring, setQuerystring] = useState("");
  const [proaddress, setProaddress] = useState("");
  const [contactDescription, setContactDescription] = useState("");
  const [checkbox, setCheckbox] = useState(true);
  const [value, setValue] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [productArr, setProductArr] = useState([]);
  const [partarrow, setPartarrow] = useState("");
  const [partlistView, setPartListView] = useState([]);
  // const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [showOptions, setShowOptions] = useState(false);
  const [searchedItem, setSearchedItem] = useState("");
  const [searchproducts, setSearchproducts] = useState("");
  const [type, setType] = useState(null);
  // const [showOptions ,setShowOptions] = useState();

  // const [val, setVal] = useState("");
  const textAreaRef = useRef("");
  const [selectedRoom, setSetlectedRoom] = useState(null);
  const currentUserId = useMemo(
    () =>
      window.sessionStorage.getItem("userid") ||
      "08da84f2-ea72-41fd-8f97-256111e904f2"
  );
  // const onChange = (e) => {
  //   setVal(e.target.value);

  // };

  const [customDrop, setCustomDrop] = useState(false);
  const [customClientName, setCustomClientName] = useState("");



  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const finDate1 = new Date(date);
  var date = finDate1.toString().split(" ");

  const inputFile = React.useRef(null);
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const finDate = new Date(date);
  var date = finDate.toString().split(" ");
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const addedArr = [];
      addedArr.push(sourceItems[source.index]);
      addedArr[addedArr.length - 1].quantity = 0;
      setProductAdded(addedArr);
      setProductAdded2(addedArr);
      setPartListView(addedArr)
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setProductId(addedArr[0].id);
    } else {
      const column = columns[source.droppableId];

      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };


  const onDragEnd1 = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];

      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const addedArr = [];
      addedArr.push(sourceItems[source.index]);
      setWareArray(addedArr);

      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
    } else {
      const column = columns[source.droppableId];

      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const onDragEnd2 = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];

      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const addedArr = [...userArray];
      var count = 0;
      for (let i = 0; i < userArray.length; i++) {
        if (sourceItems[source.index].userid == userArray[i].userid) {
          count = count + 1;
        }
      }
      if (count == 0) {
        addedArr.push(sourceItems[source.index]);
        // addedArr[addedArr.length - 1].quantity = 0;
        // addedArr.quantity.push(0)
        setUserArray(addedArr);
      }

      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];

      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  useEffect(() => {
    dispatch(fetchwarehouse());
    dispatch(fetchUserDetails(0, teamid));
    dispatch(stockAvailable("08da2f31-46b4-4ce8-80b3-fdffac7a3080"));
    dispatch(fetchClient(pagenumber, querystring));
    dispatch(fetchProductStock(pagenumber, searchproducts, type));
  }, [stockAvailable, fetchwarehouse, fetchProductStock]);

  const [contactSelect, setContactSelect] = useState([]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (searchContactData != null) {
        if (searchContactData.status == true) {
          setContactList(
            // searchContactData.data[ 0 ]?.clientcontact[ 0 ]?.contactdetails
            searchContactData.data
          );
          // setContactList(searchContactData.data);
          setContactSelect(searchContactData.data[0]?.contactid);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [searchContactData]);

  const initialRender2 = React.useRef(true);
  const initialRender3 = React.useRef(true);
  React.useEffect(() => {
    if (!initialRender2.current) {
      if (addLocationData != null) {
        if (addLocationData.status == true) {
          setLocationId(addLocationData.data.locationid);
        } else {
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [addLocationData]);

  const handleOnFocus = () => {
    setIsNewForm(true)
  }

  React.useEffect(() => {
    if (!initialRender.current) {
      if (clientData != null) {
        if (clientData.status == true) {
          setClientList(clientData.data);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [clientData]);

  // React.useEffect(() => {
  //   if (!initialRender.current) {
  //     if (clientData != null) {
  //       if (clientData.status == true) {
  //         setContactList(clientData.data[0]?.clientcontact[0]?.contactdetails);
  //       } else {
  //       }
  //     }
  //   } else {
  //     initialRender.current = false;
  //   }
  // }, [clientData]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (createOrder != null) {
        if (createOrder.status == true) {
          // if (message !== "") {
          setTimeout(() => {
            let trimText = val.trim();
            if (trimText !== "" || attachments) {
              dispatch(
                addComments(
                  userId,
                  createOrder.data.orderid,
                  val,
                  messagetype,
                  attachments
                )
              );
            }
            setCreatedOrderId(createOrder.data.orderid.split("-")[4]);
            setQuoteId(createOrder.data.ordercode)
            setCreatedStatusId(createOrder.data.statusid);
            setLoading1(false);
            setOrderCreated(true);
            createOrder.status = false
          }, 1000);
        }
      } else {
        setLoading1(false);
      }
      // }
    } else {
      initialRender.current = false;
    }
  }, [createOrder]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (userDatas != null) {
        if (userDatas.status == true) {
          setuserData(
            userDatas.data.filter((item, userid) => item.userdetails.length > 0)
          );
          const columnsFromBackend = {
            ["1"]: {
              name: "Requested",
              items: [],
            },
            ["2"]: {
              name: "To do",

              items: userDatas.data.filter(
                (item, userid) => item.userdetails.length > 0
              ),
            },
          };
          setColumns2(columnsFromBackend);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [userDatas]);

  React.useEffect(() => {
    if (!initialRender.current) {
      if (warehouseDetails != null) {
        if (warehouseDetails.status == true) {
          const columnsFromBackend = {
            ["1"]: {
              name: "Requested",
              items: [],
            },
            ["2"]: {
              name: "To do",

              items: warehouseDetails.data,
            },
          };
          setColumns1(columnsFromBackend);
        } else {
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [warehouseDetails]);
  useEffect(() => {
    if (!initialRender.current) {
      if (fetchProductdata != null) {
        if (fetchProductdata.status == true) {
          const newArray = fetchProductdata.data?.filter(function (el) {
            return el.type === "Product";
          });
          setProductdetails(newArray);
          const columnsFromBackend = {
            ["1"]: {
              name: "Requested",
              items: [],
            },
            ["2"]: {
              name: "To do",

              items: newArray,
            },
          };
          setColumns(columnsFromBackend);
        }
        setLoading(false);
      } else {
      }
    } else {
      initialRender.current = false;
    }
  }, [fetchProductdata]);

  const handleClose = (statusId, destination) => {
    setStatusPopup(false);
    setStatusId(statusId);
  };

  const handleQuantity = (e, index) => {
    const list = [...productAdded];
    list[index].quantity = e.target.value;
    setProductAdded(list);
  };
  const handleUpdateQuantity = (e, type, index) => {
    if (type == "add") {
      const list = [...productAdded];
      list[index].quantity = parseInt(list[index].quantity) + 1;
      setProductAdded(list);
    } else {
      const list = [...productAdded];
      if (list[index].quantity == 0) {
      } else {
        list[index].quantity = parseInt(list[index].quantity) - 1;
        setProductAdded(list);
      }
    }
  };
  const handleRemove = (index) => {
    const list = [...productAdded];
    list.splice(index, 1);
    setProductAdded(list);
  };

  const handleRemove1 = (index) => {
    const list = [...wareArray];
    list.splice(index, 1);
    setWareArray(list);
  };

  const handleCreateOrder = () => {
    // const productarr=[...productAdded]
    // for (let i = 0; i < productarr.length; i++) {
    //   delete productarr[i].productdetails;
    // }
    setLoading1(true);
    const docArr = [];
    if (doc) {
      const ob = { doc };
      docArr.push({ fileid: ob.doc });
    }
    const teamarr = [{ assigneeuserid: "" }];
    for (let i = 0; i < userArray.length; i++) {
      teamarr.push({ assigneeuserid: userArray[i].userid });
    }
    teamarr.splice(0, 1);

    if (partlistView.length > 0) {
      for (let i = 0; i < partlistView[0].productparts?.length; i++) {
        contacts.push({
          partid: partlistView[0].productparts[i]?.id,
          quantity: partlistView[0].productparts[i]?.allotedquantity,
          check: 0,
        });
      }
    }


    // const productarr = [];
    // for (let i = 0; i <= productAdded[0].productparts?.length; i++) {
    //   productarr.push({ partid: productAdded[0].productparts[i]?.id, quantity: productAdded[0].productparts[i]?.allotedquantity, check: 0 });
    // }
    var deliveryDate1 = moment(dueDate).toDate();

    dispatch(
      CreateOrder(
        userId,
        quoteId,
        "08da2f31-46b4-4ce8-80b3-fdffac7a3080",
        statusId == "" ? "08da2f31-46b4-4ce8-80b3-fdffac7a3058" : statusId,
        clientId,
        contactId,
        locationId,
        installation,
        deliveryDate1,
        "client",
        isConcrete == false ? 0 : 1,
        specialPart == false ? 0 : 1,
        stockStatus == false ? 0 : 1,
        productId,
        contacts,
        teamarr,
        docArr
      )
    );
  };


  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div
        className="mb-0 mt-3"
        style={{ fontWeight: 500, color: "rgb(0, 55, 102)", display: "flex", alignItems: "flex-end" }}
      >
        <span onClick={props.onClick} ref={ref}>
          <img src={calender} className="OrderCalenIcon " alt="" />
        </span>
        <label className="ms-2 fw-normal">Due date</label>
      </div>
    );
  });

  const handleChangeDate = (e) => {
    var dt = new Date(e);
    setDeliveryDate(dt);
    setDueDate(dt);
  };
  const handleClose1 = () => {
    setOrderCreated(false);
    window.sessionStorage.setItem("position", "/home");
    history.push("/home");
    // window.location.reload();
  };
  const handleAddOrder = () => {
    setOrderCreated(false);
    setProductAdded([]);
    setProductAdded2([]);
    setUserArray([]);
    setWareArray([]);
    setStatusId("");
    setDeliveryDate("");
    //window.location.reload();
    selectRef.current.clearValue()
    setIsNewForm(prevCheck => !prevCheck);
    setContactList([]);
    setQuoteId("")
    setContactName("");
    setContactId("");
    setContactEmail("");
    setContactPhone("");
    setIsConcrete(true)
    setStockStatus(true)
    setSpecialPart(false)
    setFileName("")
    setProaddress("")
    setInstallation("")
    setAttachedFileName("")
    setVal("")
    setDueDate(moment().hours(9).minutes(0).seconds(0).milliseconds(0).toDate());
  };

  const handleDoc = () => {
    inputFile.current.click();
  };

  const handleFileChange = (e) => {
    const target = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target);

    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setDoc(response.data[1].data.fileid);
          setDocumentid(response.data[1].data.fileid);
          setFileName(response.data[1].data.filename);
        } else {
        }
      })
      .catch(function (error) { });
  };

  const handleFileChange1 = (e) => {
    const target = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target);
    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setImgurl1(response.data[0]);
          setAttachments(response.data[0])
          // setCommentval(val + response.data[0])
          setAttachedFileName(target.name)
          // alert(target.name + " " + "File is Attached in comment box");
        } else {
        }
      })
      .catch(function (error) { });
  };

  const handleAddress = (address) => {
    dispatch(addLocation(userId, address.label));
  };


  // calid is client id from response
  const [calid, setCalid] = useState()
  const [string2, setString2] = useState("");

  const handleOnSearch = (string, results) => {
    dispatch(searchContact(string, calid));
    if (results?.length == 0) {
      setContactName("");
      setContactId("");
      setContactEmail("");
      setContactPhone("");
    }
  };

  const handleOnSearch2 = () => {

    dispatch(searchContact(string2, calid));

  };

  // const handleOnSearch = (string, results) => {
  //   dispatch(fetchClient(0, string));
  //   if (results.length == 0) {
  //     setContactName("");
  //     setContactId("");
  //     setContactEmail("");
  //     setContactPhone("");
  //   }
  // };

  const handleOnSelect = (item) => {
    setContactName(item.name);
    setContactId(item.contactid);
    setContactEmail(item.email);
    setContactPhone(item.phonenumber);
  };

  const onChange = (e) => {
    setVal(e.target.value);
  };


  const toggleSelect = (i) => {
    setCustomDrop(true)
  };

  const handleClientChange = (val) => {
    setClientId(val.clientid)
    setCustomClientName(val.name)
    setCustomDrop(false)
  };

  const arrowupDownPart = () => {
    setPartarrow((prev) => !prev);
  };

  const handleSelect = (value = null) => {
    if (value) {
      //  const currentIndex = stockData.findIndex(x => x.id == value);
      //  setDisablebtn(currentIndex === stockData.length - 1);
      setClientId(value.clientid);
      setCalid(value.clientid);
    }
  };

  // const handleFileattech = (e) => {
  //   const target = e.target.files[0];
  //   let formData = new FormData();
  //   formData.append("file", target);

  //   axios
  //     .post(`${API_URL}/api/Files/UploadToS3`, formData)
  //     .then(function (response) {
  //       if (response !== null) {
  //         setImgUrl(response.data[0]);
  //       } else {
  //       }
  //     })
  //     .catch(function (error) {});
  // };



  // let timeout
  // let scroll = 0
  // useEffect(() => {
  //   window.onscroll = () => {
  //     if (timeout) {
  //       clearTimeout(timeout)
  //     }
  //     timeout = setTimeout(() => {
  //       if ( window.scrollY > 180) {
  //         document.getElementById('sticky-section').classList.add('sticky-team')
  //       } else {
  //         document.getElementById('sticky-section').classList.remove('sticky-team')
  //       }

  //       scroll = window.scrollY
  //     }, 10)
  //   }
  // }, [])

  const [checkradio, setCheckradio] = useState(false);
  const [productAdded2, setProductAdded2] = useState([]);


  const [fileid, setFileid] = useState("");

  React.useEffect(() => {
    if (downloadFile != null) {
      if (downloadFile.status == true) {
        // setData2(downloadFile.data);
      }
    }
    else {
      initialRender.current = false;
    }
  }, [downloadFile]);


  ///////// ASC PDF DOWNLOAD START /////////

  const pdfdownload = () => {
    axios({
      method: "get",
      url: `${API_URL}/api/Files/DownloadFiles?documentid=${documentid}`,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
      // data: {
      //   documentid: documentid
      // },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("download", `FinalDocument.docx`);
      link.setAttribute("download", `Document.${FileMimeType[response.headers["content-type"]]}`);

      // Append to html link element page
      document.body.appendChild(link);
      console.log("res", response.headers)

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      // return docFileDownload;
      // }, 1000)
    });

  };

  ///////// ASC PDF DOWNLOAD END /////////


  // React.useEffect(() => {
  //   dispatch(DownloadFile(fileid));
  // }, []);

  // show more show less start
  // const [ showMore, setShowMore ] = useState(false);
  // const MAX_ITEMS = 5;

  // const RenderComments = () => {
  //   if (showMore) {
  //     return stockavailable1;
  //   }
  //   return stockavailable1?.slice(0, MAX_ITEMS);
  // };
  // show more show less end

  return (
    <>
      <Header />
      <div className="parent">
        <div className="outer custom-outer mb-3">
          {StatusPopup && (
            <MoveCard
              handleClose={handleClose}
              position={true}
              statusId=""
              setStatusId={setStatusId}
            />
          )}
          {orderCreated && (
            <OrderCreated
              handleCancel={handleClose1}
              handleAdd={handleAddOrder}
              orderId={createdOrderId}
              quoteId={quoteId}
              statusId={createdStatusId}
            />
          )}
          <h3 className="py-3 ps-2 themecolor header-title-sk">Order</h3>

          <div className="row">
            {loading == true ? (
              <Loader />
            ) : (
              <div
                style={
                  specialPart == false
                    ? productAdded.length < 3
                      ? {
                        display: "flex",
                        overflow: "hidden",
                        marginLeft: "12px",
                        // justifyContent: "center",
                        // maxHeight: "552px",
                      }
                      : {
                        display: "flex",
                        overflow: "hidden",
                        marginLeft: "12px",
                        // justifyContent: "center",
                        maxHeight: productAdded.length * 25 + 552,
                      }
                    : productAdded.length < 3
                      ? {
                        display: "flex",
                        overflow: "hidden",
                        marginLeft: "12px",
                        // justifyContent: "center",
                        // maxHeight: "552px",
                      }
                      : {
                        display: "flex",
                        // justifyContent: "center",
                        maxHeight: productAdded.length * 25 + 552,
                      }
                }
              >
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {Object.entries(columns).map(([columnId, column], index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                        id={(index == 1 ? "sticky-section" : '')}
                        className={(index == 1 ? 'overflow-x-auto' : '')}
                        key={columnId}
                      >
                        {/* <h2>{column.name}</h2> */}
                        <div >
                          <Droppable droppableId={columnId} key={columnId}>
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    background: snapshot.isDraggingOver
                                      ? //   ? "lightblue"
                                      //   : "lightgrey",
                                      "transparent"
                                      : "transparent",
                                    // padding: 4,
                                    // width: 515,
                                    width: 617,
                                    minHeight: 200,
                                  }}
                                >
                                  {index == 0 && (
                                    <div className="row pe-0 pe-xl-4 pe-lg-4 pe-md-4 pe-sm-0 ">
                                      <div
                                        className={
                                          isConcrete == true
                                            ? "backOrder-creat-order p-0 position-relative py-1  pe-4 "
                                            : "yellow-creat-order p-0 position-relative pt-1  pe-4 "
                                        }
                                      >
                                        <div className="col-9">
                                          {productAdded[0]?.imageurl ? (
                                            <>
                                              <img
                                                src={productAdded[0]?.imageurl}
                                                className="ms-4 pt-2"
                                                alt="#img"
                                                width={80}
                                                height={50}
                                              />
                                              <p className="ps-4 productnameHeader">
                                                {productAdded[0]?.name.length > 20 ? productAdded[0]?.name.slice(0, 20) + "..." : productAdded[0]?.name}

                                                {/* {productAdded[ 0 ]?.name} */}
                                              </p>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {/* <img
                                        src={productAdded[0]?.imageurl}
                                        className="imgOrder pt-2 ms-2"
                                        style={{
                                          height: "50px",
                                          width: "90px",
                                          borderColor: "transparent",
                                          border: "none",
                                        }}
                                        alt=""
                                      /> */}
                                        {/* <p className='fw-bold  mb-0'><span><img src={calender} className="calenOrder" alt="" /></span>due date</p > */}
                                        <div className="col-3 overlap">
                                          <DatePicker
                                            selected={dueDate}
                                            onChange={handleChangeDate}
                                            customInput={<CustomInput />}
                                            placeholderText="due date"
                                            popperPlacement="bottom-end"
                                            minDate={new Date()}
                                          />

                                          <h5
                                            className="py-0 mb-0 headclr"
                                            style={{ fontWeight: 700 }}
                                          >
                                            {moment(dueDate).format("DD.MMM.YYYY")}
                                          </h5>
                                        </div>

                                        {specialPart == true ? (
                                          <img
                                            src={specialPartIcon}
                                            width="617"
                                            style={{
                                              position: "absolute",
                                              // marginLeft: "-593px",
                                              // zIndex: 100,
                                              marginTop: "45px",
                                            }}
                                            alt="..."
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="card pb-3   cardBorder1">
                                        <div className="col-12 align-self-center">
                                          <div className="row  ps-2 pe-4">
                                            <div className="col-6">
                                              <div className="row ">
                                                <div className="col-2 text-start align-self-center ps-2">
                                                  <img
                                                    src={calender}
                                                    className=" mt-1 calenderIcon ms-2"
                                                  />
                                                </div>
                                                <div className="col-10 align-self-center">
                                                  <div className="row">
                                                    <div className="col-6  align-self-center text-center p-0">
                                                      <h4 className="orderPopupHeadText9 mb-0 mt-2">
                                                        Creation Date
                                                      </h4>
                                                    </div>
                                                    <div className="col-6  text-start mt-1 pe-2">
                                                      <h4
                                                        className="orderPopupHeadText8 mb-0 mt-2"
                                                        style={{
                                                          fontSize: "15px",
                                                          fontWeight: 400,
                                                        }}
                                                      >
                                                        {date[2] +
                                                          "." +
                                                          date[1] +
                                                          "." +
                                                          date[3]}
                                                      </h4>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-6">
                                              <div className="row ps-4">
                                                <div className="col-2 mt-1 text-start align-self-center">
                                                  <img
                                                    src={Group1087}
                                                    className="mt-1 quoteIcon"
                                                  />
                                                </div>
                                                <div className="col-10 align-self-center">
                                                  <div className="row">
                                                    <div className="col-4 mt-3 ps-1 pe-0 align-self-center">
                                                      <h4 className="orderPopupHeadText9">
                                                        Quote ID
                                                      </h4>
                                                    </div>
                                                    <div className="col-8 mt-2 pt-1 text-start">
                                                      <input
                                                        type="text"
                                                        className=" orderPopupHeadText7"
                                                        value={quoteId}
                                                        onChange={(e) =>
                                                          setQuoteId(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="  Add number"
                                                        style={{
                                                          border: "none",
                                                          backgroundColor:
                                                            "#FAFAFA",
                                                          width: "134px",
                                                          height: "29px",
                                                          borderRadius: "10px",
                                                          fontSize: "15px",
                                                          fontWaight: 500,
                                                          outline: "none",
                                                          color: "#143663",
                                                          paddingLeft: "9.5px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row ps-0 pe-3 mt-2">
                                            <div className="col-1 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start">
                                              <img
                                                src={clienticon}
                                                className="clienticon mt-2"
                                                alt=""
                                              />
                                            </div>
                                            <div className="col-11 align-self-center">
                                              <div className="row ms-2 pt-2 ">
                                                <div className="col-3 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-centerdd">
                                                  <h4
                                                    className="orderPopupHeadText"
                                                    style={{
                                                      color: " #003766",
                                                    }}
                                                  >
                                                    Client
                                                  </h4>
                                                </div>
                                                <div className="col-9 clientselect">
                                                  {/* <select
                                                    className="form-select "
                                                    style={{ fontSize: "15px" }}
                                                    aria-label="Default select example"
                                                    value={clientId}
                                                    onChange={(e) => {
                                                      setClientId(
                                                        e.target.value
                                                      );
                                                    }}
                                                  >
                                                    <option selected>
                                                      Select
                                                    </option>
                                                    {clientList.map((val) => (
                                                      <option
                                                        value={val.clientid}
                                                      >
                                                        {val.name}
                                                      </option>
                                                    ))}
                                                  </select>
                                                     */}
                                                  <div
                                                    style={{ position: "relative" }}
                                                    onClick={arrowupDownPart}>
                                                    <Select
                                                      ref={selectRef}
                                                      ///menuIsOpen={true}
                                                      classNamePrefix="sk-custom-select"
                                                      className={
                                                        partarrow == "" ? "asc-custom-container" : "ArrowUpDownpur asc-custom-container"
                                                      }
                                                      // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                      options={clientList}
                                                      getOptionLabel={(option) => option.name}
                                                      getOptionValue={(option) => option.clientid}
                                                      onChange={(value) => { handleSelect(value); setSearchedItem(""); }}
                                                    // styles={colourStyles}
                                                    // defaultValue={}
                                                    />

                                                  </div>

                                                  {/* <div className="custom-select-box">
                                                     {!customDrop ? <div className="custom-select-selected" onClick={() => toggleSelect(null)} > {customClientName ? customClientName : "Select..."}</div> : ""} 
                                                     {customDrop ?
                                                      <div className={`custom-select-outer`}>
                                                        {clientList.map((val, index) => {
                                                          return (
                                                            <>
                                                              <div className={`list-select-box ${val.clientid === clientId ? 'active' : ""}`} onClick={() => handleClientChange(val)}>
                                                              {val.name}
                                                              </div>
                                                            </>
                                                          );
                                                        })}
                                                      </div>
                                                      : ""} 

                                                  </div> */}


                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-2 align-self-center"></div>
                                          </div>

                                          <div className="row ps-0 pe-3  mt-2">
                                            <div className="col-1 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start"></div>
                                            <div className="col-11 align-self-center">
                                              <div className="row ms-2 pt-2 ">
                                                <div
                                                  className="col-3 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center"
                                                  style={{ color: "#60AAC6" }}
                                                >
                                                  <h6 className="contctName pt-1">
                                                    Contact Name
                                                  </h6>
                                                </div>
                                                {/* //////////////// DropDown old Start ////////// */}
                                                {/* <div className="col-9 justify-content-end d-flex text-end searchinput">
                                                  <div className="placeholderContact" style={{ width: 400 }}>
                                                    {!isNewForm && (
                                                      <ReactSearchAutocomplete
                                                        items={contactList}
                                                        onSearch={handleOnSearch}
                                                        onSelect={handleOnSelect}
                                                        onFocus={handleOnFocus}
                                                        placeholder="Type a name"
                                                      />
                                                    )}
                                                    {isNewForm && (
                                                      <ReactSearchAutocomplete
                                                        items={contactList}
                                                        onSearch={handleOnSearch}
                                                        onSelect={handleOnSelect}
                                                        onFocus={handleOnFocus}
                                                        placeholder="Type a name"
                                                      />
                                                    )}
                                                  </div>
                                                </div> */}
                                                {/* //////////////// DropDown old End ////////// */}

                                                {/* ///////////// DropDown Contact Name Start ///////////// */}
                                                {/* <div className="col-9 justify-content-end d-flex text-end searchinput"
                                                  onFocus={(string, calid) => {
                                                    console.log("click");
                                                    handleOnSearch2();
                                                    // handleOnSearch();
                                                    setShowOptions(true);
                                                  }}
                                                >
                                                  <div className="placeholderContact" style={{ width: 400, position: "absolute" }}>
                                                    <input
                                                      // className="ps-2 me-1 contact_name_drop"
                                                      className= {showOptions === true ? "ps-2 me-1 contact_name_drop2" : "ps-2 me-1 contact_name_drop"}
                                                      type="text"
                                                      placeholder="Type a name"
                                                      value={searchedItem}
                                                      onChange={(e) => {
                                                        setSearchedItem(e.target.value);
                                                        setShowOptions(true);
                                                      }}
                                                      onFocus={() => {
                                                        setShowOptions(true);
                                                      }}
                                                    />
                                                    {showOptions && (
                                                      <div className= {showOptions === true? "customSelect_Outer2 " : "customSelect_Outer "} >
                                                        {contactList.filter(item => item.name.toLowerCase().startsWith(searchedItem.toLowerCase())).map((item, index) => {
                                                          return (
                                                            <div
                                                              key={index}
                                                              className="customSelect"
                                                              onClick={() => {
                                                                handleOnSelect(item);
                                                                setSearchedItem(item.name);
                                                                setShowOptions(false);
                                                              }}
                                                            >
                                                              {item.name}
                                                            </div>
                                                          );
                                                        })
                                                        }
                                                      </div>
                                                    )}
                                                  </div>
                                                </div> */}
                                                {/* ///////////// DropDown  Contact Name end ///////////// */}

                                                <div
                                                  style={{ position: "relative", width: "70.5%" }}
                                                  onClick={arrowupDownPart}>
                                                  <Select
                                                    ref={selectRef}
                                                    classNamePrefix="sk-custom-select"
                                                    className={
                                                      partarrow == "" ? "asc-custom-container-contact-name" : "ArrowUpDownpur asc-custom-container-contact-name"
                                                    }
                                                    defaultValue={contactSelect}
                                                    options={contactList}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.contactid}
                                                    onChange={(e) => {
                                                      setSearchedItem(e.contactid);
                                                      setShowOptions(true);
                                                      handleOnSelect(e);
                                                    }}
                                                    onFocus={(string, calid) => {
                                                      console.log("click");
                                                      handleOnSearch2();
                                                      setShowOptions(true);
                                                    }}
                                                  />
                                                </div>

                                              </div>
                                            </div>
                                            <div className="col-2 align-self-center"></div>
                                          </div>

                                          <div className="row ps-0 pe-3  mt-4">
                                            <div className="col-2 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start"></div>
                                            <div className="col-10 align-self-center">
                                              <div className="row">
                                                <div
                                                  className="col-3 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center"
                                                  style={{ color: "#60AAC6" }}
                                                ></div>
                                                <div className="col-9  d-flex ps-0">
                                                  <div className="row ps-0">
                                                    <div
                                                      className="col-5 ps-0"
                                                      style={{
                                                        padding: "0px 0px",
                                                      }}
                                                    >
                                                      <div
                                                        className="form-group ps-0 emailform"
                                                        style={{
                                                          backgroundColor:
                                                            "#fafafa",
                                                          borderRadius: "10px",
                                                        }}
                                                      >
                                                        <label
                                                          style={{
                                                            color: "#80CFE6",
                                                            padding: "0px 14px",
                                                          }}
                                                        >
                                                          Telephone
                                                        </label>
                                                        <input
                                                          style={{
                                                            fontSize: "15px",
                                                            color: "#003766",
                                                            fontWieght: 400,
                                                            borderRadius:
                                                              "10px",
                                                          }}
                                                          type="text"
                                                          className="form-control clientinput1"
                                                          value={contactPhone}
                                                          // value={Math.floor(contactPhone)}
                                                          // value={parseInt(contactPhone)}
                                                          onChange={(e) => {
                                                            setContactPhone(
                                                              e.target.value
                                                            );
                                                          }}
                                                          readOnly
                                                          placeholder="Add a number"
                                                        />
                                                        {/* {console.log("number", contactPhone)} */}
                                                      </div>
                                                    </div>

                                                    <div className="col-7">
                                                      <div
                                                        className="form-group emailform"
                                                        style={{
                                                          backgroundColor:
                                                            "#fafafa",
                                                          borderRadius: "10px",
                                                        }}
                                                      >
                                                        <label
                                                          style={{
                                                            color: "#80CFE6",
                                                            padding: "0px 14px",
                                                          }}
                                                        >
                                                          E-mail
                                                        </label>
                                                        <input
                                                          style={{
                                                            fontSize: "15px",
                                                            color: "#003766",
                                                            fontWieght: 400,
                                                            borderRadius:
                                                              "10px",
                                                          }}
                                                          type="email"
                                                          className="form-control clientinput1"
                                                          value={contactEmail}
                                                          onChange={(e) => {
                                                            setContactEmail(
                                                              e.target.value
                                                            );
                                                          }}
                                                          readOnly
                                                          placeholder="Add an e-mail"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-2 align-self-center"></div>
                                          </div>
                                          <div className="row ps-0 pe-2  mt-2">
                                            <div className="col-1 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start">
                                              <img
                                                src={productIcon}
                                                className="orderIconPop mt-2"
                                                alt=""
                                              />
                                            </div>
                                            <div className="col-11 align-self-center">
                                              <div className="row ms-2 pt-2 ">
                                                <div className="col-3 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                  <h4
                                                    className="orderProductText"
                                                    style={{ color: "#003766" }}
                                                  >
                                                    Product
                                                  </h4>
                                                </div>
                                                <div className="col-5 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                  <h4
                                                    className="orderPopupHeadText1 ms-2"
                                                    style={{ color: "#003766" }}
                                                  >
                                                    Concrete Block
                                                  </h4>
                                                </div>
                                                <div className="col-4 px-0  justify-content-end d-flex text-end">
                                                  <div className="form-check form-switch me-4 checkbutton greyCircle">
                                                    <input
                                                      className="form-check-input ordcheck"
                                                      checked={isConcrete}
                                                      onChange={(e) => {
                                                        setIsConcrete(
                                                          e.target.checked
                                                        );
                                                      }}
                                                      type="checkbox"
                                                      role="switch"
                                                      id="flexSwitchCheckChecked"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-2 align-self-center"></div>
                                          </div>

                                          <div className="row">

                                            <div className="col-3">

                                              {/* ASC product start */}

                                              {index == 0 && (
                                                <div className="row mx-3">
                                                  <div
                                                    className={
                                                      isConcrete === true && productAdded[0]?.imageurl
                                                        ? "backOrder2 p-0 ms-4 position-relative "
                                                        :
                                                        isConcrete === false && productAdded[0]?.imageurl ?
                                                          "backOrder1 p-0 ms-4 position-relative "
                                                          :
                                                          null
                                                    }
                                                  >
                                                    {/* Remove from Both side Start */}
                                                    <div className="col-10"
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => {
                                                        const list = [...productAdded,];
                                                        list.splice(index, 1);
                                                        setProductAdded(list);
                                                      }}
                                                    >
                                                      {productAdded[0]?.imageurl ? (
                                                        <>
                                                          <img
                                                            style={{ cursor: "pointer" }}
                                                            src={productAdded[0]?.imageurl}
                                                            className="pe- pt-2"
                                                            alt="#img"
                                                            width={80}
                                                            height={50}
                                                          // onClick={() => {
                                                          //   const list = [ ...productAdded, ];
                                                          //   list.splice(index, 1);
                                                          //   setProductAdded(list);
                                                          // }}
                                                          />
                                                          <p className="ps- productname">
                                                            {productAdded[0]?.name.length > 20 ? productAdded[0]?.name.slice(0, 20) + "..." : productAdded[0]?.name}

                                                            {/* {productAdded[ 0 ]?.name} */}
                                                          </p>

                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                    {/* Remove from Both side End */}

                                                    {/* Remove from Product side Start */}
                                                    {/* <div className="col-10">
                                                      {productAdded2[ 0 ]?.imageurl ? (
                                                        <>
                                                          <img
                                                            style={{ cursor: "pointer" }}
                                                            src={productAdded2[ 0 ]?.imageurl}
                                                            className="pe-2 pt-2"
                                                            alt="#img"
                                                            width={90}
                                                            height={50}
                                                            onClick={() => {
                                                              const list = [ ...productAdded2, ];
                                                              list.splice(index, 1);
                                                              setProductAdded2(list);
                                                            }}
                                                          />
                                                          <p className="ps-3 productname">
                                                            {productAdded[ 0 ]?.name}
                                                          </p>

                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div> */}
                                                    {/* Remove from Product side End */}
                                                  </div>
                                                </div>
                                              )}

                                              {/* ASC product end */}
                                            </div>

                                            <div className="col-9">

                                              <div className="row ps-0 mt-2">

                                                <div className="col-12 align-self-center">
                                                  <div className="row pt-2 ">
                                                    <div
                                                      className="col-1 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center"
                                                      style={{ color: "#60AAC6" }}
                                                    ></div>
                                                    <div className="col-10">
                                                      <div
                                                        className="row"
                                                        style={{ color: "#80CFE6" }}
                                                      >
                                                        <div className="col ps-3">
                                                          <h6 className="ps-1">Part</h6>
                                                        </div>
                                                        <div className="col text-center">
                                                          <h6>Qty</h6>
                                                        </div>
                                                        <div className="col text-end">
                                                          <h6>Chk</h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-2 align-self-center"></div>
                                              </div>
                                              {productAdded.length == 0 ? (
                                                <div>
                                                  <div className="row ">

                                                    <div className="col-11 ps-0 align-self-center">
                                                      <div className="row pt-2 ">
                                                        <div
                                                          className="col-2 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center"
                                                          style={{
                                                            color: "#60AAC6",
                                                          }}
                                                        ></div>
                                                        <div className="col-9 ">
                                                          <div
                                                            className="conatiner me-2  mt-2 text-center"
                                                            style={{
                                                              border:
                                                                "3px dotted #C1C1C1",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <p
                                                              className="   pt-3 "
                                                              style={{
                                                                color: "#003766",
                                                                fontSize: "17px",
                                                              }}
                                                            >
                                                              + Drag Product
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* <div className="col-2 align-self-center"></div> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    height: "380px",
                                                    overflow: "auto",
                                                    overflowX: "hidden",
                                                  }}
                                                >
                                                  {productAdded[0].productparts?.map(
                                                    (item, index) => {
                                                      return (
                                                        <div className="row pt-0 ">

                                                          <div className="col-11 align-self-center">
                                                            <div className="row pt-2 ">
                                                              <div
                                                                className="col-1 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center"
                                                                style={{
                                                                  color: "#60AAC6",
                                                                }}
                                                              ></div>
                                                              <div className="col-11">
                                                                <div
                                                                  className="row"
                                                                  key={index}
                                                                >
                                                                  <div className="col ps-4">
                                                                    {/* {
                                                          item.productparts?.map((item, index) => {
                                                            return( */}

                                                                    {item.partname}

                                                                    {/* ); */}
                                                                    {/* })} */}
                                                                  </div>
                                                                  <div className="col text-center">
                                                                    {/* <div className="input-group ps-4"> */}
                                                                    <p className="ms-2" style={{ marginBottom: "5px" }}>
                                                                      {stockQuntityFormat(item.allotedquantity)}
                                                                    </p>
                                                                    {/* <input
                                                                type="text"
                                                                className=" "
                                                                onChange={(e) => {
                                                                  handleChange(
                                                                    index,
                                                                    "quantity",
                                                                    e.target.value
                                                                  );
                                                                  setContactDescription(e.target.value);
                                                                }}
                                                                value={item.allotedquantity}
                                                                disabled
                                                              /> */}
                                                                    {/* </div> */}
                                                                  </div>

                                                                  <div className="col text-end">
                                                                    {/* <input type="checkbox"
                                                              name={item.name}
                                                              id={`custom-checkbox-${index}`}
                                                              
                                                              checked={checkedState[index]}
                                                             
                                                              onChange={(e) => handleChange(index, "check")}
                                                            /> */}
                                                                    {/* <input
                                                              checked={checkbox}
                                                              type="checkbox"
                                                              onChange={() => handleChange(index,"check",checkbox )}
                                                              className="form-check-input  "
                                                              value={index}
                                                            /> */}

                                                                    {/* <input
                                                                  // type="radio"
                                                                  type="checkbox"
                                                                /> */}

                                                                    {/* ASC Radio No using Start */}

                                                                    {/* <input type="radio" checked={checkradio === ""}
                       onClick={() => {setCheckradio(true);}}
                        onChange={(e) => 
                          {if (e.target.checked === true)
                            //  {
                            //                                               setCheckradio({[index] : true})}
                            {setCheckradio("")}
                                                                           else { setCheckradio(e.target.value); 
                                                                        };
                                                                      }}
                                                                    /> */}


                                                                    <div className="custom-radio-check-sk">
                                                                      <input type={"checkbox"} />
                                                                    </div>

                                                                    {/* ASC Radio No using End */}

                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {/* <div className="col-2 align-self-center"></div> */}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {index == 1 && (
                                    <div
                                      className="row px-1"
                                      style={{
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        // position: "fixed",
                                        // right: "-118px",
                                      }}
                                    >
                                      {column.items?.map((item, index) => {
                                        return (
                                          <Draggable
                                            key={index}
                                            draggableId={item.id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <div
                                                  width={94}
                                                  className="col-2 px-1 pb-2 mt-2 "
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <div
                                                    // className={
                                                    //   item.productdetails[0]
                                                    //     ?.isConcreteBlock == 0
                                                    //     ? "card  px-0 text-center w-100 h-100 hold1 me-2"
                                                    //     : "card  px-0 text-center w-100 h-100 hold me-2"
                                                    // }
                                                    className="card  px-0 text-center w-100  me-2"
                                                    style={{
                                                      cursor: "move",
                                                      alignItems: "center",
                                                      height: "129px",
                                                      border: "none",
                                                      borderRadius: "10px",
                                                      boxShadow:
                                                        "0px 3px 12px #00367740",
                                                    }}
                                                  >
                                                    <img
                                                      src={item.imageurl}
                                                      className="imgOrder pt-2"
                                                      alt=""
                                                    />

                                                    <p className="py-0 textAdjust">
                                                      {item.name.length > 15 ? item.name.slice(0, 15) + "..." : item.name}
                                                      {/* {item.name} */}

                                                      {/* {item.productdetails[0]?.productname.split(
                                                  " "
                                                )} */}
                                                    </p>

                                                    <p className="pb-2 my-0">
                                                      {/* {item.productdetails[0]?.productname
                                                  .split(" ")
                                                  .pop()} */}
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                    </div>
                                  )}

                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div>
                      </div>
                    );
                  })}
                </DragDropContext>
              </div>
            )}
          </div >
          <div className="row">
            {loading == true ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  maxHeight: "400px",
                }}
              >
                <DragDropContext
                  onDragEnd={(result) =>
                    onDragEnd1(result, columns1, setColumns1)
                  }
                >
                  {Object.entries(columns1).map(([columnId, column], index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}

                        // className={(index == 1 ? 'overflow-x-auto' : '')}
                        className={(index == 1 ? '' : '')}
                        key={columnId}
                      >
                        {/* <h2>{column.name}</h2> */}
                        <div style={{ margin: 8 }}>
                          <Droppable droppableId={columnId} key={columnId}>
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    background: snapshot.isDraggingOver
                                      ? //   ? "lightblue"
                                      //   : "lightgrey",
                                      "transparent"
                                      : "transparent",
                                    padding: 4,
                                    // width: 515,
                                    width: 650,
                                    minHeight: 200,
                                  }}
                                >
                                  {index == 0 && (
                                    <div className="row pe-0 pe-xl-5 pe-lg-5 pe-md-5 pe-sm-0 ">
                                      <div className="card pb-3  px-4 cardBorder2">
                                        <div className="col-12 align-self-center">
                                          <div className="row pt-2 justify-content-center">
                                            <div className="row px-2 mt-5">
                                              <div className="col-1 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start">
                                                {/* <img src={stockIcon} className="orderIconPop mt-2" alt="" /> */}
                                              </div>
                                              <div className="col-11 align-self-center">
                                                <div className="row ms-2 pt-2 ">
                                                  <div
                                                    className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center"
                                                    style={{ color: "#60AAC6" }}
                                                  >
                                                    <h6 className="spclPart">
                                                      Special Part ?
                                                    </h6>
                                                  </div>
                                                  <div className="col col justify-content-end d-flex text-end me-1">
                                                    <div className="form-check form-switch checkbutton">
                                                      <input
                                                        className="form-check-input ordcheck"
                                                        checked={specialPart}
                                                        onChange={(e) => {
                                                          setSpecialPart(
                                                            e.target.checked
                                                          );
                                                        }}
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-2 align-self-center"></div>
                                            </div>
                                            <div className="row px-2 mt-2">
                                              <div className="col-1 ps-0 ps-xl-3 ps-lg-2 ps-md-2 ps-sm-4 text-start">
                                                <img
                                                  src={stockIcon}
                                                  className="orderIconPop mt-2"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="col-11 align-self-center">
                                                <div className="row ms-2 pt-2 ">
                                                  <div className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                    <h4 className="orderPopupHeadText headclr">
                                                      Stock Status
                                                    </h4>
                                                  </div>
                                                  <div className="col col justify-content-end d-flex text-end me-1">
                                                    <div className="form-check form-switch checkbutton">
                                                      <input
                                                        className="form-check-input ordcheck"
                                                        checked={stockStatus}
                                                        onChange={(e) => {
                                                          setStockStatus(
                                                            e.target.checked
                                                          );
                                                        }}
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-2 align-self-center"></div>
                                            </div>
                                            {wareArray.length == 0 ? (
                                              <div className="row px-2 mt-2">
                                                <div className="col-1 ps-0 ps-xl-3 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0 ">
                                                  <img
                                                    src={warehouseIcon}
                                                    className="orderIconPop mt-2"
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="col-11 align-self-center">
                                                  <div className="row ms-2 pt-2 ">
                                                    <div className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                      <h4 className="orderPopupHeadText headclr">
                                                        Warehouse
                                                      </h4>
                                                    </div>
                                                    {/* <div
                                                className="col text-center me-3 align-self-center"
                                                style={{
                                                  border: "6px dotted #DAF4FA",
                                                  height: "40px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <p
                                                  className="  "
                                                  style={{ color: "#003766" }}
                                                >
                                                  + Drag Warehouse
                                                </p>
                                              
                                              </div> */}

                                                    <div className="col text-end ms-5 ps-5 me-1">
                                                      <p
                                                        style={{
                                                          color: "#003766",
                                                          fontWeight: "500",
                                                        }}
                                                      >
                                                        Hanger A-20
                                                      </p>

                                                      {/* {column.items.map(
                                                  (item, index) => {
                                                    return (
                                                      <Draggable
                                                        key={item.warehouseid}
                                                        draggableId={
                                                          item.warehouseid
                                                        }
                                                        index={index}
                                                      ></Draggable>
                                                    );
                                                  }
                                                )} */}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-2 align-self-center"></div>
                                              </div>
                                            ) : (
                                              wareArray.map((item) => (
                                                <div>
                                                  <div className="row px-2 mt-3">
                                                    <div className="col-1 ps-0 ps-xl-4 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0 ">
                                                      <img
                                                        src={warehouseIcon}
                                                        className="orderIconPop "
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="col-11 align-self-center">
                                                      <div className="row ms-2 pt-2 ">
                                                        <div className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ">
                                                          <h4 className="orderPopupHeadText ">
                                                            Warehouse
                                                          </h4>
                                                        </div>
                                                        <div className="col text-end ">
                                                          <p className="">
                                                            {item.warehousename}
                                                            <span
                                                              onClick={() => {
                                                                handleRemove1(
                                                                  index
                                                                );
                                                              }}
                                                              className="my-0 ps-5 productremove"
                                                            >
                                                              X
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-2 align-self-center"></div>
                                                  </div>
                                                </div>
                                              ))
                                            )}

                                            <div className="row px-2 mt-2">
                                              <div className="col-1 ps-0 ps-xl-3 ps-lg-2 ps-md-2 ps-sm-4 text-start">
                                                <img
                                                  src={documentIcon}
                                                  className="orderIconPop mt-2"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="col-11 align-self-center">
                                                <div className="row ms-2 pt-2 ">
                                                  <div className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                    <h4 className="orderPopupHeadText headclr">
                                                      Documents
                                                    </h4>
                                                  </div>
                                                  <div className="col col justify-content-end d-flex text-end me-1" onClick={() => {
                                                    // dispatch(DownloadFile(fileid));
                                                    // dispatch(DownloadFile(fileid));
                                                  }}
                                                  >
                                                    {doc == "" ? (
                                                      <>
                                                        <span
                                                          role="button"
                                                          className=""
                                                          onClick={(e) => {
                                                            handleDoc();
                                                          }}
                                                          style={{
                                                            color: "#003766",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          Add...
                                                        </span>
                                                        <input
                                                          type="file"
                                                          id="file"
                                                          ref={inputFile}
                                                          onChange={(e) => {
                                                            handleFileChange(e);
                                                          }}
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div>
                                                          <img
                                                            src={download}
                                                            alt=""
                                                            className="me-2"
                                                            width={15}
                                                            height={15}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                              // dispatch(DownloadFile(fileid));
                                                              // dispatch(DownloadFile(doc));
                                                              pdfdownload()
                                                            }}
                                                          />
                                                        </div>
                                                        <input
                                                          type="file"
                                                          id="file"
                                                          ref={inputFile}
                                                          onChange={(e) => {
                                                            handleFileChange(e);
                                                          }}
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        />
                                                        <span
                                                          role="button"
                                                          className="fw-bold"
                                                          onClick={(e) => {
                                                            handleDoc();
                                                          }}
                                                          style={{
                                                            color: "#003766",
                                                          }}
                                                        >
                                                          {fileName.length > 20
                                                            ? fileName.slice(
                                                              0,
                                                              20
                                                            ) + "..."
                                                            : fileName}
                                                        </span>
                                                        {/* <img className="my-5" src="" onClick={(e)=>{handleDoc()}} height="10px" width="70px" role="button"   alt="uploaded"/> */}
                                                      </>
                                                    )}
                                                    {/* <span role="button" className="fw-bold" onClick={(e)=>{handleDoc()}} style={{ color: "#003766" }}>Add...</span>
                                         <input type='file' id='file' ref={inputFile} onChange={(e) => {handleFileChange(e);}} style={{ display: 'none' }} /> */}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-2 align-self-center"></div>
                                            </div>

                                            <div className="row px-2 mt-2">
                                              <div className="col-1 ps-0 ps-xl-3 ps-lg-2 ps-md-2 ps-sm-4 text-start">
                                                <img
                                                  src={deliveryIcon}
                                                  className="orderIconPop mt-2"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="col-11 align-self-center">
                                                <div className="row ms-2 pt-2 ">
                                                  <div className="col-6 ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                    <h4 className="orderPopupHeadText headclr">
                                                      Delivery Location
                                                    </h4>
                                                  </div>
                                                  <div className="col-6 justify-content-center d-flex text-center Address">
                                                    <GooglePlacesAutocomplete
                                                      apiKey={"AIzaSyBcYRPNB_ePlSHVxC1p5buVrxdL_Uou_bc"}
                                                      // apiKey={"AIzaSyBkqTYv5DS7unCFoCyRa9K8Uiqy8aJQu_A"}
                                                      className="signinputSmall1"
                                                      style={{ width: "100%" }}
                                                      selectProps={{
                                                        value: proaddress,
                                                        onChange: (value) => {
                                                          setProaddress(value);
                                                          handleAddress(value);
                                                          setLocation(value);
                                                        },
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-2 align-self-center"></div>
                                            </div>

                                            <div className="row px-2 mt-2">
                                              <div className="col-1 ps-0 ps-xl-3 ps-lg-2 ps-md-2 ps-sm-4 text-start">
                                                <img
                                                  src={installationIcon}
                                                  className="orderIconPop mt-2"
                                                  alt=""
                                                />
                                              </div>
                                              <div className="col-11 align-self-center">
                                                <div className="row ms-2">
                                                  <div className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                    <h4 className="orderPopupHeadText headclr">
                                                      Installation
                                                    </h4>
                                                  </div>
                                                  <div className="col col justify-content-end d-flex text-end me-2 Installation ">
                                                    {/* <button type="btn" className="choosebtn">Choose<span className="ms-2"><img src={arrowicon}/></span></button> */}
                                                    {/* <i
                                                      className="bi bi-arrow-right "
                                                      style={{ marginTop: "20px", width:50}}
                                                    ></i> */}
                                                    <img
                                                      src={leftarrow1}
                                                      style={{
                                                        width: 41,
                                                        height: 10,
                                                        position: "absolute",
                                                        bottom: "30px",
                                                        right: "65px",
                                                      }}
                                                    ></img>
                                                    <select
                                                      className="form-select choosebtn"
                                                      value={installation}
                                                      onChange={(e) => {
                                                        setInstallation(
                                                          e.target.value
                                                        );
                                                      }}
                                                      aria-label="Default select example"
                                                      style={{
                                                        backgroundImage:
                                                          leftarrow,
                                                      }}
                                                    >
                                                      <option value="">
                                                        Choose
                                                      </option>

                                                      <option value="RBCE">
                                                        RBCE
                                                      </option>
                                                      <option value="Client">
                                                        Client
                                                      </option>
                                                      <option value="Other">
                                                        Other...
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-2 align-self-center"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* Right side warehouseid with Div icon start*/}

                                  {/* {index == 1 && (
                              <div
                                className="row"
                                style={{ maxHeight: "347px" }}
                              >
                                {column.items.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item.warehouseid}
                                      draggableId={item.warehouseid}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            className="col-4 px-1 pb-2"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div className="col px-0 pe-2">
                                              <div
                                                className="card flex-row py-3 px-0 "
                                                style={{
                                                  background: "#FAFAFA",
                                                }}
                                               >
                                                <div className="col-4 text-center">
                                                  {" "}
                                                  <img
                                                    src={warehouseIcon}
                                                    className="orderIconPop"
                                                  />
                                                </div>
                                                <div className="col-8 text-center">
                                                  <h6 className="my-0">
                                                    Warehouse
                                                  </h6>
                                                  <h6 className="my-0">
                                                    {" "}
                                                    {item.warehousename}
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                              </div>
                            )} */}

                                  {/* Right side warehouseid with Div icon end */}

                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div>
                      </div>
                    );
                  })}
                </DragDropContext>
              </div>
            )}

            {loading == true ? (
              ""
            ) : (
              <div className="mb-5" style={{ display: "flex" }}>
                <DragDropContext
                  onDragEnd={(result) =>
                    onDragEnd2(result, columns2, setColumns2)
                  }
                >
                  {Object.entries(columns2).map(([columnId, column], index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          // overflow: "hidden"
                        }}
                        className={(index == 1 ? 'overflow-x-auto' : '')}
                        key={columnId}
                      >
                        {/* <h2>{column.name}</h2> */}
                        <div style={{ margin: 8 }}>
                          <Droppable droppableId={columnId} key={columnId}>
                            {(provided, snapshot) => {
                              return (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    background: snapshot.isDraggingOver
                                      ? //   ? "lightblue"
                                      //   : "lightgrey",
                                      "transparent"
                                      : "transparent",
                                    padding: 4,
                                    // width: 515,
                                    width: 650,
                                    minHeight: 200,
                                  }}
                                >
                                  {index == 0 && (
                                    <div className="row pe-0 pe-xl-5 pe-lg-5 pe-md-5 pe-sm-0 ">
                                      <div className="card pb-3  px-4 cardBorder3">
                                        <div className="row px-2">
                                          <div className="col-1 ps-0 ps-xl-3 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0">
                                            <img
                                              src={teamIcon}
                                              className="orderIconPop mt-3"
                                              alt=""
                                            />
                                          </div>
                                          <div className="col-11 align-self-center">
                                            <div className="row ms-2 pt-2 ">
                                              <div className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                <h4 className="orderPopupHeadText headclr">
                                                  Team
                                                </h4>
                                              </div>
                                              {userArray.length == 0 ? (
                                                <div
                                                  className="col me-4 text-center align-self-center"
                                                  style={{
                                                    border:
                                                      "3px dotted #C1C1C1",
                                                    height: "49px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <p
                                                    className=" mt-1 "
                                                    style={{ color: "#003766" }}
                                                  >
                                                    + Drag Team
                                                  </p>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>

                                          <div className="col-2 align-self-center"></div>

                                          <div className="col-10 align-self-center">
                                            <div className="row pt-2 ">
                                              {userArray.map((item, index) => (
                                                <div className="col-2 d-flex ps-0 ps-xl-0 ps-lg-0 ps-sm-3 ">
                                                  {item.userdetails[0]
                                                    .jobtitle == "Mechanic" ? (
                                                    <div className="col-6 text-center px-0 rounded-circle">
                                                      {item.userdetails[0]
                                                        .photo ? (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius:
                                                              "34px",
                                                            border:
                                                              " 6px solid #003766",
                                                          }}
                                                          type="button"
                                                          // className={
                                                          //   item.userdetails[0].jobtitle === "Mechanic"
                                                          //     ? " mt-3 "
                                                          // }
                                                          className="mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            // history.push("/order");
                                                            // window.location.reload();
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .photo ? (
                                                            <img
                                                              style={{
                                                                cursor: "pointer",
                                                                margin: "-6px -7px -6px -7px",
                                                              }}
                                                              width={40}
                                                              height={40}
                                                              className="rounded-circle"
                                                              src={
                                                                item
                                                                  .userdetails[0]
                                                                  ?.photo
                                                              }
                                                              alt=" "
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </button>
                                                      ) : (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius:
                                                              "34px",
                                                            border:
                                                              " 6px solid #003766",
                                                          }}
                                                          type="button"
                                                          className="teambtnOrder3 mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .firstname
                                                            ? (
                                                              item
                                                                .userdetails[0]
                                                                .firstname[0] +
                                                              item
                                                                .userdetails[0]
                                                                .lastname[0]
                                                            ).toUpperCase()
                                                            : ""}
                                                        </button>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {item.userdetails[0]
                                                    .jobtitle == "Driver" ? (
                                                    <div className="col-6 text-center px-0 rounded-circle">
                                                      {item.userdetails[0]
                                                        .photo ? (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius:
                                                              "34px",
                                                            border:
                                                              " 6px solid orange",
                                                          }}
                                                          type="button"
                                                          // className={
                                                          //   item.userdetails[0].jobtitle === "Mechanic"
                                                          //     ? " mt-3 "
                                                          // }
                                                          className="mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .photo ? (
                                                            <img
                                                              style={{
                                                                cursor: "pointer",
                                                                margin: "-6px -7px -6px -7px",
                                                              }}
                                                              width={40}
                                                              height={40}
                                                              className="rounded-circle"
                                                              src={
                                                                item
                                                                  .userdetails[0]
                                                                  ?.photo
                                                              }
                                                              alt=" "
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </button>
                                                      ) : (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            border:
                                                              " 6px solid orange",
                                                          }}
                                                          type="button"
                                                          className="teambtnOrder3 mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .firstname
                                                            ? (
                                                              item
                                                                .userdetails[0]
                                                                .firstname[0] +
                                                              item
                                                                .userdetails[0]
                                                                .lastname[0]
                                                            ).toUpperCase()
                                                            : ""}
                                                        </button>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {item.userdetails[0]
                                                    .jobtitle ==
                                                    "Mechanic on site" ? (
                                                    <div className="col-6 text-center px-0 rounded-circle">
                                                      {item.userdetails[0]
                                                        .photo ? (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius:
                                                              "34px",
                                                            border:
                                                              " 6px solid #80cfe6",
                                                          }}
                                                          type="button"
                                                          // className={
                                                          //   item.userdetails[0].jobtitle === "Mechanic"
                                                          //     ? " mt-3 "
                                                          // }
                                                          className="mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .photo ? (
                                                            <img
                                                              style={{
                                                                cursor: "pointer",
                                                                margin: "-6px -7px -6px -7px",
                                                              }}
                                                              width={40}
                                                              height={40}
                                                              className="rounded-circle"
                                                              src={
                                                                item
                                                                  .userdetails[0]
                                                                  ?.photo
                                                              }
                                                              alt=" "
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </button>
                                                      ) : (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            border:
                                                              " 6px solid #80cfe6",
                                                          }}
                                                          type="button"
                                                          className="teambtnOrder3 mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .firstname
                                                            ? (
                                                              item
                                                                .userdetails[0]
                                                                .firstname[0] +
                                                              item
                                                                .userdetails[0]
                                                                .lastname[0]
                                                            ).toUpperCase()
                                                            : ""}
                                                        </button>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {item.userdetails[0]
                                                    .jobtitle == "" ? (
                                                    <div className="col-6 text-center px-0 rounded-circle">
                                                      {item.userdetails[0]
                                                        .photo ? (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius:
                                                              "34px",
                                                            border: " 6px solid grey",
                                                            background: "#DAF4FA"
                                                          }}
                                                          type="button"
                                                          // className={
                                                          //   item.userdetails[0].jobtitle === "Mechanic"
                                                          //     ? " mt-3 "
                                                          // }
                                                          className="mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .photo ? (
                                                            <img
                                                              style={{
                                                                cursor: "pointer",
                                                                margin: "-6px -7px -6px -7px",
                                                              }}
                                                              width={40}
                                                              height={40}
                                                              className="rounded-circle"
                                                              src={
                                                                item
                                                                  .userdetails[0]
                                                                  ?.photo
                                                              }
                                                              alt=" "
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </button>
                                                      ) : (
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            border: " 6px solid grey",
                                                          }}
                                                          type="button"
                                                          className="teambtnOrder3 mt-3 fw-bold"
                                                          onClick={() => {
                                                            const list = [
                                                              ...userArray,
                                                            ];
                                                            list.splice(
                                                              index,
                                                              1
                                                            );
                                                            setUserArray(list);
                                                            dispatch(fetchUserDetails(0, teamid));
                                                          }}
                                                        >
                                                          {item.userdetails[0]
                                                            .firstname
                                                            ? (
                                                              item
                                                                .userdetails[0]
                                                                .firstname[0] +
                                                              item
                                                                .userdetails[0]
                                                                .lastname[0]
                                                            ).toUpperCase()
                                                            : ""}
                                                        </button>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <div className="col-6 text-start">
                                                    {/* <span
                                                      className="btn  fw-bold text-danger pb-5 ms-1"
                                                      onClick={() => {
                                                        const list = [
                                                          ...userArray,
                                                        ];
                                                        list.splice(index, 1);
                                                        setUserArray(list);
                                                      }}
                                                    >
                                                      X
                                                    </span> */}
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row px-2">
                                          <div className="col-1 ps-0 ps-xl-3 ps-lg-2 ps-md-2 ps-sm-4 text-start text-start pe-0">
                                            <img
                                              src={productsIcon}
                                              className="orderIconPop mt-2"
                                              alt=""
                                            />
                                          </div>
                                          <div className="col-11 align-self-center">
                                            <div className="row pt-2 ms-2 ">
                                              <div className="col ps-0 ps-xl-0 ps-lg-0 ps-sm-3 align-self-center">
                                                <h4 className="orderPopupHeadText headclr">
                                                  Production
                                                </h4>
                                              </div>
                                              <div className="col text-end me-2">
                                                <button
                                                  style={{
                                                    borderRadius: "14px",
                                                    width: "181px",
                                                    height: "49px",
                                                    backgroundColor: "#003766",
                                                    color: "white",
                                                    border: "none"
                                                  }}
                                                  type="button"
                                                  className="px-4 "
                                                  disabled={
                                                    wareArray.length == 0 ||
                                                      userArray.length == 0 ||
                                                      productAdded.length == 0
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() =>
                                                    setStatusPopup(true)
                                                  }
                                                >
                                                  <spna></spna>Pipeline
                                                  <span>
                                                    <i
                                                      className="bi bi-arrow-right ps-3"
                                                      style={{
                                                        marginTop: "20px",
                                                      }}
                                                    ></i>
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-2 align-self-center"></div>
                                        </div>
                                        <div className="row pt-4">
                                          <div className="col-2 px-0 text-end justify-content-center d-flex ">
                                            <button
                                              type="button"
                                              className="teambtn_order "
                                              style={{ marginTop: "30px" }}
                                            >
                                              {clientname == undefined ||
                                                clientname == null
                                                ? "SD"
                                                : firstName[0].toUpperCase() +
                                                "" +
                                                lastName[0].toUpperCase()}
                                            </button>
                                          </div>
                                          <div className="col-10 ps-0 pe-3">
                                            <div className="row pb-2 me-2">
                                              <div
                                                className="col-6 ps-4"
                                                style={{
                                                  color: "rgb(0, 55, 102)",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Comments
                                              </div>
                                              {/* <div className="col-6 text-end pe-4">
                                              Show details…
                                            </div> */}
                                            </div>
                                            {/* <div className="">
                                            <textarea
                                              className="form-control inputComments "
                                              id="exampleFormControlTextarea1"
                                              placeholder="write a comment"
                                              onChange={(e) => setMessage(e.target.value)}
                                              rows="2"
                                              style={{
                                                borderRadius: "10px",
                                                height: "110px",
                                              }}
                                            ></textarea>
                                            <div role={"button"} className="sendbtn position-relative">
                                              <p>
                                                <span className="ps-1">
                                                  <input
                                                    type="file"
                                                    id="add-attachment"
                                                    onChange={(e) => {
                                                      handleFileattech(e);
                                                    }}
                                                    style={{ display: "none" }}
                                                  />
                                                  <label htmlFor="add-attachment">
                                                    <i className="bi bi-paperclip "></i>
                                                  </label>
                                                  <img
                                                    src={leftarrow}
                                                    className="mx-2"
                                                    width="22px"
                                                    alt="leftarrow"
                                                    onClick={handleAddComments}
                                                  />
                                                </span>
                                              </p>
                                            </div>
                                          </div> */}
                                            <input
                                              type="text"
                                              className="inputOrdercmnt me-2 ps-3"
                                              placeholder=" Write a comment…"
                                              style={{ fontSize: "15px" }}
                                              onChange={onChange}
                                              value={val}
                                              // onChange={(e) =>
                                              //   setMessage(e.target.value)
                                              // }
                                              onKeyPress={(e) => {
                                                if (
                                                  e.key === "Enter" &&
                                                  e.shiftKey
                                                ) {

                                                }
                                                else if (e.key === "Enter") {
                                                  // message();
                                                } else {
                                                  return;
                                                }
                                              }}
                                            />
                                            <div
                                              role={"button"}
                                              className="sendbtn2 position-relative"
                                            >
                                              <p style={{ height: "0px" }}>
                                                <span className="ps-1">
                                                  <input
                                                    type="file"
                                                    id="add-attachment"
                                                    onChange={(e) => {
                                                      handleFileChange1(e);
                                                    }}
                                                    style={{ display: "none" }}
                                                  />
                                                  <img
                                                    src={leftarrow}
                                                    style={{
                                                      position: "relative",
                                                      left: "530px",
                                                      width: "22px",
                                                      cursor: "not-allowed",
                                                    }}
                                                    alt="leftarrow"
                                                  // onClick={handlerSendCommit}
                                                  />
                                                  <label htmlFor="add-attachment">
                                                    <i
                                                      className="bi bi-paperclip "
                                                      style={{
                                                        position: "relative",
                                                        left: "480px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></i>
                                                  </label>
                                                  <i
                                                    className="bi bi-emoji-smile"
                                                    onClick={() =>
                                                      setShowEmojiPicker(
                                                        !showEmojiPicker
                                                      )
                                                    }
                                                    style={{
                                                      position: "relative",
                                                      left: "440px",
                                                      cursor: "pointer",
                                                    }}
                                                  ></i>
                                                  {showEmojiPicker && (
                                                    <div className="emoji-picker-style">
                                                      <EmojiPicker
                                                        emojiStyle="twitter"
                                                        onEmojiClick={(e) => {
                                                          setVal(
                                                            (val) =>
                                                              val + e.emoji
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="pt-3">
                                              {attachedFileName.length ?
                                                <span
                                                  role="button"
                                                  style={{
                                                    color: "rgb(0, 55, 102)",
                                                    fontWeight: "500",
                                                    marginLeft: "12px"
                                                  }}
                                                >
                                                  {attachedFileName.length > 20 ? attachedFileName.slice(0, 20) + "..." : attachedFileName}
                                                </span>
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-5 px-0">
                                          <div className="px-0 footerline"></div>
                                          <div className="row justify-content-center">
                                            <div className="col-6 pe-0 text-center">
                                              <button
                                                className=" fw-bold ordercancelbtn"
                                                type="button"
                                                onClick={() => {
                                                  history.push("/home");
                                                }}
                                                style={{ fontWeight: 500 }}
                                              >
                                                CANCEL
                                              </button>
                                            </div>
                                            <div className="col-6 ps-0 text-center">
                                              <button
                                                className={
                                                  // wareArray.length == 0 ||
                                                  // loading1 == true ||
                                                  // userArray.length == 0 ||
                                                  // productAdded.length == 0 ||
                                                  clientId == "" ||
                                                    quoteId == "" ||
                                                    locationId == "" ||
                                                    productId == "" ||
                                                    contactId == ""
                                                    ? // doc == "" ||
                                                    // locationId == "" ||
                                                    // installation == ""
                                                    "orderaddbtn1 "
                                                    : "orderaddbtn "
                                                }
                                                type="button"
                                                disabled={
                                                  // wareArray.length == 0 ||
                                                  // loading1 == true ||
                                                  // userArray.length == 0 ||
                                                  // productAdded.length == 0 ||
                                                  clientId == "" ||
                                                  quoteId == "" ||
                                                  locationId == "" ||
                                                  productId == "" ||
                                                  contactId == ""
                                                  // doc == "" ||
                                                  // locationId == "" ||
                                                  // installation == ""
                                                  // ? true
                                                  // : false
                                                }
                                                onClick={(e) => {
                                                  handleCreateOrder(e);
                                                }}
                                                style={{ fontWeight: 700 }}
                                              >
                                                ADD
                                                {loading1 == true && (
                                                  <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Add
                                                    </span>
                                                  </div>
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {index == 1 && (
                                    <div
                                      className="row"
                                      style={{
                                        maxHeight: "347px",
                                        // overflowx: "scroll",
                                        // overflow: "auto",
                                        gap: "45px 25px",
                                      }}
                                    >
                                      {column.items.map((item, index) => {
                                        return (
                                          <Draggable
                                            key={item.userid}
                                            draggableId={item.userid}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <div
                                                  className="col-2 px-1 pb-2"
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <div className="col-12 pt-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-3 col-xl-12 ps-3 col-lg-5  col-md-5">
                                                    <div className="row mt-3">

                                                      <div className="col-5 teamavatar text-center px-0">
                                                        {item.userdetails[0]
                                                          ?.photo ? (
                                                          <div className="color12"
                                                            style={item.userdetails[0].jobtitle == "Driver" ?
                                                              { borderColor: "orange" } : item.userdetails[0].jobtitle == "Mechanic" ?
                                                                { borderColor: "#003766" } : item.userdetails[0].jobtitle == "Mechanic on site" ?
                                                                  { borderColor: "#80cfe6" } : { borderColor: "grey" }}
                                                          >
                                                            <img
                                                              src={
                                                                item
                                                                  .userdetails[0]
                                                                  ?.photo
                                                              }
                                                              alt=" "
                                                            />
                                                          </div>
                                                        ) : (

                                                          <div className="color12"
                                                            style={item.userdetails[0].jobtitle == "Driver" ?
                                                              { borderColor: "orange" } : item.userdetails[0].jobtitle == "Mechanic" ?
                                                                { borderColor: "#003766" } : item.userdetails[0].jobtitle == "Mechanic on site" ?
                                                                  { borderColor: "#80cfe6" } : { borderColor: "grey" }}
                                                          >

                                                            {/* <p className="color13">
                                                              {
                                                                (
                                                                  item.userdetails[ 0 ]
                                                                    .firstname[ 0 ] +
                                                                  item.userdetails[ 0 ]
                                                                    .lastname[ 0 ]
                                                                ).toUpperCase()
                                                              }
                                                            </p> */}

                                                            <p className="color13">
                                                              {
                                                                item.userdetails[0]?.firstname[0]?.toUpperCase() + "" +
                                                                item.userdetails[0]?.lastname[0]?.toUpperCase()
                                                              }
                                                            </p>

                                                          </div>
                                                        )}

                                                        {/* {console.log("hhh", item.userdetail)} */}

                                                        <div className="pt-2 mb-4">
                                                          <p style={{ overflow: "hidden", maxHeight: "42px" }}>
                                                            {item.userdetails[0]
                                                              ?.firstname +
                                                              " " +
                                                              item
                                                                .userdetails[0]
                                                                ?.lastname}
                                                          </p>
                                                        </div>

                                                        {/* {(
                                                        item.userdetails[0]
                                                          .firstname[0] +
                                                        item.userdetails[0]
                                                          .lastname[0]
                                                      ).toUpperCase()} */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                    </div>
                                  )}

                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div>
                      </div>
                    );
                  })}
                </DragDropContext>
              </div>
            )}
          </div>
        </div >
      </div >
      {/* {loading1 && <Loader />} */}
    </>
  );
}

export default Order;


