import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import DiscardAlert from "../../components/dialogs/Discard";
import { fetchprofilebyid, updateprofile } from '../../redux/actions/action';
import { useHistory } from "react-router-dom";
import Header from '../../components/Header';
import "../../styles/local/Profile.css";
// import Loader from '../../components/Loader';


const Profile = () => {

    const userId = window.sessionStorage.getItem("userid");

    const fetchprofileData = useSelector((state) => state.user.fetchProfileData)
    const updateProfileData = useSelector((state) => state.user.updateProfileData)

    const userBankInfo = null
    const dispatch = useDispatch();
    const history = useHistory();


    const [ loading, setLoading ] = useState(true);
    const [ editProfile, setEditProfile ] = useState(false);
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ address, setAddress ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ phonenumber, setPhonenumber ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ costhour, setCosthour ] = useState('')
    const [ jobtitle, setJobtitle ] = useState('')
    const [ rolename, setRolename ] = useState('')
    const [ teamId, setTeamId ] = useState('')

    const [ passwordType, setPasswordType ] = useState("password");


    useEffect(() => {
        dispatch(fetchprofilebyid(userId));
    }, [])
    const initialRender = React.useRef(true);

    React.useEffect(() => {

        if (!initialRender.current) {
            if (fetchprofileData != null) {
                if (fetchprofileData.status == true) {

                    setFirstName(fetchprofileData.data[ 0 ].firstname)
                    setLastName(fetchprofileData.data[ 0 ].lastname)
                    setAddress(fetchprofileData.data[ 0 ].address)
                    setPhonenumber(fetchprofileData.data[ 0 ].phonenumber)
                    setEmail(fetchprofileData.data[ 0 ].email)
                    setPassword(fetchprofileData.data[ 0 ].password)
                    setCosthour(fetchprofileData.data[ 0 ].costhour)
                    setJobtitle(fetchprofileData.data[ 0 ].jobtitle)
                    setRolename(fetchprofileData.data[ 0 ].rolename)
                    setTeamId(fetchprofileData.data[ 0 ].teamid)

                }
                else {
                }
            }
        } else {
            initialRender.current = false;
        }

    }, [ fetchprofileData ]);

    React.useEffect(() => {

        if (!initialRender.current) {
            if (updateProfileData != null) {
                if (updateProfileData.status == true) {

                    dispatch(fetchprofilebyid(userId));
                    setEditProfile(false)

                }

                else {

                }
            }
        } else {
            initialRender.current = false;
        }

    }, [ updateProfileData ]);


    const handleUpdate = (e) => {
        const abbreviation = firstName + " " + lastName
        dispatch(updateprofile(userId, firstName, lastName, phonenumber, address, teamId))
        window.sessionStorage.setItem("clientname", abbreviation);
    }

    const handleCancel = () => {

    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);

    }, [])

    const [ cancel, setCance ] = useState(false);
    const [ msg, setMsg ] = useState("");
    //////// ASC Without change START /////////////////
    const [ change, setChange ] = useState("");
    const [ changeComp, setChangeComp ] = useState("");
    //////// ASC Without change end ///////////////////

    const handleCancelBtn = (e) => {
        if (change !== changeComp) {
            setCance(true);
        } else {
            history.goBack();
            // window.location.reload();
            // setPopups(false);
        }
        // setCance(true);
        setMsg(
            "The entered data will not be saved. Do you want to cancel this changes ? "
        );
    };

    return (
        <div>
            <div>
                <Header />
            </div>

            {cancel && (
                <DiscardAlert
                    // msg={msg}
                    msg=<p style={{ paddingTop: "33px" }}>{msg}</p>
                    handleCancel={() => {
                        setCance(false);
                    }}
                    handleOk={() => {
                        setEditProfile(!editProfile)
                        setCance(false);
                        setFirstName(fetchprofileData.data[ 0 ].firstname)
                        setLastName(fetchprofileData.data[ 0 ].lastname)
                        setAddress(fetchprofileData.data[ 0 ].address)
                        setPhonenumber(fetchprofileData.data[ 0 ].phonenumber)
                        // window.location.reload();
                        // history.push("/profile");
                    }}
                />
            )}

            <div className="container-fluid" >
                <div className="container  px-xl-5 px-lg-4 px-md-3  mb-5 headerspace ">

                    <div className="row mt-5">
                        <div onClick={() => setEditProfile(!editProfile)} className='row text-end editFunc'>
                            {editProfile == false ?

                                <i className="" aria-hidden="true"></i>
                                :
                                <i className="" aria-hidden="true"></i>
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 ">
                            <div className="row personalinfrm fw-bold  fs-5 px-0 mt-2" >

                                Personal information
                            </div>
                            <div className="row nameHeadings px-0 mt-4" >
                                First name
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="text" value={firstName} onChange={(e) => {
                                        setFirstName(e.target.value);
                                        setChangeComp(e.target.value);
                                    }}
                                    />

                                    : <p className="ps-0">{firstName}</p>
                                }

                            </div>
                            <div className="row nameHeadings px-0 mt-2" >
                                Last name
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="text" value={lastName} onChange={(e) => {
                                        setLastName(e.target.value);
                                        setChangeComp(e.target.value);
                                    }}
                                    />

                                    : <p className="ps-0">{lastName}</p>
                                }

                            </div>

                            {/* <div className="row nameHeadings px-0 mt-2" >

                                Date of Birth
                            </div>

                            <div className="row poppinsfont px-0 mt-1 fs-5" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="date" value={dob} onChange={(e) => { setDob(e.target.value) }} />

                                    : <p className="ps-0">{dob}</p>}
                            </div>

                            <div className="row nameHeadings px-0 mt-2" >

                                Country
                            </div>

                            <div className="row poppinsfont px-0 mt-1 fs-5" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="text" value={country} onChange={(e) => { setCountry(e.target.value) }} />

                                    : <p className="ps-0">{country}</p>}
                            </div>
                            <div className="row nameHeadings px-0 mt-2" >

                                city
                            </div>

                            <div className="row poppinsfont px-0 mt-1 fs-5" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="text" value={city} onChange={(e) => { setCity(e.target.value) }} />

                                    : <p className="ps-0">{city}</p>}
                            </div>

                            <div className="row nameHeadings px-0 mt-2" >

                                Postal Code
                            </div>

                            <div className="row poppinsfont px-0 mt-1 fs-5" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="text" value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} />

                                    : <p className="ps-0">{postalCode}</p>}
                            </div> */}

                            <div className="row nameHeadings px-0 mt-2" >

                                Address
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="text" value={address} onChange={(e) => {
                                        setAddress(e.target.value);
                                        setChangeComp(e.target.value);
                                    }}
                                    />

                                    : <p className="ps-0">{address}</p>}
                            </div>
                            <div className="row nameHeadings px-0 mt-2" >

                                Email
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input disabled className='w-75 mt-1 profileInput2' type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />

                                    : <p className="ps-0">{email}</p>}
                            </div>

                            <div className="row nameHeadings px-0 mt-1 pt-2" >

                                Telephone
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input className='w-75 mt-1 profileInput' type="tel" value={phonenumber} onChange={(e) => {
                                        setPhonenumber(e.target.value);
                                        setChangeComp(e.target.value);
                                    }}
                                    />

                                    : <p className="ps-0">{phonenumber}</p>}
                            </div>

                            <div className="row nameHeadings px-0 mt-1 pt-2" >

                                Password
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input disabled className='w-75 mt-1 profileInput2' type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />

                                    :
                                    <input disabled type="password" className="ps-0 profileInput3" value={password} />
                                }
                            </div>

                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-5 ">

                            <div className="row nameHeadings px-0 mt-5 pt-2" >

                                Job title
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input disabled className='w-75 mt-1 profileInput2' type="text" value={jobtitle} onChange={(e) => { setJobtitle(e.target.value) }} />

                                    : <p className="ps-0">{jobtitle}</p>}
                            </div>

                            <div className="row nameHeadings px-0 mt-1 pt-2" >

                                Role in a system
                            </div>

                            <div className="row personalinfrm px-0 mt-1" >
                                {editProfile == true ?

                                    <input disabled className='w-75 mt-1 profileInput2' type="text" value={rolename} onChange={(e) => { setRolename(e.target.value) }} />

                                    : <p className="ps-0">{rolename}</p>}
                            </div>

                            <div className='row mt-lg-5 mt-sm-0 mt-md-0'></div>
                            <div className='row mt-lg-5 mt-sm-0 mt-md-0'></div>
                            <div className='row mt-lg-5 mt-sm-0 mt-md-0'></div>
                            <div className='row mt-lg-5 mt-sm-0 mt-md-0'></div>
                            <div className='row mt-lg-5 mt-sm-0 mt-md-0'></div>
                            <div className='row mt-lg-5 mt-sm-0 mt-md-0'></div>

                            <div className="text-end editFunc mt-5"
                                onClick={() => setEditProfile(!editProfile)} >
                                {editProfile == false ?

                                    <button className='p-2 editbtn h-100' type='button'>EDIT</button>
                                    :
                                    <butpton className='' type='button' aria-hidden="true"></butpton>

                                    // <i className="bi bi-pen fs-4"></i>
                                    // :
                                    // <i className="fa fa-times fs-4" aria-hidden="true"></i>
                                }
                            </div>

                        </div>

                    </div>
                    {
                        editProfile == true ?
                            <div className='d-flex flex-wrap justify-content-lg-end justify-content-md-end justify-content-sm-center pt-4 gap-3 '>

                                <div className=''>
                                    <button className='p-2 profileCancel h-100 ms-4' type='submit' onClick={(e) => {
                                        handleCancelBtn(e)
                                    }}>Cancel</button>

                                </div>

                                <div className=''>
                                    <button className='p-2 profileSave h-100 ms-4' type='submit' onClick={(e) => {
                                        handleUpdate(e)
                                    }}>Save</button>

                                </div>
                            </div> : ""
                    }
                    {/* <div className="row my-5 px-xl-0 px-lg-0 px-md-0 px-sm-4 px-4">
                        <hr className="hrline" />
                    </div> */}

                </div>

            </div>



        </div>

    )
}

export default Profile
