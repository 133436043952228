import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import Group191 from "../../assets/icons/Group191.svg";
import "../../styles/local/teamadd.css";
import Group62 from "../../assets/icons/Group62.svg";
import eyeicon from "../../assets/icons/eye.svg";
import eye from "../../assets/icons/eye.svg";
import {
  emailSend,
  updateUserDetails,
  FetchUserById,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import calender from "../../assets/icons/calendar3.svg";
import Image from "react-bootstrap/Image";
import Add1 from "../../assets/icons/Add1.png";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import axios from "axios";
import { API_URL } from "..//..//redux/constants/type";
import moment from "moment";
import teamIcon from "../../assets/icons/teamIcon.svg";
import EuropeanInput from "../../utils/EuropeanInput";
let c = 0;

const UpdateTeamUser = () => {
  const refNewStandardWageInput = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState([{ contact: "", id: 1 }]);
  const [display, setDisplay] = useState([{ contact: "", id: 1 }]);
  const UserId = window.sessionStorage.getItem("userid");
  const companyId = window.sessionStorage.getItem("compId");
  const roleId = window.sessionStorage.getItem("roleid");
  const teamid = window.sessionStorage.getItem("teamId");
  const memberId = window.sessionStorage.getItem("memberid");
  const roleName = window.sessionStorage.getItem("rolename");
  const [firstname, setFirstname] = useState("");
  const [subject, setSubject] = useState("Update Password");
  const [eventtype, setEventtype] = useState("Successfully Update Password");
  const [password, setPassword] = useState("");
  const [decodedPassword, setDecodedPassword] = useState("");
  const [lastname, setLastname] = useState("");
  const [contactCount, setContactCount] = useState(1);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [costHour, setCostHour] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactEndingdate, setContactEndingdate] = useState("");
  const [contactStartingdate, setContactStartingdate] = useState("");
  const [contactDescription, setContactDescription] = useState("");
  const [language, setLanguae] = useState([]);
  const [availability, setAvailability] = useState(false);
  const [userstate, setUserstate] = useState("1");

  const [availabilityvalue, setAvailabilityvalue] = useState();
  const [emailInvalid, setEmailInvalid] = useState(false);
  const inputFile = React.useRef(null);
  const initialRender = React.useRef(true);
  const initialRender2 = React.useRef(true);
  const [imgUrl, setImgurl] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [alottoggle, setAlottoggle] = useState(1);
  const [jobtitle, setJobtitle] = useState("");
  const [userId, setUserId] = useState("");
  const [isActive, setIsActive] = useState("");
  const updateUserData = useSelector((state) => state.user.updateUserData);
  const FetchUserByIddata = useSelector((state) => state.user.FetchUserById);
  // const teamid="08da2f31-46b4-4ce8-80b3-fdffac7a2010";

  const [contacts, setContacts] = useState([
    {
      description: "",
      startingdate: "",
      endingdate: "",
    },
  ]);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const Imagedlt = () => {
    setImgurl("");
  };

  React.useEffect(() => {
    dispatch(FetchUserById(memberId));
  }, []);
  const [role, setRole] = useState("owner");
  const [isDisabled, setDisabled] = useState(false);
  const ownref = useRef(null);

  const handleChange = (id, field, val) => {
    const modifiedContacts = contacts;
    modifiedContacts[id][field] = val;

    setContacts(modifiedContacts);
  };

  const handleClick = () => {
    setShow([...show, { contact: "", id: contactCount + 1 }]);
    setContactCount(contactCount + 1);
    setContactNumber(contactNumber + 1);

    const modifiedContacts = contacts;

    modifiedContacts.push({
      description: "",
      startingdate: "",
      endingdate: "",
    });

    setContacts(modifiedContacts);
  };

  const handleFileChange = (e) => {
    const target = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target);
    axios
      .post(`${API_URL}/api/Files/UploadToS3`, formData)
      .then(function (response) {
        if (response !== null) {
          setImgurl(response.data[0]);
        } else {
        }
      })
      .catch(function (error) { });
  };


  const [newStartDate, setNewStartDate] = useState([]);
  const [newEndDate, setNewEndDate] = useState("");
  const TodayDate = moment().format('YYYY-MM-DD');
  // console.log("today date", TodayDate);
  // console.log("starting date", newStartDate);
  // console.log("ending date", newEndDate);

  const handleAdd = (e) => {
    const now = new Date().getTime();
    console.log("today date", now);
    const isValid = contacts.filter(
      p => new Date(p.startingdate) <= now && new Date(p.endingdate) >= now
    );

    if (FetchUserByIddata.data[0]?.decodedPassword === decodedPassword) {
      dispatch(
        updateUserDetails(
          memberId,
          firstname,
          lastname,
          decodedPassword,
          phoneNumber,
          address1,
          costHour,
          notes,
          imgUrl,
          jobtitle,
          availability === true ? 1 : 0,
          userstate == availability == true && isValid.length > 0 ? 0 : 1,
          // userstate == availability == true && isValid.length !== -1 ? 0 : 1,
          // userstate == availability == true && isValid !== -1 ? 0 : 1,
          teamid,
          role,
          contacts
        )
      );
      // window.sessionStorage.setItem("image", imgUrl);

      setTimeout(() => {
        history.push("/team");
        window.location.reload();
      }, 1000);
    } else {
      // dispatch(emailSend(email, firstname));
      dispatch(
        updateUserDetails(
          memberId,
          firstname,
          lastname,
          decodedPassword,
          phoneNumber,
          address1,
          costHour,
          notes,
          imgUrl,
          jobtitle,
          availability == false ? 0 : 1,
          userstate == availability == false ? 1 : 0,
          teamid,
          role,
          contacts
        )
      );

      setTimeout(() => {
        history.push("/team");
        window.location.reload();
      }, 1000);
    }

    let userid = window.sessionStorage.getItem("userid");

    if (memberId == userid) {
      window.sessionStorage.setItem("image", imgUrl);
    }
    e.preventDefault();
  };

  // ASC JOB ROLE BORDER start {jobtitle === "Mechanic"}

  useEffect(() => {
    if (jobtitle === "Mechanic") {
      setIsActive("one");
    } else if (jobtitle === "Mechanic on site") {
      setIsActive("two");
    } else if (jobtitle === "Driver") {
      setIsActive("three");
    } else {
      setIsActive("four");
    }
  });

  // ASC JOB ROLE BORDER END

  useEffect(() => {

    // map for multiple dynamic data for starting and endingdate Start
    // let NewArrayDate = contacts.map((item) => {
    //   setNewStartDate(item?.startingdate)
    //   setNewEndDate(item?.endingdate);
    // });
    setContacts(contacts);

  }, [contacts]);

  console.log("All contacts data", contacts)

  useEffect(() => {
    setDisplay(show);
  }, [show]);

  const setGender = (event) => {
    setJobtitle(event.target.value);
  };

  let helper = [];

  React.useEffect(() => {
    if (!initialRender.current) {
      if (FetchUserByIddata != null) {
        if (FetchUserByIddata.status == true) {
          helper = FetchUserByIddata.data[0]?.contactdetails;
          // setSupplier(FetchUserByIddata.data);
          // setUserId(FetchUserByIddata.data[0]?.userid);
          setFirstname(FetchUserByIddata.data[0]?.firstname);
          setAddress1(FetchUserByIddata.data[0]?.address);
          setLastname(FetchUserByIddata.data[0]?.lastname);
          setPassword(FetchUserByIddata.data[0]?.password);
          setDecodedPassword(FetchUserByIddata.data[0]?.decodedPassword);
          setEmail(FetchUserByIddata.data[0]?.email);
          setPhoneNumber(FetchUserByIddata.data[0]?.phonenumber);
          setNotes(FetchUserByIddata.data[0]?.notes);
          setCostHour(FetchUserByIddata.data[0]?.costhour);
          setImgurl(FetchUserByIddata.data[0]?.photo);
          setRole(FetchUserByIddata.data[0]?.roleid);
          setJobtitle(FetchUserByIddata.data[0]?.jobtitle);
          // setJobtitle(FetchUserByIddata.data[0]?.jobtitle);
          if (FetchUserByIddata.data[0].isavailabilityblock === 1) {
            setAvailability(true);
          } else {
            // setAvailability(false);
          }

          let formatContacts = FetchUserByIddata.data[0].useravailabilities.map(
            (contact) => {
              return {
                description: contact?.description,
                // startingdate: moment(contact?.startingdate).format("DD-MM-YYYY"),

                startingdate: moment(contact?.startingdate).format(
                  "YYYY-MM-DD"
                ),
                endingdate: moment(contact?.endingdate).format("YYYY-MM-DD"),
              };
            }
          );
          setContacts(formatContacts);

          {
            FetchUserByIddata[0]?.useravailability.map(
              (item) => (
                setShow([...show, { contact: "", id: contactCount + 1 }]),
                setContactCount(contactCount + 1)
              )
            );
          }
        }
      }
    } else {
      initialRender.current = false;
    }
  }, [FetchUserByIddata]);

  // React.useEffect(() => {
  //   if (!initialRender2.current) {
  //     if (updateUserData != null) {
  //       if (updateUserData.status == true) {
  //         dispatch(emailSend(email, firstname));
  //         setTimeout(() => {
  //           history.push("/team");
  //           window.location.reload();
  //         }, 1000);
  //       }
  //     }
  //   } else {
  //     initialRender2.current = false;
  //   }
  // }, [updateUserData]);

  React.useEffect(() => {
    if (!initialRender2.current) {
      if (updateUserData != null) {
        if (updateUserData.status == true) {
          if (FetchUserByIddata.data[0].decodedPassword !== decodedPassword) {
            dispatch(emailSend(email, firstname, subject, eventtype));
            setTimeout(() => {
              history.push("/team");
              window.location.reload();
            }, 1000);
          }
        }
      }
    } else {
      initialRender2.current = false;
    }
  }, [updateUserData]);

  const handleAccess = (val) => {
    // const arr = [...roleModuleList]
    // arr[index]["accesslevelsid"] = val
    // setRoleModuleList(val)
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleChange2 = (event) => {
    setJobtitle(event.target.value);
  };

  const resetRadioState = () => {
    setJobtitle("");
  };

  return (
    <>
      <Header />
      <div className="container-fluid countFontclr pb-5">
        <div className="container px-0 px-sm-0 px-xl-4 px-md-4 px-lg-4">
          <div className="row px-5">
            <h3 className="py-3 ps-2 themecolor header-title-sk">  Team Member</h3>
          </div>
          <div className="row ps-4 pe-0 py-5">
            <div className="custCardCount">
              <div className="card cardHead">
                <div className="row px-5  ">
                  <h5 className="py-4 fntSupp28 text-sm-start text-center">
                    <span className="me-4">
                      <img src={teamIcon} className="" width={40} />
                    </span>
                    <span>Team Member</span>
                  </h5>
                </div>
              </div>
              <div
                className="card"
                style={{ borderRadius: "0px 0px 20px 20px" }}
              >
                <div className="row ms-4 ">
                  <div className="col-12 col-lg-7">
                    <div className="row mx-2">
                      <div className="col-12 col-lg-6">
                        <div>
                          <h3 className="mt-5 fntSupp1 ms-2">First Name</h3>
                          <input
                            className=" form-control suppInput mt-2 fntSupp4"
                            onChange={(e, value) =>
                              setFirstname(e.target.value)
                            }
                            type="text"
                            value={firstname}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div>
                          <h3 className="mt-5 fntSupp1">Last Name</h3>
                          <input
                            className=" form-control suppInput mt-2 fntSupp4"
                            onChange={(e, value) => setLastname(e.target.value)}
                            type="text"
                            value={lastname}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row ms-3 me-4 mt-1">
                      <div className="box-contactName mt-2 mt-xl-1 mx-2">
                        <div className="">
                          <span className="fntSupp12">Address</span>
                        </div>

                        <input
                          type="text"
                          className="fntSupp4"
                          onChange={(e) => setAddress1(e.target.value)}
                          value={address1}
                        />
                      </div>
                    </div>

                    <div className="row ms-3 mt-1">
                      {/* <div className="col-12 col-lg-4 ms-sm-4 me-sm-2 "> */}

                      <div className="col-12 col-lg-5">
                        <div className=" box-contactName mt-2 mt-xl-1 me-sm-3 me-xl-0">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp12">E-mail</span>
                          </div>

                          <input
                            type="text"
                            className="fntSupp4"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>

                        {/* <div className="  mt-2 mt-xl-1">
                                                        {emailInvalid == true && (
                                                            <div style={{ color: "red", fontSize: "14px" }}>
                                                                Email is invalid
                                                            </div>
                                                        )}
                                                    </div> */}
                      </div>

                      <div className="  col-12 col-lg-4   box-contactName mt-2 mt-xl-1">
                        <div className="d-flex align-items-start">
                          <span className="fntSupp12">Telephone</span>
                        </div>

                        <input
                          type="text"
                          className="fntSupp4"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          value={phoneNumber}
                        />
                      </div>
                      <div
                        className="col-12  col-lg-3  mt-2 mt-xl-1"
                      // style={{ width: "156px" }}
                      >
                        <div className="box-contactName ms-1 me-3">
                          <div className="d-flex align-items-start">
                            <span className="fntSupp12">Cost Hour</span>
                          </div>

                          <EuropeanInput
                            className="fntSupp4"
                            previewAlignment="start"
                            onChange={(newValue) => setCostHour(newValue)}
                            value={costHour}
                          />

                        </div>
                      </div>
                      <div className="rowm mt-4" style={{ width: "676px" }}>
                        <div className="box-contactName mt-1 mt-xl-5">
                          <div className="">
                            <span className="fntSupp12">Password</span>
                          </div>

                          <input
                            type={passwordType}
                            className="fntSupp4"
                            onChange={(e) => setDecodedPassword(e.target.value)}
                            value={decodedPassword}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      {passwordType === "password" ? (
                        <i
                          className="bi bi-eye-slash eyeicon"
                          onClick={togglePassword}
                        ></i>
                      ) : (
                        <img
                          src={eyeicon}
                          alt="eye"
                          className="eyeicon"
                          onClick={togglePassword}
                        />
                      )}
                    </div>
                    <div className=" mt-sm-5 pt-sm-0 ">
                      <div children="" className="Availabilitybox mx-3">
                        {" "}
                        <h5 className="addperiod">Availability</h5>
                        {contacts.length < contacts.length + 1 ? (
                          // <h6
                          //   className="addperiod"
                          //   onClick={handleClick}
                          //   style={{ cursor: "pointer" }}
                          // >
                          //   Add Period...
                          // </h6>
                          <button
                            // type="button"
                            className={
                              availability == ""
                                ? // ? "countBtnchange1 p-2"
                                // : "countBtnchange p-2"

                                // for ADD period
                                "addperiod1 p-1"
                                : "addperiod2 p-1"
                            }
                            onClick={handleClick}
                            disabled={availability == ""}
                          >
                            Add Period...
                          </button>
                        ) : (
                          " "
                        )}
                      </div>
                      <div
                        // key={item.phonenumber}
                        className="row ms-sm-4 me-sm-2 availabilitybox"
                      >
                        <div className="col-4 ">
                          <input
                            type="checkbox"
                            id="switch1"
                            className="checkbox"
                            checked={availability}
                            onChange={() => {
                              setAvailability(!availability);
                            }}
                          />
                          <label for="switch1" className="toggle">
                            {/* <p className='text-center mt-2 textClrSign'></p> */}
                          </label>
                        </div>

                        <div className=" col-4 py-1 text-center">
                          <p className="text-muted">
                            <span>
                              <img
                                src={calender}
                                className="calenOrder"
                                alt=""
                              />
                            </span>
                            Starting Date
                          </p>
                        </div>
                        <div className=" col-4 py-1 text-start">
                          <p className="text-muted">
                            <span>
                              <img
                                src={calender}
                                className="calenOrder"
                                alt=""
                              />
                            </span>
                            Ending Date
                          </p>
                        </div>

                        {contacts
                          .sort(
                            (a, b) => {
                              return (
                                // new Date(b.startingdate) - new Date(a.startingdate)
                                Math.abs(Date.now() - new Date(a.startingdate)) - Math.abs(Date.now() - new Date(b.startingdate))
                              )
                            }
                            // item.startingdate &&
                            // item.endingdate
                          )
                          .map((item, index) => (
                            <div className=" row mb-1 pe-0" key={index}>
                              <div className=" col-5 ">
                                <div className=" box-contactName me-1">
                                  <input
                                    type="text"
                                    className=" fntSupp4 "
                                    onChange={(e) => {
                                      handleChange(
                                        index,
                                        "description",
                                        e.target.value
                                      );
                                      setContactDescription(e.target.value);
                                    }}
                                    value={item.description}
                                    disabled={availability == false}
                                  />
                                  {/* <h4 className="vacations box-contactName py-1">Vacations </h4> */}
                                </div>
                              </div>
                              <div className=" col-3 numcolor">
                                <div className=" box-contactName me-1">
                                  <input
                                    type="date"
                                    className="fntSupp"
                                    onChange={(e) => {
                                      handleChange(
                                        index,
                                        "startingdate",
                                        e.target.value
                                      );
                                      setContactStartingdate(e.target.value);
                                      // setNewStartDate(item?.startingdate);
                                    }}
                                    value={item?.startingdate}
                                    disabled={availability == false}
                                  />
                                </div>
                                {/* <p className=" box-contactName vacations py-1">01.08.2022</p> */}
                              </div>
                              <div className="numcolor col-3 ">
                                {/* <p className=" box-contactName py-1">01.08.2022</p> */}
                                <div className=" box-contactName me-1">
                                  <input
                                    type="date"
                                    className="fntSupp"
                                    onChange={(e) => {
                                      handleChange(
                                        index,
                                        "endingdate",
                                        e.target.value
                                      );
                                      setContactEndingdate(e.target.value);
                                      // setNewEndDate(item?.endingdate);
                                    }}
                                    value={item?.endingdate}
                                    disabled={availability == false}
                                  />
                                </div>
                              </div>
                              <div className="col-1  pt-1">
                                <button
                                  onClick={() => {
                                    let data = [...contacts];
                                    data.splice(index, 1);
                                    setContacts(data);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  width={15}
                                  disabled={availability == false}
                                  className="deletebtn"
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* <div className="">
                      <div className=" ps-4 ps-lg-0">
                        <h3 className="mt-4 fntSupp1">Notes</h3>
                        <div className="pe-5">
                          <textarea
                            className="form-control notesForm1 mt-2 fntSupp45"
                            onChange={(e) => setNotes(e.target.value)}
                            rows="14"
                            value={notes}
                          ></textarea>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-12 col-lg-5">
                    {/* <div className="row">
                                        </div> */}

                    <div className="row ps-4 ps-lg-0 mt-3 teamdaddres">
                      {/* <h3 className="mt-5 fntSupp3">Notes</h3> */}
                      {/* <Image roundedCircle src={Add1}></Image> */}
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="col-12 text-xl-center text-lg-center text-md-center col-xl-12 col-md-12 col-lg-12 pb-4 ps-xxl-3 ps-xl-5 ps-md-3 ps-sm-3">
                          {imgUrl == "" ? (
                            <div>
                              <button
                                className="signUpldbtn text-center mt-4 hide fw-bold ms-xxl-4"
                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-5 rounded-circle"
                              className={
                                isActive == "four"
                                  ? " ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5  rounded-circle default-img-border mt-4  "
                                  : " ms-xxl-4 ms-xl-0 rounded-circle hide"
                              }
                              alt=""
                            />
                          )}

                          {imgUrl == "" ? (
                            <div>
                              <button
                                className="signUpldbtn text-center  mt-4 fw-bold ms-xxl-4"
                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-5 rounded-circle"
                              className={
                                isActive == "one"
                                  ? " ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5 rounded-circle Mechanicimgborder mt-4 "
                                  : "ms-xxl-4 ms-xl-0 one rounded-circle hide"
                              }
                              alt=""
                            />
                          )}

                          {imgUrl == "" ? (
                            <div>
                              <button
                                className={
                                  isActive == "two"
                                    ? "hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                    : "two hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                }

                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-5 rounded-circle"
                              className={
                                isActive == "two"
                                  ? " ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5 rounded-circle Mechaniconsiteimgborder mt-4 "
                                  : "ms-xxl-4 ms-xl-0 two rounded-circle hide"
                              }
                              alt=""
                            />
                          )}

                          {imgUrl == "" ? (
                            <div>
                              <button
                                className={
                                  isActive == "three"
                                    ? "hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                    : "three hide signUpldbtn text-center mt-4 fw-bold ms-xxl-4"
                                }
                                onClick={onButtonClick}
                              >
                                +<br />
                                {language.addPhoto}
                              </button>
                            </div>
                          ) : (
                            <img
                              src={imgUrl}
                              width="160px"
                              onClick={onButtonClick}
                              height="160px"
                              // className="mt-5 rounded-circle"
                              className={
                                isActive == "three"
                                  ? "  ms-xxl-4 ms-xl-0 show ms-xl-0 ms-xxl-5 rounded-circle driverimgborder mt-4 "
                                  : " ms-xxl-4 ms-xl-0 three rounded-circle hide"
                              }
                              alt=""
                            />
                          )}
                          <input
                            type="file"
                            id="file"
                            onChange={(e) => {
                              handleFileChange(e);
                            }}
                            ref={inputFile}
                            placeholder=""
                            className="custom-file-input ms-3"
                          />
                          <div className="d-flex ms-xxl-3 ms-xl-3 ms-lg-2 ms-sm-5 ps-xl-0 ps-lg-0 ps-md-3 ps-sm-0">
                            <p className="text-muted ms-xxl-5 ms-xl-0 ms-lg-0 ms-sm-0 ps-xl-0 ps-lg-0 ps-md-0 ps-sm-0 replacepic picres">
                              Replace Picture
                            </p>
                            {/* <img
                              alt="Delete"
                              src={Group62}
                              height="25px"
                              width="25px"
                              // onClick={onButtonClick}
                              className="ms-2 imgdelete"
                            /> */}

                            {/* <input type='file' id='file' ref={inputFile} onChange={(e) => { handleFileChange(e); }} style={{ display: 'none' }} /> */}

                            <img
                              type="button"
                              className="ps-2 dlticon"
                              alt="User"
                              src={Group62}
                              onClick={Imagedlt}
                              // onClick={() => {
                              //   dispatch(fetchSuppliersById(item.supplierid));
                              // }}
                              // onClick={() => {
                              //   setStatusPopup(true);
                              //   setSupplierId(item.supplierid);
                              // }}
                              style={{
                                cursor: "pointer",
                                marginBottom: "15px",
                              }}
                            />
                          </div>
                          {/* <input type='file' id='file' ref={inputFile} onChange={(e) => { handleFileChange(e); }} style={{ display: 'none' }} /> */}
                        </div>

                        {/* <img
                                                            type="button"
                                                            className="ps-2 dlticon"
                                                            alt="User"
                                                            src={Group62}
                                                            onClick={() => {
                                                              dispatch(fetchSuppliersById(item.supplierid));
                                                            }}
                                                            onClick={() => {
                                                              setStatusPopup(true);
                                                              setSupplierId(item.supplierid);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        /> */}
                      </div>

                      <div className="col-lg-6 col-md-6 col-xl-6 col-sm-6 mt-5 jobtitle">
                        <p className="text-muted">Select Job Title</p>
                        <div
                          onChange={(e) => {
                            setGender(e);
                          }}
                        >
                          <div className="form-check ">
                            <input
                              style={{ cursor: "pointer" }}
                              className="form-check-input red-color"
                              type="radio"
                              value="Mechanic"
                              name="Mechanic"
                              id="switch2"
                              checked={jobtitle === "Mechanic"}
                              onClick={() => {
                                setIsActive("one");
                                setJobtitle("");
                              }}
                              onChange={handleChange2}
                            />
                            <label className="form-check-label" for="switch2">
                              Mechanic
                            </label>
                          </div>

                          <div className="form-check ">
                            <input
                              style={{ cursor: "pointer" }}
                              className="form-check-input blue-color"
                              type="radio"
                              value="Mechanic on site"
                              name="Mechaniconsite"
                              id="switch3"
                              checked={jobtitle === "Mechanic on site"}
                              onClick={() => {
                                setIsActive("two");
                                setJobtitle("");
                              }}
                              onChange={handleChange2}
                            />
                            <label className="form-check-label" for="switch3">
                              Mechanic on site
                            </label>
                          </div>

                          <div className="form-check ">
                            <input
                              style={{ cursor: "pointer" }}
                              className="form-check-input orange-color"
                              type="radio"
                              value="Driver"
                              name="Driver"
                              id="switch4"
                              checked={jobtitle === "Driver"}
                              onClick={() => {
                                setIsActive("three");
                                setJobtitle("");
                              }}
                              onChange={handleChange2}
                            />
                            <label className="form-check-label" for="switch4">
                              Driver
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5 mt-lg-3 mt-xl-5"></div>
                    <div className="row mt-xl-0 mt-xxl-0 mt-lg-3 pt-2"></div>

                    <div className=" ms-xl-4 ms-lg-4 ms-md-4 ms-sm-0 px-xl-2 px-lg-2 px-md-2 px-sm-0">
                      <div className="row text-center signtogbt menuControl ms-xl-4 ms-lg-4 ms-md-4 ms-sm-0 mb-0 mb-0">
                        <div className="col-4 p-0">
                          {roleName === "Manager" ? (
                            <button
                              type="button"
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4d"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Owner
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setRole("08da2f30-beca-4c07-8e42-1311289b9e4d");
                              }}
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4d"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Owner
                            </button>
                          )}
                        </div>
                        <div className="col-4 p-0">

                          {roleName === "Manager" ? (
                            <button
                              type="button"
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4d"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Manager
                            </button>
                          ) : (

                            <button
                              type="button"
                              onClick={() => {
                                setRole("08da2f30-beca-4c07-8e42-1311289b9e4c");
                              }}
                              className={
                                role === "08da2f30-beca-4c07-8e42-1311289b9e4c"
                                  ? " py-2 px-0  signtogbtn1"
                                  : " py-2 px-0  signtogbtn"
                              }
                            >
                              Manager
                            </button>
                          )}
                        </div>
                        <div className="col-4 p-0">
                          <button
                            type="button"
                            onClick={() => {
                              setRole("08da2f30-beca-4c07-8e42-1311289b9e4b");
                            }}
                            className={
                              role === "08da2f30-beca-4c07-8e42-1311289b9e4b"
                                ? " py-2  px-0 signtogbtn1"
                                : " py-2  px-0 signtogbtn"
                            }
                          >
                            User
                          </button>
                        </div>
                      </div>
                      <div className="">
                        <div className="ms-2 ps-5 ps-lg-3 me-5 mt-5 pt-1">
                          <h3 className="pt-4 fntSupp1">Notes</h3>
                          <div className="pe-2">
                            <textarea
                              className="form-control notesForm1 mt-2 fntSupp45 notes"
                              onChange={(e) => setNotes(e.target.value)}
                              value={notes}
                              rows="14"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}

                  {/*  */}
                </div>

                <div className="row ps-4 ms-1 pe-5  pb-3 ">
                  <div className="col-12 col-lg-4 col-md-0 col-sm-0 col-xl-4 ">
                    <div className="row"></div>
                  </div>
                  <div className="col-12 mt-5 col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="row  justify-content-end">
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className="countBtncanc w-100 h-100 p-2"
                          style={{ fontSize: "15px" }}
                          onClick={() => history.push("/team")}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-3 col-md-3 my-1 col-xl-3">
                        <button
                          type="button"
                          className={
                            firstname == "" ||
                              lastname == "" ||
                              decodedPassword == "" ||
                              address1 == "" ||
                              email == "" ||
                              phoneNumber == "" ||
                              // for second Field
                              costHour == "" ||
                              jobtitle == ""
                              ? "countBtnchange1 w-100 p-2"
                              : "countBtnchange w-100 h-100 p-2"
                          }
                          onClick={(e) => {
                            handleAdd(e);
                          }}

                          disabled={
                            firstname == "" ||
                            lastname == ""
                            // jobtitle == ""
                            //     address1 == "" ||
                            //     address2 == "" ||
                            //     email == "" ||
                            //     phoneNumber == ""
                            //      ||
                            //     for second Field
                            //     contactName == "" ||
                            //     contactEmail == ""
                            //     notes == ""
                          }
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateTeamUser;
