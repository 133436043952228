const initialstate = {
    state:[]
}


const letReducer = (state= initialstate,action) =>{
    
    return state

}

export default letReducer;